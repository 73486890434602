import { useDispatch } from 'react-redux';
import commonService from '../services/common.service';
import { setCommonItems } from '../store/common/slice';
import { useEffect } from 'react';

export function useGetAndStoreCommonItems() {
  const dispatch = useDispatch();

  const getCities = async () => {
    const cities = await commonService.getCities();
    dispatch(setCommonItems({ items: cities, name: 'cities' }));
  };
  const getCounties = async () => {
    const counties = await commonService.getCounties();
    dispatch(setCommonItems({ items: counties, name: 'counties' }));
  };
  const getVillages = async () => {
    const villages = await commonService.getVillages();
    dispatch(setCommonItems({ items: villages, name: 'villages' }));
  };
  const getItemTypes = async () => {
    const itemTypes = await commonService.getItemTypes();
    dispatch(setCommonItems({ items: itemTypes, name: 'itemTypes' }));
  };
  const getGarbage = async () => {
    const garbage = await commonService.getGarbage();
    dispatch(setCommonItems({ items: garbage, name: 'garbage' }));
  };
  const getGarbageGroups = async () => {
    const garbageGroups = await commonService.getGarbageGroups();
    dispatch(setCommonItems({ items: garbageGroups, name: 'garbageGroups' }));
  };

  useEffect(() => {
    getCities();
    getCounties();
    getVillages();
    getItemTypes();
    getGarbage();
  }, []);
}
