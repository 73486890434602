import { baseApi, UNLOADING_KEY } from '@/store/baseApi';
import { getUnloadPoints } from '.';
import { UnloadPoint } from '@ekt-group/general-purpose-api-interfaces';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [UNLOADING_KEY],
});

export const unloadingApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnloadPoints: builder.query<UnloadPoint[], number>({
      queryFn: async (routeGroupItemId) => {
        const data = await getUnloadPoints(routeGroupItemId);
        return {
          data,
        };
      },
      providesTags: [UNLOADING_KEY],
    }),
  }),
});

export const { useGetUnloadPointsQuery } = unloadingApi;
