import { YEAR_TIME_IN_SECONDS } from '@/helpers/dateHelper';

export interface UseImageCompressionOptions {
  onSuccess: (file: File, decodedImage?: string) => void;
  onError: (error: Error) => void;
  printTimestamp?: boolean;
  printAdditionalInfo?: string[];
}

const MAX_IMAGE_WIDTH = 1920;
const MAX_IMAGE_HEIGHT = 1080;
const IMAGE_QUALITY = 0.7;

const getImageTimestamp = (lastImageModifiedValue: number) => {
  const now = new Date();

  if (!lastImageModifiedValue) {
    return now;
  }

  const lastModifiedDate = new Date(lastImageModifiedValue);

  if (Math.abs(now.getTime() - lastModifiedDate.getTime()) < YEAR_TIME_IN_SECONDS) {
    return lastModifiedDate;
  }

  return now;
};

const drawOnCanvas = (values: string[]) => (context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
  context.font = '20px Arial';

  const padding = 10;
  const rectHeight = 25 + padding;
  const xOffset = 40;
  const yOffset = 20;

  let totalHeight = 0;

  values.forEach((value) => {
    const textWidth = context.measureText(value).width;

    context.fillStyle = '#fff';
    context.fillRect(
      canvas.width - textWidth - padding * 2 - xOffset,
      canvas.height - rectHeight - yOffset - totalHeight,
      textWidth + padding * 2,
      rectHeight,
    );

    context.fillStyle = '#000';
    context.fillText(value, canvas.width - textWidth - padding - xOffset, canvas.height - padding - yOffset - totalHeight);

    totalHeight += rectHeight;
  });
};

function getOrientation(file: Blob, callback: (orientation: number, image: ArrayBuffer) => void): void {
  const reader = new FileReader();

  reader.onload = (event: ProgressEvent<FileReader>) => {
    const dataView = new DataView(event.target.result as ArrayBuffer);
    const marker = dataView.getUint16(0, false);

    if (marker !== 0xffd8) {
      return callback(-2, event.target.result as ArrayBuffer);
    }

    try {
      const length = dataView.byteLength;
      let offset = 2;

      while (offset < length) {
        const currentMarker = dataView.getUint16(offset, false);
        offset += 2;

        if (currentMarker === 0xffe1) {
          const exifString = dataView.getUint32(offset + 2, false);
          if (exifString !== 0x45786966) {
            return callback(-1, event.target.result as ArrayBuffer);
          }

          const littleEndian = dataView.getUint16(offset + 6, false) === 0x4949;
          offset += dataView.getUint32(offset + 4, littleEndian);

          const tagsCount = dataView.getUint16(offset, littleEndian);
          offset += 2;

          for (let i = 0; i < tagsCount; i++) {
            if (dataView.getUint16(offset + i * 12, littleEndian) === 0x0112) {
              return callback(dataView.getUint16(offset + i * 12 + 8, littleEndian), event.target.result as ArrayBuffer);
            }
          }
        } else if ((currentMarker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += dataView.getUint16(offset, false);
        }
      }
      return callback(-1, event.target.result as ArrayBuffer);
    } catch (error) {
      return callback(-1, event.target.result as ArrayBuffer);
    }
  };

  reader.readAsArrayBuffer(file);
}
export function convertBase64ToFile(srcEncoded: string, fileName: string, modifiedDate: number) {
  const binaryString = atob(srcEncoded.replace(/^data:image\/(png|jpeg|jpg|heif);base64,/, ''));

  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type: 'image/jpeg' });

  return new File([blob], fileName, { type: 'image/jpeg', lastModified: modifiedDate });
}

export function manualImageCompression(
  imageFile: File,
  { onSuccess, onError, printTimestamp, printAdditionalInfo = [] }: UseImageCompressionOptions,
) {
  try {
    getOrientation(imageFile, (orientation, readResult) => {
      const resizeWidth = 1000;
      const blob = new Blob([readResult], { type: imageFile.type });
      const image = new Image();

      image.src = URL.createObjectURL(blob);

      image.onload = function (event) {
        const canvas = document.createElement('canvas');

        const scaleFactor = resizeWidth / (event.target as HTMLImageElement).width;
        canvas.width = resizeWidth;
        canvas.height = (event.target as HTMLImageElement).height * scaleFactor;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(event.target as HTMLImageElement, 0, 0, canvas.width, canvas.height);

        const width = canvas.width;
        const height = canvas.height;

        if (orientation > 1) {
          if (4 < orientation && orientation < 9) {
            canvas.width = height;
            canvas.height = width;
          } else {
            canvas.width = width;
            canvas.height = height;
          }
          // set proper canvas dimensions before transform & export
          switch (orientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, width, 0);
              break;
            case 3:
              ctx.transform(-1, 0, 0, -1, width, height);
              break;
            case 4:
              ctx.transform(1, 0, 0, -1, 0, height);
              break;
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              ctx.transform(0, 1, -1, 0, height, 0);
              break;
            case 7:
              ctx.transform(0, -1, -1, 0, height, width);
              break;
            case 8:
              ctx.transform(0, -1, 1, 0, 0, width);
              break;
            default:
              break;
          }

          if (4 < orientation && orientation < 9) {
            ctx.drawImage(image, 0, 0, canvas.height, canvas.width);
          } else {
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          }
        }

        const printOnImage = [...printAdditionalInfo];

        if (printTimestamp) {
          const modifiedDate = getImageTimestamp(imageFile.lastModified).toLocaleString('et', {
            hour12: false,
            timeZone: 'Europe/Tallinn',
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          });

          printOnImage.unshift(modifiedDate);
        }

        if (printOnImage.length) {
          drawOnCanvas(printOnImage.reverse())(ctx, canvas);
        }

        const srcEncoded = canvas.toDataURL('image/jpeg', IMAGE_QUALITY);

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.restore();

        onSuccess(convertBase64ToFile(srcEncoded, imageFile.name, imageFile.lastModified), srcEncoded);
      };
    });
  } catch (error) {
    onError(error as Error);
  }
}
