import { useLiveQuery } from 'dexie-react-hooks';
import { UnloadRequest, UnloadRequestType, unloadsRequestsDb } from '../database';
import { useProcessor } from './useProcessor';
import { useOfflineStatus } from '../contexts/offlineStatusContext';
import { useCreateWaypointUnloadMutation, useDeleteWaypointUnloadMutation, useUpdateWaypointUnloadMutation } from '@/features/route';
import { WaypointUnloadDeleteDto } from '@/services/discharge-waypoints.service';
import { useAppDispatch } from '@/store/types';
import { setCreatedWaypointResult } from '@/features/unloading';
import { WaypointUnloadCreateDto, WaypointUnloadUpdateDto } from '@ekt-group/general-purpose-api-interfaces';

export function useUnloadsRequestsProcessor() {
  const requests = useLiveQuery(() => unloadsRequestsDb.requests.toArray());
  const isOffline = useOfflineStatus();

  const [createWaypointUnload] = useCreateWaypointUnloadMutation();
  const [updateWaypointUnload] = useUpdateWaypointUnloadMutation();
  const [deleteWaypointUnload] = useDeleteWaypointUnloadMutation();
  const dispatch = useAppDispatch();

  const processRequest = async ({ type, body, isEditing, timestamp, id }: UnloadRequest) => {
    if (isOffline || isEditing) {
      return;
    }

    switch (type) {
      case UnloadRequestType.Create: {
        const returnPayload = await createWaypointUnload(body as WaypointUnloadCreateDto).unwrap();
        // dispatch(setCreatedWaypointResult(returnPayload));
        break;
      }
      case UnloadRequestType.Update: {
        await updateWaypointUnload(body as WaypointUnloadUpdateDto & { id: number }).unwrap();
        break;
      }
      case UnloadRequestType.Delete: {
        await deleteWaypointUnload(body as WaypointUnloadDeleteDto).unwrap();
        break;
      }
      default: {
        throw new Error(`Unknown request type: ${type}`);
      }
    }
  };

  const deleteRequest = async (requestId: number) => {
    const request = requests.find((r) => r.id === requestId);
    if (request?.isEditing) {
      return;
    }
    return unloadsRequestsDb.requests.delete(requestId);
  };

  useProcessor<UnloadRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing unload request failed',
  });
}
