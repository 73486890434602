import React, { useState, useEffect, useContext, PropsWithChildren } from 'react';

const offlineStatusContext = React.createContext(false);

export const OfflineStatusProvider = ({ children }: PropsWithChildren) => {
  const [offlineStatus, setOfflineStatus] = useState<boolean>(!navigator.onLine);

  const offlineHandler = () => {
    setOfflineStatus(true);
  };
  const onlineHandler = () => {
    setOfflineStatus(false);
  };

  useEffect(() => {
    window.addEventListener('offline', offlineHandler);
    window.addEventListener('online', onlineHandler);

    return () => {
      window.removeEventListener('offline', offlineHandler);
      window.removeEventListener('online', onlineHandler);
    };
  }, []);

  return <offlineStatusContext.Provider value={offlineStatus}>{children}</offlineStatusContext.Provider>;
};

export const useOfflineStatus = () => {
  const context = useContext(offlineStatusContext);
  if (context === undefined) {
    throw new Error('useOnlineStatus must be used within a OfflineStatusProvider');
  }
  return context;
};
