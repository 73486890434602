import { useDispatch, useSelector } from 'react-redux';
import { setCurrentGarbageIds, setObjectGates } from '@/store/waypoints/slice';
import { useCallback, useEffect } from 'react';
import {
  selectGetWaypointsCache,
  useGetWaypointsQuery,
  GetWaypointsDto,
  waypointsApi,
  updateWaypointsRTKCache,
  updateServiceWaypointsRTKCache,
} from '@/features/route';
import { selectCurrentRouteGroupItem, WaypointType } from '@/features/home';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { SocketEventData } from '@ekt-group/general-purpose-api-interfaces';
import { WaypointsServiceStatusRequest } from '@/database/waypoints-service-status-requests.db';
import { WaypointsStatusRequest } from '@/database';

interface UseWaypointsRequestProps {
  waypointIds?: number[];
}

export function useWaypointsRequest({ waypointIds }: UseWaypointsRequestProps = {}) {
  const dispatch = useAppDispatch();
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  const payload = {
    routeGroupItemIds: [currentRouteGroupItem?.id] || [],
    isDischargeSheet: currentRouteGroupItem?.isDischargeSheet || false,
    waypointIds: waypointIds || [],
  };

  const { data: cachedData } = useAppSelector(selectGetWaypointsCache(payload));

  const { data, currentData, isLoading, isFetching, refetch } = useGetWaypointsQuery(payload, {
    skip: !currentRouteGroupItem?.id && !waypointIds?.length,
  });

  // change garbageids and objectGates everytime new waypoints are fetched
  useEffect(() => {
    const garbageIds =
      data &&
      data.reduce<number[]>((acc, item) => {
        const garbageId = item.objectItem?.garbageId;
        if (!garbageId || acc.includes(garbageId)) {
          return acc;
        }
        return [...acc, garbageId];
      }, []);

    dispatch(setCurrentGarbageIds(garbageIds));
    data && dispatch(setObjectGates(data));
  }, [data, dispatch]);

  const updateGetWaypointsRTKQuery = useCallback(
    (
      data: WaypointsStatusRequest<any> | WaypointsServiceStatusRequest,
      originalArgs: GetWaypointsDto,
      type: WaypointType.Discharge | WaypointType.Service,
    ) => {
      dispatch(
        waypointsApi.util.updateQueryData('getWaypoints', originalArgs, (draft) => {
          if (type === WaypointType.Discharge) {
            updateWaypointsRTKCache(data as WaypointsStatusRequest<any>, draft);
            return;
          }
          updateServiceWaypointsRTKCache(data as WaypointsServiceStatusRequest, draft);
        }),
      );
    },
    [dispatch],
  );

  return { data, cachedData, currentData, refetch, isLoading, isFetching, updateGetWaypointsRTKQuery };
}
