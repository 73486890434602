import { historicalCompletedOrdersDb } from '@/database/historical-completed-orders.db';
import {
  CompletedRouteOrder,
  HistoricalRoutesMatchItem,
  useLazyGetCompletedRouteOrderQuery,
  useLazyGetHistoricalRoutesMatchQuery,
} from '@/features/home';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useHistorical = () => {
  const [
    getHistoricalRoutesMatch,
    { data: historicalMatchedItems, isLoading: isLoadingHistoricalMatch, isFetching: isFetchingHistoricalMatch },
  ] = useLazyGetHistoricalRoutesMatchQuery();

  const [completedOrder, setCompletedOrder] = useState<CompletedRouteOrder>(null);
  const [getCompletedRouteOrder, { data, isLoading: isLoadingCompletedOrder, isFetching: isFetchingCompletedOrder }] =
    useLazyGetCompletedRouteOrderQuery();
  const completedOrdersFromDb = useLiveQuery(() => historicalCompletedOrdersDb.orders.toArray());

  const getCompletedOrder = useCallback(
    async (selectedRouteGroupItemId: number) => {
      if (!completedOrdersFromDb) {
        return;
      }

      const storedOrder = completedOrdersFromDb?.find(({ routeGroupItemId }) => routeGroupItemId === selectedRouteGroupItemId);

      if (storedOrder) {
        const { routeGroupItemId, order, routeCoordinates } = storedOrder;
        setCompletedOrder({ routeGroupItemId, order, routeCoordinates });
        return;
      }

      const { data: completedOrder } = await getCompletedRouteOrder(selectedRouteGroupItemId);
      if (completedOrder) {
        const order = completedOrder[0];

        await historicalCompletedOrdersDb.orders.add(order);
        setCompletedOrder(order);
      }
    },
    [completedOrdersFromDb, getCompletedRouteOrder],
  );

  return {
    getHistoricalRoutesMatch,
    historicalMatchedItems,
    isLoadingHistoricalMatch,
    isFetchingHistoricalMatch,
    isLoadingCompletedOrder,
    isFetchingCompletedOrder,
    completedOrdersFromDb,
    getCompletedOrder,
    completedOrder,
  };
};
