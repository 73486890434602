import { createSlice } from '@reduxjs/toolkit';
import { EmployeeData } from '../types';
import { AppState } from '@/store';

export interface AuthState {
  user: EmployeeData | null;
  isLoading: boolean;
  isAlcoholCheckPassed: boolean;
  isAutomaticAlcoholCheckCompleted: boolean;
}

const initialState: AuthState = {
  user: null,
  isLoading: false,
  isAlcoholCheckPassed: false,
  isAutomaticAlcoholCheckCompleted: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserState(state, action) {
      state.user = action.payload;
    },
    setIsLoadingState(state, action) {
      state.isLoading = action.payload;
    },
    setIsAlcoholCheckPassed(state, action) {
      state.isAlcoholCheckPassed = action.payload;
    },
    setIsAutomaticAlcoholCheckCompleted(state, action) {
      state.isAutomaticAlcoholCheckCompleted = action.payload;
    },
    resetAuthState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { setUserState, setIsLoadingState, setIsAlcoholCheckPassed, setIsAutomaticAlcoholCheckCompleted, resetAuthState } =
  authSlice.actions;

export const selectUserState = (state: AppState) => state.auth.user;
export const selectIsLoadingState = (state: AppState) => state.auth.isLoading;
export const selectUserId = (state: AppState) => state.auth.user?.id;
export const selectUserProfessionId = (state: AppState) => state.auth.user?.profession?.id;
export const selectIsAlcoholCheckPassed = (state: AppState) => state.auth.isAlcoholCheckPassed;
export const selectIsAutomaticAlcoholCheckCompleted = (state: AppState) => state.auth.isAutomaticAlcoholCheckCompleted;

export default authSlice.reducer;
