import { cn } from '@/utils/cn';
import React, { MouseEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { WaypointListFilteringMenuForm, WaypointListFormData } from './WaypointListFilteringMenuForm';
import { createPortal } from 'react-dom';
import FilterIcon from '@/components/icons/FilterIcon';
import { isEmptyObject } from '@/utils/isEmptyObject';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { Feature } from '@/types/features';

interface WaypointListFilteringMenuProps {
  onFilterSubmit: (val: WaypointListFormData) => void;
  onFilterButtonClicked: () => void;
  isFilterMenuVisible: boolean;
  filterMenuRef: React.RefObject<HTMLDivElement>;
  filterButtonRef: React.RefObject<HTMLButtonElement>;
}

export const WaypointListFilteringMenu = ({
  onFilterSubmit,
  isFilterMenuVisible,
  onFilterButtonClicked,
  filterMenuRef,
  filterButtonRef,
}: WaypointListFilteringMenuProps) => {
  const [isFilterDataExists, setIsFilterDataExists] = React.useState(false); // Used for changing the color of the icon only

  const { isFeatureEnabled } = useFeatureFlags();

  const { t } = useTranslation();

  const handleFilterButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (!isFeatureEnabled(Feature.WaypointsFilters)) {
      return;
    }

    onFilterButtonClicked && onFilterButtonClicked();
  };

  const handleFilterSubmit = (value: WaypointListFormData) => {
    const isFilterDataExists = !isEmptyObject(value);
    setIsFilterDataExists(isFilterDataExists);

    onFilterSubmit && onFilterSubmit(value);
  };

  return (
    <>
      <button
        ref={filterButtonRef}
        className={cn(
          'flex items-center justify-center rounded-md px-2 py-1 ',
          'dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500',
        )}
        onClick={handleFilterButtonClick}
        role="filter-button"
      >
        <FilterIcon
          size="md"
          className={cn({
            'text-red-500': isFilterDataExists,
          })}
        />
      </button>
      {createPortal(
        <div
          ref={filterMenuRef}
          className={cn(
            'fixed rounded-sm top-16 bottom-0 right-0 px-4 z-[30] bg-white text-black dark:bg-gray-800 shadow-[0px_1px_13px_0px_rgba(0,0,0,0.78)] dark:shadow-[0px_1px_13px_0px_rgba(255,255,255,0.78)] flex-col pt-3',
            'h-[calc(100%_-_125x)] overflow-y-auto w-5/6 md:w-3/4 lg:w-2/4',
            { visible: isFilterMenuVisible, invisible: !isFilterMenuVisible },
          )}
        >
          <WaypointListFilteringMenuForm onSubmit={handleFilterSubmit} />
        </div>,
        document.body,
      )}
    </>
  );
};
