import React from 'react';
import { ColorType } from '../../types/common';

interface Props {
  progress: number;
  total: number;
  color?: Partial<ColorType>;
  doneLabel?: string | JSX.Element;
  notDoneLabel?: string | JSX.Element;
}

const colors: Partial<Record<ColorType, string>> = {
  success: 'bg-green-300 dark:bg-green-600',
  primary: 'bg-blue-300 dark:bg-blue-600',
};

const ProgressBar = ({ progress, total, doneLabel, notDoneLabel, color = 'success' }: Props) => {
  const percent = Math.round((progress / total) * 100);

  return (
    <div
      className={
        'h-6 bg-gray-200 dark:bg-gray-600 w-full rounded-lg flex items-center overflow-hidden text-gray-900 dark:text-gray-100 relative'
      }
    >
      <div className={`${colors[color]} h-full text-center text-white overflow-hidden`} style={{ width: `${percent}%` }} />
      <div style={{ width: `${100 - percent}%` }} />

      <span className={'absolute left-2'}>{doneLabel && doneLabel}</span>
      <span className={'absolute right-2'}>{notDoneLabel && notDoneLabel}</span>
    </div>
  );
};

export default ProgressBar;
