export interface IconSizeProps {
  size: 'xs' | 'sm' | 'md' | 'lg';
}

export interface IconProps extends IconSizeProps {
  className?: string;
  onClick?: () => void;
}

export const iconSizeClasses = {
  xs: 'icon-xs',
  sm: 'icon-sm',
  md: 'icon-md',
  lg: 'icon-lg',
};
