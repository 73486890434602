import React from 'react';
import { useOfflineStatus } from '../../contexts/offlineStatusContext';
import WifiIcon from '../icons/WifiIcon';
import GeolocationIcon from '../icons/GeolocationIcon';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useSelector } from 'react-redux';
import { selectMode } from '../../store/settings/slice';
import { AppMode } from '../../types/common';
import AlcoholCheckIndicator from './AlcoholCheckIndicator';
import useGeolocation from 'react-hook-geolocation';
import { cn } from '@/utils/cn';
import SocketIcon from '../icons/SocketIcon';

interface HeaderIndicatorsProps {
  alcoholSpanRef?: React.RefObject<HTMLSpanElement>;
  isAlcoholPopupVisible: boolean;
  onAlcoholPopupIconClick: () => void;
  className?: string;
}

const HeaderIndicators = ({ alcoholSpanRef, isAlcoholPopupVisible, onAlcoholPopupIconClick, className }: HeaderIndicatorsProps) => {
  const appMode = useSelector(selectMode);

  const isOffline = useOfflineStatus();
  const { error } = useGeolocation();
  const { isMobileScreen } = useWindowDimensions();

  return (
    <div className={cn('flex [&span]:flex items-center gap-4 ml-auto', className)}>
      <AlcoholCheckIndicator
        alcoholIconRef={alcoholSpanRef}
        isPopupVisible={isAlcoholPopupVisible}
        onPopupIconClick={onAlcoholPopupIconClick}
      />
      {appMode === AppMode.Advanced && (
        <>
          <span className={!error ? 'text-green-500' : 'text-red-500'}>
            <GeolocationIcon size={isMobileScreen ? 'xs' : 'sm'} />
          </span>
        </>
      )}
      <span className={isOffline ? 'text-red-500' : 'text-green-500'}>
        <WifiIcon size={isMobileScreen ? 'xs' : 'sm'} />
      </span>
      <span className={isOffline ? 'text-red-500' : 'text-green-500'}>
        <SocketIcon size={isMobileScreen ? 'xs' : 'sm'} />
      </span>
    </div>
  );
};

export default HeaderIndicators;
