import messageService, { MarkMessageAsReadDTO } from './messages.service';
import { ConfirmedMessageResponse, Message } from '../types';
import { Endpoint } from '@/store';
import { socket } from '@/lib/socket';
import { createSelector } from '@reduxjs/toolkit';
import { baseApi, MESSAGE_HISTORY_KEY, NEW_MESSAGE_KEY } from '@/store/baseApi';
import { SocketEventData } from '@ekt-group/general-purpose-api-interfaces';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [NEW_MESSAGE_KEY, MESSAGE_HISTORY_KEY],
});

export const messagesApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessagesHistory: builder.query<Message[], number>({
      queryFn: async (driverId) => {
        return messageService.getMessages(driverId);
      },
      providesTags: [MESSAGE_HISTORY_KEY],
      keepUnusedDataFor: Infinity,
    }),
    getNewMessages: builder.query<Message[], number>({
      queryFn: async (driverId) => {
        return messageService.getNewMessages(driverId);
      },
      providesTags: [NEW_MESSAGE_KEY],
      onCacheEntryAdded: async (args, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
        try {
          await cacheDataLoaded;

          const listener = (message: SocketEventData<Message>) => {
            const data = message?.payload;
            if (!data) {
              return;
            }

            updateCachedData((draft) => {
              draft.push(data);
            });
          };

          socket.on('NewMessage', listener);
        } catch {}
        await cacheEntryRemoved;
        socket.off('NewMessage');
      },
    }),
    markMessageAsRead: builder.mutation<ConfirmedMessageResponse, MarkMessageAsReadDTO>({
      queryFn: async ({ messageId, timestamp }) => {
        return messageService.markMessageAsRead({ messageId, timestamp });
      },
      onQueryStarted: async ({ messageId, timestamp }, { dispatch, queryFulfilled, getState }) => {
        // Explanation: remove from NewMessages store, add into MessageHistory store
        const endpoints = messagesApi.util.selectInvalidatedBy(getState(), [
          {
            type: NEW_MESSAGE_KEY,
          },
          {
            type: MESSAGE_HISTORY_KEY,
          },
        ]);

        let newMessagesEndpoint: Endpoint = null;
        let historyMessagesEndpoint: Endpoint = null;

        for (const endpoint of endpoints) {
          const { endpointName } = endpoint;
          if (endpointName === 'getNewMessages') {
            newMessagesEndpoint = endpoint;
          }
          if (endpointName === 'getMessagesHistory') {
            historyMessagesEndpoint = endpoint;
          }
        }

        if (!newMessagesEndpoint || !historyMessagesEndpoint) {
          return;
        }

        dispatch(
          messagesApi.util.updateQueryData('getNewMessages', newMessagesEndpoint.originalArgs, (draft) => {
            const messageIndex = draft?.findIndex((message) => message.id === messageId);
            if (messageIndex !== -1) {
              const message = draft[messageIndex];

              message.readAt = timestamp || new Date().toISOString();

              dispatch(
                messagesApi.util.updateQueryData('getMessagesHistory', historyMessagesEndpoint.originalArgs, (historyDraft) => {
                  historyDraft.push(message);
                }),
              );

              if (draft.length > 0) {
                draft.splice(messageIndex, 1);
              }
            }
          }),
        );
      },
    }),
  }),
});

export const createGetNewMessagesSelector = createSelector(
  (driverId: number) => driverId,
  (driverId) => messagesApi.endpoints.getNewMessages.select(driverId),
);

export const selectNewMessages = (driverId: number) => createGetNewMessagesSelector(driverId);

export const { useGetMessagesHistoryQuery, useGetNewMessagesQuery, useMarkMessageAsReadMutation, useLazyGetNewMessagesQuery } = messagesApi;
