import React, { useRef } from 'react';
import SidebarItem from './SidebarItem';
import LocaleSwitcher from '../elements/LocaleSwitcher';
import PendingPhotosIndicator from './PendingPhotosIndicator';
import authService from '../../features/auth/api';
import { useNavigate } from 'react-router-dom';
import Button from '../elements/Button';
import LogoutIcon from '../icons/LogoutIcon';
import ConfirmationModal from '../modals/ConfirmationModal';
import useModal from '@/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { resetAuthState, selectUserState } from '@/features/auth';
import {
  isRouteTypeWithWaypoints,
  resetRouteGroupItemsState,
  selectCurrentRouteGroupItem,
  useRouteGroupItemsRequest,
} from '@/features/home';
import { useTranslation } from 'react-i18next';
import { useSettings } from '@/contexts/settingsContext';
import SettingsIcon from '../icons/SettingsIcon';
import { AppMode, HeaderItem } from '@/types/common';
import { conditionalClassNames } from '@/helpers/dom';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { resetCommonState } from '@/store/common/slice';
import { resetFuelingPageState } from '@/features/fueling';
import { resetKeysAndGatesState, resetTabletPageSettingsState, selectDisplayArrivedPage, setDisplayArrivedPage } from '@/features/route';
import UserIcon from '../icons/UserIcon';
import RouteIcon from '../icons/RouteIcon';
import { selectMode } from '@/store/settings/slice';
import SidebarOfflineSummaryWrapper from './SidebarOfflineSummaryWrapper';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import { resetWaypointsState } from '@/store/waypoints/slice';
import QuestionMarkIcon from '@/components/icons/QuestionMarkIcon';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import SettingsModal from '../modals/SettingsModal';
import RefreshIcon from '@/components/icons/RefreshIcon';
import avariiLogo from '@/assets/images/avarii-logo.png';
import { Toggle } from '../elements';
import { cn } from '@/utils/cn';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';

interface SidebarProps {
  sidebarItems?: HeaderItem[];
  close?: () => void;
  isTinyHeader?: boolean;
  isAdvancedHeader?: boolean;
  className?: string;
}

const Sidebar = ({ sidebarItems, isTinyHeader, isAdvancedHeader, close = () => {}, className }: SidebarProps) => {
  const sidebarRef = useRef();
  const offlineSummaryRef = useRef();
  const settingsRef = useRef<HTMLDivElement>(null);
  const isOffline = useOfflineStatus();

  const { showSettings, hideSettings, isVisible } = useSettings();
  const { isMobileScreen } = useWindowDimensions();
  const { isVisible: isModalVisible, toggle: toggleModal } = useModal();
  const { t } = useTranslation('loginPage');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appMode = useSelector(selectMode);
  const user = useSelector(selectUserState);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const displayArrivedPage = useSelector(selectDisplayArrivedPage);

  const { resetNavigation } = useAdvancedNavigationItems();

  const { refreshRoutesAndGetNewWaypoints, isRouteAndWaypointsUpdateLoading } = useRouteGroupItemsRequest();

  const routeRefreshButtonText = `${t('refresh')} ${t('routeGroupItemsSettingLabel').toLowerCase()}`;

  const logout = async () => {
    await authService.logout();

    toggleModal();
    navigate('/login');

    dispatch(resetCommonState());
    dispatch(resetFuelingPageState());
    dispatch(resetKeysAndGatesState());
    dispatch(resetRouteGroupItemsState());
    dispatch(resetWaypointsState());
    dispatch(resetTabletPageSettingsState());
    dispatch(resetAuthState());
  };

  const openUserGuide = () => {
    window.open(import.meta.env.VITE_USER_GUIDE_LINK, '_blank');
  };

  const openAccidentLink = () => {
    window.open(import.meta.env.VITE_TRAFFIC_ACCIDENT_LINK, '_blank');
  };

  useOutsideClick([sidebarRef], close, [settingsRef, offlineSummaryRef]);

  return (
    <div
      ref={sidebarRef}
      className={cn(
        {
          sidebar: true,
          'tiny-header-sidebar': isTinyHeader,
          'top-[4.2rem] pt-2 md:top-[7.25rem] md:w-[300px]': isAdvancedHeader,
          'top-14 md:top-20': displayArrivedPage,
          'top-16 md:top-[6.5rem]': appMode === AppMode.Advanced && !isRouteTypeWithWaypoints(currentRouteGroupItem?.sheetType),
        },
        className,
      )}
    >
      <div className="flex sm:flex-row flex-col sm:justify-around gap-4">
        <div className="flex flex-col gap-3 w-full h-full">
          <LocaleSwitcher />

          <nav className="gap-2 md:hidden grid">
            {sidebarItems?.map((item, index) => (
              <SidebarItem
                label={item.label}
                link={item.link}
                icon={item.icon}
                key={index}
                notificationAmount={item.notificationsAmount}
                onClick={close}
              />
            ))}
          </nav>

          <div className="sidebar-user">
            <UserIcon size={'sm'} />
            <div className="sidebar-user__name">
              {user?.firstname} {user?.surname}
            </div>
          </div>

          {(currentRouteGroupItem?.routeGroup?.name || currentRouteGroupItem?.name) && (
            <div className="sidebar-user">
              <RouteIcon size={'sm'} />
              <div className="sidebar-user__name">{currentRouteGroupItem?.routeGroup?.name || currentRouteGroupItem.name}</div>
            </div>
          )}

          {appMode === AppMode.Advanced ? <SidebarOfflineSummaryWrapper ref={offlineSummaryRef} /> : <PendingPhotosIndicator />}

          {appMode === AppMode.Advanced && (
            <div className="sidebar-settings">
              <Button
                text={t('routeGroupItemsSettingLabel', { ns: 'common' })}
                icon={<RouteIcon size={!isMobileScreen ? 'md' : 'sm'} />}
                size={!isMobileScreen ? 'lg' : 'md'}
                onClick={() => {
                  navigate('/');
                  dispatch(setDisplayArrivedPage(false));
                  resetNavigation();
                  close();
                }}
                wide
              />
            </div>
          )}

          <div className="sidebar-settings">
            <Button
              text={t('settings', { ns: 'common' })}
              icon={<SettingsIcon size={!isMobileScreen ? 'md' : 'sm'} />}
              size={!isMobileScreen ? 'lg' : 'md'}
              onClick={showSettings}
              wide
            />
          </div>

          <Button
            className="whitespace-nowrap"
            icon={<RefreshIcon size={'sm'} />}
            pending={isRouteAndWaypointsUpdateLoading}
            disabled={isRouteAndWaypointsUpdateLoading}
            text={routeRefreshButtonText}
            wide
            size={!isMobileScreen ? 'lg' : 'md'}
            onClick={() => {
              if (isOffline) {
                navigate(0);
              }
              refreshRoutesAndGetNewWaypoints();
            }}
          />

          <Button
            icon={<img src={avariiLogo} className="max-w-10 max-h-10" />}
            text={t('accidentLinkLabel', { ns: 'header' })}
            size={'md'}
            onClick={openAccidentLink}
            wide
            color="danger"
            className="mt-5"
          />

          <Button
            icon={<QuestionMarkIcon size={'md'} />}
            text={t('userGuide', { ns: 'header' })}
            color={'disabled'}
            size={'md'}
            onClick={openUserGuide}
            wide
          />

          <div className="flex flex-col justify-end items-center gap-4 w-full">
            <Button
              className="justify-between md:text-lg"
              onClick={() => toggleModal()}
              text={t('logout')}
              size={'md'}
              color={'danger'}
              icon={<LogoutIcon size={'sm'} />}
              wide
            />
          </div>
        </div>
      </div>

      <ConfirmationModal isVisible={isModalVisible} hide={toggleModal} submit={logout} text={t('logoutPrompt')} />
      <SettingsModal ref={settingsRef} isVisible={isVisible} onClose={hideSettings} />
    </div>
  );
};

export default Sidebar;
