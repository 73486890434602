import React, { useCallback, useEffect, useRef, useState } from 'react';
import RouteGroupItemList from '../components/RouteGroupItemList';
import { setDocumentTitle } from '@/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import { ConstructionRouteList } from '../components/ConstructionRouteList';
import { MapComponent, MapMode } from '@ekt-group/map-component';
import { CheckUpFormData, CheckUpModal, ExposedCheckUpModalFunctions, FORM_TYPE } from '../components/check-up';
import { useAllDeviceTasks, useCarAcceptances, useCarCheck, useRouteGroupItemsRequest } from '../hooks';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { selectUserId } from '@/features/auth';
import { selectCurrentRouteGroupItem, setCurrentRouteGroupItemMode } from '../stores';
import { CreateCarAcceptanceDto } from '../api';
import { AlldeviceTaskPriority } from '@ekt-group/general-purpose-api-interfaces/src/types/alldevice.enum';
import { socket } from '@/lib/socket';
import { selectIsSyncingEnabled, selectMode } from '@/store/settings/slice';
import { getTodayDate, getTomorrowDate } from '@/helpers/dateHelper';
import RouteGroupItemStartFinishModal from '../components/RouteGroupItemStartFinishModal';
import { AppMode } from '@/types/common';
import { useNavigate } from 'react-router';
import { Feature } from '@/types/features';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { setSelectedWaypointIds } from '@/store/waypoints/slice';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { setDisplayArrivedPage, setIsTakePhotoModalVisible } from '@/features/route';
import { RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';
import Button from '@/components/elements/Button';
import RefreshIcon from '@/components/icons/RefreshIcon';
import { CurrentRouteGroupItemMode } from '../types';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';

interface RoutesList<T> {
  todayRoutes: T[];
  tomorrowRoutes: T[];
}

export const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  setDocumentTitle(t('routeGroupItemsSettingLabel'));

  const { setNavigationItems } = useAdvancedNavigationItems();

  const { isFeatureEnabled } = useFeatureFlags();
  const isOffline = useOfflineStatus();

  // #region Redux Selectors
  const userId = useAppSelector(selectUserId);
  const currentRouteGroupItem = useAppSelector(selectCurrentRouteGroupItem);
  const appMode = useAppSelector(selectMode);
  const isSyncingEnabled = useAppSelector(selectIsSyncingEnabled);

  // #endregion

  // #region Local State
  const [selectedRouteGroupItemId, setSelectedRouteGroupItemId] = useState<number>(null);
  const [startFinishModalAction, setStartFinishModalAction] = useState<'start' | 'finish'>('start');
  const [isStartModalVisible, setIsStartModalVisible] = useState<boolean>(false);
  const [isCheckUpModalVisible, setIsCheckUpModalVisible] = useState<boolean>(false);
  const checkUpModalRef = useRef<ExposedCheckUpModalFunctions>(null);

  // #endregion

  // #region API Hooks
  const {
    data: tasks,
    isLoading: isTasksLoading,
    status: fetchTasksStatus,
    allDevicePriorityOptions,
  } = useAllDeviceTasks({
    deviceName: currentRouteGroupItem?.car?.carNumber || '',
  });

  const {
    routeGroupItems,
    refreshRoutesAndGetNewWaypoints,
    isLoading: isRouteGroupItemsLoading,
    isFetching: isRoutesFetching,
    setRouteGroupItemAsCurrent,
    changeRouteGroupItemMode,
    getRouteGroupItemById,
  } = useRouteGroupItemsRequest();

  const startedRouteGroupItem = routeGroupItems.find((item) => item.start && !item.end);

  const { todayRoutes, tomorrowRoutes } = routeGroupItems.reduce<RoutesList<RouteGroupItem>>(
    (acc, item) => {
      if (appMode === AppMode.Advanced && item.driverId !== userId) {
        return acc;
      }

      if (item.date === getTodayDate()) {
        acc.todayRoutes.push(item);
      }
      if (item.date === getTomorrowDate()) {
        acc.tomorrowRoutes.push(item);
      }

      return acc;
    },
    { todayRoutes: [], tomorrowRoutes: [] },
  );

  const carIdsForToday = routeGroupItems.filter((item) => item.carId && item.date === getTodayDate()).map((item) => item.carId);

  const {
    refetch: refetchCarAcceptances,
    createCarAcceptance,
    isLoading: isAcceptanceLoading,
    isCheckedUp,
  } = useCarAcceptances({ carIds: carIdsForToday, driverIds: [userId] });

  const { data: carCheckOptions, isLoading: isCheckOptionsLoading, status: fetchCarCheckStatus } = useCarCheck();

  // #endregion

  // #region Handlers

  const handleCancelCheckUp = () => {
    setIsCheckUpModalVisible(false);
    setSelectedRouteGroupItemId(null);
  };

  const handleCheckUpSubmit = async (data: CheckUpFormData) => {
    const picturesFormData = data[FORM_TYPE.PICTURES];
    const selectedCarId = currentRouteGroupItem.carId;

    const pictures = picturesFormData?.files.filter((photo) => photo.file);

    const acceptancePayload: CreateCarAcceptanceDto = {
      driverId: userId,
      carId: selectedCarId,
      files: pictures.map((photo) => photo.file),
    };
    createCarAcceptance(acceptancePayload);

    setStartFinishModalAction('start');
    setIsCheckUpModalVisible(false);
    setIsStartModalVisible(true);

    return;
  };

  const handleNavigateToRoute = useCallback(
    (routeGroupItemId: number) => {
      if (routeGroupItemId !== currentRouteGroupItem?.id) {
        setSelectedRouteGroupItemId(routeGroupItemId);
      }
      if (currentRouteGroupItem?.id !== routeGroupItemId) {
        setRouteGroupItemAsCurrent(routeGroupItemId);
      }

      navigate(appMode === AppMode.Basic ? '/photo' : `/route/${routeGroupItemId}`);
    },
    [appMode, currentRouteGroupItem?.id, navigate, setRouteGroupItemAsCurrent],
  );

  const handleOpenButtonClick = useCallback(
    (routeGroupItemId: number) => {
      const openedRouteGroupItem = getRouteGroupItemById(routeGroupItemId);

      if (!openedRouteGroupItem) {
        return;
      }

      socket.volatile.emit('RouteHasBeenChanged', { type: 'RouteHasBeenChanged', payload: { routeGroupItemId } });
      changeRouteGroupItemMode(openedRouteGroupItem);

      handleNavigateToRoute(routeGroupItemId);
    },
    [getRouteGroupItemById, changeRouteGroupItemMode, handleNavigateToRoute],
  );

  const handleModalStartButtonClick = () => {
    setIsStartModalVisible(false);

    if (currentRouteGroupItem?.id !== selectedRouteGroupItemId) {
      setRouteGroupItemAsCurrent(selectedRouteGroupItemId);
    }

    socket.volatile.emit('RouteHasBeenStarted', { type: 'RouteHasBeenStarted', payload: { routeGroupItemId: currentRouteGroupItem?.id } });

    navigate(`/route/${selectedRouteGroupItemId}`);
  };

  const handleModalFinishButtonClick = () => {
    setIsStartModalVisible(false);
    socket.volatile.emit('RouteHasBeenFinished', {
      type: 'RouteHasBeenFinished',
      payload: { routeGroupItemId: currentRouteGroupItem?.id },
    });
  };

  const handleStartOrFinishButtonClick = useCallback(
    (routeGroupItemId: number, action: 'start' | 'finish', isActive: boolean, isNotAllowedToStart: boolean) => {
      const carId = routeGroupItems.find((item) => item.id === routeGroupItemId)?.carId;
      setSelectedRouteGroupItemId(routeGroupItemId);
      setRouteGroupItemAsCurrent(routeGroupItemId);
      switch (action) {
        case 'start':
          if (isFeatureEnabled(Feature.CarCheckUp)) {
            refetchCarAcceptances();
          }

          if (startedRouteGroupItem && startedRouteGroupItem?.id !== routeGroupItemId && !isActive && isNotAllowedToStart) {
            toast(t('startFinishModal.unfinishedRouteNotification', { ns: 'routeGroupItems' }), { type: 'warning' });
            return;
          }

          dispatch(setCurrentRouteGroupItemMode(CurrentRouteGroupItemMode.Process));
          localStorage.setItem('currentRouteGroupItemMode', CurrentRouteGroupItemMode.Process);

          if (carId && !isCheckedUp(carId)) {
            checkUpModalRef?.current?.resetForm();

            setIsCheckUpModalVisible(true);
            return;
          }

          break;
        case 'finish':
          dispatch(setCurrentRouteGroupItemMode(CurrentRouteGroupItemMode.Open));
          localStorage.setItem('currentRouteGroupItemMode', CurrentRouteGroupItemMode.Open);
          break;
      }

      setIsStartModalVisible(true);
      setStartFinishModalAction(action);
    },
    [routeGroupItems, setRouteGroupItemAsCurrent, isFeatureEnabled, startedRouteGroupItem, dispatch, isCheckedUp, refetchCarAcceptances, t],
  );

  const handleRefreshRoutes = () => {
    if (isOffline) {
      navigate(0);
    }
    refreshRoutesAndGetNewWaypoints();
    refetchCarAcceptances();
  };

  // #endregion

  // #region Side effects

  useEffect(() => {
    setNavigationItems([]);
  }, [setNavigationItems]);

  useEffect(() => {
    refreshRoutesAndGetNewWaypoints();
  }, [refreshRoutesAndGetNewWaypoints]);
  // #endregion

  // #region Socket Events

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('WaypointsSelected', ({ payload: { waypointIds } }) => {
      dispatch(setSelectedWaypointIds(waypointIds));
    });
  }, [dispatch, isSyncingEnabled]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('RouteHasBeenStarted', async ({ payload: { routeGroupItemId } }) => {
      if (routeGroupItemId === currentRouteGroupItem?.id) {
        return;
      }

      toast('Received RouteHasBeenStarted event from server. Starting route...', { type: 'success' });
      setRouteGroupItemAsCurrent(routeGroupItemId);

      if (isMobile) {
        handleNavigateToRoute(routeGroupItemId);
        return;
      }

      navigate(`/route/${routeGroupItemId}`, { replace: true });
    });

    return () => {
      socket.off('RouteHasBeenStarted');
    };
  }, [isSyncingEnabled, dispatch, currentRouteGroupItem?.id, setRouteGroupItemAsCurrent, navigate, handleNavigateToRoute]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('RouteHasBeenChanged', ({ payload: { routeGroupItemId } }) => {
      toast('Received RouteHasBeenChanged event from server. Opening route...', { type: 'success' });
      handleOpenButtonClick(routeGroupItemId);
    });

    return () => {
      socket.off('RouteHasBeenChanged');
    };
  }, [handleOpenButtonClick, isSyncingEnabled, routeGroupItems]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('RouteHasBeenFinished', ({ payload: { routeGroupItemId } }) => {
      if (currentRouteGroupItem?.id === routeGroupItemId) {
        toast('Received RouteHasBeenFinished event from server. Finishing route...', { type: 'success' });
        refreshRoutesAndGetNewWaypoints();
      }
    });

    return () => {
      socket.off('RouteHasBeenFinished');
    };
  }, [currentRouteGroupItem?.id, isSyncingEnabled, refreshRoutesAndGetNewWaypoints]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('ArrivedToWaypoints', ({ payload: { waypointIds } }) => {
      toast('Received update from other devices, updating...', {
        type: 'success',
      });

      if (!waypointIds?.length) {
        toast('No waypoints received from tablet', {
          type: 'error',
        });
        return;
      }

      dispatch(setIsTakePhotoModalVisible(true));
      dispatch(setDisplayArrivedPage(true));
      if (!selectedRouteGroupItemId) {
        const routeGroupItem = routeGroupItems.find((item) => item.start);
        if (routeGroupItem) {
          setRouteGroupItemAsCurrent(routeGroupItem.id);
          setSelectedRouteGroupItemId(routeGroupItem.id);
          dispatch(setSelectedWaypointIds(waypointIds));
          handleNavigateToRoute(routeGroupItem.id);
        }

        return;
      }

      dispatch(setSelectedWaypointIds(waypointIds));
      handleNavigateToRoute(selectedRouteGroupItemId);
    });

    return () => {
      socket.off('ArrivedToWaypoints');
    };
  }, [dispatch, setRouteGroupItemAsCurrent, isSyncingEnabled, routeGroupItems, selectedRouteGroupItemId, handleNavigateToRoute]);

  // #endregion

  return (
    <>
      {/* To cache map titles */}
      <div className="absolute h-screen invisible">
        <MapComponent mode={MapMode.NoMode} />
      </div>

      <CheckUpModal
        isVisible={isCheckUpModalVisible}
        onCancel={handleCancelCheckUp}
        onAllStepsCompleted={handleCheckUpSubmit}
        ref={checkUpModalRef}
        allDeviceTasks={tasks}
        mode="full"
        filteredPriorityOptions={allDevicePriorityOptions.filter((option) => option.key !== AlldeviceTaskPriority.High)}
        carCheckOptions={carCheckOptions}
        routeGroupItem={currentRouteGroupItem}
      />

      {currentRouteGroupItem && isStartModalVisible && (
        <RouteGroupItemStartFinishModal
          isVisible={isStartModalVisible}
          routeGroupItem={currentRouteGroupItem}
          action={startFinishModalAction}
          onClose={() => setIsStartModalVisible(false)}
          onStart={handleModalStartButtonClick}
          onFinish={handleModalFinishButtonClick}
        />
      )}

      <div className="flex flex-col gap-4 py-5">
        <div className={'flex justify justify-between md:justify-start md:gap-4 items-center mb-2'}>
          <h1 className="mb-2 md:text-xl">
            {t('todayRoutes', {
              ns: 'routeGroupItems',
            })}
          </h1>
          <Button text={t('refresh', { ns: 'common' })} onClick={handleRefreshRoutes} icon={<RefreshIcon size={'md'} />} size={'md'} />
        </div>
        <div className="flex flex-col gap-2">
          <RouteGroupItemList
            routeGroupItems={todayRoutes}
            onOpenOrContinueButtonClick={handleOpenButtonClick}
            isRouteDataLoading={(isRouteGroupItemsLoading || isRoutesFetching) && !isOffline}
            onStartOrFinishButtonClick={handleStartOrFinishButtonClick}
          />
        </div>
      </div>

      <>
        <div className="flex flex-col gap-2">
          {tomorrowRoutes?.length > 0 && (
            <>
              <h1>
                {t('tomorrowRoutes', {
                  ns: 'routeGroupItems',
                })}
              </h1>
              <RouteGroupItemList
                routeGroupItems={tomorrowRoutes}
                onOpenOrContinueButtonClick={handleOpenButtonClick}
                isRouteDataLoading={(isRouteGroupItemsLoading || isRoutesFetching) && !isOffline}
                onStartOrFinishButtonClick={handleStartOrFinishButtonClick}
              />
            </>
          )}
        </div>
      </>
    </>
  );
};
