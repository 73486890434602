import httpService from '@/lib/http.service';
import { Gate } from '@ekt-group/general-purpose-api-interfaces';
import { MakeCallBody } from '@/types/phone';

export default {
  async getGates() {
    const { data } = await httpService.get<Gate[]>('/cars/gates');
    return data;
  },
  async openGate(gateId: number, body: MakeCallBody) {
    const { data } = await httpService.post(`/cars/gates/${gateId}/open`, body);
    return data;
  },
};
