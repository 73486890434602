import React from 'react';
import Spinner from '@/components/elements/Spinner';
import AccidentChatPicturesList from './AccidentChatPicturesList';
import { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';

interface AccidentChatPicturesTabProps {
  queryData: UseQueryHookResult<any>;
}

const AccidentChatPicturesTab = ({ queryData }: AccidentChatPicturesTabProps) => {
  const { data, isLoading, isError, error } = queryData;

  if (isLoading) {
    return <Spinner size={'md'} />;
  }
  if (isError) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = error ? `${error?.status} - ${JSON.stringify(error?.data?.message)}` : '';
    return <pre>{errorMessage}</pre>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!data?.length) {
    return <div>No pictures available</div>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <AccidentChatPicturesList pictures={data} />;
};

export default AccidentChatPicturesTab;
