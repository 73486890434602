import { useTranslation } from 'react-i18next';

const locales: Record<string, string> = {
  et: 'EST',
  ru: 'RUS',
  en: 'ENG',
};

export function useLocaleSwitcher() {
  const { i18n } = useTranslation();

  const handleLocaleChange = (locale: string) => {
    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  return {
    locales,
    handleLocaleChange,
    currentLocale: i18n.language,
  };
}
