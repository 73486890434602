export interface IConditionalClassNames {
  [key: string]: boolean;
}

export const conditionalClassNames = (conditions: IConditionalClassNames) => {
  return Object.entries(conditions)
    .filter(([_, condition]) => condition)
    .map(([className]) => className)
    .join(' ');
};

export const isIosDevice = () => /iPad|iPhone|iPod/.test(navigator.userAgent);
