import { GetAlldeviceTasksDto, CreateAlldeviceTaskBodyDto } from '@ekt-group/general-purpose-api-interfaces';
import { AllDeviceTask } from '@ekt-group/bi-services-api-interfaces';
import { createSelector } from '@reduxjs/toolkit';
import { ALL_DEVICE_TASKS_KEY, baseApi } from '@/store/baseApi';

const getRandomId = () => Math.floor(Math.random() * 1000);

export interface ExtendedCreateAlldeviceTaskBodyDto extends CreateAlldeviceTaskBodyDto {
  tempTaskId: number;
}

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [ALL_DEVICE_TASKS_KEY],
});

export const allDeviceApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<AllDeviceTask[], GetAlldeviceTasksDto>({
      query: (params) => {
        return {
          url: '/external/alldevice/tasks',
          method: 'GET',
          params,
        };
      },
      keepUnusedDataFor: Infinity,
      providesTags: [ALL_DEVICE_TASKS_KEY],
    }),
    createTask: builder.mutation<null, ExtendedCreateAlldeviceTaskBodyDto>({
      query: (body) => {
        delete body.tempTaskId;

        return {
          url: '/external/alldevice/tasks',
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          data: body,
        };
      },
      onQueryStarted: async ({ deviceName, userId, serviceDescription, tempTaskId, ...rest }, { dispatch, queryFulfilled, requestId }) => {
        const originalArgs: GetAlldeviceTasksDto = { deviceName, users: [parseInt(userId)] };

        const newTask: AllDeviceTask = {
          ...rest,
          taskId: tempTaskId,
          serviceDescription,
          serviceId: getRandomId(),
          serviceDuration: 'fakeDuration',
          deviceStatus: 'fakeStatus',
          deviceName,
          groupId: getRandomId(),
          deviceId: getRandomId(),
          objectId: getRandomId(),
          typeId: getRandomId(),
          priorityName: 'fakeName',
          serviceDateEnd: new Date().toISOString(),
          dueDays: 0,
          taskStatus: 'warning',
          isCompleted: false,
          comments: 'fakeComments',
          commentsNext: 'fakeCommentsNext',
          completedDate: new Date().toISOString(),
          completedDateEnd: new Date().toISOString(),
          users: [],
          confirmedByUser: 'fakeUser',
          confirmedByUserId: getRandomId(),
          confirmedOn: new Date().toISOString(),
          createdOn: new Date().toISOString(),
          faultType: 'fakeFaultType',
          actions: [],
        };

        dispatch(
          allDeviceApi.util.updateQueryData('getTasks', originalArgs, (draft) => {
            if (draft?.findIndex((task) => task.taskId === tempTaskId) === -1) {
              draft.push(newTask);
            }
          }),
        );
      },
      invalidatesTags: [ALL_DEVICE_TASKS_KEY],
    }),
    getTaskPictures: builder.query({
      query: ({ taskId, params }: { taskId: number; params: Record<string, string> }) => {
        return {
          url: `/external/alldevice/tasks/${taskId}/pictures`,
          method: 'GET',
          params,
        };
      },
    }),
  }),
});

const createGetTasksSelector = createSelector(
  (data: GetAlldeviceTasksDto) => data,
  (data) => allDeviceApi.endpoints.getTasks.select(data),
);

export const selectAllDeviceTasks = (data: GetAlldeviceTasksDto) => createGetTasksSelector(data);

export const { useGetTasksQuery, useCreateTaskMutation, useGetTaskPicturesQuery } = allDeviceApi;
