import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { HeaderItem } from '@/types/common';
import { useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon';
import { useNavigate } from 'react-router';

interface AdvancedNavigationContext {
  navigationItems: HeaderItem[];
  setNavigationItems: (navigationItems: HeaderItem[]) => void;
  replaceNavigationWithBackButton: () => void;
  resetNavigation: () => void;
}

const advancedNavigationContext = createContext<AdvancedNavigationContext>({
  navigationItems: [],
  setNavigationItems: () => {},
  replaceNavigationWithBackButton: () => {},
  resetNavigation: () => {},
});

export function AdvancedNavigationProvider({ children }: PropsWithChildren) {
  const [navigationItems, setNavigationItems] = useState<HeaderItem[]>([]);

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const replaceNavigationWithBackButton = useCallback(() => {
    const navigateTo = currentRouteGroupItem ? `/route/${currentRouteGroupItem?.id}` : '/';

    setNavigationItems([
      {
        label: t('back'),
        icon: <ArrowLeftIcon size={'md'} />,
        style: 'primary',
        action: () => navigate(navigateTo),
      },
    ]);
  }, [currentRouteGroupItem, navigate, t]);

  const resetNavigation = useCallback(() => {
    setNavigationItems([]);
  }, []);

  return (
    <advancedNavigationContext.Provider value={{ navigationItems, setNavigationItems, replaceNavigationWithBackButton, resetNavigation }}>
      {children}
    </advancedNavigationContext.Provider>
  );
}

export function useAdvancedNavigationItems() {
  const context = useContext(advancedNavigationContext);

  return context;
}
