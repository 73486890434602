import React, { useEffect, useRef, useState } from 'react';
import { useRollbar } from '@rollbar/react';
import { convertBase64ToFile } from '../../utils/compressImage';
import PhotoIcon from '../icons/PhotoIcon';
import LightningIcon from '../icons/LightningIcon';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import { conditionalClassNames } from '../../helpers/dom';
import { useTranslation } from 'react-i18next';

interface CameraWrapperProps {
  onSuccess: (photoData: File) => void;
  onError?: (error: any) => void;
  close?: () => void;
}

const CameraWrapper = ({ onSuccess, onError, close }: CameraWrapperProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const [trackCapabilities, setTrackCapabilities] = useState<MediaTrackCapabilities>(null);
  const [zoom, setZoom] = useState(1);
  const [isFlashOn, setIsFlashOn] = useState(false);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);

  const rollbar = useRollbar();
  const { t } = useTranslation('cameraWrapper');

  const startCamera = async () => {
    if (!videoRef.current) {
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
        audio: false,
      });

      videoRef.current.srcObject = stream;
      streamRef.current = stream;

      const [videoTrack] = stream.getVideoTracks();

      setTrackCapabilities(videoTrack.getCapabilities());
    } catch (error) {
      if (error instanceof DOMException && error.name === 'NotAllowedError') {
        setIsPermissionDenied(true);
      }
      rollbar.error('Error accessing the camera', error as Error);
      onError && onError(error);
    }
  };

  const stopStream = () => {
    setTrackCapabilities(null);
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const takePhoto = () => {
    if (!videoRef.current) {
      return;
    }

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const timestamp = Date.now();

    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

    stopStream();

    onSuccess(convertBase64ToFile(canvas.toDataURL('image/jpeg'), `${timestamp.toString()}.jpeg`, timestamp));
  };

  const handleZoomIn = async () => {
    if (!streamRef.current) {
      return;
    }

    const videoTrack = streamRef.current.getVideoTracks()[0];
    const capabilities = videoTrack.getCapabilities();

    if (capabilities.zoom && zoom < capabilities.zoom.max) {
      await videoTrack.applyConstraints({ advanced: [{ zoom: zoom + 1 }] });
      setZoom(zoom + 1);
    }
  };

  const handleZoomOut = async () => {
    if (!streamRef.current) {
      return;
    }

    const [videoTrack] = streamRef.current.getVideoTracks();

    if (zoom > 1) {
      await videoTrack.applyConstraints({ advanced: [{ zoom: zoom - 1 }] });
      setZoom(zoom - 1);
    }
  };

  const toggleFlash = async () => {
    if (!streamRef.current) {
      return;
    }

    const [videoTrack] = streamRef.current.getVideoTracks();
    const capabilities = videoTrack.getCapabilities();

    if (capabilities.torch) {
      await videoTrack.applyConstraints({ advanced: [{ torch: !isFlashOn }] });
      setIsFlashOn((previousState) => !previousState);
    }
  };

  const handleBack = () => {
    stopStream();
    close();
  };

  useEffect(() => {
    startCamera();

    return () => {
      stopStream();
    };
  }, []);

  return (
    <>
      <div className={'fixed top-0 left-0 z-[999] bg-black w-screen h-screen'}></div>
      <div className={'fixed top-0 left-0 z-[999] flex w-screen h-screen p-2 md:p-10'}>
        <div className="centered w-full">
          {isPermissionDenied ? (
            <div className={'flex flex-col text-xl w-1/2'}>
              <span>{t('permissionInstruction.title')}</span>
              <span>{t('permissionInstruction.firstStep')}</span>
              <span>{t('permissionInstruction.secondStep')}</span>
              <span>
                {t('permissionInstruction.thirdStep')}
                <ul className={'list-disc ml-10'}>
                  <li>{t('permissionInstruction.thirdStepList.firstItem')}</li>
                  <li>{t('permissionInstruction.thirdStepList.secondItem')}</li>
                </ul>
              </span>
              <span>{t('permissionInstruction.fourthStep')}</span>
            </div>
          ) : (
            <>
              <video ref={videoRef} autoPlay playsInline className={'w-full h-full rounded'} />
              <canvas ref={canvasRef} className={'hidden'} />

              <div className={'fixed right-4 md:right-6 top-6 md:top-20 flex flex-col gap-4 justify-center'}>
                {trackCapabilities?.torch && (
                  <button
                    className={conditionalClassNames({
                      'centered h-14 w-14 md:h-16 md:w-16 rounded-full text-2xl': true,
                      'bg-gray-800 text-gray-100 focus:active:bg-gray-700': !isFlashOn,
                      'bg-gray-300 text-black focus:active:bg-gray-400': isFlashOn,
                    })}
                    onClick={toggleFlash}
                  >
                    <LightningIcon size={'md'} />
                  </button>
                )}
                {trackCapabilities?.zoom && (
                  <>
                    <button
                      className={'h-14 w-14 md:h-16 md:w-16 rounded-full text-2xl bg-gray-800 focus:active:bg-gray-700'}
                      onClick={handleZoomIn}
                    >
                      +
                    </button>
                    <button
                      className={'h-14 w-14 md:h-16 md:w-16 rounded-full text-2xl bg-gray-800 focus:active:bg-gray-700'}
                      onClick={handleZoomOut}
                    >
                      -
                    </button>
                  </>
                )}
              </div>
              <button
                className={
                  'centered h-20 w-20 rounded-full text-2xl bg-gray-800 fixed bottom-8 left-[calc(50%-2.5rem)] focus:active:bg-gray-700 md:left-auto md:bottom-auto md:top-1/2 md:right-4 text-white'
                }
                onClick={takePhoto}
              >
                <PhotoIcon size={'md'} />
              </button>
            </>
          )}
          <button
            className={
              'centered focus:active:bg-gray-700 h-14 w-14 md:h-20 md:w-20 rounded-full text-2xl bg-gray-800 fixed top-6 md:top-20 left-4 text-white'
            }
            onClick={handleBack}
          >
            <ArrowLeftIcon size={'md'} />
          </button>
        </div>
      </div>
    </>
  );
};

export default CameraWrapper;
