import {
  WaypointsServicesValues,
  removeFormSelectedWaypointIds,
  selectArrivedPageForm,
  setDisplayArrivedPage,
  setFailedRequestPendingState,
  setSuccessRequestPendingState,
  useMarkWaypointAsDoneMutation,
  useMarkWaypointAsFailedMutation,
  useUpdateServiceWaypointStatusMutation,
  useWaypointsRequest,
} from '@/features/route';
import { SPECIAL_SERVICE_ITEM_IDS } from '@/constants/waypoints';
import { SpecialWaypointServiceItem } from '@/types/common';
import { useDispatch, useSelector } from 'react-redux';
import { WaypointsStatusRequest, waypointsStatusRequestsDb } from '@/database';
import { WaypointsStatus } from '@/types/waypoints';
import { useLiveQuery } from 'dexie-react-hooks';
import { WaypointsServiceStatusRequest, waypointsServiceStatusRequestsDb } from '@/database/waypoints-service-status-requests.db';
import { IWaypointDischarge, selectCurrentRouteGroupItem, Waypoint } from '@/features/home';
import { useTranslation } from 'react-i18next';
import { POHJA_REGION_ID } from '@/constants/regions';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { socket } from '@/lib/socket';
import { useCallback } from 'react';
import { RouteGroupItemWaypoint } from '@ekt-group/general-purpose-api-interfaces';
import useGeolocation from 'react-hook-geolocation';
import { getAndSetLocalProcessedWaypoints } from '@/helpers/localStorage/locallyProcessedWaypointsHelper';
import { addToProcessedObjectDateMap, getProcessedObjectDate } from '@/helpers/localStorage/processedObjects';
import { useRollbar } from '@rollbar/react';

const CONFIRMATION_REQUIRED_SALARY_ROW_IDS = [13, 14];

export function useArrivedPageChangeStatusRequests(arrivedWaypoints: Waypoint[]) {
  const dispatch = useDispatch();
  const { latitude, longitude } = useGeolocation();
  const isOffline = useOfflineStatus();
  const { refetch } = useWaypointsRequest();

  const [markWaypointAsDoneMutation] = useMarkWaypointAsDoneMutation();
  const [markWaypointAsFailedMutation] = useMarkWaypointAsFailedMutation();
  const [updateServiceWaypointStatusMutation] = useUpdateServiceWaypointStatusMutation();

  const { t } = useTranslation('arrivedPage');
  const { updateWaypointIds, services: formServices, serviceWaypointDischarges } = useSelector(selectArrivedPageForm) || {};
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const rollbar = useRollbar();

  const getServiceValues = useCallback(
    (selectedWaypointIds: number[]) =>
      Object.entries(formServices).reduce<WaypointsServicesValues>(
        (acc, [waypointId, waypointServices]) => {
          Object.entries(waypointServices).forEach(([serviceId, { value }]) => {
            if (!selectedWaypointIds.includes(Number(waypointId))) {
              return;
            }

            switch (serviceId) {
              case SPECIAL_SERVICE_ITEM_IDS[SpecialWaypointServiceItem.Discharge]: {
                acc.dischargesCount[waypointId] = value;
                break;
              }
              case SPECIAL_SERVICE_ITEM_IDS[SpecialWaypointServiceItem.DynamicCapacity]: {
                acc.dynamicCapacities[waypointId] = value;
                break;
              }
              case SPECIAL_SERVICE_ITEM_IDS[SpecialWaypointServiceItem.ManualWeight]: {
                acc.weights[waypointId] = value;
                break;
              }
              default: {
                if (!acc.services[waypointId]) {
                  acc.services[waypointId] = [];
                }

                acc.services[waypointId].push({
                  id: Number(serviceId),
                  value,
                });
              }
            }
          });

          return acc;
        },
        {
          services: {},
          dischargesCount: {},
          dynamicCapacities: {},
          weights: {},
        },
      ),
    [formServices],
  );

  const checkBioBagInstallation = useCallback(
    (selectedWaypointIds: number[], services: WaypointsServicesValues['services']) => {
      const confirmationRequiringServices: number[][] = [];

      (arrivedWaypoints as IWaypointDischarge[]).forEach(({ id: waypointId, services }) => {
        if (!selectedWaypointIds.includes(waypointId)) {
          return;
        }
        services.forEach(({ id: serviceId, salaryRowId, isOrderedToObject, isMonthly }) => {
          if (
            isOrderedToObject &&
            CONFIRMATION_REQUIRED_SALARY_ROW_IDS.includes(salaryRowId) &&
            !(isMonthly && currentRouteGroupItem?.base?.regionId === POHJA_REGION_ID)
          ) {
            confirmationRequiringServices.push([waypointId, serviceId]);
          }
        });
      });

      return confirmationRequiringServices.some(([waypointId, serviceId]) => {
        return services[waypointId].find(({ id }) => id === serviceId).value === 0;
      });
    },
    [arrivedWaypoints, currentRouteGroupItem?.base?.regionId],
  );

  const addRequestWithConflictChecking = useCallback(async (request: WaypointsStatusRequest<WaypointsStatus>) => {
    const table = waypointsStatusRequestsDb.requests;
    const allRequests = await table.toArray();
    const conflictingRequests = allRequests?.filter((r) => r.waypointIds === request.waypointIds);

    if (!conflictingRequests || conflictingRequests.length === 0) {
      table.add(request);
      return;
    }

    for (const pendingRequest of conflictingRequests) {
      const allWaypointIdsMatches =
        pendingRequest.waypointIds?.length === request.waypointIds?.length
          ? pendingRequest.waypointIds.every((id) => request.waypointIds.includes(id))
          : false;

      if (allWaypointIdsMatches) {
        await table.delete(pendingRequest.id);
        continue;
      }

      for (const id of pendingRequest.waypointIds) {
        if (request.waypointIds.includes(id)) {
          delete pendingRequest.body.dischargesCount[id];
          delete pendingRequest.body.dynamicCapacities[id];
          delete pendingRequest.body.weights[id];
          delete pendingRequest.body.services[id];

          pendingRequest.body.updateWaypointIds = pendingRequest.body.updateWaypointIds.filter((waypointId) => waypointId !== id);
          pendingRequest.waypointIds = pendingRequest.waypointIds.filter((waypointId) => waypointId !== id);
        }
      }

      if (!pendingRequest.waypointIds.length) {
        await table.delete(pendingRequest.id);
      } else {
        await table.update(pendingRequest.id, pendingRequest);
      }
    }

    await table.add(request);
  }, []);

  const markWaypointsAsDone = useCallback(
    async (selectedWaypointIds: number[], objectId: number, timestamp: string) => {
      dispatch(setSuccessRequestPendingState(true));
      const recordedDate = timestamp || getProcessedObjectDate(objectId);
      let payload = {} as WaypointsStatusRequest<WaypointsStatus.Done>;

      try {
        const { services, dischargesCount, dynamicCapacities, weights } = getServiceValues(selectedWaypointIds);
        const isBioBagRequiredButNotInstalled =
          currentRouteGroupItem?.isDischargeSheet && checkBioBagInstallation(selectedWaypointIds, services);

        if (isBioBagRequiredButNotInstalled && !confirm(t('bioBagNotInstalledConfirmation'))) {
          return;
        }

        payload = {
          status: WaypointsStatus.Done,
          waypointIds: selectedWaypointIds,
          body: {
            routeGroupItemId: currentRouteGroupItem?.id,
            coordinates: {
              longitude,
              latitude,
            },
            services,
            dischargesCount,
            dynamicCapacities,
            weights,
            updateWaypointIds,
            doneAt: recordedDate,
          },
        };

        markWaypointAsDoneMutation(payload)
          .unwrap()
          .catch((error) => {
            rollbar.error('[ONLINE] Failed to mark waypoints as done', {
              errorData: {
                config: error?.config,
                data: error?.data,
                status: error?.status,
                header: error?.headers,
                error,
              },
              payload,
            });
            addRequestWithConflictChecking(payload);
          });

        if (isOffline) {
          addRequestWithConflictChecking(payload);
        }

        socket.volatile.emit('WaypointsMarkedAsDischarged', {
          type: 'WaypointsMarkedAsDischarge',
          payload: { waypointIds: arrivedWaypoints?.map((waypoint) => waypoint.id), data: payload },
        });
        getAndSetLocalProcessedWaypoints(payload.waypointIds);
        dispatch(removeFormSelectedWaypointIds(payload.waypointIds));
      } finally {
        addToProcessedObjectDateMap(objectId, recordedDate);
        dispatch(setSuccessRequestPendingState(false));
      }
    },
    [
      dispatch,
      getServiceValues,
      currentRouteGroupItem?.isDischargeSheet,
      currentRouteGroupItem?.id,
      checkBioBagInstallation,
      t,
      longitude,
      latitude,
      updateWaypointIds,
      markWaypointAsDoneMutation,
      isOffline,
      arrivedWaypoints,
      rollbar,
      addRequestWithConflictChecking,
    ],
  );

  const markWaypointsAsFailed = async (
    selectedWaypointIds: number[],
    objectId: number,
    timestamp: string,
    reasonIds: number[],
    additionalNotes: string,
  ) => {
    dispatch(setFailedRequestPendingState(true));
    const recordedDate = timestamp || getProcessedObjectDate(objectId);
    let payload = {} as WaypointsStatusRequest<WaypointsStatus.Failed>;

    try {
      const { services, dischargesCount, dynamicCapacities, weights } = getServiceValues(selectedWaypointIds);

      payload = {
        waypointIds: selectedWaypointIds,
        status: WaypointsStatus.Failed,
        body: {
          routeGroupItemId: currentRouteGroupItem?.id,
          coordinates: {
            longitude,
            latitude,
          },
          services,
          dischargesCount,
          dynamicCapacities,
          weights,
          reasonIds,
          content: additionalNotes,
          updateWaypointIds,
          doneAt: recordedDate,
        },
      };

      markWaypointAsFailedMutation(payload)
        .unwrap()
        .catch((error) => {
          rollbar.error('[ONLINE] Failed to mark waypoints as failed', {
            errorData: {
              config: error?.config,
              data: error?.data,
              status: error?.status,
              header: error?.headers,
              error,
            },
            payload,
          });
          addRequestWithConflictChecking(payload);
        });

      if (isOffline) {
        addRequestWithConflictChecking(payload);
      }

      socket.volatile.emit('WaypointsMarkedAsNotDischarged', {
        type: 'WaypointsMarkedAsNotDischarged',
        payload: { waypointIds: arrivedWaypoints?.map((waypoint) => waypoint.id) || [], data: payload },
      });
      getAndSetLocalProcessedWaypoints(payload.waypointIds);

      dispatch(removeFormSelectedWaypointIds(payload.waypointIds));
    } finally {
      addToProcessedObjectDateMap(objectId, recordedDate);
      dispatch(setFailedRequestPendingState(false));
    }
  };

  const markServiceWaypointsAsDone = useCallback(
    async (selectedWaypointIds: number[], objectId: number, timestamp: string) => {
      const table = waypointsServiceStatusRequestsDb.requests;

      dispatch(setSuccessRequestPendingState(true));
      const recordedDate = timestamp || getProcessedObjectDate(objectId);
      let payload = {} as WaypointsServiceStatusRequest;

      try {
        payload = {
          status: WaypointsStatus.Done,
          serviceIds: selectedWaypointIds,
          routeGroupItemId: currentRouteGroupItem?.id,
          doneAt: recordedDate,
          discharges: serviceWaypointDischarges,
        };

        await updateServiceWaypointStatusMutation(payload).catch((error) => {
          rollbar.error('[ONLINE] Failed to mark service waypoints as done', {
            errorData: {
              config: error?.config,
              data: error?.data,
              status: error?.status,
              header: error?.headers,
              error,
            },
            payload,
          });
          table.add(payload);
        });
        socket.volatile.emit('WaypointsMarkedAsNotDischarged', {
          type: 'WaypointsMarkedAsNotDischarged',
          payload: { waypointIds: arrivedWaypoints?.map((waypoint) => waypoint.id), data: payload },
        });

        dispatch(removeFormSelectedWaypointIds(payload.serviceIds));
        return;
      } finally {
        addToProcessedObjectDateMap(objectId, recordedDate);
        dispatch(setSuccessRequestPendingState(false));
      }
    },
    [dispatch, currentRouteGroupItem?.id, serviceWaypointDischarges, updateServiceWaypointStatusMutation, arrivedWaypoints, rollbar],
  );

  const markServiceWaypointsAsFailed = async (
    selectedWaypointIds: number[],
    objectId: number,
    timestamp: string,
    reasonContent: string,
    additionalNotes: string,
  ) => {
    dispatch(setFailedRequestPendingState(true));
    const table = waypointsServiceStatusRequestsDb.requests;
    const recordedDate = timestamp || getProcessedObjectDate(objectId);
    let payload = {} as WaypointsServiceStatusRequest;
    try {
      payload = {
        status: WaypointsStatus.Failed,
        serviceIds: selectedWaypointIds,
        routeGroupItemId: currentRouteGroupItem?.id,
        content: `${reasonContent}\r\n${additionalNotes}`,
        doneAt: recordedDate,
      };

      updateServiceWaypointStatusMutation(payload)
        .unwrap()
        .catch((error) => {
          rollbar.error('[ONLINE] Failed to mark service waypoints as failed', {
            errorData: {
              config: error?.config,
              data: error?.data,
              status: error?.status,
              header: error?.headers,
              error,
            },
            payload,
          });
          table.add(payload);
        });

      socket.volatile.emit('WaypointsMarkedAsNotDischarged', {
        type: 'WaypointsMarkedAsNotDischarged',
        payload: { waypointIds: arrivedWaypoints?.map((waypoint) => waypoint.id), data: payload },
      });
      dispatch(removeFormSelectedWaypointIds(payload.serviceIds));
      return;
    } catch (error) {
      rollbar.error('[ONLINE] Failed to mark service waypoints as failed', {
        error,
        payload,
      });
    } finally {
      dispatch(setFailedRequestPendingState(false));
    }
  };

  return {
    markWaypointsAsDone,
    markWaypointsAsFailed,
    markServiceWaypointsAsDone,
    markServiceWaypointsAsFailed,
  };
}
