import React from 'react';
import PhoneCallIcon from '../../../components/icons/PhoneCallIcon';
import { useTranslation } from 'react-i18next';
import { useGatePhoneCall } from '../hooks/useGatePhoneCall';
import Spinner from '../../../components/elements/Spinner';
import { conditionalClassNames } from '../../../helpers/dom';
import { useOfflineStatus } from '../../../contexts/offlineStatusContext';

interface Props {
  label: string;
  objectId: number;
  routeGroupItemWaypointId: number;
}

const KeysModalListGateItem = ({ label, objectId, routeGroupItemWaypointId }: Props) => {
  const isOffline = useOfflineStatus();
  const { t } = useTranslation('tabletPage');

  const { isPending, handleOpenObjectGate } = useGatePhoneCall();

  return (
    <div
      className={conditionalClassNames({
        'keys-modal__list-gate-item': true,
        'keys-modal__list-gate-item--disabled': isOffline,
        'keys-modal__list-gate-item--pending': isPending,
      })}
      onClick={() => handleOpenObjectGate(objectId, routeGroupItemWaypointId)}
    >
      {isPending ? <Spinner size={'md'} /> : <PhoneCallIcon size={'md'} />}
      <span>
        {t('keysModal.openObjectGate')} - {label}
      </span>
    </div>
  );
};

export default KeysModalListGateItem;
