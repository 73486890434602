import React from 'react';
import { BasicOption } from '../../types/common';

interface SelectProps {
  options: BasicOption[];
  value: string | number;
  onChange: (value: string) => void;
  label?: string;
  size?: 'sm' | 'md' | 'lg';
}

const Select = ({ options, value, onChange, label, size }: SelectProps) => {
  return (
    <div className={`select-component ${size ?? 'md'}`}>
      {label && <label>{label}</label>}
      <select value={value ?? ''} onChange={(e) => onChange(e.target.value)}>
        {options.map(({ label, value }) => (
          <option value={value ?? ''} key={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
