import ReactGA4 from 'react-ga4';

export function initGA() {
  ReactGA4.initialize([
    {
      trackingId: import.meta.env.VITE_GA_MEASUREMENT_ID,
      gtagOptions: {},
    },
  ]);
}

export function sendPhotoUploadEvent(routeGroupName: string | undefined, routeGroupItemName: string | undefined) {
  ReactGA4.event('photo_upload', {
    route_group_name: routeGroupName,
    route_group_item_name: routeGroupItemName,
  });
}

export function sendErrorEvent(message: string, file: string, line: number, column: number, stack: string) {
  ReactGA4.event('js_error', {
    message,
    file,
    line,
    column,
    stack,
  });
}
