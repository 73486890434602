import httpService from '../lib/http.service';
import { Coordinates } from '../types/common';
import { DeletedInsertedWaypointIdsResponse, GetArrivedWaypointIdsResponse } from '../types/waypoints';

export interface UpdateStatusPayload {
  serviceIds: number[];
  routeGroupItemId: number;
  status: string;
  content?: string;
  doneAt?: string;
  discharges?: Record<string, boolean>;
}

export interface UploadServiceWaypointPicturePayload {
  picture: File;
  serviceIds: number[];
  routeGroupItemId: number;
}

export default {
  async updateWaypointCoordinates(serviceIds: number[], routeGroupItemId: number, { longitude, latitude }: Coordinates) {
    return httpService.patch('/objects/services/coordinates', {
      serviceIds,
      routeGroupItemId,
      longitude,
      latitude,
    });
  },

  async uploadWaypointPicture({ picture, serviceIds, routeGroupItemId }: UploadServiceWaypointPicturePayload) {
    const formData = new FormData();

    formData.append('file', picture);
    formData.append('serviceIds', JSON.stringify(serviceIds));
    formData.append('routeGroupItemId', routeGroupItemId.toString());

    const { data } = await httpService.post<{ filePath: string }>('/objects/services/pictures', formData);

    return data;
  },

  async getWaypointPictures(serviceIds: number[]) {
    const { data } = await httpService.get<any[]>('/objects/services/pictures', {
      params: {
        serviceIds: serviceIds.join(),
      },
    });

    return data;
  },

  async saveServiceWaypointNotes(serviceIds: number[], routeGroupItemId: number, notes: string) {
    return httpService.post('/objects/services/way-problems', {
      serviceIds,
      routeGroupItemId,
      notes,
    });
  },

  async setArrivedWaypoints(serviceIds: number[], routeGroupItemId: number) {
    const { data } = await httpService.post('/objects/services/arrived', {
      serviceIds,
      routeGroupItemId,
    });

    return data;
  },

  async getArrivedWaypoints() {
    const { data } = await httpService.get<GetArrivedWaypointIdsResponse>('/objects/services/arrived');

    return data;
  },

  async updateWaypointStatus({ status, content, serviceIds, routeGroupItemId, discharges, doneAt }: UpdateStatusPayload) {
    return httpService.post('/objects/services/status', {
      doneAt,
      serviceIds,
      routeGroupItemId,
      status,
      discharges,
      content,
    });
  },

  async getDeletedAndInsertedWaypoints(routeGroupItemId: number, lastUpdatedWaypointIdsTimestamp: string) {
    const { data } = await httpService.get<DeletedInsertedWaypointIdsResponse>('/objects/services/deleted-and-inserted', {
      params: { routeGroupItemId, lastUpdatedWaypointIdsTimestamp },
    });

    return data;
  },
};
