import { Coordinates } from '@/types/common';
import { useCallback } from 'react';
import useGeolocation from 'react-hook-geolocation';

export function useCoordinatesHelper() {
  const { error, latitude, longitude } = useGeolocation();

  // TODO: get car coordinates by bi-services if user didn't give permission for using his coordinates
  const filterByRadius = useCallback(
    <T extends Coordinates>(locations: T[], radius: number): T[] => {
      const filteredLocations: T[] = [];

      if (error) {
        return [];
      }

      locations.forEach((location) => {
        if (
          getDistanceInMeters(location, {
            latitude,
            longitude,
          }) <= radius
        ) {
          filteredLocations.push(location);
        }
      });

      return filteredLocations;
    },
    [error, latitude, longitude],
  );

  return {
    filterByRadius,
  };
}

export function getDistanceInMeters(coordinate1: Coordinates, coordinate2: Coordinates): number {
  const EARTH_RADIUS_METERS = 6371000; // Approximate radius of the Earth in meters
  const latitudeDifference = toRadians(coordinate2.latitude - coordinate1.latitude);
  const longitudeDifference = toRadians(coordinate2.longitude - coordinate1.longitude);

  const halfChordLengthSquared =
    Math.sin(latitudeDifference / 2) ** 2 +
    Math.cos(toRadians(coordinate1.latitude)) * Math.cos(toRadians(coordinate2.latitude)) * Math.sin(longitudeDifference / 2) ** 2;

  const centralAngle = 2 * Math.atan2(Math.sqrt(halfChordLengthSquared), Math.sqrt(1 - halfChordLengthSquared));

  return EARTH_RADIUS_METERS * centralAngle;
}

export const transformCoordinatesToFixedLength = (coordinates: (string | number)[]) =>
  coordinates.map(String).map((value) => value.slice(0, 9));

function toRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}
