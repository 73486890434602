import { baseApi, CONSTRUCTION_ROUTES, CONSTRUCTION_TASKS } from '@/store/baseApi';
import {
  GetConstructionTasksQueryDto,
  GetRouteSheetsQuery,
  PageDto,
  RouteSheet,
  Task,
  StartRouteSheetBodyDto,
  FinishRouteSheetBodyDto,
} from '@ekt-group/general-purpose-api-interfaces';
import { createSelector } from '@reduxjs/toolkit';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [CONSTRUCTION_ROUTES, CONSTRUCTION_TASKS],
});

export enum RouteSheetSideloading {
  Car = 'car',
  Driver = 'driver',
  Base = 'base',
  Region = 'region',
}

export interface StartRouteSheetDto extends StartRouteSheetBodyDto {
  routeSheetId: number;
  originalArgs?: GetRouteSheetsQuery;
}

export interface FinishRouteSheetDto extends FinishRouteSheetBodyDto {
  routeSheetId: number;
  originalArgs?: GetRouteSheetsQuery;
}

export const constructionApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getConstructionRoutes: builder.query<RouteSheet[], GetRouteSheetsQuery>({
      query: (params) => {
        return {
          url: '/construction/route-sheets',
          method: 'GET',
          params,
        };
      },
      transformResponse: (response: PageDto<RouteSheet>) => {
        return response?.data;
      },
      providesTags: [CONSTRUCTION_ROUTES],
    }),
    getConstructionTasks: builder.query<Task[], GetConstructionTasksQueryDto>({
      query: (params) => {
        return {
          url: '/construction/tasks',
          method: 'GET',
          params,
        };
      },
      transformResponse: (response: PageDto<Task>) => {
        return response?.data;
      },
      providesTags: [CONSTRUCTION_TASKS],
    }),
    startConstructionRouteSheet: builder.mutation<void, StartRouteSheetDto>({
      query: ({ routeSheetId, startOdometer, startTime }) => {
        return {
          url: `/construction/route-sheets/${routeSheetId}/start`,
          method: 'POST',
          data: { startOdometer, startTime },
        };
      },
      onQueryStarted: async ({ routeSheetId, startOdometer, startTime, originalArgs }, { dispatch }) => {
        if (originalArgs) {
          dispatch(
            constructionApi.util.updateQueryData('getConstructionRoutes', originalArgs, (draft) => {
              const routeSheet = draft?.find((routeSheet) => routeSheet.id === routeSheetId);
              routeSheet.start = startTime;
              routeSheet.odometerStart = startOdometer;
            }),
          );
        }
      },
    }),
    finishConstructionRouteSheet: builder.mutation<void, FinishRouteSheetDto>({
      query: ({ routeSheetId, endOdometer, endTime }) => {
        return {
          url: `/construction/route-sheets/${routeSheetId}/finish`,
          method: 'POST',
          data: { endOdometer, endTime },
        };
      },
      onQueryStarted: async ({ routeSheetId, endOdometer, endTime, originalArgs }, { dispatch }) => {
        if (originalArgs) {
          dispatch(
            constructionApi.util.updateQueryData('getConstructionRoutes', originalArgs, (draft) => {
              const routeSheet = draft?.find((routeSheet) => routeSheet.id === routeSheetId);
              routeSheet.end = endTime;
              routeSheet.odometerEnd = endOdometer;
            }),
          );
        }
      },
    }),
  }),
});

const createGetConstructionRoutesSelector = createSelector(
  (query: GetRouteSheetsQuery) => query,
  (query) => constructionApi.endpoints.getConstructionRoutes.select(query),
);

const createGetConstructionTasksSelector = createSelector(
  (query: GetConstructionTasksQueryDto) => query,
  (query) => constructionApi.endpoints.getConstructionTasks.select(query),
);

export const selectGetConstructionRoutesCache = (query: GetRouteSheetsQuery) => createGetConstructionRoutesSelector(query);
export const selectGetConstructionTasksCache = (query: GetConstructionTasksQueryDto) => createGetConstructionTasksSelector(query);

export const {
  useLazyGetConstructionRoutesQuery,
  useGetConstructionTasksQuery,
  useLazyGetConstructionTasksQuery,
  useFinishConstructionRouteSheetMutation,
  useStartConstructionRouteSheetMutation,
} = constructionApi;
