import React from 'react';
import { KojvService } from '@ekt-group/general-purpose-api-interfaces';
import IncrementingInput from '../../../../components/elements/IncrementingInput';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectArrivedPageForm, setFormServiceValue } from '@/features/route';
import TagIcon from '../../../../components/icons/TagIcon';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { SpecialWaypointServiceItem } from '@/types/common';
import { SPECIAL_SERVICE_ITEM_IDS } from '@/constants/waypoints';
import PhotoIcon from '../../../../components/icons/PhotoIcon';
import { POHJA_REGION_ID } from '@/constants/regions';

interface WaypointServiceInputItemProps {
  service: KojvService | null;
  articleCode?: KojvService['articleCode'] | SpecialWaypointServiceItem;
  waypointId?: number;
  isDisabled?: boolean;
}

const WaypointServiceInputItem = ({ service, articleCode, waypointId, isDisabled }: WaypointServiceInputItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('articleCodes');
  const { services } = useSelector(selectArrivedPageForm) || {};
  const { base, routeGroup } = useSelector(selectCurrentRouteGroupItem);

  const { serviceLimit, articleSaf, isM3, isOrderedToObject, isMonthly, isPhotoRequired } = service || {};
  const isDischarge = articleCode === SpecialWaypointServiceItem.Discharge;

  const getServiceId = () => {
    return SPECIAL_SERVICE_ITEM_IDS[articleCode as SpecialWaypointServiceItem]
      ? SPECIAL_SERVICE_ITEM_IDS[articleCode as SpecialWaypointServiceItem]
      : service?.id;
  };

  const inputValue = services?.[waypointId]?.[getServiceId()]?.value;

  const m3SpecialServiceItemIds = [SpecialWaypointServiceItem.DynamicCapacity, SpecialWaypointServiceItem.ManualWeight];

  const getStep = () => {
    if (m3SpecialServiceItemIds.includes(articleCode as SpecialWaypointServiceItem) || isM3) {
      return 0.1;
    }
    return 1;
  };

  const getMinValue = () => {
    if (articleCode === SpecialWaypointServiceItem.Discharge) {
      return 1;
    }
    return 0;
  };

  const getUnit = () => {
    if (articleCode === SpecialWaypointServiceItem.DynamicCapacity) {
      return 'm³';
    }
    if (articleCode === SpecialWaypointServiceItem.ManualWeight) {
      return 'kg';
    }
    return articleSaf?.unit || 'tk';
  };

  const handleChange = (value: number) => {
    dispatch(setFormServiceValue({ waypointId, serviceId: getServiceId(), value }));
  };

  return (
    <div className="arrived-page__list-item__service-input-item">
      <div className="leading-5">
        <div className="arrived-page__list-item__service-input-item__label">
          {isOrderedToObject && (
            <span>
              <TagIcon size={'md'} />
            </span>
          )}
          {isPhotoRequired && (
            <span>
              <PhotoIcon size={'md'} />
            </span>
          )}
          <div className={'inline-flex'}>{t(articleCode) || service.name}</div>
        </div>
        {isDischarge && !routeGroup.dischargeQuantityAllowedInTablet && (
          <small className="text-blue-500 dark:text-blue-300 leading-4">{t('notAllowedToChangeDischargeQty', { ns: 'arrivedPage' })}</small>
        )}
      </div>
      <div className="arrived-page__list-item__service-input-item__input">
        {isMonthly && base.regionId === POHJA_REGION_ID ? (
          <div className="arrived-page__list-item__service-input-item__text">{t('ordered', { ns: 'common' })}</div>
        ) : (
          <>
            {isDischarge && !routeGroup.dischargeQuantityAllowedInTablet ? (
              <div className="text-2xl">{inputValue}</div>
            ) : (
              <IncrementingInput
                value={inputValue}
                step={getStep()}
                min={getMinValue()}
                max={serviceLimit?.maxQuantity}
                onChange={handleChange}
                isDisabled={isDisabled}
              />
            )}
            <div className="arrived-page__list-item__service-input-item__unit">{getUnit()}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default WaypointServiceInputItem;
