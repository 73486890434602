import Dexie from 'dexie';
import { CompletedRouteOrderItem } from '../features/home/types/routeGroupItems';
import { Coordinates } from '../types/common';

export interface HistoricalCompletedOrder {
  id?: number;
  routeGroupItemId: number;
  routeCoordinates?: Coordinates[];
  order: CompletedRouteOrderItem[];
}

export class HistoricalCompletedOrdersDb extends Dexie {
  orders: Dexie.Table<HistoricalCompletedOrder, number>;

  constructor() {
    super('historicalCompletedOrdersDb');

    this.version(1).stores({
      orders: '++id, routeGroupItemId, order',
    });
  }
}

export const historicalCompletedOrdersDb = new HistoricalCompletedOrdersDb();
