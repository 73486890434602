import { RadioGroup } from '@/components/elements/headlessui';
import { CarProblemCanOperate } from '@/components/icons/CheckUp/CarProblemCanOperate';
import { CarProblemCannotOperate } from '@/components/icons/CheckUp/CarProblemCannotOperate';
import { CarProblemAccident } from '@/components/icons/CheckUp/CarProblemAccident';
import { ComboBoxOption } from '@/types/common';
import { AlldeviceTaskPriority } from '@ekt-group/general-purpose-api-interfaces/src/types/alldevice.enum';

interface CheckUpCarProblemsRadioGroupProps {
  priorityOptions: ComboBoxOption[];
  selectedOption: ComboBoxOption;
  onOptionChange: (option: ComboBoxOption) => void;
}

export const CheckUpCarProblemsRadioGroup = ({ priorityOptions, selectedOption, onOptionChange }: CheckUpCarProblemsRadioGroupProps) => {
  return (
    <>
      <RadioGroup<ComboBoxOption>
        value={selectedOption}
        onChange={onOptionChange}
        options={priorityOptions}
        outerClassName="flex flex-1"
        renderLabel={(option) => option.value}
        renderIcon={(option) => (
          <>
            {option.key === AlldeviceTaskPriority.Low && <CarProblemCanOperate className="w-12 h-12 text-blue-700" />}
            {option.key === AlldeviceTaskPriority.Normal && <CarProblemCannotOperate className="w-16 h-16 text-yellow-500" />}
            {option.key === AlldeviceTaskPriority.High && <CarProblemAccident className="w-14 h-14 text-red-700" />}
          </>
        )}
      />
    </>
  );
};
