import React, { ComponentPropsWithoutRef } from 'react';
import { getObjectAddress, unifiedString } from '@/helpers/strings';
import { ObjectEntity } from '@ekt-group/general-purpose-api-interfaces';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';
import { transformCoordinatesToFixedLength } from '@/helpers/coordinates';
import { PhotoUploadFormInput } from '@/features/photo';
import useGeolocation from 'react-hook-geolocation';

interface PhotoInputWithCoordinatesProps extends ComponentPropsWithoutRef<'div'> {
  arrivedObject: ObjectEntity;
  photo: File;
  photoPreview: string;
  setPhoto: (photo: File) => void;
  resetInput: () => void;
  inputRef: React.MutableRefObject<HTMLInputElement>;

  doAfterCompression?: () => void;
}

export const PhotoInputWithCoordinates = ({
  arrivedObject,
  photo,
  photoPreview,
  setPhoto,
  resetInput,
  inputRef,
  className,

  doAfterCompression,
}: PhotoInputWithCoordinatesProps) => {
  const { longitude, latitude } = useGeolocation();
  const { villagesMap, citiesMap } = useCommonItemMaps();

  const currentPosition = unifiedString(transformCoordinatesToFixedLength([latitude, longitude]));
  const arrivedObjectAddress = arrivedObject
    ? getObjectAddress(
        {
          object: arrivedObject,
          village: villagesMap[arrivedObject.villageId],
          city: citiesMap[arrivedObject.cityId],
        },
        ['county'],
      )
    : '';

  return (
    <PhotoUploadFormInput
      photo={photo}
      photoPreview={photoPreview}
      setPhoto={setPhoto}
      resetInput={resetInput}
      printOnImage={[currentPosition, arrivedObjectAddress]}
      inputRef={inputRef}
      classNames={className}
      doAfterCompression={doAfterCompression}
    />
  );
};

export default PhotoInputWithCoordinates;
