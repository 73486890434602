import httpService from '@/lib/http.service';

export async function updateTabletLastNewRequestTimestamps(id: number) {
  const { data } = await httpService.patch(`/route-group-items/${id}`, {
    lastNewWaypointsRequestAt: new Date().toISOString(),
    lastDeletedWaypointsRequestAt: new Date().toISOString(),
  });

  return data;
}
