import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './elements/Button';
import { useTranslation } from 'react-i18next';

const ErrorComponent = ({ error, resetError }: { error: Error; resetError: () => void }) => {
  const { t } = useTranslation('loginPage');
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
      setTimeout(() => {
        resetError();
      }, 0);
      return;
    }

    setTimeout(() => {
      resetError();
    }, 0);
    navigate('/');
  };

  return (
    <div className={'base-layout p-4 md:p-6 flex flex-col gap-4'}>
      <div className={'bg-gray-300 dark:bg-gray-900 p-4 rounded'}>{error?.message}</div>
      <div className={'bg-gray-300 dark:bg-gray-900 p-4 rounded overflow-x-auto'}>
        <pre className={'text-sm'}>{error?.stack}</pre>
      </div>
      <Button onClick={handleGoBack} text={t('goBack')} size={'md'} />
    </div>
  );
};

export default ErrorComponent;
