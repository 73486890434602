import React from 'react';
import { useSelector } from 'react-redux';
import { selectMode } from '../store/settings/slice';
import AdvancedLayout from './AdvancedLayout';
import { Outlet, useLocation } from 'react-router';
import MainLayout from './MainLayout';
import { AppMode } from '../types/common';
import { isRouteTypeWithWaypoints, selectCurrentRouteGroupItem } from '@/features/home';
import { selectDisplayArrivedPage } from '@/features/route';

const constructionPageRegEx = new RegExp('/construction\\/\\d+');

const LayoutProvider = () => {
  const mode = useSelector(selectMode);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const isDisplayArrivedPage = useSelector(selectDisplayArrivedPage);
  const { pathname } = useLocation();

  const isConstructionRoute = constructionPageRegEx.test(pathname);

  if (mode === AppMode.Advanced) {
    return (
      <AdvancedLayout
        isChildrenHaveWaypoints={isRouteTypeWithWaypoints(currentRouteGroupItem?.sheetType) || isConstructionRoute}
        isChildrenAModal={isDisplayArrivedPage}
      >
        <Outlet />
      </AdvancedLayout>
    );
  }

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export default LayoutProvider;
