import React from 'react';
import { getGarbageIconLink } from '@/helpers/garbage';
import HorizontalShadowScroll from '../elements/HorizontalShadowScroll';
import { useTranslation } from 'react-i18next';
import { selectCurrentRouteGroupItem, useRouteGroupItemSummary } from '@/features/home';
import { useSelector } from 'react-redux';
import ProgressBar from '../elements/ProgressBar';

const AdvancedHeaderSummary = () => {
  const { summary, removedWaypoints } = useRouteGroupItemSummary();
  const { t } = useTranslation('tabletPage');
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  if (!summary) {
    return <div className="tablet-header__summary-wrapper"></div>;
  }

  const { itemsDone, itemsTotal, capacityDone, capacityTotal } = Object.values(summary).reduce(
    (acc, curr) => {
      acc.itemsDone += curr.items.done;
      acc.itemsTotal += curr.items.total;
      acc.capacityDone += curr.capacity.done;
      acc.capacityTotal += curr.capacity.total;

      return acc;
    },
    { itemsDone: 0, itemsTotal: 0, capacityDone: 0, capacityTotal: 0 },
  );

  const capacityMark = <small>m3</small>;
  const pointsMark = <small>{t('container', { ns: 'common' }).toLowerCase().slice(0, 4)}.</small>;

  return (
    summary && (
      <div className="tablet-header__summary-wrapper">
        <div className="tablet-header__summary">
          <HorizontalShadowScroll className="tablet-header__summary__garbage-list">
            {Object.entries(summary).map(([garbageId, { items, capacity }]) => {
              return (
                <div key={garbageId} className="tablet-header__summary__garbage-list__item">
                  <img src={getGarbageIconLink(Number(garbageId))} height={18} width={18} alt="" />
                  <div className="tablet-header__summary__garbage-list__item-info">
                    <div>
                      {items.done}&nbsp;/&nbsp;{items.total}&nbsp;{pointsMark}
                    </div>
                    {currentRouteGroupItem?.isDischargeSheet && (
                      <div>
                        {capacity.done.toFixed(2)}&nbsp;/&nbsp;{capacity.total.toFixed(2)}&nbsp;
                        {capacityMark}
                      </div>
                    )}
                    {removedWaypoints && removedWaypoints?.length > 0 && (
                      <div className="whitespace-nowrap text-red-600">
                        <span>
                          {removedWaypoints.length} <small> {t('sortingModal.routeNotIncluded')}</small>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </HorizontalShadowScroll>
          <div className="tablet-header__summary__total-wrapper flex flex-col gap-1">
            <ProgressBar
              total={itemsTotal}
              progress={itemsDone}
              doneLabel={itemsDone.toString()}
              notDoneLabel={(itemsTotal - itemsDone).toString()}
            />
            <ProgressBar
              total={Number(capacityTotal.toFixed(2))}
              progress={Number(capacityDone.toFixed(2))}
              color={'primary'}
              doneLabel={
                <span>
                  {capacityDone.toFixed(2)}
                  {capacityMark}
                </span>
              }
              notDoneLabel={
                <span>
                  {(capacityTotal - capacityDone).toFixed(2)}
                  {capacityMark}
                </span>
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default AdvancedHeaderSummary;
