import React, { ComponentPropsWithoutRef, MutableRefObject, PropsWithoutRef, useCallback, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Spinner from '../../../components/elements/Spinner';
import Button from '../../../components/elements/Button';
import PhotoIcon from '../../../components/icons/PhotoIcon';
import UploadIcon from '../../../components/icons/UploadIcon';
import { useTranslation } from 'react-i18next';
import ReactSimpleImageViewer from 'react-simple-image-viewer';
import { getPhotoUploadingStatus, setPhotoUploadingStatus } from '@/helpers/localStorage/photoUploadingHelper';
import { PhotoUploadingStatus } from '@/features/photo';
import { manualImageCompression, UseImageCompressionOptions } from '@/utils/compressImage';
import { toast } from 'react-toastify';
import CameraWrapper from '../../../components/elements/CameraWrapper';
import { ImageUploadType } from '@/types/common';
import { useSelector } from 'react-redux';
import { selectUserId } from '@/features/auth';
import { useRollbar } from '@rollbar/react';
import { cn } from '@/utils/cn';
import { TIME_TO_CALL_ROLLBAR } from '@/configs/debug.config';

type ENABLED_FEATURES = 'camera' | 'upload' | 'fullscreenViewer';

interface PhotoUploadFormInputProps {
  photo: File;
  photoPreview: string;
  backgroundPhoto?: string;
  setPhoto: (file: File) => void;
  resetInput: () => void;
  inputRef: MutableRefObject<HTMLInputElement>;
  classNames?: string;
  doAfterCompression?: (compressedFile: File) => void;
  printOnImage?: string[];
  enabledFeatures?: ENABLED_FEATURES[];
  mode?: Mode;
}

export enum Mode {
  // after adding an image, the edit button disappears
  NORMAL = 'normal',

  // after adding an image, the edit button stays
  ALLOW_EDIT = 'allow_edit',
}

export const PhotoUploadFormInput = ({
  photo,
  photoPreview,
  backgroundPhoto,
  setPhoto,
  resetInput,
  inputRef,
  classNames,
  doAfterCompression,
  printOnImage,
  enabledFeatures = ['camera', 'upload', 'fullscreenViewer'],
  mode = Mode.NORMAL,
}: PhotoUploadFormInputProps) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isCameraOpened, setIsCameraOpened] = useState(false);
  const [uploadType, setUploadType] = useState<ImageUploadType>(ImageUploadType.File);
  const { t } = useTranslation();

  const rollbar = useRollbar();
  const userId = useSelector(selectUserId);

  const handleUploadButtonClick = useCallback(() => {
    setUploadType(ImageUploadType.File);
    setPhotoUploadingStatus(PhotoUploadingStatus.PhotoSelectButtonClicked);
    setTimeout(() => {
      inputRef.current?.removeAttribute('capture');
      inputRef.current?.click();
    }, 0);
  }, [setUploadType, inputRef]);

  const handlePhotoButtonClick = useCallback(() => {
    if (isMobileOnly) {
      setUploadType(ImageUploadType.Photo);
      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoTakeButtonClicked);
      setTimeout(() => {
        inputRef.current?.setAttribute('capture', 'environment');
        inputRef.current?.click();
      });
      return;
    }
    setIsCameraOpened(true);
  }, [setUploadType, inputRef, setIsCameraOpened]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoInputReturn);

      if (!event.target.files?.length) {
        return;
      }

      const startTime = performance.now();

      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoCompressionStarted);
      manualImageCompression(event.target.files[0], {
        onSuccess: (result, decodedImage) => {
          doAfterCompression && doAfterCompression(result);
          setPhoto(result);
          setPhotoUploadingStatus(PhotoUploadingStatus.PhotoCompressionFinished);

          const endTime = performance.now();

          if (endTime - startTime > TIME_TO_CALL_ROLLBAR) {
            rollbar.warn('[PhotoUploading - Offline] Long photo compression time from file upload', {
              duration: endTime - startTime,
              currentPhotoStatus: getPhotoUploadingStatus(),
              userId,
            });
          }
        },
        onError: (err) => {
          resetInput();
          toast(err.message, { type: 'error' });
        },
        printTimestamp: true,
        printAdditionalInfo: printOnImage,
      });
    },
    [doAfterCompression, printOnImage, setPhoto, resetInput, rollbar, userId],
  );

  const handleCameraInput = (photo: File) => {
    const startTime = performance.now();

    manualImageCompression(photo, {
      onSuccess: (result, decodedImage) => {
        doAfterCompression && doAfterCompression(result);
        setPhoto(result);
        setPhotoUploadingStatus(PhotoUploadingStatus.PhotoCompressionFinished);

        const endTime = performance.now();

        if (endTime - startTime > TIME_TO_CALL_ROLLBAR) {
          rollbar.warn('[PhotoUploading - Offline] Long photo compression time from camera input', {
            duration: endTime - startTime,
            currentPhotoStatus: getPhotoUploadingStatus(),
            userId,
          });
        }
      },
      onError: (err) => {
        resetInput();
        toast(err.message, { type: 'error' });
      },
      printTimestamp: true,
      printAdditionalInfo: printOnImage,
    });
    setIsCameraOpened(false);
  };

  const ImageViewer = useCallback(
    ({
      previewImgClassName,
      backgroundImgClassName,
    }: ComponentPropsWithoutRef<'img'> & {
      previewImgClassName?: string;
      backgroundImgClassName?: string;
    }) => {
      if (backgroundPhoto && !photoPreview) {
        return (
          <img
            src={backgroundPhoto}
            className={cn('z-0 h-full w-full object-cover border-none filter grayscale', backgroundImgClassName)}
          />
        );
      }

      return (
        <>
          <img
            src={photoPreview}
            className={cn('h-full w-full object-scale-down border-none', previewImgClassName)}
            onClick={() => setIsViewerOpen(true)}
          />
        </>
      );
    },
    [backgroundPhoto, photoPreview],
  );

  const ImageEditor = useCallback(
    ({ className }: ComponentPropsWithoutRef<'div'>) => {
      return (
        <>
          <div className={cn('flex flex-col items-center gap-2', className)}>
            {enabledFeatures.includes('camera') && (
              <Button
                text={t('takePhoto', { ns: 'photoPage' })}
                size="md"
                icon={<PhotoIcon size="sm" />}
                onClick={handlePhotoButtonClick}
              />
            )}
            {enabledFeatures.length > 1 && <p>{t('or')}</p>}
            {enabledFeatures.includes('upload') && (
              <Button
                text={t('uploadPhoto', { ns: 'photoPage' })}
                size="md"
                icon={<UploadIcon size="sm" />}
                onClick={handleUploadButtonClick}
              />
            )}
          </div>
        </>
      );
    },
    [enabledFeatures, handlePhotoButtonClick, handleUploadButtonClick, t],
  );

  const renderMode = useCallback(
    (mode: Mode) => {
      if (mode === Mode.NORMAL) {
        return photo ? <ImageViewer /> : <ImageEditor />;
      }

      if (mode === Mode.ALLOW_EDIT) {
        return (
          <div className="relative w-full h-full">
            <ImageViewer previewImgClassName="z-10 absolute" backgroundImgClassName="absolute opacity-70" />
            <ImageEditor className="top-1/2 left-1/2 opacity absolute z-20 transform -translate-x-1/2 -translate-y-1/2" />
          </div>
        );
      }
    },
    [ImageViewer, ImageEditor, photo],
  );

  return (
    <>
      {!isMobileOnly && isCameraOpened && (
        <CameraWrapper
          onSuccess={handleCameraInput}
          close={() => {
            setIsCameraOpened(false);
          }}
        />
      )}

      <div
        className={cn(
          'bg-gray-200 text-gray-900 rounded-md h-56',
          'dark:bg-gray-600  dark:text-gray-50',
          'border border-gray-500 border-dashed dark:border-gray-500',
          'relative flex justify-center items-center h-full w-full max-h-[50vh] overflow-hidden',
          classNames,
        )}
      >
        <input
          ref={inputRef}
          accept="image/*"
          className="hidden"
          multiple={false}
          type="file"
          onChange={handleInputChange}
          capture={'environment'}
        />
        {renderMode(mode)}
      </div>

      {enabledFeatures.includes('fullscreenViewer') && isViewerOpen && (
        <ReactSimpleImageViewer
          src={[photoPreview]}
          currentIndex={0}
          closeOnClickOutside
          disableScroll
          onClose={() => setIsViewerOpen(false)}
        />
      )}
    </>
  );
};

PhotoUploadFormInput.displayName = 'PhotoUploadFormInput';

export default PhotoUploadFormInput;
