import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const ArrowLeftIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      fill="currentColor"
      className={iconStyles[iconSizeClasses[size]]}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 98.148 98.148"
      xmlSpace="preserve"
      style={{ transform: 'rotate(180deg)' }}
    >
      <g>
        <path
          d="M33.458,97.562L80.531,50.49c0.75-0.75,0.75-2.078,0-2.828L33.456,0.586C33.081,0.211,32.572,0,32.042,0
		c-0.53,0-1.039,0.211-1.414,0.586L17.641,13.573c-0.391,0.391-0.586,0.902-0.586,1.414c0,0.512,0.195,1.023,0.586,1.414
		l32.674,32.674L17.642,81.75c-0.751,0.75-0.75,2.078,0,2.828l12.987,12.984C31.411,98.344,32.677,98.344,33.458,97.562z"
        />
      </g>
    </svg>
  );
};

export default ArrowLeftIcon;
