import React from 'react';

const SpeedometerSvg = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.12 204.12" xmlSpace="preserve">
      <g>
        <g>
          <path
            fill="currentColor"
            d="M99.682,117.637l0.075,1.131c0.186,3.139,2.806,5.597,5.962,5.597c3.289,0,5.97-2.681,5.97-5.984
			c0-3.285-2.67-5.955-5.959-5.955h-1.17L82.439,96.157L99.682,117.637z"
          />
          <path
            fill="currentColor"
            d="M33.323,41.218l2.738-2.527C51.9,24.129,71.477,15.357,92.681,13.342l3.912-0.372l0.014,24.483
			h6.277V12.916l3.733,0.179c23.492,1.092,45.788,10.608,62.773,26.809l2.641,2.516l-15.339,15.378l4.452,4.441l15.153-15.164
			l2.487,3.164c9.996,12.705,16.72,27.865,19.437,43.837l0.705,4.166l-21.101,0.014v6.292h21.641l0.172,4.32
			c0.036,0.551,0.072,1.092,0.072,1.653c0,17.114-4.559,34.01-13.192,48.858l-2.33,4.008l-16.788-16.77l-4.434,4.445l14.595,14.598
			h10.364c10.601-16.459,16.194-35.474,16.194-55.143c0-56.263-45.788-102.047-102.065-102.047C45.781,8.471,0,54.255,0,110.518
			c0,19.691,5.601,38.705,16.198,55.143h10.579l14.902-13.02l-4.13-4.738L19.92,163.314l-2.187-3.729
			c-8.718-14.92-13.32-31.877-13.32-49.066c0-0.562,0.036-1.102,0.082-1.653l0.165-4.309l20.872-0.021v-6.292H5.189l0.705-4.173
			c2.827-16.663,9.935-32.321,20.528-45.283l2.498-3.056L43.92,60.715l0.487-0.476l0.505-0.523l3.454-3.45L33.323,41.218z"
          />
          <rect x="58.049" y="188.967" fill="currentColor" width="88.025" height="6.682" />
        </g>
      </g>
    </svg>
  );
};

export default SpeedometerSvg;
