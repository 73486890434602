import { ExtendedCreateAlldeviceTaskBodyDto } from '@/features/home';
import Dexie, { Table } from 'dexie';

export interface AllDeviceRequest {
  id?: number;
  type: 'create' | 'update' | 'delete';
  payload: ExtendedCreateAlldeviceTaskBodyDto;
}

export class AllDeviceRequestDb extends Dexie {
  requests!: Table<AllDeviceRequest, number>;

  constructor() {
    super('allDeviceRequestsDb');
    this.version(1).stores({
      requests: '++id, type, payload',
    });
  }
}

export const allDeviceRequestsDb = new AllDeviceRequestDb();
