import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const ArrivedIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      version="1.1"
      className={iconStyles[iconSizeClasses[size]]}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      fill="currentColor"
    >
      <path
        d="M26.5,4.8c-0.3-0.2-0.7-0.2-1,0c-3.5,2.2-6.1,0.8-9-0.8c-3-1.6-6.5-3.4-11-0.7C5.2,3.6,5,3.9,5,4.3V18v0.3V29
	c0,0.6,0.4,1,1,1s1-0.4,1-1V18.9c3.3-1.8,5.7-0.5,8.5,1c1.9,1,4,2.1,6.4,2.1c1.4,0,3-0.4,4.6-1.4c0.3-0.2,0.5-0.5,0.5-0.9v-14
	C27,5.3,26.8,5,26.5,4.8z"
      />
    </svg>
  );
};

export default ArrivedIcon;
