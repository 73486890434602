export interface EmployeeData {
  id: number;
  firstname: string;
  surname: string;
  email: string;
  phone: string;
  workPhone: string;
  address: string;
  profession?: {
    name: string;
    id: number;
  };
}

export interface LoggedUserDto {
  isLogged: boolean;
  token: string;
  user: EmployeeData | null;
}

export interface DriverTokenDto {
  token: string;
  qrCode: string;
  url: string;
}

export enum UserProfession {
  Distributor = 1,
  Driver = 2,
  Loader = 3,
  Logistic = 8,
  TechnicalManager = 9,
  Mechanic = 10,
  DriverMechanic = 11,
  DriverLoader = 14,
  HandlingManager = 23,
}

export enum TokenUser {
  Driver = 'driver',
  Loader = 'loader',
}
