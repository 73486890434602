import Dexie from 'dexie';
import { WaypointUnloadDeleteDto } from '@/services/discharge-waypoints.service';
import { WaypointUnloadCreateDto, WaypointUnloadUpdateDto } from '@ekt-group/general-purpose-api-interfaces';

export enum UnloadRequestType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export interface UnloadRequest {
  id?: number;
  type: UnloadRequestType;
  body: (WaypointUnloadUpdateDto | WaypointUnloadCreateDto) | WaypointUnloadDeleteDto;

  isEditing?: boolean;
  timestamp?: number;
}

export class UnloadsRequestsDb extends Dexie {
  requests: Dexie.Table<UnloadRequest, number>;

  constructor() {
    super('unloadsRequestsDb');

    this.version(1).stores({
      requests: '++id, type, body',
    });
  }
}

export const unloadsRequestsDb = new UnloadsRequestsDb();
