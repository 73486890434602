import { cn } from '@/utils/cn';
import { AllDeviceTask } from '@ekt-group/bi-services-api-interfaces';
import React, { useMemo, useState } from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { ComboBoxOption } from '@/types/common';
import { CheckUpCarProblemCell } from './CheckUpCarProblemCell';
import { isFirefox } from 'react-device-detect';
import { ArrowDown } from '@/components/icons/ArrowDown';
import { ArrowUp } from '@/components/icons/ArrowUp';
import { useTranslation } from 'react-i18next';

export interface CheckUpCarProblemsTableProps {
  tasksData: AllDeviceTask[];
  priorityOptions: ComboBoxOption[];
}

const CheckUpCarProblemsTable = ({ tasksData, priorityOptions }: CheckUpCarProblemsTableProps) => {
  const columnHelper = createColumnHelper<AllDeviceTask>();
  const { t } = useTranslation('carCheckUpModal');

  const renderSortingIcon = (sorted: 'asc' | 'desc') => {
    if (sorted === 'asc') {
      return <ArrowDown className="dark:text-white" size="sm" />;
    }
    if (sorted === 'desc') {
      return <ArrowUp className="dark:text-white" size="sm" />;
    }
    return <></>;
  };

  const columns = [
    columnHelper.accessor('serviceDate', {
      cell: CheckUpCarProblemCell,
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center justify-center">
            {t('table.column.serviceDate')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
    columnHelper.accessor('serviceName', {
      cell: CheckUpCarProblemCell,
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center">
            {t('table.column.serviceName')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
    columnHelper.accessor('serviceDescription', {
      cell: CheckUpCarProblemCell,
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center">
            {t('table.column.serviceDescription')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
    columnHelper.accessor('taskStatus', {
      cell: CheckUpCarProblemCell,
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center justify-center">
            {t('table.column.taskStatus')} {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
    columnHelper.accessor('priority', {
      cell: CheckUpCarProblemCell,
      meta: { priorityOptions },
      header: ({ column }) => {
        const isSorted = column.getIsSorted();
        return (
          <div className="flex items-center justify-center">
            {t('table.column.priority')}
            {renderSortingIcon(isSorted as 'asc' | 'desc')}
          </div>
        );
      },
    }),
  ];

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'serviceDate',
      desc: true,
    },
  ]);

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: tasksData,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel<AllDeviceTask>(),
    getSortedRowModel: getSortedRowModel(),
  });

  const renderTasksRows = () => {
    return (
      <>
        {getRowModel().rows.map((row) => {
          return (
            <tr
              key={row.id}
              className={cn(
                'group dark:bg-slate-300 md:py-0 md:h-[1px] dark:text-black bg-gray-200 hover:bg-gray-400 dark:hover:bg-gray-400 even:bg-gray-300',
              )}
            >
              {row.getVisibleCells().map((cell) => (
                <td className={cn('h-full p-0 py-1 md:py-2 ', 'block md:table-cell', { 'md:h-[1px]': !isFirefox })} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </>
    );
  };

  const renderTasksHeader = () => {
    return (
      <>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="">
            {headerGroup.headers.map((header) => {
              const currentHeader = header.column.id;
              const isSortable = header.column.getCanSort();
              const sortingToggler = header.column.getToggleSortingHandler();
              const isSorted = header.column.getIsSorted();

              return (
                <th
                  key={header.id}
                  className={cn(
                    'bg-gray-300 dark:bg-gray-800 dark:text-white px-2 py-4 dark:border-white hover:bg-gray-400 dark:hover:bg-gray-400',
                    'text-lg',
                    'hidden md:table-cell',
                    {
                      'w-1/12 text-center py-1':
                        currentHeader === 'serviceDate' || currentHeader === 'priority' || currentHeader === 'taskStatus',
                      'w-5/12 text-center': currentHeader === 'serviceName',
                      'w-4/12 text-center': currentHeader === 'serviceDescription',
                      'cursor-pointer select-none': isSortable,
                      'bg-gray-300': isSorted,
                    },
                  )}
                  onClick={sortingToggler}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              );
            })}
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col flex-1 w-full md:py-2 md:px-2 gap-y-3">
      <div className="h-full overflow-auto">
        <table className="min-w-full overflow-hidden text-center border-collapse shadow-lg">
          <thead className="">{renderTasksHeader()}</thead>
          <tbody className="">{renderTasksRows()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default CheckUpCarProblemsTable;
