import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { RouteStartFinishRequest, routeStartFinishRequestsDb, RouteStartFinishRequestType } from '../database';
import { useLiveQuery } from 'dexie-react-hooks';
import { useStartMutation, useFinishMutation, useRouteGroupItemsRequest } from '@/features/home';
import { useProcessor } from './useProcessor';

export function useRouteStartFinishRequestsProcessor() {
  const requests = useLiveQuery(() => routeStartFinishRequestsDb.requests.toArray());
  const isOffline = useOfflineStatus();

  const [start] = useStartMutation();
  const [finish] = useFinishMutation();
  const { updateOdometerRTKCache } = useRouteGroupItemsRequest();

  const processRequest = async (request: RouteStartFinishRequest) => {
    const { routeGroupItemId, carId, type, startTime, endTime, startOdometerValue, endOdometerValue } = request;

    switch (type) {
      case RouteStartFinishRequestType.Start: {
        await start({ id: routeGroupItemId, startTime, odometer: startOdometerValue }).unwrap();
        updateOdometerRTKCache({ carId, odometer: startOdometerValue });

        break;
      }
      case RouteStartFinishRequestType.Finish: {
        await finish({
          id: routeGroupItemId,
          endTime,
          odometer: endOdometerValue,
          startOdometer: startOdometerValue,
        }).unwrap();
        updateOdometerRTKCache({ carId, odometer: endOdometerValue });

        break;
      }
      default: {
        throw new Error(`Unknown request type: ${type}`);
      }
    }
  };

  const deleteRequest = (requestId: number) => routeStartFinishRequestsDb.requests.delete(requestId);

  useProcessor<RouteStartFinishRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing route start finish request failed',
  });
}
