import React, { ChangeEvent, useState } from 'react';
import { manualImageCompression } from '../../utils/compressImage';
import { toast } from 'react-toastify';
import { cn } from '../../utils/cn';
import CrossMark from '../icons/CrossMark';

interface PhotoUploadsProps {
  doAfterCompression: (file: File) => void;
  className?: string;
  onPhotoRemoved?: (file: File[]) => void;
  defaultValues?: File[];
}

export const PhotoUploads = ({ doAfterCompression, className, onPhotoRemoved, defaultValues }: PhotoUploadsProps) => {
  const [files, setFiles] = useState<File[]>(defaultValues || []);
  const [message, setMessage] = useState('');

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage('');
    const formFiles = e.target.files;

    for (let i = 0; i < formFiles.length; i++) {
      const fileType = formFiles[i]['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      if (!validImageTypes.includes(fileType)) {
        setMessage('only images accepted');
        continue;
      }

      manualImageCompression(formFiles[i], {
        onSuccess: (result, decodedImage) => {
          doAfterCompression && doAfterCompression(result);
          setFiles((prevFiles) => [...prevFiles, result]);
        },
        onError: (err) => {
          toast(err.message, { type: 'error' });
        },
        printTimestamp: true,
      });
    }
  };

  const removeImage = (fileIndex: number) => {
    const newFiles = files.filter((file, index) => index !== fileIndex);
    setFiles(newFiles);
    onPhotoRemoved && onPhotoRemoved(newFiles);
  };

  const isPhotoInCache = files.length > 0;

  return (
    <>
      <div className={cn('flex h-full w-full', className)}>
        <div className="flex-1 rounded-lg shadow-xl bg-gray-50">
          <span className="flex justify-center text-[12px] mb-1 text-red-500">{message}</span>
          <div className="h-full p-4">
            <div
              className={cn('flex items-center justify-center md:w-full ', {
                'h-20 md:h-48': isPhotoInCache,
                'h-full': !isPhotoInCache,
              })}
            >
              <label className="flex justify-center cursor-pointer flex-col max-w-sm md:max-w-full md:w-full border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300 h-full">
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Select a photo</p>
                </div>
                <input type="file" onChange={handleFile} className="opacity-0" multiple name="files[]" />
              </label>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {files.map((file: File, index: number) => {
                return (
                  <div key={index} className="overflow-hidden relative">
                    <CrossMark
                      onClick={() => {
                        removeImage(index);
                      }}
                      size="sm"
                      className="absolute top-1 right-1 hover:text-red-200 cursor-pointer text-red-600"
                    />

                    <img className="max-h-dynamic-20 md:max-w-md rounded-md" src={URL.createObjectURL(file)} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
