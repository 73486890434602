import React, { PropsWithChildren, useEffect, useRef } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { cn } from '@/utils/cn';

interface Props {
  className?: string;
  outerClassName?: string;
}

const VerticalShadowScroll = ({ children, className, outerClassName }: PropsWithChildren<Props>) => {
  const { width } = useWindowDimensions();

  const containerRef = useRef<HTMLDivElement>(null);
  const topShadowRef = useRef<HTMLDivElement>(null);
  const bottomShadowRef = useRef<HTMLDivElement>(null);

  const calculateShadows = ({
    scrollTop,
    scrollHeight,
    offsetHeight,
  }: {
    scrollHeight: number;
    scrollTop: number;
    offsetHeight: number;
  }) => {
    if (topShadowRef.current === null || bottomShadowRef.current === null) {
      return;
    }

    if (offsetHeight >= scrollHeight) {
      topShadowRef.current.style.opacity = '0';
      bottomShadowRef.current.style.opacity = '0';
      return;
    }

    const currentScroll = scrollTop / (scrollHeight - offsetHeight);
    topShadowRef.current.style.opacity = currentScroll.toString();
    bottomShadowRef.current.style.opacity = (1 - currentScroll).toString();
  };

  const handleScroll = (event: Event) => {
    const { scrollHeight, scrollTop, offsetHeight } = (event as unknown as React.UIEvent<HTMLDivElement, UIEvent>).currentTarget;
    requestAnimationFrame(() => calculateShadows({ scrollHeight, scrollTop, offsetHeight }));
  };

  const handleResize = (container: HTMLDivElement) => {
    const { scrollHeight, scrollTop, offsetHeight } = container;
    calculateShadows({ scrollHeight, scrollTop, offsetHeight });
  };

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    handleResize(container);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    handleResize(containerRef.current);
  }, [containerRef.current]);

  return (
    <div className={cn('relative overflow-hidden', outerClassName)}>
      <div className={cn('shadow-scroll__content vertical', className)} ref={containerRef}>
        {children}
      </div>
      <div className="shadow-scroll__shadow shadow-scroll__shadow-top" ref={topShadowRef} />
      <div className="shadow-scroll__shadow shadow-scroll__shadow-bottom" ref={bottomShadowRef} />
    </div>
  );
};

export default VerticalShadowScroll;
