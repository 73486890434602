import React, { useRef, useState } from 'react';
import Input from './Input';

const formatNumber = (value: string) => {
  const formatted = value
    .replace(/[^0-9.,]/g, '')
    .replace(/^\./, '')
    .replace(/\.+/g, '.')
    .replace(/(\d+)\.(\d+)\..*/, '$1.$2')
    .replace(/^0+(?=\d)/, '');

  return formatted.slice(0, 20);
};

const replaceCommaWithDots = (value: string) => {
  return value.replace(/,/, '.');
};

const removeTrailingDots = (value: string) => {
  return value.replace(/\.+$/, '');
};

export const roundNumberByStep = (value: string, step: number) => Number((Math.round(Number(value) / step) * step).toFixed(1));

interface IncrementingInputProps {
  step?: number;
  min?: number;
  max?: number;
  value: number;
  onChange: (newValue: number) => void;
  isDisabled?: boolean;
}

const IncrementingInput = ({ step = 1, min, max, value = 0, onChange, isDisabled }: IncrementingInputProps) => {
  const [inputValue, setInputValue] = useState<string>((value || 0).toString());
  const previousAcceptedValue = useRef<string>('0');

  const isValidInput = (value: string): boolean => {
    const numericValue = Number(value);
    return !isNaN(numericValue) && (min === undefined || numericValue >= min) && (max === 0 || max === undefined || numericValue <= max);
  };

  const isWithinStep = (value: number): boolean => {
    if (min === undefined) return true;
    const stepCount = Math.round((value - min) / step);
    return Math.abs(value - (min + stepCount * step)) < 1e-8;
  };

  const handleIncrement = () => {
    const newValue = roundNumberByStep((Number(inputValue) + step).toString(), step);
    if (isValidInput(newValue.toString()) && isWithinStep(newValue)) {
      setInputValue(newValue.toString());
      previousAcceptedValue.current = newValue.toString();

      onChange(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = roundNumberByStep((Number(inputValue) - step).toString(), step);

    if (isValidInput(newValue.toString()) && isWithinStep(newValue)) {
      setInputValue(newValue.toString());
      previousAcceptedValue.current = newValue.toString();

      onChange(newValue);
    }
  };

  const handleValueChange = (newValue: string) => {
    const formattedValue = formatNumber(newValue);
    if (isValidInput(formattedValue)) {
      setInputValue(formattedValue);

      const numericValue = Number(formattedValue);
      if (isWithinStep(numericValue)) {
        previousAcceptedValue.current = removeTrailingDots(formattedValue);
      }

      onChange(Number(removeTrailingDots(replaceCommaWithDots(formattedValue))));
    }
  };

  return (
    <div className="incrementing-input">
      <button onClick={handleDecrement} disabled={isDisabled || (min !== undefined && Number(inputValue) === min)}>
        -
      </button>
      <Input
        value={inputValue}
        onChange={handleValueChange}
        type="text"
        inputMode="decimal"
        placeholder="0"
        disabled={isDisabled}
        onBlur={(event) => {
          const newValue = event.target.value;
          const formattedNewValue = removeTrailingDots(replaceCommaWithDots(newValue));
          const numericValue = Number(formattedNewValue);

          if (isValidInput(formattedNewValue) && isWithinStep(numericValue)) {
            setInputValue(formattedNewValue);
            previousAcceptedValue.current = formattedNewValue;
          } else {
            setInputValue(previousAcceptedValue.current || '0');
          }
        }}
      />
      {/* Max = 0 is considered to have no limit */}
      <button onClick={handleIncrement} disabled={isDisabled || (max !== undefined && max > 0 && Number(inputValue) === max)}>
        +
      </button>
    </div>
  );
};

export default IncrementingInput;
