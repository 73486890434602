import { cn } from '@/utils/cn';
import { Task } from '@ekt-group/general-purpose-api-interfaces';

interface ConstructionTaskListProps {
  tasksData: Task[];
  selectedTask?: Task;
  onTaskClick?: (task: Task) => void;
  listRef?: React.RefObject<HTMLDivElement>;
}

export const ConstructionTaskList = ({ tasksData, selectedTask, onTaskClick, listRef }: ConstructionTaskListProps) => {
  return (
    <div
      ref={listRef}
      className={cn(
        'h-[calc(var(--viewport-height))] pb-5',
        'flex flex-col place-items-center gap-2 right-0 top-0 p-1 pt-0 md:pt-1 w-full',
        'text-base md:text-xl overflow-y-auto scrollbar-hide z-10 shadow-xl',
      )}
    >
      {tasksData?.map((task) => {
        const isTaskSelected = selectedTask?.id === task.id;

        return (
          <div
            key={task.id}
            data-id={`${task.id}`}
            className={cn('flex flex-col w-full gap-2 bg-white dark:bg-gray-800 p-2 rounded shadow cursor-pointer leading-5', {
              'bg-blue-600 dark:bg-blue-800 text-gray-50': isTaskSelected,
            })}
            onClick={() => onTaskClick(task)}
          >
            <p>Activity type: {task?.activityType}</p>
            {isTaskSelected && (
              <>
                <p>Activity description: {task?.articleDescription}</p>
                <p>ACtivity penalty percent: {task?.penaltyPercent}</p>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
