import React, { useState } from 'react';
import ReactSimpleImageViewer from 'react-simple-image-viewer';

interface AccidentChatPicturesListProps {
  pictures: any[];
}

const AccidentChatPicturesList = ({ pictures }: AccidentChatPicturesListProps) => {
  const [isViewerOpened, setIsViewerOpened] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const getPictureContent = (picture: any) => {
    const type = picture.originalName.split('.').pop();
    const prefix = `data:image/${type};base64,`;

    return prefix + picture.content;
  };

  const images = pictures.map((picture) => ({ ...picture, content: getPictureContent(picture) }));

  return (
    <div className={'flex flex-wrap overflow-auto gap-2 md:gap-4'}>
      {images.map((image, index) => (
        <img
          className={'w-full h-32 md:w-48 md:h-40 object-cover border border-gray-400 rounded cursor-pointer'}
          key={image.originalName}
          src={image.content}
          alt={image.originalName}
          onClick={() => {
            setCurrentImage(index);
            setIsViewerOpened(true);
          }}
        />
      ))}

      {isViewerOpened && (
        <ReactSimpleImageViewer
          src={images.map(({ content }) => content)}
          currentIndex={currentImage}
          closeOnClickOutside
          disableScroll
          onClose={() => setIsViewerOpened(false)}
        />
      )}
    </div>
  );
};

export default AccidentChatPicturesList;
