import React, { useEffect, useState } from 'react';

interface CheckboxProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  id: string;
}

const Checkbox = ({ value = false, onChange, label, id }: CheckboxProps) => {
  const [isSelected, setIsSelected] = useState(value);

  useEffect(() => {
    onChange(isSelected);
  }, [isSelected]);

  return (
    <div
      className={'flex items-center gap-2 max-w-fit'}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <input
        className={'h-12 w-12'}
        type="checkbox"
        id={id}
        checked={isSelected}
        onChange={(event) => {
          setIsSelected(event.target.checked);
        }}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
};

export default Checkbox;
