import React, { PropsWithChildren } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { conditionalClassNames } from '../../helpers/dom';

interface NotificationBadgeWrapperProps extends PropsWithChildren {
  notificationsAmount: number | null;
  bounce?: boolean;
  onlyOnMobile?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const MOBILE_WIDTH_BREAKPOINT = 767;

const sizes = {
  sm: 'h-4 w-4 text-sm',
  md: 'h-6 w-6 text-base',
  lg: 'h-8 w-8',
};

const NotificationBadgeWrapper = ({ notificationsAmount, bounce, children, onlyOnMobile, size = 'md' }: NotificationBadgeWrapperProps) => {
  const { isMobileScreen } = useWindowDimensions();

  if (!notificationsAmount || (onlyOnMobile && isMobileScreen)) {
    return <div className={'relative'}>{children}</div>;
  }

  return (
    <div className={'relative'}>
      <div
        className={conditionalClassNames({
          'absolute -top-3 flex justify-center items-center -right-4 bg-red-600 text-white rounded-full aspect-square': true,
          'animate-bounce': bounce,
          [sizes[size]]: true,
        })}
      >
        <span className={'mx-2 notification-span'}>{notificationsAmount}</span>
      </div>
      {children}
    </div>
  );
};

export default NotificationBadgeWrapper;
