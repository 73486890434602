import React, { useEffect } from 'react';
import gasCardsService from '../api/gas-cards.service';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import Select from '@/components/elements/Select';
import Spinner from '@/components/elements/Spinner';
import InfoIcon from '@/components/icons/InfoIcon';
import {
  selectFuelAmount,
  selectGasCards,
  selectIsFuelingSaving,
  selectIsLoadingGasCards,
  selectSelectedFuelId,
  selectSelectedGasCard,
  setFuelAmount,
  setGasCards,
  setIsFuelingSaving,
  setIsLoadingGasCards,
  setSelectedGasCardId,
} from '@/features/fueling';
import SaveIcon from '@/components/icons/SaveIcon';
import { toast } from 'react-toastify';
import { HeaderItem } from '@/types/common';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { waypointsTanksRequestsDb } from '@/database';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useCreateWaypointTankMutation } from '@/features/route';
import useGeolocation from 'react-hook-geolocation';
import FuelManualEntryForm from '../components/FuelManualEntryForm';

export const FuelingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { latitude, longitude } = useGeolocation();
  const { t, i18n } = useTranslation('fuelPage');

  const { id: routeGroupItemId, carId } = useSelector(selectCurrentRouteGroupItem) ?? {};
  const { id: gasCardId, fuelVendorId, manualEntryAllowed, pin: gasCardPin } = useSelector(selectSelectedGasCard) ?? {};
  const gasCards = useSelector(selectGasCards);
  const fuelId = useSelector(selectSelectedFuelId);
  const fuelAmount = useSelector(selectFuelAmount);
  const isLoading = useSelector(selectIsLoadingGasCards);
  const isSaving = useSelector(selectIsFuelingSaving);

  const isOffline = useOfflineStatus();
  const [createWaypointTank] = useCreateWaypointTankMutation();

  const saveFueling = async () => {
    if (fuelAmount === 0) {
      toast(t('enterFuelAmount'), { type: 'warning' });
      return;
    }

    dispatch(setIsFuelingSaving(true));

    try {
      const payload = {
        body: {
          latitude,
          longitude,
          routeGroupItemId,
          carId,
          cardId: gasCardId,
          fuelVendorId,
          fuelId,
          value: fuelAmount,
        },
      };

      await createWaypointTank(payload.body)
        .unwrap()
        .catch((error) => {
          waypointsTanksRequestsDb.requests.add(payload);
        });

      navigate(`/route/${routeGroupItemId}`);
    } finally {
      dispatch(setIsFuelingSaving(false));
      dispatch(setFuelAmount(0));
    }
  };

  const getNavigationItems = (): HeaderItem[] => {
    return [
      {
        label: t('save', { ns: 'common' }),
        icon: <SaveIcon size="md" />,
        disabled: gasCardId ? !manualEntryAllowed : true,
        action: saveFueling,
        isPending: isSaving,
      },
      {
        label: t('back', { ns: 'common' }),
        icon: <ArrowLeftIcon size={'md'} />,
        style: 'primary',
        action: () => navigate(`/route/${routeGroupItemId}`),
      },
    ];
  };

  const { setNavigationItems } = useAdvancedNavigationItems();

  const getGasCard = async () => {
    if (isLoading) {
      return;
    }

    dispatch(setIsLoadingGasCards(true));
    try {
      const cards = await gasCardsService.getGasCards(routeGroupItemId);
      dispatch(setGasCards(cards));
      dispatch(setSelectedGasCardId(cards[0]?.id));
    } finally {
      dispatch(setIsLoadingGasCards(false));
    }
  };

  const onCardChange = (cardId: string) => {
    const card = gasCards.find((card) => card.id === Number(cardId));
    dispatch(setSelectedGasCardId(card.id));
  };

  useEffect(() => {
    if (gasCards.length > 0) {
      return;
    }

    setNavigationItems(getNavigationItems());

    getGasCard();
  }, []);

  useEffect(() => {
    setNavigationItems(getNavigationItems());
  }, [gasCardId, isSaving, fuelAmount, fuelId, latitude, longitude, i18n.resolvedLanguage]);

  if (isLoading) {
    return <Spinner size="md" />;
  }

  return (
    <div className="fueling-page">
      <div className="fueling-page__wrapper">
        <div className="fueling-page__gas-cards-select">
          <span>{t('refuelCard')}:</span>
          <div>
            <Select
              options={gasCards.map((card) => ({ label: `${card.fuelVendor.name} ${card.code}`, value: card.id }))}
              value={gasCardId}
              onChange={onCardChange}
              size="lg"
            />
          </div>
        </div>
        <div className="fueling-page__gas-card-pin">
          <span>{t('refuelCard')} PIN:</span>
          <span className="font-bold">{gasCardPin}</span>
        </div>
        {manualEntryAllowed ? (
          <FuelManualEntryForm vendorId={fuelVendorId} />
        ) : (
          <div className="fueling-page__information">
            <InfoIcon size={'md'} />
            {t('automaticRefuelingInfo')}
          </div>
        )}
      </div>
    </div>
  );
};
