import { RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem, setCurrentRouteGroupItemMode } from '@/features/home';
import { formatToEstonianDate, getTodayDate } from '@/helpers/dateHelper';
import { unifiedString } from '@/helpers/strings';
import { selectMode } from '@/store/settings/slice';
import { AppMode } from '@/types/common';
import { conditionalClassNames } from '@/helpers/dom';
import Button from '@/components/elements/Button';
import StartIcon from '@/components/icons/StartIcon';
import EyeIcon from '@/components/icons/EyeIcon';
import ArrivedIcon from '@/components/icons/ArrivedIcon';
import { CurrentRouteGroupItemMode } from '@/features/home';
import TruckIcon from '@/components/icons/TruckIcon';
import CalendarIcon from '@/components/icons/CalendarIcon';
import RouteIcon from '@/components/icons/RouteIcon';
import LoaderIcon from '@/components/icons/LoaderIcon';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CheckMark from '@/components/icons/CheckMark';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import { useUserProfession } from '@/features/home';
import PhoneCallIcon from '@/components/icons/PhoneCallIcon';

interface RouteGroupItemProps {
  item: RouteGroupItem;
  shouldForbidStart?: boolean;
  onStartOrFinishButtonClick: (routeGroupItemId: number, action: 'start' | 'finish', isActive: boolean, shouldForbidStart: boolean) => void;
  onContinueOrOpenButtonClick?: (routeGroupItemId: number) => void;
}

const RouteGroupItemListItem = ({
  item,
  shouldForbidStart,
  onStartOrFinishButtonClick,
  onContinueOrOpenButtonClick,
}: RouteGroupItemProps) => {
  const [displayDriverPhone, setDisplayDriverPhone] = useState(false);

  const { t } = useTranslation();
  const { isMobileScreen } = useWindowDimensions();
  const { isUserLoader } = useUserProfession();

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  const appMode = useSelector(selectMode);

  const isBasicAppMode = appMode === AppMode.Basic;

  const isTodayRoute = item?.date === getTodayDate();
  const isActive = isBasicAppMode ? currentRouteGroupItem?.id === item?.id : item?.start && !item?.end && !item?.isDone && isTodayRoute;
  const isFinished = item?.isDone && !item?.isInspected;
  const isInspected = item?.isDone && item?.isInspected;

  const { car, routeGroup, loader, driver, isDischargeSheet, sheetType, name: routeGroupItemName } = item;

  const handleClick = () => {
    if (isBasicAppMode) {
      onContinueOrOpenButtonClick?.(item.id);
    }
  };

  const handleClickOnDriver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setDisplayDriverPhone(true);
  };

  const handlePhoneClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (!driver.workPhone) {
      return;
    }
    window.open(`tel:${driver.workPhone}`, '_self');
  };

  return (
    <div
      className={conditionalClassNames({
        'route-group-item': true,
        'route-group-item__active': isActive,
      })}
      onClick={handleClick}
    >
      {isActive && isBasicAppMode && <div className="marker">{t('current')}</div>}
      <div className="route-group-item__labels">
        <div className={'leading-6'}>
          <RouteIcon size={'md'} /> {isDischargeSheet ? routeGroup?.name : routeGroupItemName}
        </div>
        <div>
          <CalendarIcon size={'md'} />
          {formatToEstonianDate(item.date)}
        </div>
        {car?.carNumber && (
          <div>
            <TruckIcon size={'md'} />
            {car?.carNumber}
          </div>
        )}
        {isUserLoader() && item.driver && (
          <div onClick={handleClickOnDriver} className={'cursor-pointer'}>
            <LoaderIcon size={'md'} />
            {unifiedString([driver.firstname, driver.surname], ' ')}
            {displayDriverPhone && (
              <div className="flex items-center gap-2" onClick={handlePhoneClick}>
                <PhoneCallIcon size={'md'} />
                {driver.workPhone ? driver.workPhone : '— — —'}
              </div>
            )}
          </div>
        )}
        {!isUserLoader() && item.loader && (
          <div>
            <LoaderIcon size={'md'} />
            {unifiedString([loader.firstname, loader.surname], ' ')}
          </div>
        )}
      </div>

      {!isBasicAppMode && (
        <div className="route-group-item__actions" onClick={(event) => event.stopPropagation()}>
          {isTodayRoute && (
            <>
              {isFinished || isInspected ? (
                <div className={'flex items-center gap-4 justify-center h-[3.5rem] ml-6 md:ml-0 md:mr-8'}>
                  <span>{t('finished')}</span>
                  <CheckMark size={isMobileScreen ? 'sm' : 'md'} />
                </div>
              ) : (
                <Button
                  className="md:w-56 w-full"
                  text={isActive ? t('finish') : t('start')}
                  size={isMobileScreen ? 'md' : 'lg'}
                  color={isActive ? 'primary' : 'success'}
                  onClick={() => onStartOrFinishButtonClick(item.id, isActive ? 'finish' : 'start', isActive, shouldForbidStart)}
                  disabled={isFinished}
                  icon={isActive ? <ArrivedIcon size={isMobileScreen ? 'sm' : 'md'} /> : <StartIcon size={isMobileScreen ? 'sm' : 'md'} />}
                />
              )}
            </>
          )}
          <Button
            className="md:w-56 w-full"
            text={isActive ? t('continue') : t('open')}
            size={isMobileScreen ? 'md' : 'lg'}
            color={isActive ? 'warning' : 'primary'}
            icon={isActive ? <StartIcon size={isMobileScreen ? 'sm' : 'md'} /> : <EyeIcon size={isMobileScreen ? 'sm' : 'md'} />}
            onClick={() => onContinueOrOpenButtonClick?.(item.id)}
          />
        </div>
      )}
    </div>
  );
};

export default RouteGroupItemListItem;
