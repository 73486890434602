import { useCallback, useState } from 'react';
import waypointsService from '../../../services/discharge-waypoints.service';
import serviceWaypoints from '../../../services/service-waypoints.service';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useDispatch } from 'react-redux';

export function useArrivedWaypoints() {
  const [arrivedWaypointsIds, setArrivedWaypointsIds] = useState<number[]>([]);
  const [isDischargeArrivedRoute, setIsDischargeArrivedRoute] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const isOffline = useOfflineStatus();

  const getArrivedWaypointIds = useCallback(async () => {
    if (isLoading || isOffline) {
      return;
    }

    setIsLoading(true);
    try {
      const [arrivedWaypoints, arrivedServiceWaypoints] = await Promise.all([
        waypointsService.getArrivedWaypointIds(),
        serviceWaypoints.getArrivedWaypoints(),
      ]);

      const { waypointIds, routeGroupItemId, timestamp } = arrivedWaypoints;
      const {
        waypointIds: serviceWaypointIds,
        routeGroupItemId: serviceRouteGroupItemId,
        timestamp: serviceTimestamp,
      } = arrivedServiceWaypoints;

      if (!timestamp && !serviceTimestamp) {
        return;
      }
      if (timestamp && !serviceTimestamp) {
        setArrivedWaypointsIds(waypointIds);
        setIsDischargeArrivedRoute(true);
        return;
      }
      if (!timestamp && serviceTimestamp) {
        setArrivedWaypointsIds(serviceWaypointIds);
        setIsDischargeArrivedRoute(false);
        return;
      }

      const isWaypointsArrivedAfterServiceWaypoints = new Date(timestamp) > new Date(serviceTimestamp);

      setArrivedWaypointsIds(isWaypointsArrivedAfterServiceWaypoints ? waypointIds : serviceWaypointIds);
      setIsDischargeArrivedRoute(!isWaypointsArrivedAfterServiceWaypoints);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, isOffline, dispatch]);

  return { arrivedWaypointsIds, isLoading, isDischargeArrivedRoute, getArrivedWaypointIds };
}
