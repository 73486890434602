import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const LightningIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      className={iconStyles[iconSizeClasses[size]]}
      height="800px"
      width="800px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <polygon
          fill={'currentColor'}
          points="386.415,193.208 287.481,193.208 359.434,0 161.566,0 125.585,280.151 206.528,280.151 170.557,512"
        />
      </g>
    </svg>
  );
};

export default LightningIcon;
