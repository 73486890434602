import { getDistanceInMeters } from '@/helpers/coordinates';
import { METERS_AMOUNT_TO_DISABLE_SET_COORDINATES_BUTTON } from '@/constants/waypoints';
import React from 'react';
import { toast } from 'react-toastify';
import { CoordinateUpdateEntity, CoordinateUpdateRequest, coordinateUpdateRequestsDb } from '@/database';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsCurrentRouteGroupItemOpenedMode, Waypoint } from '@/features/home';
import { useUpdateWaypointCoordinatesMutation } from '../api';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import useGeolocation from 'react-hook-geolocation';

export function useCoordinatesClickHandler(waypoint: Waypoint) {
  const { longitude, latitude } = useGeolocation();
  const { t } = useTranslation('services');
  const [updateWaypointCoordinates] = useUpdateWaypointCoordinatesMutation();
  const isOffline = useOfflineStatus();

  const isRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);

  const isCarInAroundAllowedDistanceFromObject = () => {
    const objectCoordinates = {
      longitude: waypoint.object.longitude,
      latitude: waypoint.object.latitude,
    };

    return getDistanceInMeters({ longitude, latitude }, objectCoordinates) <= METERS_AMOUNT_TO_DISABLE_SET_COORDINATES_BUTTON;
  };

  const handleCoordinatesClick = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isRouteGroupItemOpenedMode) {
      return;
    }
    if (!longitude || !latitude) {
      toast(t('carPositionNotAvailable', { ns: 'arrivedPage' }), { type: 'warning' });
      return;
    }
    if (!isCarInAroundAllowedDistanceFromObject()) {
      toast(t('tooFarFromObject', { ns: 'arrivedPage' }), { type: 'warning' });
      return;
    }

    const payload: CoordinateUpdateRequest = {
      routeGroupItemId: waypoint.routeGroupItemId,
      body: {
        entity: CoordinateUpdateEntity.ServiceWaypoint,
        serviceIds: [waypoint.id],
        longitude,
        latitude,
      },
    };

    updateWaypointCoordinates(payload)
      .unwrap()
      .catch((error) => {
        coordinateUpdateRequestsDb.requests.add(payload);
      });
  };

  return { handleCoordinatesClick };
}
