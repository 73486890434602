import { io, Socket } from 'socket.io-client';
import { getBackendHost } from '@/helpers/backend';
import { ServerToTablet, TabletToServer } from '@ekt-group/general-purpose-api-interfaces';

const CONNECTION_PATH = '/tablet-stream';
const RECONNECTION_DELAY = 15000;

export const socket: Socket<ServerToTablet, TabletToServer> = io(getBackendHost(), {
  path: CONNECTION_PATH,
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: RECONNECTION_DELAY,
  retries: 10,
  withCredentials: true,
});
