import React, { useEffect, useState } from 'react';
import ModalWrapper from '@/components/modals/ModalWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { selectDisplayKeysModal, setDisplayKeysModal, selectGates, selectKeysAndRemotes } from '@/features/route';
import VerticalShadowScroll from '@/components/elements/VerticalShadowScroll';
import { useTranslation } from 'react-i18next';
import Button from '@/components/elements/Button';
import KeysModalListKeyAndRemoteItem from './KeysModalListKeyAndRemoteItem';
import { Gate } from '@ekt-group/general-purpose-api-interfaces';
import { selectIsCurrentRouteGroupItemOpenedMode } from '@/features/home';
import KeysModalListGateItem from './KeysModalListGateItem';
import KeysModalListObjectGateItem from './KeysModalListObjectGateItem';
import { conditionalClassNames } from '@/helpers/dom';
import { useCoordinatesHelper } from '@/helpers/coordinates';
import { selectObjectGates } from '@/store/waypoints/slice';

enum KeysModalGroup {
  KeysAndRemotes = 'keys-and-remotes',
  GsmGates = 'gsm-gates',
  ObjectGates = 'object-gates',
}

const RADIUS_FOR_GATE_DISPLAY = 500;

const KeysModal = () => {
  const [selectedGroupId, setSelectedGroupId] = useState<string>('keys-and-remotes');
  const [filteredGates, setFilteredGates] = useState<Gate[]>([]);
  const { t } = useTranslation('tabletPage');
  const { filterByRadius } = useCoordinatesHelper();
  const dispatch = useDispatch();

  const displayKeysModal = useSelector(selectDisplayKeysModal);
  const gates = useSelector(selectGates);
  const keysAndRemotes = useSelector(selectKeysAndRemotes);
  const objectGates = useSelector(selectObjectGates);
  const isRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);

  const groups = [
    {
      id: KeysModalGroup.KeysAndRemotes,
      label: t('keysModal.keysAndRemotes'),
    },
    {
      id: KeysModalGroup.GsmGates,
      label: t('keysModal.gsmGates'),
    },
    {
      id: KeysModalGroup.ObjectGates,
      label: t('keysModal.objectGates'),
    },
  ] as const;

  const closeModal = () => {
    dispatch(setDisplayKeysModal(false));
  };

  const filterGatesByRadius = async () => {
    const filtered = await filterByRadius<Gate>(gates, RADIUS_FOR_GATE_DISPLAY);
    setFilteredGates(filtered);
  };

  useEffect(() => {
    if (!displayKeysModal) {
      return;
    }

    filterGatesByRadius();
  }, [gates, displayKeysModal]);

  return (
    <ModalWrapper isVisible={displayKeysModal} wide className="h-fit py-2">
      <div className="h-full flex flex-col text-gray-800 dark:text-gray-100">
        <div className="keys-modal__group-select">
          {groups.map(({ id, label }) => (
            <button
              className={conditionalClassNames({
                'keys-modal__group-select__item': true,
                'keys-modal__group-select__item--active': selectedGroupId === id,
              })}
              onClick={() => setSelectedGroupId(id)}
              key={`group-${id}`}
            >
              {label}
            </button>
          ))}
        </div>
        <div className="keys-modal__content">
          <div className="keys-modal__content__group">
            <h1>{t('keysModal.keysAndRemotes')}</h1>
            {keysAndRemotes.length > 0 ? (
              <VerticalShadowScroll className="keys-modal__list">
                {keysAndRemotes.map((data) => (
                  <KeysModalListKeyAndRemoteItem data={data} key={`remote-${data.id}`} />
                ))}
              </VerticalShadowScroll>
            ) : (
              <p>{t('keysModal.noKeysAndRemotes')}</p>
            )}
          </div>
          <div className="keys-modal__content__group">
            <h1>{t('keysModal.gsmGates')}</h1>
            {isRouteGroupItemOpenedMode ? (
              <p>{t('keysModal.willBeDisplayedWhenRouteStarted')}</p>
            ) : filteredGates.length > 0 ? (
              <VerticalShadowScroll className="keys-modal__list">
                {filteredGates.map((gate) => (
                  <KeysModalListGateItem gate={gate} key={`gsm-${gate.id}`} />
                ))}
              </VerticalShadowScroll>
            ) : (
              <p>{t('keysModal.noGatesAroundInRadius', { radius: RADIUS_FOR_GATE_DISPLAY })}</p>
            )}
          </div>
          <div className="keys-modal__content__group">
            <h1>{t('keysModal.objectGates')}</h1>
            {isRouteGroupItemOpenedMode ? (
              <p>{t('keysModal.willBeDisplayedWhenRouteStarted')}</p>
            ) : objectGates.length > 0 ? (
              <VerticalShadowScroll className="keys-modal__list">
                {objectGates.map(({ description, objectId, routeGroupItemWaypointId }) => (
                  <KeysModalListObjectGateItem
                    objectId={objectId}
                    routeGroupItemWaypointId={routeGroupItemWaypointId}
                    label={description}
                    key={`object-gate-${objectId}`}
                  />
                ))}
              </VerticalShadowScroll>
            ) : (
              <p>{t('keysModal.noObjectGates')}</p>
            )}
          </div>
          <div className="keys-modal__content__group-mobile">
            {isRouteGroupItemOpenedMode && selectedGroupId !== 'keys-and-remotes' ? (
              <p>{t('keysModal.willBeDisplayedWhenRouteStarted')}</p>
            ) : selectedGroupId === KeysModalGroup.KeysAndRemotes && keysAndRemotes.length > 0 ? (
              <VerticalShadowScroll className="keys-modal__list">
                {keysAndRemotes.map((data) => (
                  <KeysModalListKeyAndRemoteItem data={data} key={`remote-${data.id}`} />
                ))}
              </VerticalShadowScroll>
            ) : selectedGroupId === KeysModalGroup.GsmGates && filteredGates.length > 0 ? (
              <VerticalShadowScroll className="keys-modal__list">
                {filteredGates.map((gate) => (
                  <KeysModalListGateItem gate={gate} key={`gsm-${gate.id}`} />
                ))}
              </VerticalShadowScroll>
            ) : selectedGroupId === KeysModalGroup.GsmGates && filteredGates.length === 0 ? (
              <p>{t('keysModal.noGatesAroundInRadius', { radius: RADIUS_FOR_GATE_DISPLAY })}</p>
            ) : selectedGroupId === KeysModalGroup.ObjectGates && objectGates.length > 0 ? (
              <VerticalShadowScroll className="keys-modal__list">
                {objectGates.map(({ description, objectId, routeGroupItemWaypointId }) => (
                  <KeysModalListObjectGateItem
                    label={description}
                    objectId={objectId}
                    routeGroupItemWaypointId={routeGroupItemWaypointId}
                    key={`object-gate-${objectId}`}
                  />
                ))}
              </VerticalShadowScroll>
            ) : (
              <p>{t('noData', { ns: 'common' })}</p>
            )}
          </div>
        </div>
        <div className="flex items-center mt-4 ">
          <Button text={t('close', { ns: 'common' })} wide size={'md'} color={'disabled'} onClick={closeModal} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default React.memo(KeysModal);
