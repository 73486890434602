import { PhotoUploadingStatus } from '../../features/photo/types/photo';

const localStorageKey = 'photoUploadingStep';
const localStorageKeyHeap = 'photoUploadingStepHeap';

interface GetPhotoUploadingStatusReturn {
  status: PhotoUploadingStatus;
  heap: any;
}

export const getPhotoUploadingStatus = (): GetPhotoUploadingStatusReturn => {
  return {
    status: localStorage.getItem(localStorageKey) as PhotoUploadingStatus,
    heap: localStorage.getItem(localStorageKeyHeap),
  };
};

export const setPhotoUploadingStatus = (status: PhotoUploadingStatus) => {
  localStorage.setItem(localStorageKey, status);
  localStorage.setItem(
    localStorageKeyHeap,
    JSON.stringify({
      totalJSHeapSize: performance.memory?.totalJSHeapSize,
      usedJSHeapSize: performance.memory?.usedJSHeapSize,
      jsHeapSizeLimit: performance.memory?.jsHeapSizeLimit,
    }),
  );
};
