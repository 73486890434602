import React from 'react';
import { useLocaleSwitcher } from '@/hooks/useLocaleSwitcher';
import { useTranslation } from 'react-i18next';

interface LoginLocalSwitcherProps {
  showLabel?: boolean;
  className?: string;
}

const LoginLocaleSwitcher = ({ showLabel = true, className }: LoginLocalSwitcherProps) => {
  const { locales, currentLocale, handleLocaleChange } = useLocaleSwitcher();
  const { t } = useTranslation();

  return (
    <div className={`login__locale-switcher ${className}`}>
      {showLabel && <label htmlFor="locale-switcher">{t('language')}:</label>}
      <select id="locale-switcher" value={currentLocale} onChange={(event) => handleLocaleChange(event.target.value)}>
        {Object.entries(locales).map(([locale, label]) => {
          return (
            <option key={'locale-' + locale} value={locale}>
              {label.toUpperCase()}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default LoginLocaleSwitcher;
