import React, { ReactNode, useEffect } from 'react';
import ToggleButton from '@/components/elements/ToggleButton';
import { useTranslation } from 'react-i18next';
import Button from '@/components/elements/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDisplayMapSettings,
  selectMapSettings,
  setDisplayMapSettings,
  setEnableRotation,
  setPanOnWaypointChange,
  setShowDoneWaypoints,
  setShowNavigationLine,
  setStickToCar,
  setTilesMode,
} from '@/features/route';
import PointChangeIcon from '@/components/icons/PointChangeIcon';
import MagnetIcon from '@/components/icons/MagnetIcon';
import RouteLineIcon from '@/components/icons/RouteLineIcon';
import CheckMark from '@/components/icons/CheckMark';
import { MapTileMode } from '@ekt-group/map-component';
import { conditionalClassNames } from '@/helpers/dom';
import MapIcon from '@/components/icons/MapIcon';
import RefreshIcon from '@/components/icons/RefreshIcon';
import VerticalShadowScroll from '@/components/elements/VerticalShadowScroll';

interface SettingsOption {
  label: string;
  icon?: ReactNode;
  id: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export const MapSettingsSidebar = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation('tabletPage');

  const isActive = useSelector(selectDisplayMapSettings);
  const mapSettings = useSelector(selectMapSettings);

  const options: SettingsOption[] = [
    {
      id: 'pan-on-waypoints-change',
      label: t('mapSettings.panOnWaypointsChange'),
      value: mapSettings.panOnWaypointChange,
      onChange: () => {
        dispatch(setPanOnWaypointChange(!mapSettings.panOnWaypointChange));
        if (mapSettings.stickToCar) {
          dispatch(setStickToCar(false));
        }
      },
      icon: <PointChangeIcon size={'sm'} />,
    },
    {
      id: 'stick-to-car',
      label: t('mapSettings.stickToCar'),
      value: mapSettings.stickToCar,
      onChange: () => {
        dispatch(setStickToCar(!mapSettings.stickToCar));
        if (mapSettings.panOnWaypointChange) {
          dispatch(setPanOnWaypointChange(false));
        }
      },
      icon: <MagnetIcon size={'sm'} />,
    },
    {
      id: 'show-navigation-line',
      label: t('mapSettings.showNavigationLine'),
      value: mapSettings.showNavigationLine,
      onChange: () => dispatch(setShowNavigationLine(!mapSettings.showNavigationLine)),
      icon: <RouteLineIcon size={'sm'} />,
    },
    {
      id: 'enableRotation',
      label: t('mapSettings.rotateMap'),
      value: mapSettings.enableRotation,
      onChange: () => dispatch(setEnableRotation(!mapSettings.enableRotation)),
      icon: <RefreshIcon size={'sm'} />,
    },
    {
      id: 'show-done-waypoints',
      label: t('mapSettings.showDoneWaypoints'),
      value: mapSettings.showDoneWaypoints,
      onChange: () => dispatch(setShowDoneWaypoints(!mapSettings.showDoneWaypoints)),
      icon: <CheckMark size={'sm'} />,
    },
  ];

  const closeSidebar = () => {
    dispatch(setDisplayMapSettings(false));
  };

  useEffect(() => {
    localStorage.setItem('mapSettings', JSON.stringify(mapSettings));
  }, [mapSettings]);

  return (
    <div className={`tablet-page__map-settings-sidebar z-[39] ${isActive ? 'active' : ''}`}>
      <VerticalShadowScroll className="tablet-page__map-settings-sidebar__list max-h-[calc(100vh-9.75rem)] md:max-h-[calc(100vh-13.75rem)] overflow-y-auto scrollbar-hide">
        {options.map((option) => (
          <div className="tablet-page__map-settings-sidebar__list-item" key={option.id}>
            <label htmlFor={option.id} className="tablet-page__map-settings-sidebar__list-item__label">
              {!!option.icon && option.icon}
              <span className={'leading-6'}>{option.label}</span>
            </label>
            <ToggleButton checked={option.value} onChange={option.onChange} inputId={option.id} disabled={option.disabled} />
          </div>
        ))}
        <div className="tablet-page__map-settings-sidebar__list-item flex-shrink-0 flex-col items-start py-4">
          <span className="flex gap-2 items-center">
            <MapIcon size={'sm'} />
            {t('mapSettings.mapType')}
          </span>
          <div className="flex justify-between w-full gap-4">
            {Object.entries(MapTileMode).map(([key, value]) => {
              const isActive = mapSettings.tilesMode === value;

              return (
                <button
                  className={conditionalClassNames({
                    'tablet-page__map-settings-sidebar__list-item__tile-modep-2 h-16 rounded-md w-1/2 text-center border': true,
                    'bg-green-600 dark:bg-green-600 border-green-500 dark:border-green-700 text-white': isActive,
                    'bg-gray-100 dark:bg-gray-600 text-gray-800 dark:text-gray-100 border-gray-300 dark:border-gray-500': !isActive,
                  })}
                  onClick={() => {
                    dispatch(setTilesMode(value));
                    closeSidebar();
                  }}
                  key={key}
                >
                  {key}
                </button>
              );
            })}
          </div>
        </div>
      </VerticalShadowScroll>
      <div className="tablet-page__map-settings-sidebar__footer">
        <Button text={t('close', { ns: 'common' })} color={'disabled'} size={'md'} onClick={closeSidebar} wide />
      </div>
    </div>
  );
});

MapSettingsSidebar.displayName = 'MapSettingsSidebar';
