import React from 'react';
import ReactDOM from 'react-dom';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';
import ModalWrapper from './ModalWrapper';

interface ConfirmationModalProps {
  isVisible: boolean;
  hide: () => void;
  submit: () => void;
  submitText?: string;
  text: string;
}

const ConfirmationModal = ({ isVisible, hide, text, submit, submitText }: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper isVisible={isVisible}>
      <p className="modal-content">{text}</p>
      <div className="flex mt-4 gap-2">
        <Button text={t('cancel')} size={'md'} color={'disabled'} wide onClick={hide} />
        <Button
          text={submitText || t('submit')}
          color={'danger'}
          size={'md'}
          wide
          onClick={() => {
            submit();
            hide();
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default ConfirmationModal;
