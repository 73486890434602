import React, { useState } from 'react';
import WaypointsUpdatesModal from './WaypointsUpdatesModal';
import useWaypointUpdates from '../../hooks/waypoints/useWaypointUpdates';

const WaypointsUpdatesModalWrapper = () => {
  const [isWaypointsUpdatesModalVisible, setIsWaypointsUpdatesModalVisible] = useState(false);

  useWaypointUpdates({
    openWaypointsUpdatesModal: () => setIsWaypointsUpdatesModalVisible(true),
  });

  return (
    <WaypointsUpdatesModal
      isVisible={isWaypointsUpdatesModalVisible}
      onClose={() => {
        setIsWaypointsUpdatesModalVisible(false);
      }}
    />
  );
};

export default React.memo(WaypointsUpdatesModalWrapper);
