import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const PencilIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 192 192"
      className={iconStyles[iconSizeClasses[size]]}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fill="none"
    >
      <path
        d="m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67"
        style={{
          fill: 'none',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'currentColor',
          strokeWidth: 12,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        transform="translate(-77.923 40.646)"
      />
      <path
        d="m195.656 33.271-52.882 52.882"
        style={{
          fill: 'none',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'currentColor',
          strokeWidth: 12,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 5,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        transform="translate(-77.923 40.646)"
      />
    </svg>
  );
};

export default PencilIcon;
