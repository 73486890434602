import React, { PropsWithChildren, useEffect } from 'react';
import AdvancedHeader from '../components/navigation/AdvancedHeader';
import { AdvancedNavigationProvider } from '@/contexts/advancedNavigationContext';
import { useWaypointsStatusRequestsProcessor } from '@/processors/useWaypointsStatusRequestsProcessor';
import { useRouteStartFinishRequestsProcessor } from '@/processors/useRouteStartFinishRequestsProcessor';
import { useWaypointsNotesRequestsProcessor } from '@/processors/useWaypointsNotesRequestsProcessor';
import { useUnloadsRequestsProcessor } from '@/processors/useUnloadsRequestsProcessor';
import { useWaypointsTanksRequestsProcessor } from '@/processors/useWaypointsTanksRequestsProcessor';
import { useCoordinateUpdateRequestsProcessor } from '@/processors/useCoordinateUpdateRequestsProcessor';
import { useWaypointsServiceStatusRequestsProcessor } from '@/processors/useWaypointsServiceStatusRequestsProcessor';
import { ImagesModalProvider } from '@/contexts/imagesModalContext';
import { useCarAcceptanceProcessor } from '@/processors/useCarAcceptanceProcessor';
import { useAllDeviceTasksProcessor } from '@/processors/useAllDeviceTasksProcessor';
import { cn } from '@/utils/cn';
import { useRouteSpecificProps } from '@/hooks/useRouteSpecificProps';

export interface AdvancedLayoutProps {
  isChildrenHaveWaypoints?: boolean;
  isChildrenAModal?: boolean;
}

// Full screen but not modal = other types of routes that are not discharged, service
// Full screen but is modal is arrivedPage
// Not full screen is the route/construction page
const AdvancedLayout = ({ children, isChildrenHaveWaypoints, isChildrenAModal }: PropsWithChildren<AdvancedLayoutProps>) => {
  const { handle } = useRouteSpecificProps();

  useWaypointsStatusRequestsProcessor();
  useWaypointsServiceStatusRequestsProcessor();
  useWaypointsNotesRequestsProcessor();
  useWaypointsTanksRequestsProcessor();
  useCoordinateUpdateRequestsProcessor();
  useRouteStartFinishRequestsProcessor();
  useUnloadsRequestsProcessor();
  useCarAcceptanceProcessor();
  useAllDeviceTasksProcessor();

  return (
    <ImagesModalProvider>
      <AdvancedNavigationProvider>
        <div
          className={cn('min-h-dynamic-100 bg-gray-100 text-gray-900 flex flex-col', 'dark:bg-gray-700 dark:text-gray-100', {
            'md:w-[calc(100vw-300px)]': handle?.isPageWithId && isChildrenHaveWaypoints && !isChildrenAModal,
            'w-screen ': handle?.isPageWithId && !isChildrenAModal,
          })}
        >
          <AdvancedHeader
            shouldDisplayMenu={!handle?.isPageWithId || !isChildrenHaveWaypoints || isChildrenAModal}
            shouldHeaderIconsDisplay={!isChildrenAModal}
          />
          <div
            className={cn('relative', {
              'px-2 md:px-4 md:pt-4': !handle?.isPageWithId || isChildrenAModal,
              'md:pt-0': handle?.isPageWithId && !isChildrenAModal,
            })}
          >
            {children}
          </div>
        </div>
      </AdvancedNavigationProvider>
    </ImagesModalProvider>
  );
};

export default AdvancedLayout;
