import { useEffect, useRef, useState } from 'react';

interface UsePhotoUploadFormProps {
  onUpload?: (photo: File) => Promise<void>;
  defaultValues?: {
    photo: File;
    photoPreview?: string;
  };
}

export function usePhotoUploadForm({ onUpload, defaultValues }: UsePhotoUploadFormProps) {
  const [photoPreview, setPhotoPreview] = useState(defaultValues?.photoPreview || '');
  const [compressedPhoto, setCompressedPhoto] = useState<File | null>(defaultValues?.photo || null);

  const inputRef = useRef<HTMLInputElement>(null);

  const resetInput = () => {
    setPhotoPreview(defaultValues?.photoPreview || '');
    setCompressedPhoto(null);

    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = '';
  };

  const uploadPhoto = async () => {
    if (!compressedPhoto) {
      return;
    }

    await onUpload(compressedPhoto);

    resetInput();
  };

  useEffect(() => {
    if (!compressedPhoto) {
      return;
    }

    const urlObject = URL.createObjectURL(compressedPhoto);

    setPhotoPreview(urlObject);

    return () => URL.revokeObjectURL(urlObject);
  }, [compressedPhoto]);

  return {
    photo: compressedPhoto,
    setPhoto: setCompressedPhoto,
    photoPreview,
    inputRef,
    resetInput,
    uploadPhoto,
  };
}
