import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@/store';
import { AppMode } from '@/types/common';

export interface SettingsState {
  mode: AppMode;
  isSyncingEnabled: boolean;
  isAppDisabled: boolean;
}

const savedMode = localStorage.getItem('appMode') as AppMode;
const initialSyncingEnabled = localStorage.getItem('isSyncingEnabled') === 'false' ? false : true;
const initialMode = savedMode && Object.values(AppMode).includes(savedMode) ? savedMode : AppMode.Advanced;

const initialState: SettingsState = {
  mode: initialMode,
  isSyncingEnabled: initialSyncingEnabled,
  isAppDisabled: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setMode(state, action) {
      state.mode = action.payload;
    },
    setIsAppDisabled(state, action) {
      state.isAppDisabled = action.payload;
    },
    setSyncingEnabled(state, action) {
      state.isSyncingEnabled = action.payload;
      localStorage.setItem('isSyncingEnabled', action.payload.toString());
    },
    resetSettingsState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { setMode, setIsAppDisabled, resetSettingsState, setSyncingEnabled } = settingsSlice.actions;

export const selectMode = (state: AppState) => state.settings.mode;
export const selectIsAppDisabled = (state: AppState) => state.settings.isAppDisabled;
export const selectIsSyncingEnabled = (state: AppState) => state.settings.isSyncingEnabled;

export default settingsSlice.reducer;
