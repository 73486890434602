import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

type ThemeType = 'dark' | 'light';

interface ThemeContext {
  theme: ThemeType;
  setTheme: (theme: ThemeType) => void;
  isMapDarkMode: boolean;
  setIsMapDarkMode: (value: boolean) => void;
}

const themeContext = createContext<ThemeContext>({ theme: 'light', isMapDarkMode: false, setTheme: () => {}, setIsMapDarkMode: () => {} });

export function ThemeProvider({ children }: PropsWithChildren) {
  const [theme, setTheme] = useState<ThemeType>(() => {
    const savedTheme = localStorage.getItem('theme') as ThemeType;
    const isPreferredThemeDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    return savedTheme || (isPreferredThemeDark ? 'dark' : 'light');
  });

  const [isMapDarkMode, setIsMapDarkMode] = useState(() => {
    const savedIsMapDarkMode = localStorage.getItem('isMapDarkMode');
    return savedIsMapDarkMode ? JSON.parse(savedIsMapDarkMode) : false;
  });

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.documentElement.className = theme;
    document.querySelector('meta[name="theme-color"]').setAttribute('content', theme === 'dark' ? '#1F2937' : '#FFFFFF');
  }, [theme]);

  useEffect(() => {
    localStorage.setItem('isMapDarkMode', JSON.stringify(isMapDarkMode));
  }, [isMapDarkMode]);

  return <themeContext.Provider value={{ theme, setTheme, isMapDarkMode, setIsMapDarkMode }}>{children}</themeContext.Provider>;
}

export function useTheme() {
  const context = useContext(themeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}
