import React, { useEffect, useState } from 'react';
import Button from '../../../components/elements/Button';
import { useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useWaypointForPhotoUpload } from '../hooks/useWaypointForPhotoUpload';
import { setDocumentTitle } from '@/hooks/useDocumentTitle';
import { getTodayDate } from '@/helpers/dateHelper';
import Spinner from '@/components/elements/Spinner';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import { socket } from '@/lib/socket';
import { isMobile } from 'react-device-detect';
import { WaypointInfoForm, RoutePhotoUploadForm } from '../components';
import WaypointSelectItem from '../components/WaypointSelectItem';
import { useRollbar } from '@rollbar/react';

export const PhotoPage = () => {
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const navigate = useNavigate();
  const isOffline = useOfflineStatus();
  const { t } = useTranslation('photoPage');
  setDocumentTitle(t('uploadPhoto'));

  const { waypoints: allWaypoints, waypointsForUpload, fetchByArrivedWaypoints, isLoading, setWaypoints } = useWaypointForPhotoUpload();
  const [waypointIdsForUpload, setWaypointIdsForUpload] = useState<number[]>([]);
  const [isPhotoPresent, setIsPhotoPresent] = useState<boolean>(false);

  const { resetNavigation } = useAdvancedNavigationItems();

  const rollbar = useRollbar();

  useEffect(() => {
    resetNavigation();
  }, [resetNavigation]);

  useEffect(() => {
    setWaypointIdsForUpload(waypointsForUpload?.map((waypoint) => waypoint.id));
  }, [waypointsForUpload]);

  useEffect(() => {
    socket.on('ArrivedToWaypoints', async ({ payload: { waypointIds } }) => {
      toast('Received update from tablet, updating...', {
        type: 'success',
      });

      if (!waypointIds?.length) {
        toast('No waypoints received from tablet', {
          type: 'error',
        });
        return;
      }

      if (isPhotoPresent) {
        toast('Photo is present, please submit it first', {
          type: 'error',
        });
        return;
      }

      setWaypoints(waypointIds);
    });

    return () => {
      socket.off('ArrivedToWaypoints');
    };
  }, [isPhotoPresent, setWaypoints]);

  const handleRefreshButtonClick = async () => {
    await fetchByArrivedWaypoints();
  };

  const toggleWaypointFromUploadList = (waypointId: number) => {
    if (waypointIdsForUpload.includes(waypointId)) {
      setWaypointIdsForUpload(waypointIdsForUpload.filter((id: number) => waypointId !== id));
      return;
    }

    setWaypointIdsForUpload([...waypointIdsForUpload, waypointId]);
  };

  const handleMapButtonClick = async () => {
    if (!currentRouteGroupItem) {
      navigate('/');
      toast(t('selectRouteGroupItem'), { type: 'warning' });
      return;
    }

    navigate('/waypoints');
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!waypointsForUpload) {
    return <></>;
  }

  const isSubmitDisabled = !waypointsForUpload?.length || currentRouteGroupItem?.date !== getTodayDate();

  return (
    <div className="photoPage">
      <RoutePhotoUploadForm
        arrivedObject={waypointsForUpload?.[0]?.object || null}
        waypointIds={waypointIdsForUpload}
        disableSubmit={isSubmitDisabled}
        onPhotoDeleted={() => setIsPhotoPresent(false)}
        onPhotoUpload={() => {
          setIsPhotoPresent(true);
        }}
        onPhotoSubmitted={() => {
          setIsPhotoPresent(false);
        }}
        onPhotoAdded={() => {
          setIsPhotoPresent(true);
          if (isSubmitDisabled) {
            rollbar.warn('Photo added but submit button is disabled', {
              waypointsForUpload,
              waypointIdsForUpload,
              currentRouteGroupItem,
              todayDate: getTodayDate(),
            });
          }
        }}
      />

      <div className="photo__page-head">
        <Button
          wide
          size={'md'}
          text={t('refreshFromTablet')}
          color={'primary'}
          onClick={handleRefreshButtonClick}
          disabled={isLoading || isOffline}
        />
        <Button wide size={'md'} text={t('chooseOnMap')} onClick={handleMapButtonClick} />
      </div>

      {isLoading ? (
        <div>{t('loadingArrivedWaypoints')}</div>
      ) : waypointsForUpload?.length === 0 ? (
        <div>{t('noWaypointsText')}</div>
      ) : (
        <WaypointInfoForm waypoint={waypointsForUpload?.[0]} />
      )}

      {waypointsForUpload?.length > 0 && !isLoading && (
        <>
          <span className={'leading-5'}>{t('waypointWithMultipleItems')}:</span>
          <div className="photo__waypoint-items-wrapper">
            {waypointIdsForUpload &&
              waypointsForUpload?.map((waypoint) => {
                return (
                  <WaypointSelectItem
                    waypoint={waypoint}
                    isSelected={waypointIdsForUpload?.includes(waypoint.id)}
                    clickHandler={toggleWaypointFromUploadList}
                    key={waypoint.id}
                  />
                );
              })}
          </div>
        </>
      )}

      <div className={'mt-4'}></div>
    </div>
  );
};
