import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../../store';
import { Kojv, KojvCondition, WaypointLogDetails } from '@ekt-group/general-purpose-api-interfaces';
import { WaypointsStatus } from '../../../types/waypoints';
import { Waypoint } from '../../home/types/routeGroupItems';

export interface ArrivedPageForm {
  selectedWaypointIds: number[];
  updateWaypointIds: number[];
  waypointIdsForPhotoUpload: number[];
  serviceWaypointDischarges?: Record<string, boolean>;
  services: {
    [key: string | number]: {
      [key: string | number]: {
        value: number;
      };
    };
  };
}

interface PhotoRequiredState {
  isRequired?: boolean;
  isUploaded?: boolean;
}

export interface ArrivedPageSliceState {
  isLoading: boolean;
  isSuccessRequestPending: boolean;
  isFailedRequestPending: boolean;
  form: ArrivedPageForm | null;
  kojv: Kojv | null;
  kojvConditions: KojvCondition | null;
  isKojvConditionsModalVisible: boolean;
  isPhotoOnMobileModalVisible: boolean;
  isTakePhotoModalVisible: boolean;
  photos: Record<string, { filePath: string }[]>;
  successButtonDisabledState: PhotoRequiredState & { waypointIds?: number[] };
  failedButtonDisabledState: PhotoRequiredState;
}

const initialState: ArrivedPageSliceState = {
  isLoading: false,
  isFailedRequestPending: false,
  isSuccessRequestPending: false,
  form: null,
  kojv: null,
  kojvConditions: null,
  isKojvConditionsModalVisible: false,
  isPhotoOnMobileModalVisible: false,
  isTakePhotoModalVisible: false,
  photos: {},
  successButtonDisabledState: {
    isRequired: true,
    isUploaded: false,
    waypointIds: [],
  },
  failedButtonDisabledState: {
    isRequired: true,
    isUploaded: false,
  },
};

export const arrivedPageSlice = createSlice({
  name: 'arrivedPage',
  initialState,
  reducers: {
    setArrivedPageLoadingState(state, action) {
      state.isLoading = action.payload;
    },
    setArrivedPageForm(state, action: PayloadAction<ArrivedPageForm>) {
      state.form = action.payload;
    },
    setFormSelectedWaypointIds(state, action) {
      state.form = { ...state.form, selectedWaypointIds: action.payload };
    },
    toggleSelectedWaypointId(state, action) {
      if (state.form.selectedWaypointIds.includes(action.payload)) {
        state.form.selectedWaypointIds = state.form.selectedWaypointIds.filter((id) => id !== action.payload);
        return;
      }
      state.form.selectedWaypointIds.push(action.payload);
    },
    addUpdateWaypointId(state, action) {
      state.form.updateWaypointIds.push(action.payload);
    },
    removeUpdateWaypointId(state, action) {
      state.form.updateWaypointIds = state.form.updateWaypointIds.filter((id) => id !== action.payload);
    },
    setFormWaypointIdsForPhotoUpload(state, action) {
      state.form = { ...state.form, waypointIdsForPhotoUpload: action.payload };
    },
    toggleWaypointIdForPhotoUpload(state, action) {
      if (state.form.waypointIdsForPhotoUpload.includes(action.payload)) {
        state.form.waypointIdsForPhotoUpload = state.form.waypointIdsForPhotoUpload.filter((id) => id !== action.payload);
        return;
      }
      state.form.waypointIdsForPhotoUpload.push(action.payload);
    },
    setFormServiceValue(state, { payload }: PayloadAction<{ waypointId: number; serviceId: number | string; value: number }>) {
      state.form = {
        ...state.form,
        services: {
          ...state.form.services,
          [payload.waypointId]: {
            ...state.form.services[payload.waypointId],
            [payload.serviceId]: {
              value: payload.value,
            },
          },
        },
      };
    },
    setServiceWaypointDischarges(state, { payload }: PayloadAction<{ waypointId: number; value: boolean }>) {
      state.form = {
        ...state.form,
        serviceWaypointDischarges: {
          ...state.form.serviceWaypointDischarges,
          [payload.waypointId]: payload.value,
        },
      };
    },
    setArrivedKojv(state, action) {
      state.kojv = action.payload;
    },
    setArrivedKojvConditions(state, action) {
      state.kojvConditions = action.payload;
    },
    setIsKojvConditionsModalVisible(state, action) {
      state.isKojvConditionsModalVisible = action.payload;
    },
    setPhotoOnMobileModalVisible(state, action) {
      state.isPhotoOnMobileModalVisible = action.payload;
    },
    setIsTakePhotoModalVisible(state, action) {
      state.isTakePhotoModalVisible = action.payload;
    },
    resetArrivedPageState(state) {
      Object.assign(state, initialState);
    },
    setArrivedPagePhotos(state, { payload }: PayloadAction<ArrivedPageSliceState['photos']>) {
      const newPhotos: ArrivedPageSliceState['photos'] = {};

      Object.keys(payload).forEach((key) => {
        if (!state.photos[key]) {
          newPhotos[key] = payload[key];
          return;
        }

        const allPhotosMatched = payload[key].every(({ filePath }) => {
          return state.photos[key].find((photo) => photo.filePath === filePath);
        });

        if (!allPhotosMatched) {
          state.photos[key] = payload[key];
        }
      });

      if (!Object.keys(newPhotos).length) {
        return;
      }

      state.photos = { ...state.photos, ...newPhotos };
    },
    removePhotoLog(state, { payload }: PayloadAction<{ waypointIds: number[]; filePath: string }>) {
      const filteredPhotos = { ...state.photos };
      payload.waypointIds.forEach((waypointId) => {
        if (!filteredPhotos[waypointId]) {
          return;
        }
        filteredPhotos[waypointId] = filteredPhotos[waypointId].filter((photo) => photo.filePath !== payload.filePath);
      });
      state.photos = filteredPhotos;
    },
    setSuccessButtonDisabledState(state, { payload }: PayloadAction<ArrivedPageSliceState['successButtonDisabledState']>) {
      state.successButtonDisabledState = { ...state.successButtonDisabledState, ...payload };
    },
    setFailedButtonDisabledState(state, { payload }: PayloadAction<ArrivedPageSliceState['failedButtonDisabledState']>) {
      state.failedButtonDisabledState = { ...state.failedButtonDisabledState, ...payload };
    },
    setSuccessRequestPendingState(state, { payload }: PayloadAction<boolean>) {
      state.isSuccessRequestPending = payload;
    },
    setFailedRequestPendingState(state, { payload }: PayloadAction<boolean>) {
      state.isFailedRequestPending = payload;
    },
    removeFormSelectedWaypointIds(state, { payload }: PayloadAction<number[]>) {
      state.form = { ...state.form, selectedWaypointIds: state.form?.selectedWaypointIds.filter((id) => !payload.includes(id)) };
    },
  },
});

export const {
  setArrivedPageLoadingState,
  setFormSelectedWaypointIds,
  setFormWaypointIdsForPhotoUpload,
  toggleSelectedWaypointId,
  toggleWaypointIdForPhotoUpload,
  setArrivedPageForm,
  setFormServiceValue,
  setArrivedKojv,
  setArrivedKojvConditions,
  setIsKojvConditionsModalVisible,
  setPhotoOnMobileModalVisible,
  setIsTakePhotoModalVisible,
  setSuccessButtonDisabledState,
  setFailedButtonDisabledState,
  setArrivedPagePhotos,
  setServiceWaypointDischarges,
  setSuccessRequestPendingState,
  setFailedRequestPendingState,
  resetArrivedPageState,
  addUpdateWaypointId,
  removeUpdateWaypointId,
  removePhotoLog,
  removeFormSelectedWaypointIds,
} = arrivedPageSlice.actions;

export const selectArrivedPageLoadingState = (state: AppState) => state.arrivedPage.isLoading;
export const selectArrivedPageForm = (state: AppState) => state.arrivedPage.form;
export const selectArrivedKojv = (state: AppState) => state.arrivedPage.kojv;
export const selectArrivedKojvConditions = (state: AppState) => state.arrivedPage.kojvConditions;
export const selectIsKojvConditionsModalVisible = (state: AppState) => state.arrivedPage.isKojvConditionsModalVisible;
export const selectIsPhotoOnMobileModalVisible = (state: AppState) => state.arrivedPage.isPhotoOnMobileModalVisible;
export const selectIsTakePhotoModalVisible = (state: AppState) => state.arrivedPage.isTakePhotoModalVisible;
export const selectArrivedPagePhotosByWaypointId = (waypointId: number) => (state: AppState) => state.arrivedPage.photos[waypointId];
export const selectArrivedPagePhotos = (state: AppState) => state.arrivedPage.photos;
export const selectIsSuccessButtonDisabled = (state: AppState) => {
  const { isRequired, isUploaded } = state.arrivedPage.successButtonDisabledState;
  return isRequired ? isRequired && !isUploaded : false;
};
export const selectIsFailedButtonDisabled = (state: AppState) => {
  const { isRequired, isUploaded } = state.arrivedPage.failedButtonDisabledState;
  return isRequired ? isRequired && !isUploaded : false;
};
export const selectRequiredPhotosWaypointIds = (state: AppState) => state.arrivedPage.successButtonDisabledState.waypointIds;
export const selectSuccessRequestPendingState = (state: AppState) => state.arrivedPage.isSuccessRequestPending;
export const selectFailedRequestPendingState = (state: AppState) => state.arrivedPage.isFailedRequestPending;
export const selectServiceWaypointsDischarges = (state: AppState) => state.arrivedPage.form.serviceWaypointDischarges;

export default arrivedPageSlice.reducer;
