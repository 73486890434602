import React, { useEffect, useState } from 'react';
import ModalWrapper from '@/components/modals/ModalWrapper';
import { useLiveQuery } from 'dexie-react-hooks';
import { waypointsUpdatesDB, WaypointsUpdatesItem, WaypointsUpdatesItemMark } from '@/database';
import { getObjectAddress } from '@/helpers/strings';
import { useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem, selectIsCurrentRouteGroupItemOpenedMode, Waypoint } from '@/features/home';
import { selectCommonItemMap } from '@/store/common/slice';
import Button from '@/components/elements/Button';
import { MapComponent, MapMode } from '@ekt-group/map-component';
import { useTranslation } from 'react-i18next';
import VerticalShadowScroll from '@/components/elements/VerticalShadowScroll';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const WaypointsUpdatesModal = ({ isVisible, onClose }: Props) => {
  const [lastNotSeenUpdates, setLastNotSeenUpdates] = useState<WaypointsUpdatesItem[]>([]);
  const [notAffectedWaypoints, setNotAffectedWaypoints] = useState<Waypoint[]>([]);
  const [deletedWaypoints, setDeletedWaypoints] = useState<Waypoint[]>([]);
  const [insertedWaypoints, setInsertedWaypoints] = useState<Waypoint[]>([]);

  const waypointsUpdates = useLiveQuery(() => waypointsUpdatesDB.updates.toArray());

  const citiesMap = useSelector(selectCommonItemMap('cities'));
  const villagesMap = useSelector(selectCommonItemMap('villages'));
  const { t, i18n } = useTranslation('tabletPage');

  const isCurrentRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  useEffect(() => {
    if (isCurrentRouteGroupItemOpenedMode || !waypointsUpdates?.length) {
      return;
    }

    const lastUpdates = waypointsUpdates.filter(({ isSeen, mark, routeGroupItemId }) => {
      return routeGroupItemId === currentRouteGroupItem?.id && mark === WaypointsUpdatesItemMark.Update && !isSeen;
    });

    if (!lastUpdates.length) {
      return;
    }

    setLastNotSeenUpdates(lastUpdates);
  }, [waypointsUpdates]);

  useEffect(() => {
    if (isCurrentRouteGroupItemOpenedMode || !lastNotSeenUpdates || !isVisible) {
      return;
    }

    lastNotSeenUpdates.forEach(({ insertedIds, deletedIds, items, timestamp: notSeenUpdateTimestamp }) => {
      if (insertedIds?.length) {
        const inserted = items.filter(({ id }) => insertedIds.includes(id) && !insertedWaypoints.find((item) => item.id === id));
        setInsertedWaypoints((prev) => [...prev, ...inserted]);
      }
      if (deletedIds?.length) {
        const previousUpdate = waypointsUpdates
          .filter(({ routeGroupItemId }) => routeGroupItemId === currentRouteGroupItem?.id)
          .reduce((update, currentUpdate) => {
            if (!update) {
              return currentUpdate;
            }
            if (currentUpdate.timestamp > notSeenUpdateTimestamp) {
              return update;
            }
            if (currentUpdate.timestamp > update.timestamp && currentUpdate.timestamp < notSeenUpdateTimestamp) {
              return currentUpdate;
            }
            return update;
          }, null);

        const previousItems = previousUpdate?.items || [];
        const deleted = previousItems.filter(({ id }) => deletedIds.includes(id) && !deletedWaypoints.find((item) => item.id === id));
        setDeletedWaypoints((prev) => [...prev, ...deleted]);
      }
      setNotAffectedWaypoints(items.filter(({ id }) => !insertedIds?.includes(id)));
    });
  }, [lastNotSeenUpdates, isVisible]);

  const handleClose = () => {
    onClose();
    lastNotSeenUpdates.forEach(({ id }) => {
      waypointsUpdatesDB.updates.update(id, { isSeen: true });
    });
    setLastNotSeenUpdates([]);
    setDeletedWaypoints([]);
    setInsertedWaypoints([]);
  };

  return (
    <ModalWrapper isVisible={isVisible} wide>
      {lastNotSeenUpdates.length > 0 && (
        <div className="waypoints-updates-modal">
          <div className="waypoints-updates-modal__content">
            <div className={'waypoints-updates-modal__map'}>
              <MapComponent
                mode={MapMode.WaypointsUpdates}
                currentWaypoints={notAffectedWaypoints}
                deletedWaypoints={deletedWaypoints}
                insertedWaypoints={insertedWaypoints}
                locale={i18n.resolvedLanguage}
              />
            </div>
            <VerticalShadowScroll className="waypoints-updates-modal__list">
              {insertedWaypoints.length > 0 && (
                <div className="waypoints-updates-modal__list-group">
                  <span>{t('waypointsUpdatesModal.insertedWaypoints')}</span>
                  <ul>
                    {insertedWaypoints.map(({ id, object }) => (
                      <li key={id}>
                        {getObjectAddress({ object, city: citiesMap?.[object.cityId], village: villagesMap?.[object.villageId] }, [
                          'county',
                        ])}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {deletedWaypoints.length > 0 && (
                <div className="waypoints-updates-modal__list-group">
                  <span>{t('waypointsUpdatesModal.deletedWaypoints')}</span>
                  <ul>
                    {deletedWaypoints.map(({ id, object }) => (
                      <li key={id}>
                        {getObjectAddress({ object, city: citiesMap?.[object.cityId], village: villagesMap?.[object.villageId] }, [
                          'county',
                        ])}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </VerticalShadowScroll>
          </div>
          <div className="waypoints-updates-modal__footer">
            <Button onClick={handleClose} text={t('close', { ns: 'common' })} wide size={'lg'} color={'success'} />
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

export default WaypointsUpdatesModal;
