import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { allDeviceRequestsDb } from '@/database';
import { selectUserId } from '@/features/auth';
import { useCreateTaskMutation } from '@/features/home';
import { useRollbar } from '@rollbar/react';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export const useAllDeviceTasksProcessor = () => {
  const [createAllDeviceTaskRequest] = useCreateTaskMutation();

  const rollbar = useRollbar();
  const userId = useSelector(selectUserId);
  const isOffline = useOfflineStatus();

  const { t } = useTranslation('carCheckUpModal');

  const processAllDeviceTasksQueue = useCallback(async () => {
    const table = allDeviceRequestsDb.requests;

    const tableItems = await table.toArray();
    if (!tableItems.length) {
      return;
    }
    const itemsToCreate = tableItems.filter((item) => item.type === 'create');

    for (const item of itemsToCreate) {
      try {
        await createAllDeviceTaskRequest(item.payload).unwrap();
        await table.delete(item.id);
      } catch (error) {
        rollbar.error('[AllDeviceTasks] Queue Processing failed', error as Error, item, { userId });
        toast(t('error.addTaskFailedOffline'), { type: 'error' });
        if ((error as Response).status === 403) {
          await table.delete(item.id);
        }
      }
    }
  }, [createAllDeviceTaskRequest, rollbar, userId, t]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (!isOffline) {
        await processAllDeviceTasksQueue();
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [isOffline, processAllDeviceTasksQueue]);
};
