import React from 'react';
import Button from '@/components/elements/Button';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import useModal from '@/hooks/useModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isToday } from '@/helpers/dateHelper';
import { setPhotoUploadingStatus } from '@/helpers/localStorage/photoUploadingHelper';
import { PhotoUploadingStatus, usePhotoUploadForm } from '@/features/photo';
import { photoDB, PhotoEntity } from '@/database';
import { AppMode } from '@/types/common';
import { useRollbar } from '@rollbar/react';
import { useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { selectMode } from '@/store/settings/slice';
import { selectUserId } from '@/features/auth';
import PhotoInputWithCoordinates from './PhotoInputWithCoordinates';
import { ObjectEntity } from '@ekt-group/general-purpose-api-interfaces';

interface RoutePhotoUploadFormProps {
  disableSubmit: boolean;
  waypointIds: number[];
  arrivedObject?: ObjectEntity;
  onPhotoUpload?: () => void;
  onPhotoSubmitted?: () => void;
  onPhotoDeleted?: () => void;
  onPhotoAdded?: () => void;
}

export const RoutePhotoUploadForm = ({
  waypointIds,
  arrivedObject,
  disableSubmit,
  onPhotoUpload,
  onPhotoSubmitted,
  onPhotoDeleted,
  onPhotoAdded,
}: RoutePhotoUploadFormProps) => {
  const { isVisible, toggle: toggleModal } = useModal();
  const { t } = useTranslation();
  const rollbar = useRollbar();

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const driverId = useSelector(selectUserId);
  const appMode = useSelector(selectMode);

  const { inputRef, photo, setPhoto, photoPreview, resetInput, uploadPhoto } = usePhotoUploadForm({
    onUpload: async () => {
      if (!waypointIds?.length) {
        toast(t('selectWaypoint', { ns: 'photoPage' }), {
          type: 'error',
        });
        return;
      }

      onPhotoUpload?.();

      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadRequestAddingToDatabase);

      await photoDB.photos.add({
        photo,
        waypointIds,
        routeGroupItemName: currentRouteGroupItem?.name,
        routeGroupName: currentRouteGroupItem?.routeGroup?.name,
        routeGroupItemId: currentRouteGroupItem?.id,
        timestamp: Date.now(),
        isBasicAppMode: appMode === AppMode.Basic,
        entity: currentRouteGroupItem?.isDischargeSheet ? PhotoEntity.DischargeWaypoint : PhotoEntity.ServiceWaypoint,
      });

      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadRequestAddedToDatabase);
    },
  });

  return (
    <>
      <PhotoInputWithCoordinates
        arrivedObject={arrivedObject}
        photo={photo}
        photoPreview={photoPreview}
        setPhoto={setPhoto}
        resetInput={resetInput}
        inputRef={inputRef}
        doAfterCompression={onPhotoAdded}
      />

      <div className="photo__submit-actions">
        <Button
          disabled={!Boolean(photo)}
          text={t('delete')}
          color="danger"
          size="md"
          onClick={() => {
            toggleModal();
            onPhotoDeleted?.();
          }}
          wide={true}
        />
        <Button
          disabled={!Boolean(photo) || disableSubmit}
          text={t('submit')}
          color="success"
          size="md"
          onClick={() => {
            uploadPhoto();
            onPhotoSubmitted?.();
          }}
          wide={true}
        />
      </div>

      <ConfirmationModal
        text={t('confirmationText', { ns: 'photoPage' })}
        submitText={t('delete')}
        isVisible={isVisible}
        hide={toggleModal}
        submit={resetInput}
      />
    </>
  );
};
