import React, { useEffect, useState } from 'react';
import WaypointDischargeListItem from './waypoints/WaypointDischargeListItem';
import { UnloadingBaseWaypoint, Waypoint, WaypointType } from '@/features/home';
import { useSelector } from 'react-redux';
import { selectSelectedUnloadPoint } from '@/features/unloading';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import WaypointServiceListItem from './waypoints/WaypointServiceListItem';
import { selectSelectedWaypointIds } from '@/store/waypoints/slice';

interface MobileSelectedWaypointGroupProps {
  waypointGroups: (Waypoint | UnloadingBaseWaypoint)[][];
}

const MobileSelectedWaypointGroup = ({ waypointGroups }: MobileSelectedWaypointGroupProps) => {
  const [selectedMobileGroup, setSelectedMobileGroup] = useState<(Waypoint | UnloadingBaseWaypoint)[]>(null);
  const selectedUnloadPoint = useSelector(selectSelectedUnloadPoint);
  const selectedWaypointIds = useSelector(selectSelectedWaypointIds);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width >= 767 || !selectedWaypointIds.length || !waypointGroups.length) {
      return;
    }

    const foundItem = waypointGroups.find((group) => group.some(({ id }) => selectedWaypointIds.includes(id)));

    if (foundItem) {
      setSelectedMobileGroup(foundItem);
    }
  }, [width, selectedWaypointIds]);

  return (
    selectedMobileGroup &&
    !selectedUnloadPoint && (
      <div className="tablet-page__waypoints-list__group-item--mobile">
        {selectedMobileGroup[0].type === WaypointType.Discharge ? (
          <WaypointDischargeListItem
            key={`mobile-discharge-waypoint-list-item`}
            waypointsGroup={selectedMobileGroup as Waypoint<WaypointType.Discharge>[]}
            selectedWaypointIds={selectedWaypointIds}
            isOnMainScreen
          />
        ) : (
          <WaypointServiceListItem
            key={`mobile-service-waypoint-list-item`}
            waypointsGroup={selectedMobileGroup as Waypoint<WaypointType.Service>[]}
            selectedWaypointIds={selectedWaypointIds}
            isOnMainScreen
          />
        )}
      </div>
    )
  );
};

export default MobileSelectedWaypointGroup;
