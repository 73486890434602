import { cn } from '@/utils/cn';
import { ConstructionTaskList } from './ConstructionTaskList';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { selectDisplaySidebarOnMobile, setDisplaySidebarOnMobile } from '../../stores';
import HeaderIndicators from '@/components/elements/HeaderIndicators';
import { useRef } from 'react';
import { useSidebar } from '@/hooks/useSidebar';
import WaypointListHamburgerMenu from '../waypoints/WaypointListBar/WaypointListHamburgerMenu';
import { Task } from '@ekt-group/general-purpose-api-interfaces';
import Spinner from '@/components/elements/Spinner';

interface ConstructionTaskListWithMenuProps {
  tasksData: Task[];
  selectedTask?: Task;
  onTaskClick?: (task: Task) => void;
  isLoading?: boolean;
  isFetching?: boolean;
  listRef?: React.RefObject<HTMLDivElement>;
}

export const ConstructionTaskListWithMenu = ({
  tasksData,
  selectedTask,
  onTaskClick,
  listRef,
  isLoading,
  isFetching,
}: ConstructionTaskListWithMenuProps) => {
  const dispatch = useAppDispatch();

  const isDisplaySidebarOnMobile = useAppSelector(selectDisplaySidebarOnMobile);

  const { isSidebarDisplayed: isAlcoholPopupVisible, closeSidebar: closeAlcoholPopup, toggleSidebar: toggleAlcoholPopup } = useSidebar();
  const { isSidebarDisplayed, closeSidebar, toggleSidebar } = useSidebar();

  const alcoholSpanRef = useRef<HTMLSpanElement>(null);

  const handleHambugerMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    closeAlcoholPopup();
    toggleSidebar();
  };

  if (!tasksData?.length) {
    return <div>No Task data</div>;
  }

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <div
        className={cn('modal-overlay hidden md:hidden', 'z-40', {
          'block md:hidden': isDisplaySidebarOnMobile,
        })}
        onClick={() => dispatch(setDisplaySidebarOnMobile(false))}
      ></div>
      <div
        className={cn(
          'waypoints-list-bar z-20 fixed w-5/6 md:w-[300px] -right-full md:right-0 top-0 md:z-10 h-dynamic-100 transition-[right] duration-150 ease-in',
          'bg-white dark:bg-gray-600',
          'hidden md:block',
          {
            'block right-0 z-[41]': isDisplaySidebarOnMobile,
            hidden: !isDisplaySidebarOnMobile,
          },
        )}
      >
        <div className={cn('flex p-2 justify-between w-full gap-x-2', 'top-[4.2rem]')}>
          <HeaderIndicators
            isAlcoholPopupVisible={isAlcoholPopupVisible}
            onAlcoholPopupIconClick={toggleAlcoholPopup}
            alcoholSpanRef={alcoholSpanRef}
            className="ml-[unset]"
          />

          <WaypointListHamburgerMenu
            isSidebarVisible={isSidebarDisplayed}
            closeSidebar={closeSidebar}
            onHamburgerMenuClick={handleHambugerMenuClick}
            buttonClassName="w-[unset] button-outlined"
          />
        </div>

        <ConstructionTaskList tasksData={tasksData} selectedTask={selectedTask} onTaskClick={onTaskClick} listRef={listRef} />
      </div>
    </>
  );
};
