import Dexie from 'dexie';
import { WaypointsStatus } from '../types/waypoints';

export interface WaypointsServiceStatusRequest {
  id?: number;
  status: WaypointsStatus;
  doneAt?: string;
  serviceIds: number[];
  routeGroupItemId: number;
  content?: string;
  discharges?: Record<string, boolean>;
}

export class WaypointsServiceStatusRequestsDb extends Dexie {
  requests: Dexie.Table<WaypointsServiceStatusRequest, number>;

  constructor() {
    super('waypointsServiceStatusRequestsDb');
    this.version(1).stores({
      requests: '++id, status, doneAt, serviceIds, routeGroupItemId, content, discharges',
    });
  }
}

export const waypointsServiceStatusRequestsDb = new WaypointsServiceStatusRequestsDb();
