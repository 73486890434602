import SpeedometerSvg from '@/components/SpeedometerSvg';
import { cn } from '@/utils/cn';
import { useTranslation } from 'react-i18next';

interface OdometerInputProps {
  inputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  odometerValue: number;
  shouldDisplayTodayDistance: boolean;
  startOdometerValue: number;
  className?: string;
}

export const OdometerInput = ({
  handleInputChange,
  inputValue,
  shouldDisplayTodayDistance,
  odometerValue,
  startOdometerValue,
  className,
}: OdometerInputProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'speedometer-input mt-2 md:mt-0 relative text-center flex flex-col items-center mx-auto h-[16rem] w-[16rem] md:h-[22.4rem] md:w-[22.4rem]',
        className,
      )}
    >
      <SpeedometerSvg />
      <input inputMode="decimal" value={inputValue} placeholder="0" onChange={handleInputChange} />
      <div className="speedometer-input__footer">
        <span>
          {t('startFinishModal.enterOdometerValue', {
            ns: 'routeGroupItems',
          })}
        </span>
        {shouldDisplayTodayDistance && (
          <span>
            {t('startFinishModal.todayDistance', {
              ns: 'routeGroupItems',
            })}
            :&nbsp;
            <span className="font-bold">{odometerValue - startOdometerValue < 0 ? 0 : odometerValue - startOdometerValue}&nbsp;km</span>
          </span>
        )}
      </div>
    </div>
  );
};
