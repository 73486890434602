import React, { useCallback } from 'react';
import { MapComponent, MapMode } from '@ekt-group/map-component';
import styles from '../../../assets/styles/waypoints.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setDocumentTitle } from '@/hooks/useDocumentTitle';
import { useWaypointsRequest } from '@/features/route';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';
import { setSelectedWaypointIds } from '@/store/waypoints/slice';
import { getBackendHost } from '@/helpers/backend';
import { socket } from '@/lib/socket';
import useGeolocation from 'react-hook-geolocation';

export const WaypointsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const { data: waypoints, refetch: refetchWayPoints } = useWaypointsRequest();

  const { countiesMap, garbageMap, itemTypesMap, citiesMap, villagesMap } = useCommonItemMaps();
  const { error } = useGeolocation();

  const waypointsForMap = waypoints?.map((waypoint) => {
    const { objectItem, object } = waypoint;
    const { itemTypeId, garbageId } = objectItem;
    const { cityId, villageId, countyId } = object;

    return {
      ...waypoint,
      city: citiesMap[cityId],
      village: villagesMap[villageId],
      county: countiesMap[countyId],
      garbage: garbageMap[garbageId],
      itemType: itemTypesMap[itemTypeId],
    };
  });

  setDocumentTitle(t('waypoints'));

  useEffect(() => {
    if (!currentRouteGroupItem) {
      return;
    }

    refetchWayPoints();
  }, [currentRouteGroupItem, refetchWayPoints]);

  const onObjectClick = useCallback(
    async (waypointIds: number[]) => {
      socket.volatile.emit('WaypointsSelected', { type: 'WaypointsSelected', payload: { waypointIds } });
      dispatch(setSelectedWaypointIds(waypointIds));
      localStorage.setItem('selectedWaypointIds', JSON.stringify(waypointIds));
      navigate('/photo');
    },
    [dispatch, navigate],
  );

  return (
    <div className={styles.waypointsMapWrapper}>
      {currentRouteGroupItem && (
        <MapComponent
          mode={MapMode.DriverWaypoints}
          generalPurposesHostUrl={getBackendHost()}
          routeGroupItem={currentRouteGroupItem}
          waypoints={waypointsForMap}
          locale={i18n.resolvedLanguage}
          onObjectClick={onObjectClick}
          isGeolocationEnabled={!error}
        />
      )}
    </div>
  );
};
