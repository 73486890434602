import React from 'react';
import { MapComponent, MapMode } from '@ekt-group/map-component';
import { Coordinates } from '@/types/common';
import { getBackendHost } from '@/helpers/backend';

interface AccidentChatMapTabProps {
  carNumber: string;
  chatToken: string;
  accidentLocation: Coordinates;
}

const AccidentChatMapTab = ({ carNumber, accidentLocation, chatToken }: AccidentChatMapTabProps) => {
  return (
    <MapComponent
      mode={MapMode.AccidentChatMode}
      generalPurposesHostUrl={getBackendHost()}
      accidentLocation={accidentLocation}
      carNumber={carNumber}
      chatToken={chatToken}
    />
  );
};

export default AccidentChatMapTab;
