import Dexie from 'dexie';

export enum PhotoDbRequestAction {
  Upload = 'upload',
  Delete = 'delete',
}

export interface PhotoDbRequest {
  id?: number;
  action: PhotoDbRequestAction;
  waypointIds?: number[];
  photoPath?: string;
  photoId?: number;
}

export class PhotoRequestsDb extends Dexie {
  requests: Dexie.Table<PhotoDbRequest, number>;

  constructor() {
    super('photoRequestsDb');

    this.version(1).stores({
      requests: '++id, action, waypointIds, photoPath, photoId',
    });
  }
}

export const photoRequestsDb = new PhotoRequestsDb();
