import React from 'react';
import { useTranslation } from 'react-i18next';
import { photoDB } from '../../database';
import { useLiveQuery } from 'dexie-react-hooks';

const PendingPhotosIndicator = () => {
  const photos = useLiveQuery(() => photoDB.photos.toArray());
  const { t } = useTranslation('offline');

  const pendingPhotosAmount = photos?.filter(({ serverFilePath }) => !serverFilePath)?.length || 0;

  if (!pendingPhotosAmount) {
    return null;
  }

  return <div className={'pending-requests-indicator'}>{t('photos.waitingForUpload', { amount: pendingPhotosAmount })}</div>;
};

export default PendingPhotosIndicator;
