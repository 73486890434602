import React from 'react';
import { conditionalClassNames } from '../../helpers/dom';

interface ToggleButtonProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  inputId?: string;
  disabled?: boolean;
}

const ToggleButton = ({ checked, label, onChange, inputId, disabled }: ToggleButtonProps) => {
  return (
    <label
      className={conditionalClassNames({
        'toggle-button': true,
        'toggle-button__disabled': disabled,
      })}
    >
      <input
        type="checkbox"
        checked={checked}
        id={inputId}
        onChange={(event) => onChange(event.target.checked)}
        className="sr-only peer"
        disabled={disabled}
      />
      <div className="toggle-button__body peer-focus:outline-none peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-green-600" />
      {label && <span className="toggle-button__label">{label}</span>}
    </label>
  );
};

export default ToggleButton;
