import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import Spinner from './components/elements/Spinner';
import store from './store';
import './i18n';
import { initGA } from './analytics/google-analytics';
import { registerSW } from 'virtual:pwa-register';

import './assets/styles/globals.css';
import '@ekt-group/map-component/dist/index.css';
import './assets/styles/app.scss';
import ReloadPrompt from './components/pwa/ReloadPrompt';
import { OfflineStatusProvider } from './contexts/offlineStatusContext';
import { ThemeProvider } from './contexts/themeContext';
import { rollbarConfig } from './configs/rollbar.config';
import ErrorComponent from './components/ErrorComponent';
import AppRoutes from './router';
import { SocketStatusContextProvider } from './contexts/socketContext';

if (import.meta.env.VITE_AZURE_ANALYTICS_INSIGHTS_CONNECTION_STRING) {
  import('./analytics/azure-analytics-insights');
}

initGA();

// TODO: think about other solutions (e.g: redux-persist) for offline mode purposes

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={ErrorComponent}>
        <ThemeProvider>
          <OfflineStatusProvider>
            <SocketStatusContextProvider>
              <ReduxProvider store={store}>
                <Suspense
                  fallback={
                    <div className="app-suspense">
                      <Spinner size={'lg'} color={'disabled'} />
                    </div>
                  }
                >
                  <AppRoutes />
                  <ReloadPrompt />
                </Suspense>
              </ReduxProvider>
            </SocketStatusContextProvider>
          </OfflineStatusProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
);

registerSW({ immediate: true });
