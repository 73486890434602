import React from 'react';
import Header from '../components/navigation/Header';
import { Outlet } from 'react-router';

const MapLayout = () => {
  return (
    <div className="main-layout">
      <Header isTiny />
      <div className="map-wrapper">
        <Outlet />
      </div>
    </div>
  );
};

export default MapLayout;
