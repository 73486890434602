import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { setCreatedWaypointResult, setSelectedUnloadPoint } from '@/features/unloading';
import Spinner from '@/components/elements/Spinner';
import UnloadPointsListItem from '../components/UnloadPointsListItem';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import { UnloadPoint } from '@ekt-group/general-purpose-api-interfaces';
import { useNavigate } from 'react-router';
import { socket } from '@/lib/socket';
import { useAppSelector } from '@/store/types';
import { selectIsSyncingEnabled } from '@/store/settings/slice';
import { useUnloads } from '../hooks/useUnloads';

export const UnloadingPage = () => {
  const dispatch = useDispatch();

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const { unloadPoints, isUnloadPointLoading, getWaypointUnloads } = useUnloads(currentRouteGroupItem?.id);

  const { replaceNavigationWithBackButton } = useAdvancedNavigationItems();
  const navigate = useNavigate();
  const isSyncingEnabled = useAppSelector(selectIsSyncingEnabled);

  useEffect(() => {
    replaceNavigationWithBackButton();
  }, [replaceNavigationWithBackButton]);

  const handleUnloadPointClick = (point: UnloadPoint) => {
    dispatch(setSelectedUnloadPoint(point));
    navigate(`/route/${currentRouteGroupItem?.id}`);
  };

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }
    socket.on('Unloaded', getWaypointUnloads);
    return () => {
      socket.off('Unloaded', getWaypointUnloads);
    };
  }, [isSyncingEnabled, getWaypointUnloads]);

  return (
    <div className="unloading-page">
      {isUnloadPointLoading ? (
        <Spinner size="md" />
      ) : (
        <div className="unloading-page__list">
          {unloadPoints?.map((point) => (
            <UnloadPointsListItem point={point} key={point.id} onPointClick={handleUnloadPointClick} />
          ))}
        </div>
      )}
    </div>
  );
};
