import { useEffect, useState } from 'react';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';
import { WaypointType } from '@/features/home';
import { selectSelectedPlanningItem, useWaypointsRequest } from '@/features/route';
import { useSelector } from 'react-redux';
import { usePlanning } from '@/features/route';

interface RouteGroupItemSummary {
  [garbageId: number]: {
    capacity: {
      total: number;
      done: number;
    };
    items: {
      total: number;
      done: number;
    };
  };
}

export function useRouteGroupItemSummary() {
  const { data: waypoints } = useWaypointsRequest();
  const { itemTypesMap } = useCommonItemMaps();
  const planningItem = useSelector(selectSelectedPlanningItem);
  const removedWaypoints: number[] = [];

  const summary: RouteGroupItemSummary =
    waypoints?.reduce<RouteGroupItemSummary>((acc, waypoint) => {
      if (!itemTypesMap) {
        return acc;
      }
      const { objectItem } = waypoint;
      const { garbageId, itemTypeId } = objectItem;
      const { capacity, isDynamicCapacity } = itemTypesMap[itemTypeId];

      if (!acc[garbageId]) {
        acc[garbageId] = {
          capacity: {
            total: 0,
            done: 0,
          },
          items: {
            total: 0,
            done: 0,
          },
        };
      }

      const isDone = waypoint.type === WaypointType.Discharge ? waypoint.done : waypoint.doneAt;
      const childrenCount = waypoint.type === WaypointType.Discharge ? waypoint.childrenCount || 0 : 0;

      if (waypoint.type === WaypointType.Discharge) {
        const summaryCapacity = isDynamicCapacity
          ? Number(waypoint.dynamicGarbageM3) || 0
          : Number(capacity) + Number(capacity) * childrenCount;

        acc[garbageId].capacity.total += summaryCapacity;

        if (isDone) {
          acc[garbageId].capacity.done += summaryCapacity;
        }
      }

      const summaryContainerCount = 1 + childrenCount;

      acc[garbageId].items.total += summaryContainerCount;

      if (isDone) {
        acc[garbageId].items.done += summaryContainerCount;
      }

      return acc;
    }, {} as RouteGroupItemSummary) || {};

  if (planningItem) {
    const routePlans = planningItem?.planning?.response?.routes;
    const routePlansWithoutLastItem = routePlans?.slice(0, routePlans.length - 1);
    for (const routePlan of routePlansWithoutLastItem) {
      for (const step of routePlan.steps) {
        const waypointGroup = waypoints?.find((waypoint) => waypoint.object.id === step?.job);

        if (!waypointGroup && step.job) {
          removedWaypoints.push(step?.job);
        }
      }
    }
  }

  return { summary, removedWaypoints };
}
