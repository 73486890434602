import { useEffect, useState } from 'react';
import { selectUserState } from '@/features/auth';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useVerifyUserRequest from './useVerifyUserRequest';

export default function useUserAuth() {
  const [queryParams] = useSearchParams();
  const { verifyUser } = useVerifyUserRequest();

  const userState = useSelector(selectUserState);
  const [isUserLoading, setIsUserLoading] = useState(!Boolean(userState));

  useEffect(() => {
    if (userState) {
      return;
    }

    setIsUserLoading(true);

    const token = queryParams.get('token') as string;
    verifyUser(token).finally(() => setIsUserLoading(false));
  }, []);

  return { isUserLoading, verifyUser };
}
