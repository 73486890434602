import GarageIcon from '@/components/icons/GarageIcon';
import TruckUnloadingIcon from '@/components/icons/TruckUnloadingIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface WaypointUnloadingAndBaseListItemProps {
  type: 'unloading' | 'base';
}

export const WaypointUnloadingAndBaseListItem = ({ type }: WaypointUnloadingAndBaseListItemProps) => {
  const { t } = useTranslation('tabletPage');
  const title = type === 'base' ? t('waypoints.baseLabel') : t('waypoints.unloadingLabel');
  const icon = type === 'base' ? <GarageIcon size="md" /> : <TruckUnloadingIcon size="md" />;
  return (
    <div className="flex gap-x-2 w-full h-full rounded-md bg-gray-200 dark:bg-gray-800 z-10 pl-2 py-2">
      <div>{icon}</div>
      <div>{title}</div>
    </div>
  );
};
