import Rollbar from 'rollbar';

export const rollbarConfig: Rollbar.Configuration = {
  enabled: import.meta.env.VITE_IS_TESTING_ENVIRONMENT !== 'true',
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: __APP_VERSION__,
  },
};
