import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useTranslation } from 'react-i18next';
import generalService from '../services/general.service';
import gatesService from '../features/route/api/gates.service';
import keysAndRemotesService from '../features/route/api/keys-and-remotes.service';
import gasCardsService from '../features/fueling/api/gas-cards.service';
import waypointsService from '../services/discharge-waypoints.service';
import kojvService from '../features/route/api/kojv.service';
import { isRouteTypeWithWaypoints } from '@/features/home';
import { getUnloadPoints } from '@/features/unloading';
import { ObjectService, RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';

interface WaypointsRelatedIds {
  garbageIds: number[];
  kojvIds: number[];
}

export function usePreCache({ routeGroupItems }: { routeGroupItems: RouteGroupItem[] }) {
  const [isPreCacheResolved, setIsPreCacheResolved] = useState(false);
  const isOffline = useOfflineStatus();
  const { t } = useTranslation('offline');

  const getWaypointsByRouteGroupItemIds = useCallback(async () => {
    if (routeGroupItems) {
      const allWaypointsResponses = await Promise.all(
        routeGroupItems
          .filter(({ sheetType }) => isRouteTypeWithWaypoints(sheetType))
          .map(({ id, isDischargeSheet }) => {
            return isDischargeSheet
              ? generalService.getWaypoints({ routeGroupItemIds: [id] })
              : generalService.getServiceWaypoints({ routeGroupItemIds: [id] });
          }),
      );

      const garbageIds: number[] = [];
      const kojvIds: number[] = [];

      for (const response of allWaypointsResponses) {
        if (response.data) {
          for (const waypoint of response.data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { kojv, objectItem } = waypoint;
            const { garbageId } = objectItem;

            if (!garbageIds.includes(garbageId)) {
              garbageIds.push(garbageId);
            }
            if (!kojvIds.includes(kojv?.id)) {
              kojvIds.push(kojv?.id);
            }
          }
        }
      }

      return { garbageIds, kojvIds };
    }
  }, [routeGroupItems]);

  const getGarbageIcons = useCallback(async (garbageIds: number[]) => {
    // requesting garbage icons for setting them to cache
    await Promise.all(
      garbageIds.map((id) => {
        const image = new Image();
        image.src = `https://toits.ekkt.ee/upload/icons/garbage_id_${id}.jpg`;
      }),
    );
  }, []);

  const getKojvConditions = useCallback(async (kojvIds: number[]) => {
    await Promise.all(
      kojvIds.map((id) => {
        return kojvService.getKojvConditions(id);
      }),
    );
  }, []);

  const getKeysAndRemotesByRouteGroupItemIds = useCallback(async () => {
    if (routeGroupItems) {
      await Promise.all([
        gatesService.getGates(),
        ...routeGroupItems.map(({ id }) => {
          return keysAndRemotesService.getKeysAndRemotes(id);
        }),
      ]);
    }
  }, [routeGroupItems]);

  const getFuelCardsByRouteGroupItemIds = useCallback(async () => {
    if (routeGroupItems) {
      await Promise.all(
        routeGroupItems.map(({ id }) => {
          return gasCardsService.getGasCards(id);
        }),
      );
    }
  }, [routeGroupItems]);

  const getUnloadPointsByRouteGroupItemIds = useCallback(async () => {
    if (routeGroupItems) {
      await Promise.all(
        routeGroupItems.map(({ id }) => {
          return getUnloadPoints(id);
        }),
      );
    }
  }, [routeGroupItems]);

  const getDischargeAndFailureReasons = useCallback(async () => {
    await waypointsService.getDischargeFailureReasons();
  }, []);

  const runPreCache = useCallback(async () => {
    const { garbageIds, kojvIds } = await getWaypointsByRouteGroupItemIds();

    await Promise.all([
      getGarbageIcons(garbageIds.filter(Boolean)),
      getKojvConditions(kojvIds.filter(Boolean)),
      getKeysAndRemotesByRouteGroupItemIds(),
      getFuelCardsByRouteGroupItemIds(),
      getUnloadPointsByRouteGroupItemIds(),
      getDischargeAndFailureReasons(),
    ]);

    setIsPreCacheResolved(true);
  }, [
    getWaypointsByRouteGroupItemIds,
    getGarbageIcons,
    getKojvConditions,
    getKeysAndRemotesByRouteGroupItemIds,
    getFuelCardsByRouteGroupItemIds,
    getUnloadPointsByRouteGroupItemIds,
    getDischargeAndFailureReasons,
  ]);

  useEffect(() => {
    if (!routeGroupItems?.length || isPreCacheResolved || isOffline) {
      return;
    }

    runPreCache();
  }, [runPreCache, routeGroupItems, isOffline, isPreCacheResolved]);
}
