import React, { useEffect } from 'react';
import Input from '@/components/elements/Input';
import Select from '@/components/elements/Select';
import fuelService from '../api';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFuel,
  selectFuelAmount,
  selectIsLoadingFuel,
  selectSelectedFuelId,
  setFuel,
  setFuelAmount,
  setIsLoadingFuel,
  setSelectedFuelId,
} from '@/features/fueling';
import Spinner from '@/components/elements/Spinner';
import { useTranslation } from 'react-i18next';

interface FuelManualEntryFormProps {
  vendorId: number;
}

const defaultFuelName = 'diisel';

const FuelManualEntryForm = ({ vendorId }: FuelManualEntryFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('fuelPage');

  const fuelAmount = useSelector(selectFuelAmount);
  const fuelTypes = useSelector(selectFuel);
  const selectedFuelId = useSelector(selectSelectedFuelId);
  const isLoading = useSelector(selectIsLoadingFuel);

  const getFuelTypes = async () => {
    if (isLoading) {
      return;
    }

    dispatch(setIsLoadingFuel(true));
    try {
      const fuel = await fuelService.getFuel(vendorId);
      dispatch(setFuel(fuel));
      const defaultFuel = fuel.find((fuel) => fuel.name.toLowerCase() === defaultFuelName);
      dispatch(setSelectedFuelId(defaultFuel ? defaultFuel.id : fuel[0].id));
    } finally {
      dispatch(setIsLoadingFuel(false));
    }
  };

  const handleFuelTypeChange = (fuelId: string) => {
    const fuel = fuelTypes.find((fuel) => fuel.id === Number(fuelId));
    dispatch(setSelectedFuelId(fuel.id));
  };

  const handleFuelAmountChange = (fuelAmount: string) => {
    dispatch(setFuelAmount(Number(fuelAmount)));
  };

  useEffect(() => {
    if (fuelTypes.length > 0) {
      return;
    }

    getFuelTypes();
  }, []);

  return (
    <div className="fueling-page__manual-input">
      <div className="fueling-page__manual-input__group">
        <div className="fueling-page__manual-input__group__label">
          <span>{t('fuelAmount')}</span>
          <small>{t('fuelUnit')}</small>
        </div>
        <div className="fueling-page__manual-input__group__input">
          <Input type={'tel'} inputMode={'decimal'} value={fuelAmount} onChange={handleFuelAmountChange} />
        </div>
      </div>
      <div className="fueling-page__manual-input__group">
        <div className="fueling-page__manual-input__group__label">
          <span>{t('fuelType')}</span>
        </div>
        <div className="fueling-page__manual-input__group__input">
          {isLoading ? (
            <Spinner size="md" />
          ) : (
            <Select
              options={fuelTypes.map(({ name, id }) => {
                return { label: name, value: id };
              })}
              value={selectedFuelId}
              onChange={handleFuelTypeChange}
              size="lg"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FuelManualEntryForm;
