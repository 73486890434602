import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedUnloadPoint } from '@/features/unloading';
import { useObjectAddress } from '@/hooks/useObjectAddress';
import { unifiedString } from '@/helpers/strings';
import { setDisplayArrivedPage, setDisplaySidebarOnMobile } from '@/features/route';
import { IWaypointDischarge } from '../../../home/types/routeGroupItems';
import WaypointListItemTemplate from './WaypointListItemTemplate';
import LockIcon from '@/components/icons/LockIcon';
import ClockIcon from '@/components/icons/ClockIcon';
import { MAIN_DISCHARGE_WAYPOINT_KEYS_TO_CHECK } from '@/constants/waypoints';

interface WaypointDischargeListItemProps {
  waypointsGroup: IWaypointDischarge[];
  selectedWaypointIds: number[];
  isOnMainScreen?: boolean;
}

// TODO: Add more proper checks
const propsAreEqual = (prevProps: WaypointDischargeListItemProps, nextProps: WaypointDischargeListItemProps) => {
  const prevIsSelected = prevProps.waypointsGroup.every(({ id }) => prevProps.selectedWaypointIds.includes(id));
  const nextIsSelected = nextProps.waypointsGroup.every(({ id }) => nextProps.selectedWaypointIds.includes(id));

  const isStatusChanged = MAIN_DISCHARGE_WAYPOINT_KEYS_TO_CHECK.some((key) => {
    return prevProps.waypointsGroup.some((waypoint) => {
      const nextWaypoint = nextProps.waypointsGroup.find(({ id }) => waypoint.id === id);
      if (!nextWaypoint) {
        return true;
      }
      return waypoint[key] !== nextWaypoint[key];
    });
  });

  return prevIsSelected === nextIsSelected && !isStatusChanged;
};

const WaypointDischargeListItem = ({ waypointsGroup, selectedWaypointIds, isOnMainScreen }: WaypointDischargeListItemProps) => {
  const dispatch = useDispatch();
  const selectedUnloadPoint = useSelector(selectSelectedUnloadPoint);

  const { object, owner } = waypointsGroup[0] || {};
  const notes: string[] = [];

  waypointsGroup.forEach(({ object, objectItem, objectItemDischarge }) => {
    if (object?.notes && !notes.includes(object.notes)) {
      notes.push(object.notes);
    }
    if (objectItem?.driverNotes && !notes.includes(objectItem.driverNotes)) {
      notes.push(objectItem.driverNotes);
    }
    if (objectItemDischarge?.notes && !notes.includes(objectItemDischarge.notes)) {
      notes.push(objectItemDischarge.notes);
    }
  });

  const address = useObjectAddress(object, ['county']);
  const unifiedNotes = unifiedString(notes, '. ');
  const isSelected = waypointsGroup.some((waypoint) => selectedWaypointIds.includes(waypoint.id));
  const isDone = waypointsGroup.every((waypoint) => waypoint.done && !waypoint.fail);
  const isFailed = waypointsGroup.every((waypoint) => waypoint.done && waypoint.fail);
  const isPartiallyDone = !isDone && !isFailed && waypointsGroup.some((waypoint) => waypoint.done);
  const hasComposter = waypointsGroup.some((waypoint) => waypoint.object?.isEvaldComposter);

  const handleArrivedClick = useCallback(() => {
    dispatch(setDisplayArrivedPage(true));
    dispatch(setDisplaySidebarOnMobile(false));
  }, []);

  const timeWindows =
    object?.serviceStartTime && object?.serviceEndTime ? `${object.serviceStartTime.slice(0, 5)}-${object.serviceEndTime.slice(0, 5)}` : '';

  return (
    <>
      <WaypointListItemTemplate
        onArrivedButtonClick={handleArrivedClick}
        isArrivedButtonDisabled={Boolean(selectedUnloadPoint)}
        isSelected={isSelected}
        isDone={isDone}
        isFailed={isFailed}
        isPartiallyDone={isPartiallyDone}
        isOnMainScreen={isOnMainScreen}
        isDisabled={!waypointsGroup.some((waypoint) => waypoint.isFiltered)}
        address={address}
        ownerName={owner?.companyName || unifiedString([owner?.firstname, owner?.surname], ' ')}
        notes={unifiedNotes}
        timeWindows={timeWindows}
        uploadPlan={object?.uploadPlan}
        waypointsGroup={waypointsGroup}
        hasComposter={hasComposter}
        addressPrefixSlot={
          <div className={'w-4 flex flex-col gap-1 mr-1 text-gray-300 dark:text-gray-400'}>
            <span className={object?.hasLock ? 'text-yellow-500' : ''}>
              <LockIcon size={'sm'} />
            </span>
            <span className={timeWindows ? 'text-red-600' : ''}>
              <ClockIcon size={'sm'} />
            </span>
          </div>
        }
      />
    </>
  );
};

export default React.memo(WaypointDischargeListItem, propsAreEqual);
