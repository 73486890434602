import { useEffect, useState } from 'react';
import { useRollbar } from '@rollbar/react';
import { useOfflineStatus } from '../contexts/offlineStatusContext';
import { useSelector } from 'react-redux';
import { selectUserId } from '../features/auth/stores/slice';
import { AxiosError } from 'axios';

interface BaseDBItem {
  id?: number;
}

const RETRY_INTERVAL = 1000 * 60 * 10;

interface UseProcessorProps<DBItem extends BaseDBItem> {
  dbItems: DBItem[];
  processDbItem: (item: DBItem) => Promise<void>;
  deleteDbItem: (itemId: DBItem['id']) => Promise<void>;
  rollbarErrorMessage?: string;
}

export function useProcessor<DBItem extends BaseDBItem>({
  dbItems,
  processDbItem,
  deleteDbItem,
  rollbarErrorMessage = 'Processing db item failed',
}: UseProcessorProps<DBItem>) {
  const [isProcessing, setIsProcessing] = useState(false);
  const rollbar = useRollbar();
  const isOffline = useOfflineStatus();
  const userId = useSelector(selectUserId);

  const process = async () => {
    if (isProcessing || !dbItems?.length) {
      return;
    }

    setIsProcessing(true);

    for (const dbItem of dbItems) {
      try {
        await processDbItem(dbItem);
        if (!isOffline) {
          await deleteDbItem(dbItem.id);
        }
      } catch (error) {
        rollbar.error(rollbarErrorMessage, error as Error, dbItem, { userId });
        if (error instanceof AxiosError) {
          if (error.response?.status === 403) {
            await deleteDbItem(dbItem.id);
          }
        }
      }
    }

    setIsProcessing(false);
  };

  useEffect(() => {
    process();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isOffline) {
        process();
      }
    }, RETRY_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    process();
  }, [isOffline, dbItems]);
}
