import React, { useEffect, useState } from 'react';
import ModalWrapper from './ModalWrapper';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';
import ReactSimpleImageViewer from 'react-simple-image-viewer';
import VerticalShadowScroll from '../elements/VerticalShadowScroll';
import TrashIcon from '../icons/TrashIcon';
import useModal from '../../hooks/useModal';
import ConfirmationModal from './ConfirmationModal';
import { PhotoDbRequestAction, photoRequestsDb } from '../../database';
import { socket } from '@/lib/socket';

export interface ImageModalItem {
  photoId?: number;
  url: string;
  filePath: string;
}

interface ImagesModalProps {
  isVisible: boolean;
  onClose: () => void;
  images: ImageModalItem[];
  waypointId: number;
}

const ImagesModal = ({ isVisible, onClose, images, waypointId }: ImagesModalProps) => {
  const [imageToDelete, setImageToDelete] = useState<ImageModalItem | null>(null);

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const { isVisible: isConfirmationModalVisible, toggle: toggleConfirmationModal } = useModal();

  const { t } = useTranslation();

  const hideConfirmationModal = () => {
    setImageToDelete(null);
    toggleConfirmationModal();
  };

  const handleImageDelete = async () => {
    if (!imageToDelete) {
      return;
    }

    await photoRequestsDb.requests.add({
      action: PhotoDbRequestAction.Delete,
      photoPath: imageToDelete.filePath,
      photoId: imageToDelete.photoId,
      waypointIds: [waypointId],
    });

    socket.volatile.emit('PhotoDeleted', {
      type: 'PhotoDeleted',
      payload: {
        waypointIds: [waypointId],
        filePath: imageToDelete.filePath,
      },
    });

    setImageToDelete(null);
  };

  useEffect(() => {
    if (isVisible && !images.length) {
      onClose();
    }
  }, [isVisible, images]);

  return (
    <ModalWrapper isVisible={isVisible} wide>
      <div className="images-modal">
        <VerticalShadowScroll className="images-modal__images">
          {images.map((image, index) => (
            <div key={index} className="images-modal__image flex flex-col gap-2 p-2">
              <img
                width={300}
                height={200}
                src={image.url}
                onClick={() => {
                  setCurrentImage(index);
                  setIsViewerOpen(true);
                }}
              />
              <Button
                size={'md'}
                text={t('delete')}
                color={'danger'}
                onClick={() => {
                  setImageToDelete(image);
                  toggleConfirmationModal();
                }}
                icon={<TrashIcon size={'sm'} />}
                wide
              />
            </div>
          ))}
        </VerticalShadowScroll>
        {isViewerOpen && (
          <ReactSimpleImageViewer
            src={images.map(({ url }) => url)}
            currentIndex={currentImage}
            closeOnClickOutside
            disableScroll
            onClose={() => setIsViewerOpen(false)}
          />
        )}
        <div className="images-modal__footer">
          <Button text={t('close')} size="md" wide color={'disabled'} onClick={onClose} />
        </div>
      </div>

      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        hide={hideConfirmationModal}
        submit={handleImageDelete}
        text={t('confirmationText', { ns: 'photoPage' })}
      />
    </ModalWrapper>
  );
};

export default ImagesModal;
