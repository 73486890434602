import React, { useRef, useState } from 'react';
import { selectDisplaySidebarOnMobile } from '@/features/route';
import { useSelector } from 'react-redux';
import WaypointListHamburgerMenu from './WaypointListHamburgerMenu';
import WaypointListSortingMenu from './WaypointListSortingMenu';
import { WaypointListFilteringMenu } from './WaypointListFilteringMenu';
import { cn } from '@/utils/cn';
import HeaderIndicators from '@/components/elements/HeaderIndicators';
import AdvancedHeaderSummary from '@/components/navigation/AdvancedHeaderSummary';
import { useIsRoutePage } from '@/helpers/router';
import { WaypointListFormData } from './WaypointListFilteringMenuForm';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { useSidebar } from '@/hooks/useSidebar';

interface WaypointsListBarProps {
  onFilterSubmit?: (val: WaypointListFormData) => void;
  shouldDisplayFilterAndSorting?: boolean;
}

const WaypointsListBar = ({ onFilterSubmit, shouldDisplayFilterAndSorting }: WaypointsListBarProps) => {
  const isDisplayOnMobile = useSelector(selectDisplaySidebarOnMobile);

  const { isSidebarDisplayed: isFilterMenuVisible, toggleSidebar: toggleFilterMenu, closeSidebar: closeFilterMenu } = useSidebar();
  const filterMenuRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLButtonElement>(null);

  const { isSidebarDisplayed: isSortingMenuVisible, toggleSidebar: toggleSortingMenu, closeSidebar: closeSortingMenu } = useSidebar();
  const sortingMenuRef = useRef<HTMLDivElement>(null);
  const sortingButtonRef = useRef<HTMLButtonElement>(null);

  const { isSidebarDisplayed, closeSidebar, toggleSidebar } = useSidebar();

  const { isSidebarDisplayed: isAlcoholPopupVisible, closeSidebar: closeAlcoholPopup, toggleSidebar: toggleAlcoholPopup } = useSidebar();
  const alcoholSpanRef = useRef<HTMLSpanElement>(null);

  useOutsideClick([filterMenuRef, filterButtonRef, sortingMenuRef, sortingButtonRef, alcoholSpanRef], () => {
    closeFilterMenu();
    closeSortingMenu();
    closeAlcoholPopup();
  });

  const onFilterButtonClick = () => {
    closeSidebar();
    closeSortingMenu();
    closeAlcoholPopup();
    toggleFilterMenu();
  };

  const onSortingButtonClick = () => {
    closeSidebar();
    closeFilterMenu();
    closeAlcoholPopup();
    toggleSortingMenu();
  };

  const onHamburgerMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    closeFilterMenu();
    closeSortingMenu();
    closeAlcoholPopup();
    toggleSidebar();
  };

  const onAlcoholSpanClick = () => {
    closeFilterMenu();
    closeSortingMenu();
    toggleAlcoholPopup();
  };

  return (
    <div
      className={cn('waypoints-list-bar z-[51] relative', {
        'waypoints-list-bar--mobile-visible': isDisplayOnMobile,
      })}
    >
      <div
        className={cn(
          'centered ml-auto px-2 md:w-[300px] py-2 bg-white dark:bg-gray-800',
          'justify-between',
          'waypoints-list-bar__item__actions top-[4.2rem] md:w-[300px]',
        )}
      >
        <HeaderIndicators
          isAlcoholPopupVisible={isAlcoholPopupVisible}
          alcoholSpanRef={alcoholSpanRef}
          onAlcoholPopupIconClick={onAlcoholSpanClick}
          className="md:gap-3 ml-0"
        />
        {shouldDisplayFilterAndSorting && (
          <>
            <WaypointListFilteringMenu
              onFilterSubmit={(data) => {
                toggleFilterMenu();
                onFilterSubmit?.(data);
              }}
              onFilterButtonClicked={onFilterButtonClick}
              filterMenuRef={filterMenuRef}
              filterButtonRef={filterButtonRef}
              isFilterMenuVisible={isFilterMenuVisible}
            />
            <WaypointListSortingMenu
              sortingMenuRef={sortingMenuRef}
              sortingButtonRef={sortingButtonRef}
              isSortingMenuVisible={isSortingMenuVisible}
              onSortingButtonClicked={onSortingButtonClick}
              closeMenu={closeSortingMenu}
            />
          </>
        )}
        <WaypointListHamburgerMenu
          isSidebarVisible={isSidebarDisplayed}
          closeSidebar={closeSidebar}
          onHamburgerMenuClick={onHamburgerMenuClick}
        />
      </div>
      <AdvancedHeaderSummary />
    </div>
  );
};

export default WaypointsListBar;
