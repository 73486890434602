import { useDispatch, useSelector } from 'react-redux';
import { selectMode, setMode } from '@/store/settings/slice';
import { AppMode } from '@/types/common';

export function useAppMode() {
  const appMode = useSelector(selectMode);
  const dispatch = useDispatch();

  const isBasicMode = appMode === AppMode.Basic;
  const isAdvancedMode = appMode === AppMode.Advanced;

  const setAppMode = (mode: AppMode): void => {
    dispatch(setMode(mode));
  };

  return { appMode, isBasicMode, isAdvancedMode, setAppMode };
}
