import React from 'react';
import { cn } from '@/utils/cn';

export interface Tab {
  id: string;
  label: string;
  icon?: JSX.Element;
}

interface TabsProps {
  tabs: Tab[];
  currentTabId: string;
  onChange: (tabId: string) => void;
  className?: string;
}

const Tabs = ({ tabs, currentTabId, onChange, className }: TabsProps) => {
  return (
    <div
      className={cn({
        'flex gap-4 md:text-lg': true,
        className: Boolean(className),
      })}
    >
      {tabs.map((tab) => (
        <button
          className={cn({
            'flex gap-2 items-center border-b-2 border-gray-800 dark:border-gray-100 pb-1 pr-2 cursor-pointer': true,
            'text-blue-500 dark:text-blue-400 border-blue-500 dark:border-blue-400': currentTabId === tab.id,
          })}
          onClick={() => onChange(tab.id)}
          key={tab.id}
        >
          {tab.icon && <div>{tab.icon}</div>}
          <div>{tab.label}</div>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
