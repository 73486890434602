import { RouteGroupItem, RouteGroupItemPlanning, RouteGroupItemPlanningService } from '@ekt-group/general-purpose-api-interfaces';
import generalService, { GetRouteGroupItemsListDto } from '../../../services/general.service';
import { createApi } from '@reduxjs/toolkit/query/react';
import routeGroupItemsService, { RouteGroupItemFinishDto, RouteGroupItemStartDto } from './route-group-items.service';
import { extendedBaseQuery } from '@/lib/http.service';
import { CompletedRouteOrder, HistoricalRoutesMatchItem } from '../types';
import { baseApi, ROUTE_GROUP_ITEMS_KEY } from '@/store/baseApi';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [ROUTE_GROUP_ITEMS_KEY],
});

export const routeGroupItemsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getRouteGroupItemsList: builder.query<RouteGroupItem[], GetRouteGroupItemsListDto>({
      queryFn: async ({ userId, role }) => {
        const result = await generalService.getRouteGroupItemsList({ userId, role });
        return { data: result };
      },
      providesTags: [ROUTE_GROUP_ITEMS_KEY],
      merge: (currentCache, newItems) => {
        if (navigator.onLine) {
          currentCache = newItems;
        }
      },
    }),
    start: builder.mutation<RouteGroupItem, RouteGroupItemStartDto>({
      queryFn: async (obj) => {
        return routeGroupItemsService.start(obj);
      },
      invalidatesTags: [ROUTE_GROUP_ITEMS_KEY],
      onQueryStarted: async ({ id, startTime, odometer }, { dispatch, queryFulfilled, getState }) => {
        const endpoints = routeGroupItemsApi.util.selectInvalidatedBy(getState(), [
          {
            type: ROUTE_GROUP_ITEMS_KEY,
          },
        ]);

        for (const endpoint of endpoints) {
          const { endpointName, originalArgs } = endpoint;
          if (endpointName !== 'getRouteGroupItemsList') {
            continue;
          }
          const patchResult = dispatch(
            routeGroupItemsApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
              const item = draft.find((item) => item.id === id);
              if (item) {
                item.start = startTime;
                item.car.odometer = odometer;
              }
            }),
          );
          // queryFulfilled.catch(patchResult.undo);
        }
      },
    }),
    finish: builder.mutation<RouteGroupItem, RouteGroupItemFinishDto>({
      queryFn: async (obj) => {
        return routeGroupItemsService.finish(obj);
      },
      invalidatesTags: [ROUTE_GROUP_ITEMS_KEY],
      onQueryStarted: async ({ id, endTime }, { dispatch, queryFulfilled, getState }) => {
        const endpoints = routeGroupItemsApi.util.selectInvalidatedBy(getState(), [
          {
            type: ROUTE_GROUP_ITEMS_KEY,
          },
        ]);

        for (const endpoint of endpoints) {
          const { endpointName, originalArgs } = endpoint;
          if (endpointName !== 'getRouteGroupItemsList') continue;
          const patchResult = dispatch(
            routeGroupItemsApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
              const item = draft.find((item) => item.id === id);
              if (item) {
                item.isDone = true;
                item.end = endTime;
              }
            }),
          );
          // queryFulfilled.catch(patchResult.undo);
        }
      },
    }),
    getHistoricalRoutesMatch: builder.query<HistoricalRoutesMatchItem[], number>({
      queryFn: async (id) => {
        return routeGroupItemsService.getHistoricalRoutesMatch(id);
      },
    }),
    getCompletedRouteOrder: builder.query<CompletedRouteOrder[], number>({
      queryFn: async (id) => {
        return routeGroupItemsService.getCompletedRouteOrder(id);
      },
    }),
    getPlanningForRouteGroupItem: builder.query<RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>[], number>({
      queryFn: async (id) => {
        return routeGroupItemsService.getPlanningForRouteGroupItem(id);
      },
    }),
  }),
});

export const {
  useGetRouteGroupItemsListQuery,
  useStartMutation,
  useFinishMutation,
  useGetHistoricalRoutesMatchQuery,
  useLazyGetHistoricalRoutesMatchQuery,
  useLazyGetCompletedRouteOrderQuery,
  useGetPlanningForRouteGroupItemQuery,
  useLazyGetPlanningForRouteGroupItemQuery,
} = routeGroupItemsApi;
