import React, { useState } from 'react';
import { Message, useMessages } from '@/features/messages';
import useModal from '@/hooks/useModal';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '@/components/modals/ModalWrapper';
import Button from '@/components/elements/Button';
import VerticalShadowScroll from '@/components/elements/VerticalShadowScroll';

const MessagesModalWrapper = () => {
  const { isVisible, hide, show } = useModal();

  const [lastNewMessage, setLastNewMessage] = useState<Message | null>(null);
  const { markMessageAsRead } = useMessages({
    onNewMessage: (message: Message) => {
      setLastNewMessage(message);
      if (!isVisible) {
        show();
      }
    },
  });

  const { t } = useTranslation();

  const handleClose = async (message: Message) => {
    hide();
    await markMessageAsRead(message?.id);
  };

  return (
    <ModalWrapper isVisible={isVisible} wide className="overflow-auto p-2 mt-5 max-h-[90dvh] max-w-[90%] md:max-w-[90%] self-start">
      <div className="bg-slate-200 dark:bg-slate-700 p-3 h-fit rounded-md max-h-[50dvh] overflow-auto">
        <b>{lastNewMessage?.messageHeader && <div dangerouslySetInnerHTML={{ __html: lastNewMessage?.messageHeader }} />}</b>
        <VerticalShadowScroll outerClassName="overflow-auto" className="">
          <div dangerouslySetInnerHTML={{ __html: lastNewMessage?.messageBody }} />
        </VerticalShadowScroll>
      </div>

      <div className={'w-full mt-3 justify-self-end'}>
        <Button text={t('close')} color={'success'} onClick={() => handleClose(lastNewMessage)} size={'lg'} wide />
      </div>
    </ModalWrapper>
  );
};

export default MessagesModalWrapper;
