import { ListBoxOption } from '@/components/elements';
import { IWaypointDischarge } from '@/features/home';
import { selectWaypointGroupsWithAdditionalInfo } from '@/store/waypoints/slice';
import { Garbage, ItemType, Kojv, KojvService, ObjectEntity } from '@ekt-group/general-purpose-api-interfaces';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatKojvTimeSlot } from '../../helpers/formatKojvTimeSlot';
import { useAppSelector } from '@/store/types';

interface ItemWithQuantity<T> {
  item: T;
  quantity: number;
  capacity?: number;
}

interface TrueFalseCount {
  trueCount: number;
  falseCount: number;
}

export const useWaypointOptionsForForm = () => {
  const waypointGroupsWithAdditionalInfo = useAppSelector(selectWaypointGroupsWithAdditionalInfo);

  const itemTypesOptions = useMemo(
    () =>
      waypointGroupsWithAdditionalInfo.reduce<ItemWithQuantity<ItemType>[]>((acc, waypointGroup) => {
        waypointGroup.forEach((waypoint) => {
          if (!waypoint.itemType) {
            return;
          }
          const found = acc.find((itemType) => itemType.item.id === waypoint.itemType?.id);
          if (!found) {
            acc.push({ item: waypoint.itemType, quantity: 1, capacity: Number(waypoint.itemType?.capacity) || 0 });
          } else {
            found.capacity = found.capacity + Number(waypoint.itemType?.capacity || 0);
            found.quantity++;
          }
        });

        return acc;
      }, []),
    [waypointGroupsWithAdditionalInfo],
  );

  const garbagesOptions = useMemo(
    () =>
      waypointGroupsWithAdditionalInfo.reduce<ItemWithQuantity<Garbage>[]>((acc, waypointGroup) => {
        waypointGroup.forEach((waypoint) => {
          if (!waypoint.garbage) {
            return;
          }

          const found = acc.find((garbage) => garbage.item.id === waypoint.garbage?.id);
          if (!found) {
            acc.push({ item: waypoint.garbage, quantity: 1 });
          } else {
            found.quantity++;
          }
        });

        return acc;
      }, []),
    [waypointGroupsWithAdditionalInfo],
  );

  const kojvNamesOptions = useMemo(
    () =>
      waypointGroupsWithAdditionalInfo.reduce<ItemWithQuantity<Kojv>[]>((acc, waypointGroup) => {
        waypointGroup.forEach((waypoint) => {
          const kojvObj = (waypoint as IWaypointDischarge).kojv;
          if (!kojvObj) {
            return;
          }

          const found = acc.find((kojv) => kojv.item.name === kojvObj.name);
          if (!found) {
            acc.push({ item: kojvObj, quantity: 1 });
          } else {
            found.quantity++;
          }
        });

        return acc;
      }, []),
    [waypointGroupsWithAdditionalInfo],
  );

  const servicesOptions = useMemo(
    () =>
      waypointGroupsWithAdditionalInfo.reduce<ItemWithQuantity<KojvService>[]>((acc, waypointGroup) => {
        waypointGroup.forEach((waypoint) => {
          const services = (waypoint as IWaypointDischarge).services;
          if (!services) {
            return;
          }

          services.forEach((service) => {
            const found = acc.find((optionService) => optionService.item.id === service.id);
            if (!found) {
              acc.push({ item: service, quantity: 1 });
            } else {
              found.quantity++;
            }
          });
        });

        return acc;
      }, []),
    [waypointGroupsWithAdditionalInfo],
  );

  const hasKeyOptions: ItemWithQuantity<ListBoxOption>[] = useMemo(() => {
    const hasLockCount: TrueFalseCount = waypointGroupsWithAdditionalInfo.reduce(
      (acc, waypointGroup) => {
        waypointGroup.forEach((waypoint) => {
          if (!waypoint.object) {
            return;
          }

          const hasLock = waypoint.object?.hasLock;
          if (hasLock) {
            acc.trueCount++;
          } else {
            acc.falseCount++;
          }
        });

        return acc;
      },
      { trueCount: 0, falseCount: 0 },
    );

    return [
      { item: { key: '1', value: true, label: 'Has key' }, quantity: hasLockCount.trueCount },
      { item: { key: '0', value: false, label: 'Does not have key' }, quantity: hasLockCount.falseCount },
    ];
  }, [waypointGroupsWithAdditionalInfo]);

  const mobileGateOptions: ItemWithQuantity<ListBoxOption>[] = useMemo(() => {
    const hasGateCount: TrueFalseCount = waypointGroupsWithAdditionalInfo.reduce(
      (acc, waypointGroup) => {
        waypointGroup.forEach((waypoint) => {
          if (!waypoint.object) {
            return;
          }

          const hasGate = waypoint.object?.gatePhone || waypoint.object?.gatePhone2 || waypoint.object?.gateClosePhone;
          if (hasGate) {
            acc.trueCount++;
          } else {
            acc.falseCount++;
          }
        });

        return acc;
      },
      { trueCount: 0, falseCount: 0 },
    );

    return [
      { item: { key: '1', value: true, label: 'Has mobile gate' }, quantity: hasGateCount.trueCount },
      { item: { key: '0', value: false, label: 'Does not have mobile gate' }, quantity: hasGateCount.falseCount },
    ];
  }, [waypointGroupsWithAdditionalInfo]);

  const serviceTimeOptions: ItemWithQuantity<ListBoxOption>[] = useMemo(() => {
    const serviceTimeCount: TrueFalseCount = waypointGroupsWithAdditionalInfo.reduce(
      (acc, waypointGroup) => {
        waypointGroup.forEach((waypoint) => {
          const isExistServiceTime =
            (waypoint as IWaypointDischarge).object.serviceStartTime || (waypoint as IWaypointDischarge).object.serviceEndTime;
          if (isExistServiceTime) {
            acc.trueCount++;
          } else {
            acc.falseCount++;
          }
        });

        return acc;
      },
      { trueCount: 0, falseCount: 0 },
    );

    return [
      { item: { key: '1', value: true, label: 'Has service time' }, quantity: serviceTimeCount.trueCount },
      { item: { key: '0', value: false, label: 'Does not have service time' }, quantity: serviceTimeCount.falseCount },
    ];
  }, [waypointGroupsWithAdditionalInfo]);

  const timeSlotOptions = useMemo(
    () =>
      waypointGroupsWithAdditionalInfo.reduce<ItemWithQuantity<string>[]>((acc, waypointGroup) => {
        waypointGroup.forEach((waypoint) => {
          const timeSlotStart = (waypoint as IWaypointDischarge).kojv?.conditions?.allowedSlotsAt;
          const timeSlotEnd = (waypoint as IWaypointDischarge).kojv?.conditions?.allowedSlotsTo;

          if (!timeSlotStart || !timeSlotEnd) {
            return;
          }

          const combinedTimeSlot = `${formatKojvTimeSlot(timeSlotStart)}-${formatKojvTimeSlot(timeSlotEnd)}`;

          const found = acc.find((timeSlot) => timeSlot.item === combinedTimeSlot);
          if (!found) {
            acc.push({ item: combinedTimeSlot, quantity: 1 });
          } else {
            found.quantity++;
          }
        });

        return acc;
      }, []),
    [waypointGroupsWithAdditionalInfo],
  );

  return {
    itemTypesOptions,
    garbagesOptions,
    kojvNamesOptions,
    servicesOptions,
    hasKeyOptions,
    serviceTimeOptions,
    timeSlotOptions,
    mobileGateOptions,
  };
};
