import { useEffect, useState } from 'react';
import { ArrivedPageForm, selectArrivedPageForm, setArrivedPageForm } from '@/features/route';
import { SPECIAL_SERVICE_ITEM_IDS } from '@/constants/waypoints';
import { SpecialWaypointServiceItem } from '@/types/common';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem, selectIsCurrentRouteGroupItemOpenedMode, Waypoint, WaypointType } from '@/features/home';
import { useLiveQuery } from 'dexie-react-hooks';
import { waypointsStatusRequestsDb } from '@/database';
import { WaypointsStatus } from '@/types/waypoints';
import { ObjectServiceActivityType } from '@/types/objects';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';

const getWaypointDischargesCount = ({ isBag, bagsCount, childrenCount }: { isBag: boolean; bagsCount: number; childrenCount: number }) => {
  if (isBag && bagsCount) {
    return bagsCount;
  }
  if (childrenCount) {
    return childrenCount + 1;
  }
  return 1;
};

export function useArrivedPageInitialForm(arrivedWaypoints: Waypoint[]) {
  const [isFormInitialized, setIsFormInitialized] = useState(false);

  const dispatch = useDispatch();
  const pendingDischargeRequests = useLiveQuery(() => waypointsStatusRequestsDb.requests.toArray());

  const form = useSelector(selectArrivedPageForm);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const isCurrentRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const { itemTypesMap } = useCommonItemMaps();

  const setArrivedPageInitialForm = () => {
    if (!arrivedWaypoints.length) {
      return;
    }
    if (!currentRouteGroupItem?.isDischargeSheet) {
      if (isCurrentRouteGroupItemOpenedMode) {
        return;
      }

      const serviceArrivedWaypoints = arrivedWaypoints as Waypoint<WaypointType.Service>[];

      const initialDischargesMap = serviceArrivedWaypoints.reduce<Record<string, boolean>>((acc, { id, activityType }) => {
        if (activityType !== ObjectServiceActivityType.DeInstall) {
          return acc;
        }

        return { ...acc, [id]: false };
      }, {});
      const selectedWaypointIds = serviceArrivedWaypoints.reduce<number[]>((acc, { id, doneAt, isFailed }) => {
        if (isFailed || doneAt) {
          return acc;
        }

        return [...acc, id];
      }, []);

      dispatch(
        setArrivedPageForm({
          selectedWaypointIds,
          updateWaypointIds: [],
          waypointIdsForPhotoUpload: [],
          serviceWaypointDischarges: initialDischargesMap,
          services: null,
        }),
      );
      return;
    }

    const initialArrivedPageForm = (arrivedWaypoints as Waypoint<WaypointType.Discharge>[]).reduce<ArrivedPageForm>(
      (acc, { id, services, done, waypointLogDetails, dynamicGarbageM3, weightKg, childrenCount, bagsCount, objectItem }) => {
        if (!isCurrentRouteGroupItemOpenedMode && !done) {
          acc.selectedWaypointIds.push(id);
        }

        const serviceLogs = waypointLogDetails?.filter(({ type }) => type === 'extra_service') || [];
        const serviceLogsValueMap = serviceLogs.reduce<Record<string, number>>((map, log) => {
          return { ...map, [log.kojvServiceId]: Number(log.value) };
        }, {});

        if (!acc.services[id]) {
          acc.services[id] = {};
        }

        const pendingRequest = pendingDischargeRequests?.find(
          ({ waypointIds, status }) => waypointIds.includes(id) && status !== WaypointsStatus.DeleteData,
        );

        services.forEach(({ id: serviceId }) => {
          if (!acc.services[id][serviceId]) {
            const pendingRequestService = pendingRequest?.body.services[id].find(({ id }) => id === serviceId);
            acc.services[id][serviceId] = {
              value: pendingRequestService?.value ?? serviceLogsValueMap[serviceId] ?? 0,
            };
          }
        });

        const waypointDischargesCount = getWaypointDischargesCount({
          isBag: itemTypesMap[objectItem.itemTypeId]?.isBag,
          bagsCount,
          childrenCount,
        });

        acc.services[id][SPECIAL_SERVICE_ITEM_IDS[SpecialWaypointServiceItem.DynamicCapacity]] = {
          value: pendingRequest?.body.dynamicCapacities[id] ?? dynamicGarbageM3 ?? 0,
        };
        acc.services[id][SPECIAL_SERVICE_ITEM_IDS[SpecialWaypointServiceItem.Discharge]] = {
          value: pendingRequest?.body.dischargesCount[id] ?? waypointDischargesCount,
        };
        acc.services[id][SPECIAL_SERVICE_ITEM_IDS[SpecialWaypointServiceItem.ManualWeight]] = {
          value: pendingRequest?.body.weights[id] ?? weightKg ?? 0,
        };

        return acc;
      },
      { selectedWaypointIds: [], waypointIdsForPhotoUpload: [], updateWaypointIds: [], services: {} },
    );

    dispatch(setArrivedPageForm(initialArrivedPageForm));
  };

  useEffect(() => {
    setArrivedPageInitialForm();
  }, []);

  useEffect(() => {
    setArrivedPageInitialForm();
  }, [pendingDischargeRequests]);

  useEffect(() => {
    if (form && (currentRouteGroupItem?.isDischargeSheet ? form.services && Object.keys(form.services).length > 0 : true)) {
      setIsFormInitialized(true);
    }
  }, [form]);

  return {
    isFormInitialized,
  };
}
