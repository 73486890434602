import React from 'react';
import { RouteGroupItemSummary } from '../types/routeGroupItems';
import { useTranslation } from 'react-i18next';

interface Props {
  routeSummary: RouteGroupItemSummary;
}

const RouteGroupItemStartFinishModalSummaryTables = ({ routeSummary }: Props) => {
  const { t } = useTranslation('routeGroupItems');

  const processSummaryItems = (
    summaryItems: RouteGroupItemSummary['itemTypes'] | RouteGroupItemSummary['services'],
    isServices = false,
  ) => {
    if (!summaryItems || !Object.keys(summaryItems).length) {
      return [];
    }

    return Object.entries(summaryItems)
      .reduce((acc, [key, { value, name }]) => {
        const translatedName = isServices ? t(key, { ns: 'articleCodes' }) : name;

        const item = {
          name: isServices ? (translatedName && key !== translatedName ? translatedName : name) : name,
          value: value.toFixed(2),
          key,
        };

        return [...acc, item];
      }, [])
      .sort((a, b) => {
        const aValue = parseFloat(a.value);
        const bValue = parseFloat(b.value);

        return bValue - aValue;
      });
  };

  const itemTypeSummaryItems = processSummaryItems(routeSummary.itemTypes);
  const serviceSummaryItems = processSummaryItems(routeSummary.services, true);

  return (
    <div className="route-group-item-start-finish-modal__summary-tables">
      <table>
        <thead>
          <tr>
            <th>{t('startFinishModal.containerType')}</th>
            <th>{t('startFinishModal.count')}</th>
          </tr>
        </thead>
        <tbody>
          {itemTypeSummaryItems.length > 0 ? (
            itemTypeSummaryItems.map(({ key, value, name }) => (
              <tr key={key}>
                <td>{name}</td>
                <td>{value}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>{t('noData', { ns: 'common' })}</td>
            </tr>
          )}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>{t('startFinishModal.serviceName')}</th>
            <th>{t('startFinishModal.count')}</th>
          </tr>
        </thead>
        <tbody>
          {serviceSummaryItems.length > 0 ? (
            serviceSummaryItems.map(({ value, name, key }) => (
              <tr key={key}>
                <td>{name}</td>
                <td>{parseFloat(value.toString()).toFixed(2)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>{t('noData', { ns: 'common' })}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RouteGroupItemStartFinishModalSummaryTables;
