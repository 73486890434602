import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@/store';
import { UnloadPoint, WaypointUnload } from '@ekt-group/general-purpose-api-interfaces';

export interface UnloadingPageState {
  selectedUnloadPoint: UnloadPoint | null;

  // shows if the unload point was successfully created in server side
  createdWaypointResult: WaypointUnload | null;
}

const initialState: UnloadingPageState = {
  selectedUnloadPoint: null,
  createdWaypointResult: null,
};

export const unloadingPageSlice = createSlice({
  name: 'unloadingPage',
  initialState,
  reducers: {
    setSelectedUnloadPoint: (state, action) => {
      state.selectedUnloadPoint = action.payload;
    },
    resetUnloadingPageState: (state) => {
      Object.assign(state, initialState);
    },
    setCreatedWaypointResult: (state, action) => {
      state.createdWaypointResult = action.payload;
    },
  },
});

export const { setSelectedUnloadPoint, resetUnloadingPageState, setCreatedWaypointResult } = unloadingPageSlice.actions;

export const selectSelectedUnloadPoint = (state: AppState) => state.unloadingPage.selectedUnloadPoint;
export const selectCreatedWaypointResult = (state: AppState) => state.unloadingPage.createdWaypointResult;

export default unloadingPageSlice.reducer;
