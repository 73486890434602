import React, { PropsWithChildren } from 'react';

type PopupPosition = 'top' | 'left' | 'right' | 'bottom' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

interface PopupProps {
  position?: PopupPosition;
}

const positionClasses: Record<PopupPosition, string> = {
  bottom: 'top-full left-1/2',
  right: 'left-full top-1/2',
  left: 'right-full top-1/2',
  top: 'bottom-full left-1/2',
  'top-left': 'bottom-full right-full',
  'top-right': 'bottom-full left-full',
  'bottom-left': 'top-full right-full',
  'bottom-right': 'top-full left-full',
};

const Popup = ({ position = 'right', children }: PropsWithChildren<PopupProps>) => {
  return <div className={`absolute ${positionClasses[position]} rounded-md min-w-fit p-2 dark:bg-gray-600 bg-gray-200`}>{children}</div>;
};

export default Popup;
