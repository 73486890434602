import Dexie from 'dexie';

export interface MessagesRequest {
  id?: number;
  timestamp: string;
  messageId: number;
}

export class MessagesRequestsDb extends Dexie {
  requests: Dexie.Table<MessagesRequest, number>;

  constructor() {
    super('messagesRequestsDb');

    this.version(1).stores({
      requests: '++id, timestamp, messageId',
    });
  }
}

export const messagesRequestsDb = new MessagesRequestsDb();
