import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider, Routes } from 'react-router-dom';
import MapLayout from '../layouts/MapLayout';
import BaseLayout from '../layouts/BaseLayout';
import AppLayout from '../layouts/AppLayout';
import ErrorComponent from '../components/ErrorComponent';
import { LoginPage } from '@/features/auth';
import { AccessDeniedPage, NotFoundPage, ServerErrorPage } from '@/features/misc';
import { FuelingPage } from '@/features/fueling';
import { PhotoPage } from '@/features/photo';
import { MessagesPage } from '@/features/messages';
import { RoutePage } from '@/features/route';
import { WaypointsPage } from '@/features/waypoints';
import { AccidentChatPage } from '@/features/accident-chat';
import { ErrorBoundary } from '@rollbar/react';
import { HomePage } from '@/features/home';
import { UnloadingPage } from '@/features/unloading';
import { ConstructionPage } from '@/features/constructions';
import { RouteIdentifier } from '@/hooks/useRouteSpecificProps';
import LayoutProvider from '@/layouts/LayoutProvider';

export const AppRoutes = () => {
  const routeRouteProps: RouteIdentifier = {
    isPageWithId: true,
  };

  const constructionRouteProps: RouteIdentifier = {
    isPageWithId: true,
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/accident-chat" element={<AccidentChatPage />} />
        <Route
          element={
            <ErrorBoundary fallbackUI={ErrorComponent}>
              <BaseLayout />
            </ErrorBoundary>
          }
        >
          <Route element={<AppLayout />}>
            <Route element={<LayoutProvider />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/photo" element={<PhotoPage />} />
              <Route path="/messages" element={<MessagesPage />} />
              <Route path="/route/:routeId" element={<RoutePage />} handle={routeRouteProps} />
              <Route path="/fueling" element={<FuelingPage />} />
              <Route path="/unloading" element={<UnloadingPage />} />
              <Route path="/construction/:routeSheetId" element={<ConstructionPage />} handle={constructionRouteProps} />
            </Route>

            <Route element={<MapLayout />}>
              <Route path="/waypoints" element={<WaypointsPage />} />
            </Route>
          </Route>

          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/500" element={<ServerErrorPage />} />
          <Route path="/access-denied" element={<AccessDeniedPage />} />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router}></RouterProvider>;
};

export default AppRoutes;
