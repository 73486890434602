import { useCallback, useEffect } from 'react';
import gatesService from '../api/gates.service';
import { useDispatch, useSelector } from 'react-redux';
import { setGates, setKeysAndRemotes } from '@/features/route';
import keysAndRemotesService from '../api/keys-and-remotes.service';
import { selectCurrentRouteGroupItem } from '@/features/home';

export function useKeysAndGates() {
  const dispatch = useDispatch();
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  const getGates = useCallback(async () => {
    const gates = await gatesService.getGates();
    dispatch(setGates(gates));
  }, [dispatch]);

  const getKeysAndRemotes = useCallback(async () => {
    const keysAndRemotes = await keysAndRemotesService.getKeysAndRemotes(currentRouteGroupItem.id);
    dispatch(setKeysAndRemotes(keysAndRemotes));
  }, [currentRouteGroupItem, dispatch]);

  useEffect(() => {
    if (!currentRouteGroupItem || !currentRouteGroupItem.isDischargeSheet) {
      return;
    }

    getGates();
    getKeysAndRemotes();
  }, [getKeysAndRemotes, getGates, currentRouteGroupItem]);
}
