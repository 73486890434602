import React from 'react';
import AccidentMessageList from './AccidentMessageList';
import DriverMessageForm, { FormMode } from '@ekt-group/driver-message-form';
import '@ekt-group/driver-message-form/dist/index.css';
import { useAccidentChatMessages } from '../hooks/useAccidentChatMessages';
import { AccidentChatMessage } from '@ekt-group/general-purpose-api-interfaces';
import { useRollbar } from '@rollbar/react';
import { getBackendHost } from '@/helpers/backend';
import Spinner from '@/components/elements/Spinner';

interface AccidentChatMessagesTabProps {
  messages: AccidentChatMessage[];
  isLoading: boolean;
  chatToken: string;
  userId?: number;
  userName?: string;
}

const AccidentChatMessagesTab = ({ messages, isLoading, chatToken, userId, userName }: AccidentChatMessagesTabProps) => {
  const { addMessageToStore } = useAccidentChatMessages({ chatToken });

  const rollbar = useRollbar();

  const handleFormSubmit = (content: string, title?: string) => {
    addMessageToStore({ content, title });
  };

  const handleFormError = (error: unknown) => {
    rollbar.error('[AccidentChat]: Message sending failed', error as Error, { chatToken, userName, userId });
  };

  if (isLoading) {
    return <Spinner size={'lg'} />;
  }

  return (
    <>
      <AccidentMessageList messages={messages} />
      <div className={'[&_img]:w-[30rem] [&_trix-editor]:max-h-[15rem] [&_trix-editor]:overflow-auto w-full'}>
        <DriverMessageForm
          mode={FormMode.AccidentChat}
          baseUrl={getBackendHost()}
          chatToken={chatToken}
          author={userName}
          onSend={handleFormSubmit}
          onError={handleFormError}
        />
      </div>
    </>
  );
};

export default AccidentChatMessagesTab;
