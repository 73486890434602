import Dexie from 'dexie';
import { WaypointTank } from '@ekt-group/general-purpose-api-interfaces';

export interface WaypointsTanksRequest {
  id?: number;
  body: Partial<WaypointTank>;
}

export class WaypointsTanksRequestsDb extends Dexie {
  requests: Dexie.Table<WaypointsTanksRequest, number>;

  constructor() {
    super('waypointsTanksRequestsDb');
    this.version(1).stores({
      requests: '++id, body',
    });
  }
}

export const waypointsTanksRequestsDb = new WaypointsTanksRequestsDb();
