import React, { useEffect, useState } from 'react';
import ModalWrapper from '@/components/modals/ModalWrapper';
import { CompletedRouteOrder, CompletedRouteOrderItem, HistoricalRoutesMatchItem } from '@/features/home';
import Button from '@/components/elements/Button';
import { MapComponent, MapMode, PlanningRoutes, PlanningStep, PlanningStepType } from '@ekt-group/map-component';
import { useWaypointsRequest } from '@/features/route';
import { useTranslation } from 'react-i18next';
import SortingModalHistoricalRouteList from './SortingModalHistoricalRouteList';
import SortingModalLegend from './SortingModalLegend';
import { SortingModalPlanningItem } from './SortingModalPlanningItem';
import { RouteGroupItemPlanning, RouteGroupItemPlanningService, PlanningVroomPlanning } from '@ekt-group/general-purpose-api-interfaces';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { Feature } from '@/types/features';
import { cn } from '@/utils/cn';

interface Props {
  onContinueUseClick: (planningObject?: PlanningRoutes, completedOrder?: CompletedRouteOrder) => void;
  onCloseBackClick: () => void;
  onMatchedItemChange: (routeGroupItemId: number) => void;
  onPlanningItemClick: (optimizedItem: RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>) => void;
  selectedRouteGroupItemId?: number;
  optimizedItem?: RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>;
  isVisible?: boolean;
  shouldNotProvideDataToMap?: boolean;
  historicalMatchedItems: HistoricalRoutesMatchItem[];
  completedOrder: CompletedRouteOrder;
  isFetchingCompletedOrder?: boolean;
  isLoadingHistoricalMatch?: boolean;
  isLoadingPlanning?: boolean;
  isPlanningSelected: boolean;
}

/**
 * Processes an optimized item and returns the planning routes.
 * @param item The item to process.
 * @returns The planning routes.
 */
const processOptimizedItem = (item: RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>): PlanningRoutes => {
  const result: PlanningRoutes = {
    planningPolyline: null,
    routes: [],
  };

  if (!item) {
    return null;
  }

  result.planningPolyline = item.planning.polyline;
  const routes = (item.planning as PlanningVroomPlanning).response.routes;
  for (const route of routes) {
    const steps = route.steps.reduce<PlanningStep[]>((acc, each) => {
      // really making sure that .location exists
      if (!each?.location) {
        return acc;
      }

      const step: PlanningStep = {
        type: each.type as PlanningStepType,
        location: each.location,
        id: each.id,
      };

      acc.push(step);
      return acc;
    }, []);

    result.routes.push({
      polyline: route.geometry,
      steps,
    });
  }
  return result;
};

const SortingModal = ({
  isVisible = false,
  onCloseBackClick,
  onContinueUseClick,
  selectedRouteGroupItemId,
  onMatchedItemChange,
  onPlanningItemClick,
  historicalMatchedItems,
  completedOrder,
  optimizedItem = null,
  isPlanningSelected = true,
  isFetchingCompletedOrder = false,
  isLoadingHistoricalMatch = false,
  isLoadingPlanning = false,
  shouldNotProvideDataToMap = false,
}: Props) => {
  const { t } = useTranslation();
  const { data: waypoints } = useWaypointsRequest();
  const { isFeatureEnabled } = useFeatureFlags();

  // Prepare data to pass to the mapcomponent
  const planningObject = processOptimizedItem(optimizedItem);

  const mainObjects: CompletedRouteOrderItem[] = waypoints?.reduce((acc, waypoint) => {
    const { id: objectId, longitude, latitude } = waypoint.object;

    if (acc.find(({ id }) => objectId === id)) {
      return acc;
    }

    acc.push({ type: 'object', id: objectId, longitude, latitude });

    return acc;
  }, []);

  const handleCloseBackClick = () => {
    onCloseBackClick && onCloseBackClick();
  };

  const handleContinueUseClick = () => {
    onContinueUseClick && onContinueUseClick(planningObject, completedOrder);
  };

  const mode = isPlanningSelected ? MapMode.Optimized : MapMode.DriverRouteCompare;

  const renderLabel = () => {
    const optimizedLabel = t('sorting.optimized', { ns: 'tabletPage' });
    const historicalLabel = t('sorting.historicalOrder', { ns: 'tabletPage' });

    return isFeatureEnabled(Feature.OptimizedSorting) ? `${optimizedLabel} / ${historicalLabel}` : historicalLabel;
  };

  return (
    <ModalWrapper isVisible={isVisible} className="md:max-h-[95vh] overflow-auto">
      <div className={'flex flex-col h-full gap-y-2'}>
        <div className={'flex flex-col flex-grow md:flex-row items-start gap-4 h-[80vh]'}>
          <div className={'w-full md:w-3/4 flex flex-col'}>
            <div
              className={cn('h-dynamic-50 rounded overflow-hidden', {
                'md:h-dynamic-80': mode === MapMode.Optimized,
                'md:h-dynamic-75': mode === MapMode.DriverRouteCompare,
              })}
            >
              {/* We do it this way because each map is in a different context.
              Re-rendering from switching mode will cause it to dispose */}
              {mode === MapMode.Optimized && <MapComponent mode={mode} planningObject={planningObject || null} />}
              {mode === MapMode.DriverRouteCompare && (
                <MapComponent
                  mode={mode}
                  mainObjects={shouldNotProvideDataToMap ? [] : mainObjects}
                  targetObjects={shouldNotProvideDataToMap ? [] : completedOrder?.order}
                  routeCoordinates={shouldNotProvideDataToMap ? [] : completedOrder?.routeCoordinates}
                />
              )}
            </div>
            <div className="mt-2">{mode === MapMode.DriverRouteCompare && <SortingModalLegend />}</div>
          </div>
          <div className="md:w-1/4 gap-y-3 flex flex-col flex-1 w-full h-full overflow-y-auto">
            {isFeatureEnabled(Feature.OptimizedSorting) && (
              <>
                <div className="gap-y-2 flex flex-col w-full h-full pb-2">
                  <div className="md:text-2xl mb-1 text-lg font-bold border-b-2 border-gray-300">{renderLabel()}</div>
                  <div className="dark:border-gray-700 flex flex-col flex-grow w-full gap-2 overflow-auto overflow-y-auto border-b border-gray-400">
                    <SortingModalPlanningItem
                      optimizedItem={optimizedItem}
                      onClick={onPlanningItemClick}
                      isLoadingPlanning={isLoadingPlanning}
                      isPlanningSelected={isPlanningSelected}
                    />

                    <SortingModalHistoricalRouteList
                      selectedRouteGroupItemId={selectedRouteGroupItemId}
                      handleMatchedItemSelect={onMatchedItemChange}
                      isFetchingCompletedOrder={isFetchingCompletedOrder}
                      isLoadingHistoricalMatch={isLoadingHistoricalMatch}
                      isVisible={isVisible}
                      historicalMatchedItems={historicalMatchedItems}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={'flex gap-4 h-[10%] md:h-[unset] py-2 items-center'}>
          <Button size={'lg'} color={'disabled'} text={t('close')} wide onClick={handleCloseBackClick} />
          <Button size={'lg'} text={t('submit')} wide disabled={isFetchingCompletedOrder} onClick={handleContinueUseClick} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SortingModal;
