import { ComponentPropsWithoutRef } from 'react';
import React from 'react';

export const RadioButtonUnchecked = ({ ...rest }: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g>
        <circle cx="22.5" cy="22.5" r="22" fill="white" stroke="#0934A3" />
      </g>
    </svg>
  );
};
