import React, { ComponentPropsWithoutRef } from 'react';

export const CarProblemAccident = ({ ...rest }: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 489.518 489.518"
      xmlSpace="preserve"
      {...rest}
    >
      <g>
        <path d="M478.964,312.102h-1.542v-7.755c0-5.667-4.852-8.532-8.801-9.644l-5.843-10.989c-0.61-1.148-1.741-1.928-3.031-2.091   l-43.622-5.503l-40.258-63.789c-0.733-1.161-2.01-1.865-3.383-1.865h-96.312c-1.641,0-3.115,1.002-3.719,2.527l-9.977,25.189   c-0.449,1.133-0.357,2.408,0.25,3.465l2.804,4.88l-6.194,2.72c-1.66,0.729-2.622,2.482-2.345,4.274l1.287,8.322l-5.656,3.424   c-0.747,0.453-1.326,1.137-1.647,1.949l-3.033,7.658l-0.882,2.142l-7.362-4.111c-1.229-0.686-2.729-0.676-3.949,0.027   c-1.22,0.703-1.98,1.996-2.001,3.404l-0.003,0.231c-0.573-0.304-1.215-0.466-1.874-0.466c-2.529,0-4.819,0.339-6.804,1.008   c-2.467,0.829-4.73,2.74-3.375,15.199l-4.752,1.972c-1.493,0.62-2.467,2.078-2.467,3.694v4.811c0,1.273,0.606,2.471,1.633,3.225   l4.262,3.128l-2.901,2.962h-0.643c-1.867,0-3.565,1.319-3.979,3.139c-0.721,3.169,1.988,4.544,3.606,5.366   c0.969,0.492,2.305,1.134,3.748,1.827c1.043,0.5,2.477,1.189,3.837,1.862c-0.965,1.014-1.916,2.16-2.651,3.356l-7.13-0.192   c-1.07-0.024-2.124,0.379-2.898,1.132c-0.773,0.753-1.21,1.787-1.21,2.866c0,0.589-0.029,1.155-0.056,1.686   c-0.106,2.152-0.238,4.83,1.689,6.854c1.215,1.276,2.912,1.923,5.044,1.923h23.138c2.118,16.618,16.336,29.51,33.519,29.51   c17.184,0,31.404-12.892,33.522-29.509h76.305c2.118,16.618,16.336,29.509,33.519,29.509c17.184,0,31.405-12.891,33.523-29.509   h28.613c5.82,0,10.554-4.734,10.554-10.554v-8.711C489.518,316.837,484.784,312.102,478.964,312.102z M273.482,353.431   c-8.713,0-15.801-7.09-15.801-15.804c0-8.714,7.088-15.803,15.801-15.803c8.714,0,15.804,7.089,15.804,15.803   C289.287,346.341,282.197,353.431,273.482,353.431z M416.829,353.431c-8.713,0-15.801-7.09-15.801-15.804   c0-8.714,7.088-15.803,15.801-15.803c8.715,0,15.805,7.089,15.805,15.803C432.634,346.342,425.543,353.431,416.829,353.431z" />
        <path d="M206.347,252.617c0.238,0.028,0.474,0.042,0.707,0.042c3.001,0,5.593-2.247,5.952-5.301l9.821-83.646   c0.387-3.291-1.968-6.272-5.259-6.659c-3.293-0.383-6.272,1.969-6.659,5.259l-9.821,83.646   C200.701,249.249,203.056,252.231,206.347,252.617z" />
        <path d="M247.48,219.909l-26.114,34.411c-2.003,2.64-1.487,6.403,1.152,8.406c1.084,0.823,2.358,1.221,3.623,1.221   c1.813,0,3.604-0.818,4.784-2.374l26.114-34.411c2.003-2.64,1.487-6.403-1.152-8.406   C253.247,216.754,249.482,217.27,247.48,219.909z" />
        <path d="M228.229,213.95c0.975,0.594,2.051,0.876,3.115,0.876c2.024,0,4-1.025,5.13-2.88l41.654-68.407   c1.724-2.831,0.826-6.522-2.004-8.245c-2.831-1.725-6.521-0.827-8.245,2.004l-41.654,68.407   C224.501,208.535,225.399,212.227,228.229,213.95z" />
        <path d="M186.328,188.606c0.538,2.886,3.059,4.901,5.892,4.901c0.365,0,0.735-0.033,1.107-0.103   c3.258-0.607,5.406-3.741,4.799-6.998l-3.725-19.98c-0.607-3.258-3.742-5.407-6.998-4.799c-3.258,0.607-5.406,3.741-4.799,6.998   L186.328,188.606z" />
        <path d="M212.364,292.868c0-8.083-6.576-14.659-14.659-14.659h-0.835c-1.763-14.587-6.91-50.523-8.708-63   c-0.374-2.595-2.389-4.647-4.978-5.066l-43.486-7.058l-35.94-83.484c-0.948-2.201-3.115-3.627-5.511-3.627H6c-3.313,0-6,2.687-6,6   v199.783c0,3.314,2.687,6,6,6h74.924c-0.007,0.322-0.024,0.641-0.024,0.964c0,24.716,20.106,44.825,44.82,44.825   c24.716,0,44.824-20.108,44.824-44.824c0-0.324-0.018-0.643-0.024-0.965h28.163c0.589,0,1.158-0.087,1.696-0.248   c6.901-1.27,11.985-7.293,11.985-14.412V292.868z M64.611,203.625l-12.689,13.749H20.771V136.63h67.76l28.392,65.064H69.021   C67.346,201.694,65.747,202.394,64.611,203.625z M125.72,355.545c-14.789,0-26.82-12.034-26.82-26.825   c0-14.791,12.032-26.824,26.82-26.824c14.791,0,26.824,12.034,26.824,26.825C152.544,343.512,140.511,355.545,125.72,355.545z" />
      </g>
    </svg>
  );
};
