import { MakeCallBody } from '../types/phone';
import httpService from '../lib/http.service';

export default {
  async openObjectGate(objectId: number, body: MakeCallBody) {
    await httpService.post(`/objects/${objectId}/open-gate`, body);
  },
  async closeObjectGate(objectId: number, body: MakeCallBody) {
    await httpService.post(`/objects/${objectId}/close-gate`, body);
  },
  async updateObjectItemCoordinates(objectItemId: number, longitude: number, latitude: number) {
    await httpService.patch('/objects/items/coordinates', {
      objectItemId,
      longitude,
      latitude,
    });
  },
};
