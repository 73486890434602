import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnLogin } from '../../auth/hooks/useRedirectOnLogin';
import { setDocumentTitle } from '../../../hooks/useDocumentTitle';

export const AccessDeniedPage = () => {
  const { t } = useTranslation('accessDeniedPage');
  setDocumentTitle('Access Denied');
  useRedirectOnLogin();

  return (
    <div className="access-denied-wrapper">
      <h3 className="access-denied-title">{t('title')}</h3>
      <h5>{t('subtitle')}</h5>
    </div>
  );
};
