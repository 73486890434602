import React from 'react';
import { useTheme } from '@/contexts/themeContext';
import MoonIcon from '@/components/icons/MoonIcon';
import SunIcon from '@/components/icons/SunIcon';
import { cn } from '@/utils/cn';

const ThemeToggleButton = () => {
  const { setTheme, theme } = useTheme();

  const isLight = theme === 'light';
  const isDark = theme === 'dark';

  const toggleTheme = () => {
    setTheme(isLight ? 'dark' : 'light');
  };

  return (
    <button
      className={cn({
        'w-[3.8rem] p-1 flex rounded-full justify-between items-center border border-gray-600 dark:border-gray-100 dark:bg-gray-800 dark:text-gray-100 bg-gray-100 text-yellow-400 transition duration-150 ease-in':
          true,
      })}
      onClick={() => toggleTheme()}
    >
      <div
        className={cn({
          'order-2': isLight,
          'order-1': isDark,
        })}
      >
        {isDark ? <MoonIcon size={'sm'} /> : <SunIcon size={'sm'} />}
      </div>
      <div
        className={cn({
          'h-5 w-5 rounded-full': true,
          'bg-white order-2': isDark,
          'bg-yellow-400 order-1': isLight,
        })}
      ></div>
    </button>
  );
};

export default ThemeToggleButton;
