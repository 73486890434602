import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const VineBottleIcon = ({ size }: IconSizeProps) => (
  <svg
    fill="currentColor"
    height="800px"
    width="800px"
    version="1.2"
    baseProfile="tiny"
    className={iconStyles[iconSizeClasses[size]]}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-63 65 128 128"
    xmlSpace="preserve"
  >
    <path
      d="M-9.9,184.1l-15.8-2V155c30.2-9.2,13.4-43.1,10.1-52.1h-27.9c-3.3,9-20.1,42.9,10.1,52.1v27.1l-15.8,2
	c-1.1,0.1-1.8,1.1-1.7,2.2c0.1,1.1,1,1.7,2.2,1.7h38.3c1.2,0,2.1-0.6,2.2-1.7C-8.1,185.2-8.9,184.2-9.9,184.1 M39.5,99.4V69.5H24
	v29.9c-8.1,2-14.2,9.3-14.2,18v66.9c0,2.1,1.7,3.7,3.7,3.7h36.4c2,0,3.7-1.7,3.7-3.7v-66.9C53.6,108.7,47.6,101.4,39.5,99.4z
	 M49,163.6H14.4V129H49V163.6z"
    />
  </svg>
);

export default VineBottleIcon;
