import React, { useState } from 'react';
import authService from '../../../auth/api';
import { useDispatch, useSelector } from 'react-redux';
import { DriverTokenDto, TokenUser } from '@/features/auth';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { selectIsPhotoOnMobileModalVisible, setPhotoOnMobileModalVisible } from '@/features/route';
import Button from '../../../../components/elements/Button';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { useTranslation } from 'react-i18next';
import PhotoOnMobileQR from './PhotoOnMobileQR';

const PhotoOnMobileModal = () => {
  const [tokenData, setTokenData] = useState<Record<TokenUser, DriverTokenDto>>({
    [TokenUser.Driver]: null,
    [TokenUser.Loader]: null,
  });
  const [isLoadingState, setIsLoadingState] = useState<Record<TokenUser, boolean>>({
    [TokenUser.Driver]: false,
    [TokenUser.Loader]: false,
  });

  const { t } = useTranslation('arrivedPage');

  const dispatch = useDispatch();
  const isModalVisible = useSelector(selectIsPhotoOnMobileModalVisible);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  const getToken = async (tokenUser: TokenUser) => {
    switch (tokenUser) {
      case TokenUser.Driver: {
        tokenData[TokenUser.Loader] = null;
        break;
      }
      case TokenUser.Loader: {
        tokenData[TokenUser.Driver] = null;
        break;
      }
    }

    setIsLoadingState((prevState) => ({ ...prevState, [tokenUser]: true }));
    const userId = tokenUser === TokenUser.Driver ? currentRouteGroupItem?.driverId : currentRouteGroupItem?.loaderId;

    if (!userId) {
      return;
    }

    try {
      const userTokenData = await authService.getToken(userId);
      setTokenData((prevState) => ({ ...prevState, [tokenUser]: userTokenData }));
    } finally {
      setIsLoadingState((prevState) => ({ ...prevState, [tokenUser]: false }));
    }
  };

  return (
    <ModalWrapper isVisible={isModalVisible} title={t('photo.qrScanText')} className="h-fit">
      <div className="photo-on-mobile-modal">
        <div className={'pb-8 pt-4 text-lg centered flex-col gap-8'}>
          <div className="flex flex-col lg:flex-row gap-4 md:gap-16 dark:text-gray-50 max-h-[40vh] md:max-h-none">
            <PhotoOnMobileQR
              user={TokenUser.Driver}
              isLoading={isLoadingState[TokenUser.Driver]}
              data={tokenData[TokenUser.Driver]}
              onClick={() => getToken(TokenUser.Driver)}
            />
            {currentRouteGroupItem?.loaderId && (
              <PhotoOnMobileQR
                user={TokenUser.Loader}
                isLoading={isLoadingState[TokenUser.Loader]}
                data={tokenData[TokenUser.Loader]}
                onClick={() => getToken(TokenUser.Loader)}
              />
            )}
          </div>

          <span className={'px-1 md:px-8'}>{t('photo.afterQrScanText')}</span>
        </div>
        <div className="photo-on-mobile-modal__footer">
          <Button
            text={t('close', { ns: 'common' })}
            onClick={() => dispatch(setPhotoOnMobileModalVisible(false))}
            wide
            size={'md'}
            color={'disabled'}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default PhotoOnMobileModal;
