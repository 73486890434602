import React, { useCallback, useEffect, useMemo } from 'react';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import { HeaderItem } from '@/types/common';
import CheckMark from '@/components/icons/CheckMark';
import CrossMark from '@/components/icons/CrossMark';
import ListIcon from '@/components/icons/ListIcon';
import ClipboardIcon from '@/components/icons/ClipboardIcon';
import {
  selectArrivedPageForm,
  selectIsFailedButtonDisabled,
  selectIsSuccessButtonDisabled,
  selectSuccessRequestPendingState,
  setFormWaypointIdsForPhotoUpload,
  setIsKojvConditionsModalVisible,
  setIsTakePhotoModalVisible,
  setPhotoOnMobileModalVisible,
  setDisplayArrivedPage,
  useUpdateWaypointCoordinatesMutation,
} from '@/features/route';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem, selectIsCurrentRouteGroupItemOpenedMode, Waypoint } from '@/features/home';
import PhotoIcon from '@/components/icons/PhotoIcon';
import MobilePhoneIcon from '@/components/icons/MobilePhoneIcon';
import { CoordinateUpdateEntity, CoordinateUpdateRequest, coordinateUpdateRequestsDb } from '@/database';
import { useTranslation } from 'react-i18next';
import { getDistanceInMeters } from '@/helpers/coordinates';
import GpsIcon from '@/components/icons/GpsIcon';
import { METERS_AMOUNT_TO_DISABLE_SET_COORDINATES_BUTTON } from '@/constants/waypoints';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon';
import { toast } from 'react-toastify';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import useGeolocation from 'react-hook-geolocation';

interface UseArrivedPageNavigationProps {
  checkMarkAction: () => void;
  crossMarkAction: () => void;
  dischargeNotesAction: () => void;
  arrivedWaypoint: Waypoint;
}

export function useArrivedPageNavigation({
  checkMarkAction,
  crossMarkAction,
  dischargeNotesAction,
  arrivedWaypoint,
}: UseArrivedPageNavigationProps) {
  const dispatch = useDispatch();
  const isOffline = useOfflineStatus();
  const [updateWaypointCoordinates] = useUpdateWaypointCoordinatesMutation();

  const { t, i18n } = useTranslation('arrivedPage');

  const isSuccessButtonDisabled = useSelector(selectIsSuccessButtonDisabled);
  const isFailedButtonDisabled = useSelector(selectIsFailedButtonDisabled);
  const isSuccessRequestPending = useSelector(selectSuccessRequestPendingState);

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const isRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);

  const { selectedWaypointIds, services: formServices } = useSelector(selectArrivedPageForm) || {};

  const isNoWaypointsSelected = useMemo(() => selectedWaypointIds?.length === 0, [selectedWaypointIds]);

  const { setNavigationItems } = useAdvancedNavigationItems();
  const { latitude, longitude } = useGeolocation();

  const isCarInAroundAllowedDistanceFromObject = useMemo(() => {
    if (!arrivedWaypoint || !longitude || !latitude) {
      return false;
    }

    const objectCoordinates = {
      longitude: arrivedWaypoint.object.longitude,
      latitude: arrivedWaypoint.object.latitude,
    };

    return (
      getDistanceInMeters(
        {
          latitude,
          longitude,
        },
        objectCoordinates,
      ) <= METERS_AMOUNT_TO_DISABLE_SET_COORDINATES_BUTTON
    );
  }, [longitude, latitude, arrivedWaypoint]);

  const handleUpdateCoordinates = useCallback(async () => {
    const body: Partial<CoordinateUpdateRequest['body']> = currentRouteGroupItem?.isDischargeSheet
      ? {
          longitude,
          latitude,
          entity: CoordinateUpdateEntity.DischargeWaypoint,
          waypointIds: selectedWaypointIds,
        }
      : {
          longitude,
          latitude,
          entity: CoordinateUpdateEntity.ServiceWaypoint,
          serviceIds: selectedWaypointIds,
        };

    const payload: CoordinateUpdateRequest = {
      routeGroupItemId: currentRouteGroupItem?.id,
      body: body as CoordinateUpdateRequest['body'],
    };

    try {
      await updateWaypointCoordinates(payload).unwrap();
    } catch (error) {
      toast('Error while updating coordinates', error);
      await coordinateUpdateRequestsDb.requests.add(payload);
    }

    toast(t('arrivedPage.coordinatesUpdated'));
  }, [
    latitude,
    longitude,
    currentRouteGroupItem?.id,
    currentRouteGroupItem?.isDischargeSheet,
    selectedWaypointIds,
    t,
    updateWaypointCoordinates,
  ]);

  const navigationItems: HeaderItem[] = useMemo(
    () => [
      {
        icon: <CheckMark size={'md'} />,
        label: currentRouteGroupItem?.isDischargeSheet ? t('navbar.dischargeCompleted') : t('navbar.servicesDone'),
        disabled: isRouteGroupItemOpenedMode || isNoWaypointsSelected || isSuccessButtonDisabled || isSuccessRequestPending,
        action: checkMarkAction,
        isPending: isSuccessRequestPending,
      },
      {
        icon: <CrossMark size={'md'} />,
        label: currentRouteGroupItem?.isDischargeSheet ? t('navbar.unableToDischarge') : t('navbar.servicesFailed'),
        style: 'danger-dark',
        action: crossMarkAction,
        disabled: isRouteGroupItemOpenedMode || isNoWaypointsSelected || isFailedButtonDisabled || isSuccessRequestPending,
      },
      {
        icon: <PhotoIcon size={'md'} />,
        label: t('navbar.takePhoto'),
        action: () => {
          dispatch(setIsTakePhotoModalVisible(true));
          dispatch(setFormWaypointIdsForPhotoUpload(selectedWaypointIds));
        },
        disabled: isRouteGroupItemOpenedMode || isNoWaypointsSelected,
      },
      {
        icon: <MobilePhoneIcon size={'md'} />,
        label: t('navbar.takePhotoOnMobile'),
        style: 'warning',
        action: () => dispatch(setPhotoOnMobileModalVisible(true)),
        disabled: isRouteGroupItemOpenedMode || isNoWaypointsSelected,
      },
      {
        icon: <GpsIcon size={'md'} />,
        label: t('navbar.updateCoordinates'),
        action: handleUpdateCoordinates,
        disabled: isRouteGroupItemOpenedMode || isNoWaypointsSelected || !isCarInAroundAllowedDistanceFromObject,
      },
      {
        icon: <ListIcon size={'md'} />,
        label: t('navbar.dischargeNotes'),
        action: dischargeNotesAction,
        disabled: isRouteGroupItemOpenedMode || isNoWaypointsSelected,
      },
      ...(currentRouteGroupItem?.isDischargeSheet
        ? [
            {
              icon: <ClipboardIcon size={'md'} />,
              label: t('navbar.priceListAndConditions'),
              action: () => dispatch(setIsKojvConditionsModalVisible(true)),
            },
          ]
        : []),
      {
        label: t('back', { ns: 'common' }),
        icon: <ArrowLeftIcon size={'md'} />,
        action: () => dispatch(setDisplayArrivedPage(false)),
        style: 'primary',
      },
    ],
    [
      checkMarkAction,
      isRouteGroupItemOpenedMode,
      isSuccessButtonDisabled,
      isSuccessRequestPending,
      isNoWaypointsSelected,
      currentRouteGroupItem?.isDischargeSheet,
      t,
      crossMarkAction,
      dischargeNotesAction,
      isFailedButtonDisabled,
      selectedWaypointIds,
      handleUpdateCoordinates,
      dispatch,
      isCarInAroundAllowedDistanceFromObject,
    ],
  );

  useEffect(() => {
    setNavigationItems(navigationItems);
  }, [navigationItems, setNavigationItems]);
}
