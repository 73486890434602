import httpService from '../../../lib/http.service';
import { Kojv, KojvCondition } from '@ekt-group/general-purpose-api-interfaces';

interface KojvConditionsResponse {
  detailed: KojvCondition;
  standard: KojvCondition;
}

export default {
  async getKojv() {
    const { data } = await httpService.get<Kojv[]>('/kojv');
    return data;
  },
  async getKojvConditions(kojvId: number) {
    const { data } = await httpService.get<KojvConditionsResponse>(`/kojv/${kojvId}/conditions`);
    return data;
  },
};
