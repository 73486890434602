import httpService from '../../../lib/http.service';
import { UnloadPoint } from '@ekt-group/general-purpose-api-interfaces';

export const getUnloadPoints = async (routeGroupItemId: number) => {
  const { data } = await httpService.get<UnloadPoint[]>('/unload-points', { params: { routeGroupItemId } });

  if (!data) {
    return [];
  }
  return data;
};
