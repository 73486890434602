import React from 'react';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';
import iconStyles from '../../assets/styles/icons.module.scss';

const MapIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 32 32"
      className={iconStyles[iconSizeClasses[size]]}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.985 28.050l7.033-3.214v-20.886l-7.033 3.213v20.887zM12.015 24.836l6.98 2.893v-20.887l-6.98-2.892v20.886zM3.981 7.163v20.887l7.043-2.892v-20.887l-7.043 2.892z"></path>
    </svg>
  );
};

export default MapIcon;
