import httpService from '@/lib/http.service';
import { DriverTokenDto, LoggedUserDto } from '@/features/auth';

export default {
  verifyUserToken(token: string) {
    return httpService.get<LoggedUserDto>('/auth/tablet/login', { params: { token } });
  },
  sendSmsCode(phone: string) {
    return httpService.post('/auth/phone/sms', { phone }, { withCredentials: false });
  },
  signInByPhone(phone: string, code: string) {
    return httpService.post<LoggedUserDto>('/auth/phone/login', { phone, code });
  },
  async getToken(driverId: number) {
    const { data } = await httpService.get<DriverTokenDto>('/auth/tablet/token', {
      params: { driverId },
    });
    return data;
  },
  logout() {
    return httpService.post('/auth/tablet/logout', null);
  },
};
