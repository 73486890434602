import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { Feature } from '@/types/features';
import { CreateAlldeviceTaskBodyDto } from '@ekt-group/general-purpose-api-interfaces';
import { selectAllDeviceTasks, useCreateTaskMutation, useGetTasksQuery } from '../api/all-device.api';
import { useAppSelector } from '@/store/types';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUserId } from '@/features/auth';
import { useRollbar } from '@rollbar/react';
import { toast } from 'react-toastify';
import { useAppMode } from '@/hooks/useAppMode';
import { allDeviceRequestsDb } from '@/database';
import { AlldeviceTaskPriority } from '@ekt-group/general-purpose-api-interfaces/src/types/alldevice.enum';

interface UseAllDeviceTasksProps {
  deviceName: string;
}

export const useAllDeviceTasks = ({ deviceName }: UseAllDeviceTasksProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { t } = useTranslation('carCheckUpModal');
  const { isBasicMode } = useAppMode();

  const { data: tasksData } = useAppSelector(selectAllDeviceTasks({ deviceName }));
  const { isLoading, isFetching, status, refetch } = useGetTasksQuery(
    {
      deviceName,
    },
    { skip: !isFeatureEnabled(Feature.CarCheckUp) || deviceName === '' || isBasicMode },
  );

  const [createAllDeviceTaskRequest] = useCreateTaskMutation();
  const rollbar = useRollbar();
  const userId = useSelector(selectUserId);

  const allDevicePriorityOptions = [
    {
      key: AlldeviceTaskPriority.Low,
      value: t('priorityLow'),
    },
    {
      key: AlldeviceTaskPriority.Normal,
      value: t('priorityNormal'),
    },
    {
      key: AlldeviceTaskPriority.High,
      value: t('priorityHigh'),
    },
  ];

  const createTask = useCallback(
    async (payload: CreateAlldeviceTaskBodyDto) => {
      const table = allDeviceRequestsDb.requests;

      const tempId = Date.now(); // we use this for reconcilation with optimistic-updated data and real data when online again

      try {
        await createAllDeviceTaskRequest({ ...payload, tempTaskId: tempId }).unwrap();
      } catch (error) {
        toast(t('error.addTaskFailedNetwork'), { type: 'error' });
        rollbar.error('[AllDeviceTasks] HTTP Request failed. ', error as Error, payload, { userId });
        table.add({ type: 'create', payload: { ...payload, tempTaskId: tempId } });
      }
    },
    [createAllDeviceTaskRequest, t, rollbar, userId],
  );

  return {
    data: tasksData || [],
    isLoading,
    isFetching,
    createTask,
    status,
    allDevicePriorityOptions,
  };
};
