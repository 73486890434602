import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArrivedKojv,
  selectArrivedKojvConditions,
  selectIsKojvConditionsModalVisible,
  setIsKojvConditionsModalVisible,
} from '@/features/route';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import Button from '../../../../components/elements/Button';
import VerticalShadowScroll from '../../../../components/elements/VerticalShadowScroll';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../components/elements/Spinner';

const KojvConditionsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('kojvConditions');

  const isVisible = useSelector(selectIsKojvConditionsModalVisible);
  const kojv = useSelector(selectArrivedKojv);
  const detailedConditions = useSelector(selectArrivedKojvConditions);

  const conditions = detailedConditions
    ? Object.fromEntries(
        Object.entries(detailedConditions).map(([key, value]) => {
          if (typeof value === 'string') {
            return [key, value.replace(/\r\n/g, '<br />')];
          }
          if (value === null) {
            return [key, '---'];
          }
          return [key, value];
        }),
      )
    : {};

  const keysToDisplay = [
    'handTransportDistance',
    'externalWasteInfo',
    'containerOverfill',
    'openingTool',
    'freeReverseMeters',
    'insideTheGate',
    'containerLinerInstallation',
    'specialRequirements',
    'conditionsForAccess',
    'incompatibleWaste',
  ];

  return (
    <ModalWrapper isVisible={isVisible} title={t('title')} wide className="max-w-[80%] max-h-[80%] md:max-h-[80%]">
      <div className="text-gray-800 dark:text-gray-50 md:text-xl md:p-4 flex flex-col gap-4">
        {!detailedConditions ? (
          <Spinner size="md" />
        ) : (
          <VerticalShadowScroll className="kojv-conditions-modal__list">
            <div className="kojv-conditions-modal__list-item kojv-conditions-modal__title">
              <span>{t('priceList', { ns: 'common' })}</span>
              <span>{kojv?.name}</span>
            </div>
            <div className="kojv-conditions-modal__list-item">
              <span>{t('allowedTime')}</span>
              <span>
                {conditions?.allowedSlotsAt.slice(0, 5)}&nbsp;-&nbsp;{conditions?.allowedSlotsTo.slice(0, 5)}
              </span>
            </div>
            {keysToDisplay.map((key) => (
              <div key={key} className="kojv-conditions-modal__list-item">
                <span>{t(key)}</span>
                <span dangerouslySetInnerHTML={{ __html: conditions[key] }} />
              </div>
            ))}
          </VerticalShadowScroll>
        )}
        <div className="flex items-center h-full">
          <Button
            text={t('close', { ns: 'common' })}
            onClick={() => {
              dispatch(setIsKojvConditionsModalVisible(false));
            }}
            size="md"
            color="disabled"
            wide
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default KojvConditionsModal;
