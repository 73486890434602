import { UnloadPoint, WaypointUnload, WaypointUnloadCreateDto, WaypointUnloadUpdateDto } from '@ekt-group/general-purpose-api-interfaces';
import { UnloadRequest, UnloadRequestType, unloadsRequestsDb } from '@/database';
import {
  setCoordinatesToObject,
  useCreateWaypointUnloadMutation,
  useDeleteWaypointUnloadMutation,
  useUpdateWaypointUnloadMutation,
  useWaypointsRequest,
} from '@/features/route';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { useCallback, useState } from 'react';
import { formatToFixedCoordinates } from '@/utils/coordinates';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { socket } from '@/lib/socket';
import { useLazyGetOrsRouteQuery } from '../api/route-planner.api';
import { WaypointUnloadDeleteDto } from '@/services/discharge-waypoints.service';
import { selectCreatedWaypointResult, setCreatedWaypointResult } from '@/features/unloading';
import { getLocation } from '@/lib/geolocation';
import useGeolocation from 'react-hook-geolocation';

export interface SaveUnloadPayload {
  weight: number;
  grossWeight?: number;
  netWeight?: number;
  garbageId: number;
  onUnload?: () => void;
}

export interface UpdateUnloadPayload {
  weight: number;
  grossWeight?: number;
  netWeight?: number;
}

export function useUnloadFormRequests(unloadPoint?: UnloadPoint) {
  const [isPending, setIsPending] = useState(false);

  const dispatch = useDispatch();
  const createdWaypointResult = useSelector(selectCreatedWaypointResult);
  const isOffline = useOfflineStatus();

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const { data: waypoints } = useWaypointsRequest();
  const waypoint = waypoints?.[0];

  const [getOrsRoute] = useLazyGetOrsRouteQuery();

  const [createWaypointUnload] = useCreateWaypointUnloadMutation();
  const [updateWaypointUnload] = useUpdateWaypointUnloadMutation();
  const [deleteWaypointUnload] = useDeleteWaypointUnloadMutation();

  const { longitude, latitude } = useGeolocation();

  const saveUnload = async ({ weight, grossWeight, netWeight, garbageId, onUnload }: SaveUnloadPayload) => {
    if (!unloadPoint || !waypoint) {
      return;
    }

    const payload = {
      routeGroupItemId: currentRouteGroupItem.id,
      carId: currentRouteGroupItem.carId,
      value: weight,
      gross: grossWeight,
      net: netWeight,
      garbageId: garbageId,
      unloadPointId: unloadPoint.id,
      longitude,
      latitude,
      issued: new Date().toISOString(),
      waypointId: waypoint.id,
    };

    const queueRequest: UnloadRequest = {
      type: UnloadRequestType.Create,
      body: {
        ...payload,
      },
    };

    setIsPending(true);
    try {
      socket.volatile.emit('Unloaded', {
        type: 'Unloaded',
        payload: payload as unknown as WaypointUnload,
      });
      const returnPayload = await createWaypointUnload(payload)
        .unwrap()
        .catch((error) => {
          unloadsRequestsDb.requests.add(queueRequest);
        });
      dispatch(setCreatedWaypointResult(returnPayload));

      onUnload && onUnload();
    } finally {
      setIsPending(false);
    }
  };

  const updateUnload = useCallback(
    async ({ weight, grossWeight, netWeight }: UpdateUnloadPayload, unload: Partial<WaypointUnload>) => {
      if ('isPending' in unload) {
        const { id: pendingUnloadId, ...restBody } = unload;
        unloadsRequestsDb.requests.update(pendingUnloadId, { body: { ...restBody, value: weight, gross: grossWeight, net: netWeight } });
        return;
      }

      const payload: UnloadRequest = {
        type: UnloadRequestType.Update,
        body: {
          waypointId: waypoint?.id || 0,
          routeGroupItemId: currentRouteGroupItem?.id,
          id: unload.id,
          value: weight,
          gross: grossWeight,
          net: netWeight,
        },
      };

      await updateWaypointUnload(payload.body as WaypointUnloadUpdateDto & { id: number })
        .unwrap()
        .catch((error) => {
          unloadsRequestsDb.requests.add(payload);
        });
      createdWaypointResult && dispatch(setCreatedWaypointResult({ ...createdWaypointResult, ...payload.body }));
      socket.volatile.emit('Unloaded', {
        type: 'Unloaded',
        payload: payload.body as unknown as WaypointUnload,
      });
    },
    [currentRouteGroupItem?.id, updateWaypointUnload, waypoint, dispatch, createdWaypointResult],
  );

  const deleteUnload = useCallback(
    async (unload: Partial<WaypointUnload>) => {
      if (!waypoint) {
        return;
      }
      const payload: UnloadRequest = {
        type: UnloadRequestType.Delete,
        body: {
          routeGroupItemId: currentRouteGroupItem.id,
          id: unload.id,
        },
      };

      await deleteWaypointUnload(payload.body as WaypointUnloadDeleteDto)
        .unwrap()
        .catch((error) => {
          unloadsRequestsDb.requests.add(payload);
        });
    },
    [currentRouteGroupItem.id, deleteWaypointUnload, waypoint],
  );

  const createOrUpdateUnloadIndexDb = async (payload: SaveUnloadPayload) => {
    const unloadRequestsIndexedDb = await unloadsRequestsDb.requests.toArray();
    const editingUnloadRequest = unloadRequestsIndexedDb.find((request) => request.isEditing);

    const payloadBody: WaypointUnloadCreateDto = {
      routeGroupItemId: currentRouteGroupItem.id,
      carId: currentRouteGroupItem.carId,
      value: payload.weight,
      gross: payload.grossWeight,
      net: payload.netWeight,
      garbageId: payload.garbageId,
      unloadPointId: unloadPoint.id,
      longitude,
      latitude,
      issued: new Date().toISOString(),
      waypointId: waypoint?.id,
    };

    if (!editingUnloadRequest) {
      const queueRequest: UnloadRequest = {
        type: UnloadRequestType.Create,
        // isEditing: true,
        body: payloadBody,
        timestamp: new Date().getTime(),
      };
      await unloadsRequestsDb.requests.add(queueRequest);
      return;
    }
    await unloadsRequestsDb.requests.update(editingUnloadRequest.id, {
      ...editingUnloadRequest,
      body: payloadBody,
      timestamp: new Date().getTime(),
    });
  };

  const setNavigationToUnloadPoint = async () => {
    const {
      coords: { latitude, longitude },
    } = await getLocation();

    if (!latitude || !longitude || !unloadPoint) {
      return;
    }

    const currentPosition = [longitude, latitude];
    const unloadPointPosition = formatToFixedCoordinates([unloadPoint.longitude, unloadPoint.latitude]);

    const { data, isError } = await getOrsRoute({
      start: currentPosition,
      end: unloadPointPosition,
    });

    if (!data || isError) {
      return;
    }

    dispatch(setCoordinatesToObject(data.features[0].geometry.coordinates));
  };

  return {
    saveUnload,
    setNavigationToUnloadPoint,
    isPending,
    createOrUpdateUnloadIndexDb,
    updateUnload,
    deleteUnload,
    createdWaypointResult,
  };
}
