import { AllDeviceTask } from '@ekt-group/bi-services-api-interfaces';
import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { cn } from '@/utils/cn';
import { AlldeviceTaskPriority } from '@ekt-group/general-purpose-api-interfaces/src/types/alldevice.enum';
import Chip from '@/components/elements/Chip';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '@/hooks/useBreakpoint';

// second type is referring to the type of the value, which can be string or number (in case of priority column)
export const CheckUpCarProblemCell = ({ row, getValue, column }: CellContext<AllDeviceTask, string | number>) => {
  const priority = row.getValue<number>('priority');
  let cellElement: JSX.Element = null;
  const { t } = useTranslation('carCheckUpModal');
  const { isBelowMd } = useBreakpoint('md');
  // only available for priority column
  const allAvailablePriorityOptions = column.columnDef.meta?.priorityOptions || [];

  let specificClassName = '';

  const renderNormalCell = (header: string, value: string) => {
    if (isBelowMd) {
      return (
        <>
          {isBelowMd && <span className="font-bold whitespace-nowrap">{header}:&nbsp;</span>}
          <span className="">{value}</span>
        </>
      );
    }

    return <>{value}</>;
  };

  const renderStatusCell = (header: string, type: 'complete' | 'pending') => {
    let chipComponent = null;
    if (type === 'complete') {
      chipComponent = (
        <Chip variant="success" size="sm" className="w-[6rem]">
          {t('table.taskStatusComplete')}
        </Chip>
      );
    }

    if (type === 'pending') {
      chipComponent = (
        <Chip variant="warning" size="sm" className="w-[6rem]">
          {t('table.taskStatusPending')}
        </Chip>
      );
    }

    return (
      <>
        {isBelowMd && <span className="font-bold">{header}: </span>}
        {chipComponent}
      </>
    );
  };

  const renderPriorityCell = (header: string, value: string, type: AlldeviceTaskPriority) => {
    let chipComponent = null;
    if (type === AlldeviceTaskPriority.Low) {
      chipComponent = (
        <Chip variant="primary" size="sm" className="w-[10rem]">
          {value}
        </Chip>
      );
    }

    if (type === AlldeviceTaskPriority.Normal) {
      chipComponent = (
        <Chip variant="danger" size="sm" className="w-[10rem]">
          {value}
        </Chip>
      );
    }

    if (type === AlldeviceTaskPriority.High) {
      chipComponent = (
        <Chip variant="warning" size="sm" className="w-[10rem]">
          {value}
        </Chip>
      );
    }

    return (
      <>
        {isBelowMd && <span className="font-bold">{header}:</span>}
        {chipComponent}
      </>
    );
  };

  switch (column.id) {
    case 'serviceDate':
      // transform date from yyyy-mm-dd to dd.mm.yy
      const date = getValue() as string;
      const dateArray = date.split('-');
      const formattedDate = `${dateArray[2]}.${dateArray[1]}.${dateArray[0].slice(2)}`;

      cellElement = renderNormalCell(t('table.column.serviceDate'), formattedDate);
      specificClassName = 'justify-between md:justify-center text-right md:text-center font-bold px-4';
      break;
    case 'serviceName':
      cellElement = renderNormalCell(t('table.column.serviceName'), getValue() as string);
      specificClassName = 'justify-between md:justify-start px-4 text-right md:text-left md:px-0';
      break;
    case 'serviceDescription':
      cellElement = renderNormalCell(t('table.column.serviceDescription'), getValue() as string);
      specificClassName = 'justify-between md:justify-start px-4 text-right md:text-left md:px-0';
      break;
    case 'taskStatus':
      const originalText = getValue() as string;
      const status = originalText === 'complete' ? 'complete' : 'pending';
      cellElement = renderStatusCell(t('table.column.taskStatus'), status);
      specificClassName = 'justify-between md:justify-center md:text-center px-4 md:px-0 ';
      break;
    case 'priority':
      const priorityAsText = allAvailablePriorityOptions?.find((option) => option.key === (getValue() as number))?.value || '';
      cellElement = renderPriorityCell(t('table.column.priority'), priorityAsText, priority);
      specificClassName = 'justify-between md:justify-center md:text-center px-4 md:px-0 ';
      break;
  }

  return <div className={cn('h-full w-full flex items-start md:items-center py-1 px-2', specificClassName)}>{cellElement}</div>;
};
