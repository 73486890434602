import React from 'react';
import PhoneCallIcon from '@/components/icons/PhoneCallIcon';
import { ObjectGate } from '../types/gates';
import { Gate } from '@ekt-group/general-purpose-api-interfaces';
import { useSelector } from 'react-redux';
import { useGatePhoneCall } from '../hooks/useGatePhoneCall';
import Spinner from '@/components/elements/Spinner';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { conditionalClassNames } from '@/helpers/dom';

import { selectIsCurrentRouteGroupItemOpenedMode } from '@/features/home';
import { useWaypointsRequest } from '../hooks';

interface OpenGatesActionProps {
  gate: Gate | ObjectGate;
  isSecondNumber?: boolean;
}

const OpenGatesAction = ({ gate, isSecondNumber = false }: OpenGatesActionProps) => {
  const isOffline = useOfflineStatus();
  const isRouteGroupItemInOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const { data: waypoints } = useWaypointsRequest();

  const { handleOpenObjectGate, handleOpenGate, handleCloseObjectGate, isPending } = useGatePhoneCall();

  const handleOpenAction = async () => {
    if ('objectId' in gate) {
      await handleOpenObjectGate(gate.objectId, gate.routeGroupItemWaypointId, isSecondNumber);
    } else {
      await handleOpenGate(gate.id);
    }
  };

  const shouldDisplayClosePhone = 'closePhone' in gate && gate.closePhone && waypoints.some(({ object }) => object.id === gate.objectId);

  const componentWidth = shouldDisplayClosePhone ? 'w-[calc(100vw-1rem)]' : 'w-[calc(50vw-1rem)]';

  return (
    <div className={`flex min-h-[4rem] gap-2 ${componentWidth} md:max-w-[calc(100vw-300px-1rem)] md:w-fit justify-between md:gap-16`}>
      <div
        className={conditionalClassNames({
          'open-gates-actions__left': true,
          'keys-modal__list-gate-item': true,
          'keys-modal__list-gate-item--disabled': isOffline || isRouteGroupItemInOpenedMode,
          'keys-modal__list-gate-item--pending': isPending,
        })}
        onClick={handleOpenAction}
      >
        {isPending ? <Spinner size={'md'} color={'danger'} /> : <PhoneCallIcon size={'md'} />}
        <span>
          {gate.description}
          {isSecondNumber ? ' - 2' : ''}
        </span>
      </div>
      {shouldDisplayClosePhone && (
        <div
          className={conditionalClassNames({
            'open-gates-actions__right': true,
            'keys-modal__list-gate-item': true,
            'keys-modal__list-gate-item--disabled': isOffline || isRouteGroupItemInOpenedMode,
          })}
          onClick={() => handleCloseObjectGate(gate.objectId, gate.routeGroupItemWaypointId)}
        >
          {isPending ? <Spinner /> : <PhoneCallIcon size={'md'} />}
          <span>{gate.description}</span>
        </div>
      )}
    </div>
  );
};

export default OpenGatesAction;
