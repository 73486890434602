import React, { useEffect, useRef } from 'react';
import { getReadableDateTime } from '@/helpers/dateHelper';
import DoubleTickIcon from '@/components/icons/DoubleTickIcon';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import VerticalShadowScroll from '@/components/elements/VerticalShadowScroll';

interface AccidentMessageListProps {
  messages: any[];
}

const domParser = new DOMParser();

const AccidentMessageList = ({ messages }: AccidentMessageListProps) => {
  const { isMobileScreen } = useWindowDimensions();
  const lastMessageRef = useRef<HTMLDivElement>(null);

  const updatedMessages = messages.map((message) => {
    const document = domParser.parseFromString(message.content, 'text/html');
    const messageContent = document.body;
    const messageAuthor = document.getElementById('accident-chat-message-author');

    if (messageAuthor) {
      messageContent.removeChild(messageAuthor);
    }

    return { ...message, content: messageContent.outerHTML, author: messageAuthor?.innerText };
  });

  const scrollToLastMessage = () => {
    if (!lastMessageRef.current) {
      return;
    }

    lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [messages]);

  return (
    <div className={'flex flex-grow flex-col overflow-y-auto w-full'}>
      <VerticalShadowScroll className={'flex flex-col overflow-y-auto w-full h-full gap-2 text-gray-900 dark:text-gray-100'}>
        {updatedMessages.length > 0 &&
          updatedMessages.map((message, index) => (
            <div
              ref={index === updatedMessages.length - 1 ? lastMessageRef : null}
              key={message.timestamp}
              className={
                'flex flex-col w-fit max-w-[calc(100vw-2rem)] md:max-w-[calc(100vw-4rem)] gap-0.5 border bg-gray-200 border-gray-300 dark:border-gray-500 dark:bg-gray-700 rounded p-0.5 px-1 md:p-2'
              }
            >
              <div className={'flex gap-1 md:gap-2 md:text-xl font-semibold'}>
                {message.author && (
                  <div>
                    {message.author}
                    {message.header && ':'}
                  </div>
                )}
                {message.header && <div dangerouslySetInnerHTML={{ __html: message.header }}></div>}
              </div>
              <div
                className={'[&_img]:w-[38rem] [&_figcaption]:text-sm md:text-xl'}
                dangerouslySetInnerHTML={{ __html: message.content }}
              ></div>
              <div className={'justify-end flex gap-1 items-center'}>
                <span className={'text-xs md:text-base opacity-50'}>{getReadableDateTime(message.timestamp, 'datetime')}</span>
                <span className={message.isRead ? 'text-green-500' : 'text-gray-400'}>
                  <DoubleTickIcon size={isMobileScreen ? 'sm' : 'md'} />
                </span>
              </div>
            </div>
          ))}
        {messages.length === 0 && <div className={'text-gray-600 dark:text-gray-100'}>No messages</div>}
      </VerticalShadowScroll>
    </div>
  );
};

export default AccidentMessageList;
