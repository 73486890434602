import { WaypointNotesEntity, WaypointsNotesRequest, waypointsNotesRequestsDb } from '../database';
import { useLiveQuery } from 'dexie-react-hooks';
import { useOfflineStatus } from '../contexts/offlineStatusContext';
import { useProcessor } from './useProcessor';
import { useSaveDischargeWaypointNotesMutation, useSaveServiceWaypointNotesMutation } from '@/features/route';

export function useWaypointsNotesRequestsProcessor() {
  const requests = useLiveQuery(() => waypointsNotesRequestsDb.requests.toArray());
  const isOffline = useOfflineStatus();
  const [saveDischargeWaypointNotes] = useSaveDischargeWaypointNotesMutation();
  const [saveServiceWaypointNotes] = useSaveServiceWaypointNotesMutation();

  const processRequest = async (request: WaypointsNotesRequest) => {
    if (isOffline) {
      return;
    }

    const { waypointIds, dischargeNoteIds, additionalNotes, entity, routeGroupItemId } = request;

    switch (entity) {
      case WaypointNotesEntity.DischargeWaypoint: {
        await saveDischargeWaypointNotes({ waypointIds, reasonIds: dischargeNoteIds, additionalNotes }).unwrap();
        break;
      }
      case WaypointNotesEntity.ServiceWaypoint: {
        await saveServiceWaypointNotes({ waypointIds, routeGroupItemId, additionalNotes }).unwrap();
        break;
      }
      default: {
        throw new Error(`Unknown entity: ${entity}`);
      }
    }
  };

  const deleteRequest = (requestId: number) => waypointsNotesRequestsDb.requests.delete(requestId);

  useProcessor<WaypointsNotesRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing waypoint note request failed',
  });
}
