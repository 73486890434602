import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const ListIcon = ({ size }: IconSizeProps) => (
  <svg
    fill="currentColor"
    className={iconStyles[iconSizeClasses[size]]}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="800px"
    height="800px"
    viewBox="0 0 95.494 95.494"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <circle cx="10.906" cy="17.294" r="10.906" />
        <circle cx="10.906" cy="47.294" r="10.906" />
        <circle cx="10.906" cy="78.2" r="10.906" />
        <g>
          <path
            d="M34.258,24.43h59.236c0.529,0,1.039-0.211,1.414-0.586s0.586-0.884,0.586-1.414l-0.002-10.271c0-1.104-0.896-2-2-2
				H34.258c-1.104,0-2,0.896-2,2V22.43C32.258,23.534,33.153,24.43,34.258,24.43z"
          />
          <path
            d="M93.492,40.158H34.258c-1.104,0-2,0.896-2,2v10.271c0,1.104,0.896,2,2,2h59.236c0.529,0,1.039-0.211,1.414-0.586
				s0.586-0.885,0.586-1.414l-0.002-10.271C95.492,41.054,94.598,40.158,93.492,40.158z"
          />
          <path
            d="M93.492,71.064H34.258c-1.104,0-2,0.896-2,2v10.271c0,1.104,0.896,2,2,2h59.236c0.529,0,1.039-0.211,1.414-0.586
				s0.586-0.885,0.586-1.414l-0.002-10.271C95.492,71.96,94.598,71.064,93.492,71.064z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ListIcon;
