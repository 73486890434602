import Button from '@/components/elements/Button';
import ModalWrapper from '@/components/modals/ModalWrapper';
import { unloadsRequestsDb } from '@/database';
import { selectCreatedWaypointResult } from '@/features/unloading';
import { WaypointUnload } from '@ekt-group/general-purpose-api-interfaces';
import { useLiveQuery } from 'dexie-react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface UnloadFormConfirmCloseModalProps {
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const UnloadFormConfirmCloseModal = ({ isVisible, onConfirm, onCancel }: UnloadFormConfirmCloseModalProps) => {
  const { t } = useTranslation();
  const createdWaypointResult = useSelector(selectCreatedWaypointResult);
  const editingUnloadRequestBody =
    useLiveQuery(() => unloadsRequestsDb.requests.toArray())?.find((request) => request.isEditing)?.body || null;

  const displayInfo: Partial<WaypointUnload> = createdWaypointResult || editingUnloadRequestBody;

  return (
    <ModalWrapper isVisible={isVisible} className="h-fit md:w-2/5 w-2/3 p-5">
      <div className="gap-y-5 flex flex-col">
        <div className="text-2xl font-extrabold">
          {t('entryDeleteLabel', {
            ns: 'unloadingPage',
          })}
          :
        </div>
        <div className="text-xl">
          <div>
            <span className="font-extrabold"></span>
            {t('grossWeight', {
              ns: 'unloadingPage',
            })}
            : {displayInfo?.gross}
          </div>
          <div>
            <span className="font-extrabold"></span>
            {t('netWeight', {
              ns: 'unloadingPage',
            })}
            : {displayInfo?.net}
          </div>
          <div>
            <span className="font-extrabold"></span>
            {t('weight', {
              ns: 'unloadingPage',
            })}
            : {displayInfo?.value}
          </div>
        </div>
        <div className="md:flex-row flex flex-col items-center justify-center w-full gap-5">
          <Button
            className="flex-1 mr-2"
            size="lg"
            onClick={onCancel}
            text={t('cancel', {
              ns: 'common',
            })}
            color="disabled"
          />
          <Button
            className="flex-1 mr-2"
            size="lg"
            onClick={onConfirm}
            text={t('confirm', {
              ns: 'common',
            })}
            color="primary"
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
