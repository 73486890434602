import httpService from '@/lib/http.service';
import { ConfirmedMessageResponse, Message } from '@/features/messages';
import { getDaysAgoDate, getTodayDate } from '@/helpers/dateHelper';

const MESSAGES_HISTORY_DAYS = 30;

export interface MarkMessageAsReadDTO {
  messageId: number;
  timestamp?: string;
}

export default {
  async getMessages(driverId: number) {
    const endDate = getTodayDate();
    const startDate = getDaysAgoDate(MESSAGES_HISTORY_DAYS);

    return httpService.get<Message[]>('/messages/history', {
      params: { driverId, from: startDate, to: endDate },
    });
  },
  async getNewMessages(driverId: number) {
    return httpService.get<Message[]>('/messages/unread', { params: { driverId }, withCredentials: true });
  },
  async markMessageAsRead({ messageId, timestamp }: MarkMessageAsReadDTO) {
    return httpService.post<ConfirmedMessageResponse>('/messages/confirm', null, {
      params: { id: messageId, timestamp },
    });
  },
};
