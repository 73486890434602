import React, { useMemo, useRef, useState } from 'react';
import {
  WaypointListSortingOption,
  setCarLogCoordinates,
  setHistoricalSortingRouteGroupItemId,
  setPlanningObject,
  setSelectedPlanningItem,
  setSelectedWaypointListSortingOption,
} from '@/features/route';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import SortingIcon from '@/components/icons/SortingIcon';
import SortingModal from '../sortingModal/SortingModal';
import { CompletedRouteOrder, selectCurrentRouteGroupItem } from '@/features/home';
import { useHistorical, usePlanning } from '../../../hooks/sortingModal';
import { RouteGroupItemPlanning, RouteGroupItemPlanningService } from '@ekt-group/general-purpose-api-interfaces';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { Feature } from '@/types/features';
import { PlanningRoutes } from '@ekt-group/map-component';
import Button from '@/components/elements/Button';
import { cn } from '@/utils/cn';

interface WaypointListSortingMenuProps {
  onSortingButtonClicked: () => void;
  isSortingMenuVisible: boolean;
  sortingButtonRef: React.RefObject<HTMLButtonElement>;
  sortingMenuRef: React.RefObject<HTMLDivElement>;
  closeMenu: () => void;
}

const WaypointListSortingMenu = ({
  sortingMenuRef,
  sortingButtonRef,
  isSortingMenuVisible,
  onSortingButtonClicked,
  closeMenu,
}: WaypointListSortingMenuProps) => {
  const HISTORICAL_AND_OPTIMIZED_ORDER = `${WaypointListSortingOption.HistoricalOrder}/${WaypointListSortingOption.Optimized}`;

  const dispatch = useDispatch();

  const [currentSortingOption, setCurrentSortingOption] = useState<string>(() => {
    const savedSortingOption = localStorage.getItem('sortingOption');
    return savedSortingOption || WaypointListSortingOption.Closest;
  });
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  const [selectedRouteGroupItemId, setSelectedRouteGroupItemId] = useState<number>();
  const [isPlanningSelected, setIsPlanningSelected] = useState<boolean>(false);

  const [isSortingModalDisplayed, setIsSortingModalDisplayed] = useState(false);

  const { t } = useTranslation('tabletPage');
  const { isFeatureEnabled } = useFeatureFlags();

  const {
    getHistoricalRoutesMatch,
    historicalMatchedItems,
    isLoadingHistoricalMatch,
    isFetchingHistoricalMatch,
    isFetchingCompletedOrder,
    getCompletedOrder,
    completedOrder,
  } = useHistorical();
  const { getPlanningItems, allPlanningItems, optimizedItem, isLoadingPlanning, isFetchingPlanning } = usePlanning();

  const handleSortingOptionClick = async (option: string | WaypointListSortingOption) => {
    if (option === HISTORICAL_AND_OPTIMIZED_ORDER) {
      setIsSortingModalDisplayed(true);
      setCurrentSortingOption(HISTORICAL_AND_OPTIMIZED_ORDER);
      const [historicalItems, planningItems] = await Promise.all([
        getHistoricalRoutesMatch(currentRouteGroupItem?.id).unwrap(),
        isFeatureEnabled(Feature.OptimizedSorting) && getPlanningItems(currentRouteGroupItem?.id).unwrap(),
      ]);

      if (planningItems) {
        handlePlanningItemSelect(optimizedItem);
      } else {
        handleMatchedItemSelect(historicalItems[0].routeGroupItemId);
      }

      return;
    }

    dispatch(setPlanningObject(null));
    dispatch(setCarLogCoordinates(null));
    setCurrentSortingOption(option);
    dispatch(setSelectedWaypointListSortingOption(option as WaypointListSortingOption));
    closeMenu();
  };

  const handleSortingModalClose = () => {
    setIsSortingModalDisplayed(false);
    closeMenu();
  };

  const handleSortingModalContinueUseClick = (planningObject: PlanningRoutes, completedOrder: CompletedRouteOrder) => {
    let sortingOption = null;

    if (selectedRouteGroupItemId) {
      sortingOption = WaypointListSortingOption.HistoricalOrder;
      localStorage.setItem('historicalSortingRouteGroupItemId', selectedRouteGroupItemId?.toString() || '');
      dispatch(setHistoricalSortingRouteGroupItemId(selectedRouteGroupItemId));
      dispatch(setPlanningObject(null));

      // this is to draw in the main map
      dispatch(setCarLogCoordinates(completedOrder?.routeCoordinates || []));
    }

    if (isPlanningSelected) {
      sortingOption = WaypointListSortingOption.Optimized;
      localStorage.setItem('selectedPlanningItem', JSON.stringify(optimizedItem));

      dispatch(setCarLogCoordinates(null));

      // this is to use in the sorting at useSortedWaypointGroup.ts
      dispatch(setSelectedPlanningItem(optimizedItem));
      // this is to draw in the main map
      dispatch(setPlanningObject(planningObject));
    }

    dispatch(setSelectedWaypointListSortingOption(sortingOption));
    setCurrentSortingOption(`${WaypointListSortingOption.HistoricalOrder}/${WaypointListSortingOption.Optimized}`);
    setIsSortingModalDisplayed(false);
    closeMenu();
  };

  const handleMatchedItemSelect = async (routeGroupItemId: number) => {
    setSelectedRouteGroupItemId(routeGroupItemId);
    setIsPlanningSelected(false);
    await getCompletedOrder(routeGroupItemId);
  };

  const handlePlanningItemSelect = (optimizedItem: RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>) => {
    setSelectedRouteGroupItemId(null);
    setIsPlanningSelected(optimizedItem !== null);
    dispatch(setHistoricalSortingRouteGroupItemId(null));
  };

  const shouldNotProvideDataToMap = isFetchingCompletedOrder || isLoadingHistoricalMatch || !completedOrder;
  const menuItems = useMemo(
    () => [
      {
        key: WaypointListSortingOption.Alphabetical,
        label: t(`sorting.${WaypointListSortingOption.Alphabetical}`),
        enabled: true,
      },
      {
        key: WaypointListSortingOption.Closest,
        label: t(`sorting.${WaypointListSortingOption.Closest}`),
        enabled: true,
      },
      {
        key: HISTORICAL_AND_OPTIMIZED_ORDER,
        label: `${t(`sorting.${WaypointListSortingOption.HistoricalOrder}`)}/${t(`sorting.${WaypointListSortingOption.Optimized}`)}`,
        enabled: isFeatureEnabled(Feature.OptimizedSorting),
      },
    ],
    [t, HISTORICAL_AND_OPTIMIZED_ORDER, isFeatureEnabled],
  );

  return (
    <>
      <button ref={sortingButtonRef} className={'hamburger-button'} onClick={onSortingButtonClicked} role="sorting-button">
        <SortingIcon size={'md'} />
      </button>

      {isSortingMenuVisible &&
        createPortal(
          <div ref={sortingMenuRef} className={'sidebar shadow-lg flex-col pt-3 md:pt-0  md:w-[300px]'}>
            <div className="sm:flex-row sm:justify-around flex flex-col gap-4">
              <div className="flex flex-col w-full h-full gap-3">
                {menuItems.map((option) => {
                  if (!option.enabled) {
                    return null;
                  }

                  return (
                    <Button
                      key={option.key}
                      color="primary"
                      className={cn({
                        'cursor-pointer px-2 py-4 rounded text-xl w-full text-left': true,
                        'bg-blue-500 dark:bg-gray-600': option.key !== currentSortingOption,
                        'bg-blue-900 dark:bg-blue-500 text-gray-50': option.key === currentSortingOption,
                      })}
                      innerClassName="justify-start"
                      onClick={() => handleSortingOptionClick(option.key)}
                      text={option.label}
                    />
                  );
                })}
              </div>
            </div>
          </div>,
          document.querySelector('.waypoints-list-bar'),
        )}

      <SortingModal
        isVisible={isSortingModalDisplayed}
        optimizedItem={optimizedItem}
        isPlanningSelected={isPlanningSelected}
        completedOrder={completedOrder}
        historicalMatchedItems={historicalMatchedItems}
        onMatchedItemChange={handleMatchedItemSelect}
        onPlanningItemClick={handlePlanningItemSelect}
        onContinueUseClick={handleSortingModalContinueUseClick}
        onCloseBackClick={handleSortingModalClose}
        isLoadingHistoricalMatch={isFetchingHistoricalMatch}
        isLoadingPlanning={isFetchingPlanning}
        isFetchingCompletedOrder={isFetchingCompletedOrder}
        selectedRouteGroupItemId={selectedRouteGroupItemId}
        shouldNotProvideDataToMap={shouldNotProvideDataToMap}
      />
    </>
  );
};

export default WaypointListSortingMenu;
