import {
  Contract,
  Kojv,
  KojvService,
  ObjectEntity,
  ObjectEntityItem,
  ObjectItemDischarge,
  ObjectService,
  Owner,
  WaypointLogDetails,
} from '@ekt-group/general-purpose-api-interfaces';
import { Coordinates } from '@/types/common';

export enum CurrentRouteGroupItemMode {
  Open = 'open',
  Process = 'process',
}

export interface RouteGroupItemSummary {
  itemTypes: Record<string, { value: number; name: string }>;
  services: Record<string, { value: number; name: string }>;
}

export enum RouteGroupItemType {
  Discharge = 0,
  DischargeHour = 1,
  Service = 2,
  Garage = 3,
  Waiting = 4,
  Special = 5,
}

export enum WaypointType {
  Discharge = 'discharge',
  Service = 'service',
}

export interface IWaypointBase {
  id: number;
  object: ObjectEntity;
  objectItem: ObjectEntityItem;
  owner?: Owner;
  contract?: Contract;
  routeGroupItemId?: number;
  isFiltered?: boolean;
}

export interface IWaypointDischarge extends IWaypointBase {
  type: WaypointType.Discharge;
  objectItemDischarge: ObjectItemDischarge;
  kojv: Kojv;
  services: KojvService[];
  waypointLogDetails: WaypointLogDetails[];
  time: string;
  timestamp: string;
  hasExtraM3Ordered?: boolean;
  done: boolean;
  fail: boolean;
  dynamicGarbageM3?: number;
  weightKg?: number;
  childrenCount?: number;
  bagsCount?: number;
}

export interface IWaypointService extends IWaypointBase {
  type: WaypointType.Service;
  activityType?: number;
  doneAt?: string;
  isFailed: boolean;
  notes?: string;
  isUsed: boolean;
  relatedServices?: ObjectService[];
}

export type Waypoint<Type extends WaypointType = any> = Type extends WaypointType.Service
  ? IWaypointService
  : Type extends WaypointType.Discharge
  ? IWaypointDischarge
  : never;

export type UnloadingBaseWaypoint = {
  id?: number;
  type: 'unloading' | 'base';
  coordinates?: Coordinates;
  isFiltered?: boolean;
};

export interface CompletedRouteOrderItem {
  type: 'unload' | 'object';
  id: number;
  longitude: number;
  latitude: number;
}

export interface CompletedRouteOrder {
  routeGroupItemId: number;
  routeCoordinates?: Coordinates[];
  order: CompletedRouteOrderItem[];
}

export interface HistoricalRoutesMatchItem {
  routeGroupItemId: number;
  date: string;
  unloadCount: number;
  distanceKm: number;
  objectsMatch: number;
}
