import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../../store';
import { Gate, ObjectKey } from '@ekt-group/general-purpose-api-interfaces';

export interface GatesState {
  items: Gate[];
  keysAndRemotes: ObjectKey[];
}

const initialState: GatesState = {
  items: [],
  keysAndRemotes: [],
};

export const keysAndGates = createSlice({
  name: 'keysRemotesAndGates',
  initialState,
  reducers: {
    setGates: (state, action) => {
      state.items = action.payload;
    },
    setKeysAndRemotes: (state, action) => {
      state.keysAndRemotes = action.payload;
    },
    resetKeysAndGatesState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setGates, setKeysAndRemotes, resetKeysAndGatesState } = keysAndGates.actions;

export const selectGates = (state: AppState) => state.keysRemotesAndGates.items;
export const selectKeysAndRemotes = (state: AppState) => state.keysRemotesAndGates.keysAndRemotes;

export default keysAndGates.reducer;
