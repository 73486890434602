import React, { forwardRef } from 'react';
import {
  AllDeviceRequest,
  CarsAcceptanceRequest,
  PhotoDb,
  RouteStartFinishRequest,
  UnloadRequest,
  WaypointsNotesRequest,
  WaypointsStatusRequest,
  WaypointsTanksRequest,
} from '../../database';
import ModalWrapper from '../modals/ModalWrapper';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';
import { conditionalClassNames } from '../../helpers/dom';
import RouteIcon from '../icons/RouteIcon';
import TruckUnloadingIcon from '../icons/TruckUnloadingIcon';
import ArrivedIcon from '../icons/ArrivedIcon';
import ListIcon from '../icons/ListIcon';
import FuelIcon from '../icons/FuelIcon';
import ImageGalleryIcon from '../icons/ImageGalleryIcon';
import { CarCrash } from '../icons/CheckUp/CarCrash';
import PencilIcon from '../icons/PencilIcon';

interface OfflineSummaryProps {
  isSummaryVisible: boolean;
  onClose?: () => void;
  routeStartFinishRequests: RouteStartFinishRequest[];
  unloads: UnloadRequest[];
  discharges: WaypointsStatusRequest<any>[];
  dischargeNotes: WaypointsNotesRequest[];
  tanks: WaypointsTanksRequest[];
  acceptances: CarsAcceptanceRequest[];
  tasks: AllDeviceRequest[];
  photos: PhotoDb[];
}

const OfflineSummary = forwardRef<HTMLDivElement, OfflineSummaryProps>(
  (
    { isSummaryVisible, onClose, photos, routeStartFinishRequests, dischargeNotes, discharges, unloads, tanks, acceptances, tasks },
    ref,
  ) => {
    const { t } = useTranslation('offline');

    const items = [
      {
        title: t('summary.routeStartFinish'),
        count: routeStartFinishRequests?.length,
        icon: <RouteIcon size={'sm'} />,
      },
      {
        title: t('summary.unloads'),
        count: unloads?.length,
        icon: <TruckUnloadingIcon size={'sm'} />,
      },
      {
        title: t('summary.discharges'),
        count: discharges?.length,
        icon: <ArrivedIcon size={'sm'} />,
      },
      {
        title: t('summary.dischargeNotes'),
        count: dischargeNotes?.length,
        icon: <ListIcon size={'sm'} />,
      },
      {
        title: t('summary.tanks'),
        count: tanks?.length,
        icon: <FuelIcon size={'sm'} />,
      },
      {
        title: t('summary.photos'),
        count: photos?.length,
        icon: <ImageGalleryIcon size={'sm'} />,
      },
      {
        title: t('summary.tasks'),
        count: tasks?.length,
        icon: <PencilIcon size={'sm'} />,
      },
      {
        title: t('summary.acceptances'),
        count: acceptances?.length,
        icon: <CarCrash className="w-5 h-5" />,
      },
    ];

    return (
      <ModalWrapper ref={ref} isVisible={isSummaryVisible} title={t('summary.title')}>
        <div className="offline-summary">
          <div className="offline-summary__list">
            {items.map((item, index) => (
              <div
                key={index}
                className={conditionalClassNames({
                  'offline-summary__list-item': true,
                  'offline-summary__list-item--active': item.count > 0,
                })}
              >
                <div className="offline-summary__list-item-icon">{item.icon}</div>
                <span className="offline-summary__list-item-title">{item.title}</span>
                <span className="offline-summary__list-item-count">{item.count}</span>
              </div>
            ))}
            <div className="offline-summary__footer">
              <Button text={t('close', { ns: 'common' })} onClick={onClose} size={'md'} color={'disabled'} wide />
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  },
);

OfflineSummary.displayName = 'OfflineSummary';

export default OfflineSummary;
