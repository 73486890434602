import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

interface UseUnloadWeightsForm {
  initialWeight: number | null;
  initialGrossWeight: number | null;
  initialNetWeight: number | null;
}

export interface WeightForm {
  weight: number;
  grossWeight: number;
  netWeight: number;
}

export function useUnloadWeightsForm(
  { initialWeight, initialGrossWeight, initialNetWeight }: UseUnloadWeightsForm,
  isAllowGrossNetInput: boolean,
) {
  const { watch, setValue, register } = useForm<WeightForm>({
    values: {
      weight: initialWeight || 0,
      grossWeight: initialGrossWeight || 0,
      netWeight: initialNetWeight || 0,
    },
  });

  const netWeight = watch('netWeight', 0);
  const grossWeight = watch('grossWeight');
  const weight = watch('weight');

  const isInvalidWeight = useCallback(
    (weight: number, grossWeight: number, netWeight: number) => {
      if (!isAllowGrossNetInput) {
        return weight < 0;
      }
      return netWeight > grossWeight || netWeight < 0 || grossWeight < 0;
    },
    [isAllowGrossNetInput],
  );

  useEffect(() => {
    if (isAllowGrossNetInput) {
      setValue('weight', grossWeight - netWeight);
    }
  }, [netWeight, grossWeight, isAllowGrossNetInput, setValue]);

  return {
    isInvalidWeight,
    register,
    setValue,
    watch,
    netWeight,
    grossWeight,
    weight,
  };
}
