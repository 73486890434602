import React, { useRef, useState } from 'react';
import { useAlcoholCheck } from '@/features/auth';
import VineBottleIcon from '../icons/VineBottleIcon';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Button from './Button';
import Popup from './Popup';
import RefreshIcon from '../icons/RefreshIcon';
import StartIcon from '../icons/StartIcon';
import { useTranslation } from 'react-i18next';
import { useRouteSpecificProps } from '@/hooks/useRouteSpecificProps';
import { cn } from '@/utils/cn';

interface AlcoholCheckIndicatorProps {
  alcoholIconRef: React.RefObject<HTMLSpanElement>;
  isPopupVisible: boolean;
  onPopupIconClick: () => void;
}

const AlcoholCheckIndicator = ({ alcoholIconRef, isPopupVisible, onPopupIconClick }: AlcoholCheckIndicatorProps) => {
  const { t } = useTranslation();

  const { isAlcoholCheckLoading, isAlcoholCheckPassed, isAlcoholMeasurementStartLoading, checkAlcoholPass, startAlcoholMeasurement } =
    useAlcoholCheck();
  const { isMobileScreen } = useWindowDimensions();

  const { handle } = useRouteSpecificProps();

  const getPopupPosition = () => {
    if (handle?.isPageWithId) {
      return 'bottom-right';
    }
    if (isMobileScreen) {
      return 'bottom-left';
    }

    return 'left';
  };

  return (
    <span
      ref={alcoholIconRef}
      onClick={onPopupIconClick}
      className={cn('relative cursor-pointer z-[31]', {
        'text-green-500': isAlcoholCheckPassed,
        'text-red-500': !isAlcoholCheckPassed,
      })}
    >
      <VineBottleIcon size={isMobileScreen ? 'sm' : 'md'} />

      {isPopupVisible && (
        <Popup position={getPopupPosition()}>
          <div className={'flex flex-col gap-2 w-52'}>
            <Button
              size={'md'}
              text={t('startMeasurement', { ns: 'alcometer' })}
              disabled={isAlcoholCheckLoading}
              pending={isAlcoholMeasurementStartLoading}
              color={'success'}
              onClick={startAlcoholMeasurement}
              icon={<StartIcon size={'md'} />}
              wide
            />
            <Button
              size={'md'}
              text={t('refresh')}
              onClick={checkAlcoholPass}
              disabled={isAlcoholMeasurementStartLoading}
              pending={isAlcoholCheckLoading}
              icon={<RefreshIcon size={'sm'} />}
              wide
            />
          </div>
        </Popup>
      )}
    </span>
  );
};

export default AlcoholCheckIndicator;
