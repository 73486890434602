import React, { useEffect, useState } from 'react';
import Logo from '../../../assets/images/logo.png';
import Button from '../../../components/elements/Button';
import authService from '../api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserState, setUserState } from '../stores/slice';
import { useNavigate } from 'react-router-dom';
import QrReader from '../../../components/elements/QrReader';
import QrScanner from 'qr-scanner';
import ScanResult = QrScanner.ScanResult;
import useVerifyUserRequest from '../hooks/useVerifyUserRequest';
import { useTranslation } from 'react-i18next';
import LoginLocaleSwitcher from '../components/LoginLocaleSwitcher';
import { setDocumentTitle } from '@/hooks/useDocumentTitle';
import { useRollbar, useRollbarPerson } from '@rollbar/react';

export const LoginPage = () => {
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [signMode, setSignMode] = useState<'phone' | 'qr'>('phone');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeSending, setIsCodeSending] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUserState);

  useRollbarPerson(user);

  const { t } = useTranslation('loginPage');
  const { t: commonTranslations } = useTranslation();
  const { verifyUser } = useVerifyUserRequest();

  const rollbar = useRollbar();

  setDocumentTitle(t('signIn'));

  const sendSmsCode = async () => {
    if (isCodeSending) {
      return;
    }

    try {
      setIsCodeSending(true);
      await authService.sendSmsCode(phone);
      setIsCodeSent(true);
    } finally {
      setIsCodeSending(false);
    }
  };

  const handleSignByQr = () => {
    setSignMode('qr');
  };

  const onQrDecode = async ({ data }: ScanResult) => {
    if (!data || isSigningIn) {
      return;
    }
    const requiredParts = [document.location.origin, 'token='];

    if (!requiredParts.every((part) => data.includes(part))) {
      toast(t('invalidQrCode'), { type: 'error' });
      return;
    }

    const token = new URL(data).searchParams.get('token');

    setIsSigningIn(true);
    try {
      await verifyUser(token);
    } finally {
      setIsSigningIn(false);
    }
  };

  const handleSignByPhone = async () => {
    setSignMode('phone');

    if (!phone) {
      toast(t('pleaseEnterPhone'), { type: 'warning' });
      return;
    }

    await sendSmsCode();
  };

  const handleCodeSignIn = async () => {
    if (!code) {
      toast(t('pleaseEnterCode'), { type: 'warning' });
      return;
    }

    try {
      const { data } = await authService.signInByPhone(phone, code);
      if (data.isLogged) {
        dispatch(setUserState(data.user));
      }
    } catch (error) {
      rollbar.error('Error signing in by phone', error as Error, { phone, code });
    } finally {
      setIsSigningIn(false);
    }
  };

  const handleGoBackClick = () => {
    setIsCodeSent(false);
    setCode('');
    setSignMode('phone');
  };

  const handleResendSmsCode = async () => {
    await sendSmsCode();
    toast(t('codeWasSent'), { type: 'success' });
  };

  useEffect(() => {
    if (signMode === 'phone' && isCodeSent) {
      document.getElementById('code').focus();
    }
  }, [signMode, isCodeSent]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const isInitialView = signMode === 'phone' && !isCodeSent;
  const isCodeView = signMode === 'phone' && isCodeSent;
  const isQrView = signMode === 'qr';

  return (
    <div className="login-wrapper">
      <div className="login__logo">
        <img src={Logo} alt="" />
      </div>
      <div className="login__title">{t('welcome')}</div>
      {isInitialView && (
        <div className="login__phone-input">
          <label htmlFor={'phone-number'}>{commonTranslations('phoneNumber')}</label>
          <input id={'phone-number'} type="tel" placeholder={'12345678'} value={phone} onChange={(event) => setPhone(event.target.value)} />
        </div>
      )}
      {isCodeView && (
        <div className="login__phone-input">
          <label htmlFor="code">{commonTranslations('code')}</label>
          <input
            id={'code'}
            type="tel"
            placeholder={'123456'}
            value={code}
            autoComplete="off"
            onChange={(event) => setCode(event.target.value)}
          />
          <span className="login__code-was-sent">{t('codeWasSentOnPhone')}</span>
          <div className="login__additional-actions">
            <span onClick={handleGoBackClick}>{t('goBack')}</span>
            <span onClick={handleResendSmsCode} className={isCodeSending ? 'disabled' : ''}>
              {isCodeSending ? t('sending') : t('resendCode')}
            </span>
          </div>
        </div>
      )}
      {isQrView && (
        <div className="login__qr-wrapper">
          <div className="login__qr-title">{t('scanQrCode')}</div>
          <QrReader onDecode={onQrDecode} />
          <div className="login__qr-description">{t('qrRedirectInfo')}</div>
          <div className="login__additional-actions">
            <span onClick={handleGoBackClick}>{t('goBack')}</span>
          </div>
        </div>
      )}
      <div className="login__actions">
        {isInitialView && (
          <>
            <Button text={t('signInByPhone')} wide size={'md'} onClick={handleSignByPhone} pending={isCodeSending} />
            <Button text={t('signInByQrCode')} color={'disabled'} onClick={handleSignByQr} wide size={'md'} />
          </>
        )}
        {isCodeView && <Button text={t('signIn')} wide size={'md'} onClick={handleCodeSignIn} pending={isSigningIn} />}
      </div>
      {isInitialView && <LoginLocaleSwitcher />}
    </div>
  );
};
