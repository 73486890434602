export enum WaypointsStatus {
  Done = 'done',
  Failed = 'fail',
  DeleteData = 'deleteData',
}

export interface DeletedInsertedWaypointIdsResponse {
  deletedIds: number[];
  insertedIds: number[];
  updatedIds: number[];
}

export interface GetArrivedWaypointIdsResponse {
  waypointIds: number[];
  routeGroupItemId: number;
  timestamp: string;
}

export enum WaypointDischargingStatus {
  NotProcessing = 'not-processing',
  DischargeButtonClicked = 'discharge-button-clicked',
  DischargeFailedButtonClicked = 'discharge-failed-button-clicked',
  GettingServiceValues = 'getting-service-values',
  CheckingBioBagInstallationRequiring = 'checking-bio-bag-installation-requiring',
  InitializingDischargePayload = 'initializing-discharge-payload',
  CheckingPendingConflicts = ' checking-pending-conflicts',
  PendingConflictsNotFoundSavingPayloadToDatabase = 'pending-conflicts-not-found-saving-payload-to-database',
  PendingConflictsFoundStartHandling = 'pending-conflicts-found-start-handling',
  PendingConflictsResolvedSavingPayloadToDatabase = 'pending-conflicts-resolved-saving-payload-to-database',
}

export enum WaypointDischargeConflictStatus {
  NotHandlingConflicts = 'not-handling-conflicts',
  CheckingAllWaypointIdsMatching = 'checking-all-waypoint-ids-matching',
  AllWaypointIdsMatchedStartDeletingOldPayload = 'all-waypoint-ids-matched-start-deleting-old-payload',
  NotAllWaypointIdsMatchedStartingReplacingOldData = 'not-all-waypoint-ids-matched-starting-replacing-old-data',
  NoOldDataLeftDeletingOldPayload = 'no-old-data-left-deleting-old-payload',
  OldDataLeftUpdatingOldPayload = 'old-data-left-updating-old-payload',
}
