import { cn } from '@/utils/cn';
import iconStyles from '../../assets/styles/icons.module.scss';
import { IconProps, iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';
import React from 'react';

export const ArrowUp = ({ size, className }: IconProps) => {
  return (
    <svg
      className={cn(iconStyles[iconSizeClasses[size]], className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      fill="currentColor"
    >
      <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
    </svg>
  );
};
