import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const ImageGalleryIcon = ({ size }: IconSizeProps) => (
  <svg
    fill="currentColor"
    className={iconStyles[iconSizeClasses[size]]}
    width="800px"
    height="800px"
    viewBox="0 0 36 36"
    version="1.1"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>image-gallery-solid</title>
    <path d="M30.14,3h0a1,1,0,0,0-1-1h-22a1,1,0,0,0-1,1h0V4h24Z" className="clr-i-solid clr-i-solid-path-1"></path>
    <path d="M32.12,7V7a1,1,0,0,0-1-1h-26a1,1,0,0,0-1,1h0V8h28Z" className="clr-i-solid clr-i-solid-path-2"></path>
    <path
      d="M32.12,10H3.88A1.88,1.88,0,0,0,2,11.88V30.12A1.88,1.88,0,0,0,3.88,32H32.12A1.88,1.88,0,0,0,34,30.12V11.88A1.88,1.88,0,0,0,32.12,10ZM8.56,13.45a3,3,0,1,1-3,3A3,3,0,0,1,8.56,13.45ZM30,28h-24l7.46-7.47a.71.71,0,0,1,1,0l3.68,3.68L23.21,19a.71.71,0,0,1,1,0L30,24.79Z"
      className="clr-i-solid clr-i-solid-path-3"
    ></path>
    <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
  </svg>
);

export default ImageGalleryIcon;
