import { useLiveQuery } from 'dexie-react-hooks';
import { CoordinateUpdateRequest } from '../database';
import { coordinateUpdateRequestsDb } from '../database';
import { useProcessor } from './useProcessor';
import { useUpdateWaypointCoordinatesMutation } from '@/features/route';

export function useCoordinateUpdateRequestsProcessor() {
  const requests = useLiveQuery(() => coordinateUpdateRequestsDb.requests.toArray());
  const [updateWaypointCoordinates] = useUpdateWaypointCoordinatesMutation();

  const processRequest = async ({ body, routeGroupItemId }: CoordinateUpdateRequest) => {
    await updateWaypointCoordinates({
      body,
      routeGroupItemId,
    }).unwrap();
  };

  const deleteRequest = (requestId: number) => coordinateUpdateRequestsDb.requests.delete(requestId);

  useProcessor<CoordinateUpdateRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing coordinates update request failed',
  });
}
