import React, { useState } from 'react';

export function useSidebar() {
  const [isSidebarDisplayed, setIsSidebarDisplayed] = useState(false);

  const toggleSidebar = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsSidebarDisplayed((prev) => !prev);
    event?.stopPropagation();
  };

  const closeSidebar = () => {
    setIsSidebarDisplayed(false);
  };

  return {
    isSidebarDisplayed,
    toggleSidebar,
    closeSidebar,
  };
}
