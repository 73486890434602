import React from 'react';
import { useTranslation } from 'react-i18next';

const GOOGLE_FORM_LINK = 'https://forms.gle/oAerfaowbn1QTdMg6';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <a target={'_blank'} href={GOOGLE_FORM_LINK} className="footer__google-form-link" rel="noreferrer">
        {t('shareOpinionLabel')}
      </a>
    </div>
  );
};

export default Footer;
