import React, { useState } from 'react';
import { BasicOption } from '../../types/common';
import TrashIcon from '../icons/TrashIcon';
import SaveIcon from '../icons/SaveIcon';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import VerticalShadowScroll from '../elements/VerticalShadowScroll';
import { conditionalClassNames } from '../../helpers/dom';
import CheckMark from '../icons/CheckMark';
import Spinner from '../elements/Spinner';
import { useTranslation } from 'react-i18next';
import HorizontalShadowScroll from '../elements/HorizontalShadowScroll';
import { toast } from 'react-toastify';
import { createPortal } from 'react-dom';

interface FullScreenModalProps {
  items: BasicOption<number>[];
  isSaveRequestPending?: boolean;
  reasonRequired?: boolean;
  multipleSelect?: boolean;
  notesRequired?: boolean;
  onClose: () => void;
  onSave: (itemIds: number[], itemNote: string) => void;
}

const FullScreenModal = ({
  items,
  onSave,
  onClose,
  reasonRequired = false,
  notesRequired = false,
  multipleSelect = false,
  isSaveRequestPending = false,
}: FullScreenModalProps) => {
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [additionalNotes, setAdditionalNotes] = useState('');

  const { t } = useTranslation('arrivedPage');

  const toggleSelectedItem = (itemId: number) => {
    if (isSaveRequestPending) {
      return;
    }

    if (multipleSelect) {
      if (selectedItemIds.includes(itemId)) {
        removeSelectedItem(itemId);
        return;
      }

      setSelectedItemIds((prev) => [...prev, itemId]);
      return;
    }

    if (selectedItemIds.includes(itemId)) {
      setSelectedItemIds([]);
      return;
    }

    setSelectedItemIds([itemId]);
  };

  const removeSelectedItem = (itemId: number) => {
    if (isSaveRequestPending) {
      return;
    }

    setSelectedItemIds((prev) => prev.filter((id) => id !== itemId));
  };

  const handleSave = () => {
    if (reasonRequired && !selectedItemIds.length) {
      toast(t('fullscreenModal.selectAtLeastOneReason'), { type: 'warning' });
      return;
    }
    if (notesRequired && !additionalNotes) {
      toast('Notes are empty', { type: 'warning' });
      return;
    }

    onSave(selectedItemIds, additionalNotes);
  };

  return createPortal(
    <div className="fullscreen-modal text-gray-900 dark:text-gray-50">
      <div
        className={conditionalClassNames({
          'fullscreen-modal__content': true,
          'w-full': notesRequired,
        })}
      >
        <div className="fullscreen-modal__navbar">
          <button
            className={conditionalClassNames({
              'tablet-header__navigation-link': true,
              'header__link-disabled': isSaveRequestPending || (!selectedItemIds.length && !additionalNotes),
            })}
            disabled={isSaveRequestPending || (!selectedItemIds.length && !additionalNotes)}
            onClick={handleSave}
          >
            {isSaveRequestPending ? <Spinner size={'lg'} /> : <SaveIcon size={'md'} />}
            <span>{t('save', { ns: 'common' })}</span>
          </button>
          <button
            disabled={isSaveRequestPending}
            className={conditionalClassNames({
              'tablet-header__navigation-link': true,
              'tablet-header__navigation-link--warning': true,
              'header__link-disabled': isSaveRequestPending,
            })}
            onClick={() => {
              setSelectedItemIds([]);
              setAdditionalNotes('');
            }}
          >
            <TrashIcon size={'md'} />
            <span>{t('fullscreenModal.clearSelection')}</span>
          </button>
          <button
            disabled={isSaveRequestPending}
            className={conditionalClassNames({
              'tablet-header__navigation-link': true,
              'tablet-header__navigation-link--primary': true,
              'header__link-disabled': isSaveRequestPending,
            })}
            onClick={onClose}
          >
            <ArrowLeftIcon size={'md'} />
            <span>{t('back', { ns: 'common' })}</span>
          </button>
        </div>
        {!notesRequired && (
          <HorizontalShadowScroll className="fullscreen-modal__selected-list">
            {selectedItemIds.length === 0 ? (
              <div className="p-3">{t('fullscreenModal.selectItemFromList')}</div>
            ) : (
              selectedItemIds.map((itemId) => {
                const { label, value } = items.find(({ value }) => value === itemId);

                return (
                  <div key={itemId} className="fullscreen-modal__selected-item">
                    <span>{label}</span>
                    <button onClick={() => removeSelectedItem(value)}>
                      <TrashIcon size="sm" />
                    </button>
                  </div>
                );
              })
            )}
          </HorizontalShadowScroll>
        )}
        <div className="fullscreen-modal__additional-notes">
          <textarea
            placeholder={t('fullscreenModal.additionalNotes')}
            value={additionalNotes}
            onChange={(event) => setAdditionalNotes(event.target.value)}
          />
        </div>
        {!notesRequired && (
          <div className="fullscreen-modal__sidebar">
            <VerticalShadowScroll className="h-[50vh] md:h-screen">
              {items.map(({ label, value }) => {
                const isSelected = selectedItemIds.includes(value);

                return (
                  <div
                    key={value}
                    className={conditionalClassNames({
                      'fullscreen-modal__sidebar-item': true,
                      'fullscreen-modal__sidebar-item--selected': isSelected,
                    })}
                    onClick={() => toggleSelectedItem(value)}
                  >
                    <span>{label}</span>
                    {isSelected && <CheckMark size="sm" />}
                  </div>
                );
              })}
            </VerticalShadowScroll>
          </div>
        )}
      </div>
    </div>,
    document.body,
  );
};

export default FullScreenModal;
