import React from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';

function ReloadPrompt() {
  const { t } = useTranslation();

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      // eslint-disable-next-line prefer-template, no-console
      console.log('SW Registered: ' + r);
    },
    onRegisterError(error) {
      console.error('SW registration error', error);
    },
  });

  const close = () => {
    setNeedRefresh(false);
  };

  return (
    <div className="p-0 m-0 fixed bottom-0 right-0 w-full md:bottom-4 md:right-4 md:w-auto z-[9999]">
      {needRefresh && (
        <div className="bg-white p-3 border border-gray-300 rounded rounded-b-none shadow-lg flex flex-col gap-1">
          <div className="text-gray-900">
            <span>{t('newContentPrompt', { ns: 'offline' })}</span>
          </div>
          <div className="flex gap-2">
            <Button text={t('reload')} onClick={() => updateServiceWorker(true)} />
            <Button text={t('close')} onClick={close} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ReloadPrompt;
