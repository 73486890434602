import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@/store';
import { MapTileMode } from '@ekt-group/map-component';
import { WaypointListSortingOption } from '@/features/route';

export interface TabletPageSettingsState {
  displayMapSettings: boolean;
  displayKeysModal: boolean;
  displaySidebarOnMobile: boolean;
  displayArrivedPage: boolean;
  selectedWaypointListSortingOption?: WaypointListSortingOption;
  historicalSortingRouteGroupItemId?: number;
  mapSettings: {
    panOnWaypointChange: boolean;
    stickToCar: boolean;
    showNavigationLine: boolean;
    autoZoom: boolean;
    showDoneWaypoints: boolean;
    enableRotation: boolean;
    tilesMode: MapTileMode;
  };
}

const savedMapSettings = localStorage.getItem('mapSettings');
const savedSortingOption = localStorage.getItem('sortingOption');
const saveHistoricalSortingRouteGroupItemId = localStorage.getItem('historicalSortingRouteGroupItemId');

const defaultMapSettings = {
  panOnWaypointChange: false,
  stickToCar: true,
  showNavigationLine: true,
  autoZoom: true,
  enableRotation: true,
  showDoneWaypoints: true,
  tilesMode: MapTileMode.OpenStreetMaps,
};

const initialState: TabletPageSettingsState = {
  displayMapSettings: false,
  displayKeysModal: false,
  displaySidebarOnMobile: false,
  displayArrivedPage: false,
  selectedWaypointListSortingOption:
    savedSortingOption !== undefined ? (savedSortingOption as WaypointListSortingOption) : WaypointListSortingOption.Closest,
  historicalSortingRouteGroupItemId: saveHistoricalSortingRouteGroupItemId ? Number(saveHistoricalSortingRouteGroupItemId) : null,
  mapSettings: savedMapSettings ? JSON.parse(savedMapSettings) : defaultMapSettings,
};

export const tabletPageSettingsSlice = createSlice({
  name: 'tabletPageSettings',
  initialState,
  reducers: {
    setDisplayMapSettings: (state, action) => {
      state.displayMapSettings = action.payload;
    },
    toggleMapSettingsDisplayedState: (state) => {
      state.displayMapSettings = !state.displayMapSettings;
    },
    setPanOnWaypointChange: (state, action) => {
      state.mapSettings.panOnWaypointChange = action.payload;
    },
    setStickToCar: (state, action) => {
      state.mapSettings.stickToCar = action.payload;
    },
    setShowNavigationLine: (state, action) => {
      state.mapSettings.showNavigationLine = action.payload;
    },
    setAutoZoom: (state, action) => {
      state.mapSettings.autoZoom = action.payload;
    },
    setEnableRotation: (state, action) => {
      state.mapSettings.enableRotation = action.payload;
    },
    setShowDoneWaypoints: (state, action) => {
      state.mapSettings.showDoneWaypoints = action.payload;
    },
    setMapSettings: (state, action) => {
      state.mapSettings = action.payload;
    },
    setDisplayKeysModal: (state, action) => {
      state.displayKeysModal = action.payload;
    },
    setTilesMode: (state, action) => {
      state.mapSettings.tilesMode = action.payload;
    },
    toggleDisplayKeysModal: (state) => {
      state.displayKeysModal = !state.displayKeysModal;
    },
    setDisplaySidebarOnMobile: (state, action) => {
      state.displaySidebarOnMobile = action.payload;
    },
    setDisplayArrivedPage: (state, action) => {
      state.displayArrivedPage = action.payload;
    },
    setSelectedWaypointListSortingOption: (state, action: PayloadAction<WaypointListSortingOption>) => {
      state.selectedWaypointListSortingOption = action.payload;
    },
    setHistoricalSortingRouteGroupItemId: (state, action) => {
      state.historicalSortingRouteGroupItemId = action.payload;
    },
    resetTabletPageSettingsState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setDisplayMapSettings,
  toggleMapSettingsDisplayedState,
  setPanOnWaypointChange,
  setStickToCar,
  setShowNavigationLine,
  setAutoZoom,
  setEnableRotation,
  setShowDoneWaypoints,
  setMapSettings,
  setTilesMode,
  setDisplayKeysModal,
  setDisplaySidebarOnMobile,
  setDisplayArrivedPage,
  setSelectedWaypointListSortingOption,
  setHistoricalSortingRouteGroupItemId,
  toggleDisplayKeysModal,
  resetTabletPageSettingsState,
} = tabletPageSettingsSlice.actions;

export const selectDisplayMapSettings = (state: AppState) => state.tabletPageSettings.displayMapSettings;
export const selectDisplayKeysModal = (state: AppState) => state.tabletPageSettings.displayKeysModal;
export const selectDisplaySidebarOnMobile = (state: AppState) => state.tabletPageSettings.displaySidebarOnMobile;
export const selectDisplayArrivedPage = (state: AppState) => state.tabletPageSettings.displayArrivedPage;

export const selectSelectedWaypointListSortingOption = (state: AppState) => state.tabletPageSettings.selectedWaypointListSortingOption;
export const selectHistoricalSortingRouteGroupItemId = (state: AppState) => state.tabletPageSettings.historicalSortingRouteGroupItemId;

export const selectMapSettings = (state: AppState) => state.tabletPageSettings.mapSettings;

export default tabletPageSettingsSlice.reducer;
