import React from 'react';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';
import iconStyles from '../../assets/styles/icons.module.scss';

const StartIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      className={iconStyles[iconSizeClasses[size]]}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g transform="translate(0 -1036.362)">
        <path
          transform="translate(0 1036.362)"
          d="M8 1a7 7 0 0 0-7 7 7 7 0 0 0 7 7 7 7 0 0 0 7-7 7 7 0 0 0-7-7zm0 1a6 6 0 0 1 6 6 6 6 0 0 1-6 6 6 6 0 0 1-6-6 6 6 0 0 1 6-6z"
          style={{
            opacity: 1,
            vectorEffect: 'none',
            fill: 'currentColor',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 4,
            strokeLinecap: 'square',
            strokeLinejoin: 'round',
            strokeMiterlimit: 4,
            strokeDasharray: 'none',
            strokeDashoffset: 3.20000005,
            strokeOpacity: 0.55063291,
          }}
        />

        <path
          d="m6 1041.362 5 3-5 3z"
          style={{
            opacity: 1,
            vectorEffect: 'none',
            fill: 'currentColor',
            fillOpacity: 1,
            stroke: 'none',
            strokeWidth: 4,
            strokeLinecap: 'square',
            strokeLinejoin: 'round',
            strokeMiterlimit: 4,
            strokeDasharray: 'none',
            strokeDashoffset: 3.20000005,
            strokeOpacity: 0.55063291,
          }}
        />
      </g>
    </svg>
  );
};

export default StartIcon;
