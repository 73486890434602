import React, { useCallback, useEffect, useState } from 'react';
import Spinner from '@/components/elements/Spinner';
import { getReadableDateTime } from '@/helpers/dateHelper';
import { HistoricalRoutesMatchItem } from '@/features/home';
import { useTranslation } from 'react-i18next';
import { cn } from '@/utils/cn';

interface Props {
  selectedRouteGroupItemId: number;
  historicalMatchedItems: HistoricalRoutesMatchItem[];
  isVisible?: boolean;
  handleMatchedItemSelect: (routeGroupItemId: number) => void;
  isLoadingHistoricalMatch: boolean;
  isFetchingCompletedOrder: boolean;
}

const SortingModalHistoricalRouteList = ({
  selectedRouteGroupItemId,
  isVisible = false,
  historicalMatchedItems,
  handleMatchedItemSelect,
  isLoadingHistoricalMatch,
  isFetchingCompletedOrder,
}: Props) => {
  const { t } = useTranslation();
  if (!isVisible) {
    return null;
  }

  if (isLoadingHistoricalMatch) {
    return (
      <div className="flex items-center justify-center w-full h-dynamic-18 md:h-dynamic-58">
        <Spinner size={'xl'} />
      </div>
    );
  }

  return (
    <>
      {historicalMatchedItems?.map(({ routeGroupItemId, distanceKm, unloadCount, objectsMatch, date }) => (
        <div
          key={`match-${routeGroupItemId}`}
          className={cn({
            'grid grid-cols-2 relative items-center p-2 border border-gray-400 dark:border-gray-700 rounded text-base md:text-xl cursor-pointer':
              true,
            'bg-green-600 text-white': selectedRouteGroupItemId === routeGroupItemId,
          })}
          onClick={() => handleMatchedItemSelect(routeGroupItemId)}
        >
          {selectedRouteGroupItemId === routeGroupItemId && isFetchingCompletedOrder && (
            <div className={'absolute right-2 top-2'}>
              <Spinner size={'md'} />
            </div>
          )}
          <div className={'flex items-center gap-2 md:flex-col md:items-start md:gap-0'}>
            <span>{t('distance')}:</span>
            <span className={'font-bold'}>{distanceKm}km</span>
          </div>
          <div className={'flex items-center gap-2 md:flex-col md:items-start md:gap-0'}>
            <span>{t('date')}:</span>
            <span className="font-bold">{date ? getReadableDateTime(date, 'date') : '---'}</span>
          </div>
          <div className={'flex items-center gap-2 md:flex-col md:items-start md:gap-0'}>
            <span>{t('historicalSortingModal.unloads', { ns: 'tabletPage' })}:</span>
            <span className={'font-bold'}>{unloadCount}</span>
          </div>
          <div className={'flex items-center gap-2 md:flex-col md:items-start md:gap-0'}>
            <span>{t('historicalSortingModal.match', { ns: 'tabletPage' })}:</span>
            <span
              className={cn({
                'font-bold text-lg md:text-2xl': true,
                'text-green-500': selectedRouteGroupItemId !== routeGroupItemId && objectsMatch >= 70,
                'text-orange-500': selectedRouteGroupItemId !== routeGroupItemId && objectsMatch >= 50 && objectsMatch < 70,
                'text-red-500': selectedRouteGroupItemId !== routeGroupItemId && objectsMatch < 50,
              })}
            >
              {objectsMatch}%
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

export default SortingModalHistoricalRouteList;
