import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnLogin } from '../../auth/hooks/useRedirectOnLogin';
import { setDocumentTitle } from '../../../hooks/useDocumentTitle';

export const ServerErrorPage = () => {
  const { t } = useTranslation('errors');
  setDocumentTitle(t('serverError'));
  useRedirectOnLogin();

  return (
    <div className="error-page__wrapper">
      <span className="error-page__code">500</span>
      <div className="error-page__separator"></div>
      <span className="error-page__text">{t('serverError')}</span>
    </div>
  );
};
