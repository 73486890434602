import React, { PropsWithChildren, useEffect, useRef } from 'react';
import './BasicFullScreenModal.scss';
import { cn } from '../../../utils/cn';
import CrossMark from '../../icons/CrossMark';
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll';
import Button from '@/components/elements/Button';

interface BasicFullScreenModalProps {
  isVisible: boolean;
  isCloseable?: boolean;

  onClose?: () => void;

  renderHeader?: () => JSX.Element;
  renderFooter?: () => JSX.Element;

  headerClassNames?: string;
  mainClassNames?: string;
  footerClassNames?: string;
}

export const BasicFullScreenModal = ({
  children,
  isVisible,
  isCloseable = false,
  onClose,
  renderHeader,
  renderFooter,
  headerClassNames,
  mainClassNames,
  footerClassNames,
}: PropsWithChildren<BasicFullScreenModalProps>) => {
  const titleRef = useRef<HTMLDivElement>(null);

  useDisableBodyScroll(isVisible);

  useEffect(() => {
    titleRef?.current.scrollIntoView();
  }, [children]);

  return (
    <div
      className={cn([
        'invisible pointer-events-none fixed w-full h-full top-0 left-0 right-0 items-center justify-center z-40 transition-opacity duration-300',
        'text-black dark:text-white',
        { 'visible flex pointer-events-auto': isVisible },
      ])}
    >
      <div className="absolute w-full h-full bg-gray-100 dark:bg-gray-600 opacity-100">
        <div className="fixed w-full h-full z-50 overflow-y-auto">
          {isCloseable && (
            <div
              onClick={onClose}
              className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black dark:text-white text-sm z-50"
            >
              <CrossMark size="sm" />
            </div>
          )}

          <div className="h-full mx-auto text-left items-center flex flex-col">
            <div
              ref={titleRef}
              className={cn(
                'sticky top-0 z-10 flex w-full justify-between items-center flex-1 max-h-20 px-2 md:px-4 md:py-2 shadow-lg bg-white dark:bg-gray-700',
                headerClassNames,
              )}
            >
              {renderHeader()}
            </div>

            <div className={cn('w-full flex flex-1 items-center justify-center', mainClassNames)}>{children}</div>

            <div
              className={cn(
                'sticky bottom-0 z-10 h-fit w-full max-h-dynamic-17 flex-1 bg-white dark:bg-gray-700 shadow-[-1px_0px_33px_-14px_rgba(0,0,0,0.6)]',
                footerClassNames,
              )}
            >
              {renderFooter()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
