import RouteGroupItemListItem from './RouteGroupItemListItem';
import Spinner from '../../../components/elements/Spinner';
import { useTranslation } from 'react-i18next';
import { RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';

interface RouteGroupItemListProps {
  routeGroupItems: RouteGroupItem[];
  onOpenOrContinueButtonClick: (routeGroupItemId: number) => void;
  onStartOrFinishButtonClick: (routeGroupItemId: number, action: 'start' | 'finish', isActive: boolean, shouldForbidStart: boolean) => void;
  isRouteDataLoading?: boolean;
}

const RouteGroupItemList = ({
  routeGroupItems,
  onOpenOrContinueButtonClick,
  onStartOrFinishButtonClick,
  isRouteDataLoading = false,
}: RouteGroupItemListProps) => {
  const { t } = useTranslation('routeGroupItems');

  if (isRouteDataLoading) {
    return <Spinner size={'md'} />;
  }
  if (!routeGroupItems?.length || routeGroupItems?.length === 0) {
    return <p className="no-items">{t('noItemsForToday')}</p>;
  }

  return (
    <>
      {routeGroupItems.map((item) => (
        <RouteGroupItemListItem
          item={item}
          key={item.id}
          // do not allow to start route if driver has already started another route. Exception: If route name contains DL
          shouldForbidStart={!item.routeGroup?.name.includes('DL')}
          onStartOrFinishButtonClick={onStartOrFinishButtonClick}
          onContinueOrOpenButtonClick={onOpenOrContinueButtonClick}
        />
      ))}
    </>
  );
};

export default RouteGroupItemList;
