import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const WifiIcon = ({ size }: IconSizeProps) => (
  <svg
    fill="currentColor"
    className={iconStyles[iconSizeClasses[size]]}
    height="400px"
    width="400px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 461.432 461.432"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M125.896,398.928c0,33.683-27.308,60.999-61.022,60.999c-33.684,0-61.006-27.316-61.006-60.999
			c0-33.729,27.322-61.038,61.006-61.038C98.588,337.89,125.896,365.198,125.896,398.928z"
        />
      </g>
      <g>
        <g>
          <path
            d="M0,229.636c0,8.441,6.606,15.379,15.036,15.809c60.318,3.076,100.885,25.031,138.248,62.582
				c36.716,36.864,60.071,89.759,64.082,137.769c0.686,8.202,7.539,14.524,15.77,14.524h56.701c4.344,0,8.498-1.784,11.488-4.935
				c2.992-3.15,4.555-7.391,4.333-11.729c-8.074-158.152-130.669-278.332-289.013-286.23c-4.334-0.217-8.564,1.355-11.709,4.344
				C1.792,164.759,0,168.908,0,173.247V229.636z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M0,73.411c0,8.51,6.713,15.482,15.216,15.819c194.21,7.683,350.315,161.798,358.098,355.879
				c0.34,8.491,7.32,15.208,15.818,15.208h56.457c4.297,0,8.408-1.744,11.393-4.834c2.985-3.09,4.585-7.258,4.441-11.552
				C453.181,199.412,261.024,9.27,16.38,1.121C12.089,0.978,7.925,2.583,4.838,5.568C1.751,8.551,0,12.661,0,16.954V73.411z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default WifiIcon;
