import { useLiveQuery } from 'dexie-react-hooks';
import { waypointsStatusRequestsDb, WaypointsStatusRequest } from '../database';
import { WaypointsStatus } from '../types/waypoints';
import { useProcessor } from './useProcessor';
import { useDeleteStatusDataMutation, useMarkWaypointAsDoneMutation, useMarkWaypointAsFailedMutation } from '@/features/route';
import { getAndSetLocalProcessedWaypoints } from '@/helpers/localStorage/locallyProcessedWaypointsHelper';
import { useRollbar } from '@rollbar/react';
import { useAppSelector } from '@/store/types';
import { selectUserId } from '@/features/auth';
import { isEmpty } from 'lodash';

const DELETE_REQUESTS_AFTER_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;

export function useWaypointsStatusRequestsProcessor() {
  const requests = useLiveQuery(() => waypointsStatusRequestsDb.requests.toArray());

  const [markWaypointAsDone] = useMarkWaypointAsDoneMutation();
  const [markWaypointAsFailed] = useMarkWaypointAsFailedMutation();
  const [deleteStatusData] = useDeleteStatusDataMutation();
  const rollbar = useRollbar();
  const userId = useAppSelector(selectUserId);

  const processRequest = async (payload: WaypointsStatusRequest<any>) => {
    let mutation;
    switch (payload.status) {
      case WaypointsStatus.Done:
        mutation = markWaypointAsDone(payload);
        break;
      case WaypointsStatus.Failed:
        mutation = markWaypointAsFailed(payload);
        break;
      case WaypointsStatus.DeleteData:
        mutation = deleteStatusData(payload);
        break;
      default:
        throw new Error(`Unknown request status type: ${payload.status}`);
    }

    try {
      const data = await mutation.unwrap();
      if (!isEmpty(data)) {
        waypointsStatusRequestsDb.requests.delete(payload.id);
      }
    } catch (error) {
      rollbar.error('[OFFLINE] Failed to process waypoint status request', {
        userId,
        payload,
        error,
      });
    }

    if (payload.status !== WaypointsStatus.DeleteData) {
      getAndSetLocalProcessedWaypoints(payload.waypointIds);
    }
  };

  const deleteRequest = (requestId: number) => {
    // keep 403 requests for a week
    const request = requests.find((r) => r.id === requestId);
    if (!request) {
      return;
    }

    if (request.body.doneAt && Date.now() - Date.parse(request.body.doneAt) < DELETE_REQUESTS_AFTER_IN_MILLISECONDS) {
      return;
    }

    return waypointsStatusRequestsDb.requests.delete(requestId);
  };

  useProcessor<WaypointsStatusRequest<any>>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing waypoint status request failed',
  });
}
