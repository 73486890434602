import { City, County, ObjectEntity, Owner, Village } from '@ekt-group/general-purpose-api-interfaces';

export const unifiedString = (items: (string | number | undefined)[], separator = ', ') => items.filter(Boolean).join(separator);

interface GetWaypointAddressParams {
  object: ObjectEntity;
  village?: Village;
  city?: City;
  county?: County;
}

export const getObjectAddress = (params: GetWaypointAddressParams, exclude: ('city' | 'village' | 'county')[] = []): string => {
  const { object } = params;
  const streetWithHouseNumber = unifiedString(
    [
      object?.street,
      object?.houseNumber,
      object?.flatNumber ? `- ${object?.flatNumber}` : '',
      object?.addressAddon ? `(${object?.addressAddon})` : '',
    ],
    ' ',
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const addressParts = ['village', 'city', 'county'].filter((part) => !exclude.includes(part)).map((part) => params?.[part]?.[part]);
  return unifiedString([streetWithHouseNumber, ...addressParts]);
};

export const getOwnerName = ({ companyName, firstname, surname }: Owner) => {
  return companyName || unifiedString([firstname, surname], ' ');
};
