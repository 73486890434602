import httpService from '../../../lib/http.service';
import { CompletedRouteOrder, HistoricalRoutesMatchItem, RouteGroupItemSummary } from '@/features/home';
import { RouteGroupItem, RouteGroupItemPlanning, RouteGroupItemPlanningService } from '@ekt-group/general-purpose-api-interfaces';

export interface RouteGroupItemStartDto {
  id: number;
  startTime: string;
  odometer: number;
}

export interface RouteGroupItemFinishDto {
  id: number;
  endTime: string;
  odometer: number;
  startOdometer?: number;
}

export default {
  async start({ id, startTime, odometer }: RouteGroupItemStartDto) {
    return httpService.post<RouteGroupItem>(`/route-group-items/${id}/start`, {
      startOdometer: odometer,
      startTime,
    });
  },
  async finish({ id, endTime, odometer, startOdometer }: RouteGroupItemFinishDto) {
    return httpService.post<RouteGroupItem>(`/route-group-items/${id}/finish`, {
      endOdometer: odometer,
      startOdometer,
      endTime,
    });
  },
  async getSummary(id: number) {
    const { data } = await httpService.get<RouteGroupItemSummary>(`/route-group-items/${id}/summary`);

    return data;
  },

  async getHistoricalRoutesMatch(id: number) {
    return httpService.get<HistoricalRoutesMatchItem[]>(`/routes/items/${id}/historical-routes-match`);
  },

  async getCompletedRouteOrder(id: number) {
    return httpService.get<CompletedRouteOrder[]>(`/routes/items/completed-order`, {
      params: { routeGroupItemIds: id },
    });
  },

  async getPlanningForRouteGroupItem(id: number) {
    return httpService.get<RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>[]>(`/routes/items/${id}/planning`);
  },
};
