import { CarAcceptance } from '@ekt-group/general-purpose-api-interfaces';
import { getTodayDate } from '@/helpers/dateHelper';
import { extendedBaseQuery } from '@/lib/http.service';
import { baseApi, CAR_ACCEPTANCE_KEY } from '@/store/baseApi';

export interface GetCarAcceptanceDto {
  driverIds?: number[];
  carIds?: number[];
  startDate?: string;
  endDate?: string;
}

export interface CreateCarAcceptanceDto {
  driverId?: number;
  carId: number;
  files: File[];
}

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [CAR_ACCEPTANCE_KEY],
});

export const carAcceptancesApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarAcceptance: builder.query<CarAcceptance[], GetCarAcceptanceDto>({
      query: ({ driverIds, carIds, endDate, startDate }) => {
        return {
          url: '/cars/acceptances',
          method: 'GET',
          params: {
            driverIds,
            carIds,
            endDate,
            startDate,
          },
        };
      },
      keepUnusedDataFor: Infinity, // keep it until it is invalidated,
    }),
    createCarAcceptance: builder.mutation<CarAcceptance, CreateCarAcceptanceDto>({
      query: ({ carId, files, driverId }) => {
        const body = new FormData();
        for (const fileItem of files) {
          body.append('file[]', fileItem);
        }
        body.append('carId', carId.toString());
        body.append('driverId', driverId.toString());

        return {
          url: '/cars/acceptances',
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          data: body,
        };
      },
      invalidatesTags: [CAR_ACCEPTANCE_KEY],
      onQueryStarted: async ({ carId, driverId, files }, { dispatch, queryFulfilled }) => {
        // only update cache when offline
        queryFulfilled.catch(() =>
          dispatch(
            carAcceptancesApi.util.updateQueryData('getCarAcceptance', undefined, (draft) => {
              const latestEntry = draft[draft.length - 1];
              const tempId = latestEntry?.id + 1 || 0;

              draft.push({ carId, driverId, createdAt: getTodayDate(), id: tempId });
            }),
          ),
        );
      },
      extraOptions: {
        maxRetries: 3,
      },
    }),
  }),
});

export const { useGetCarAcceptanceQuery, useCreateCarAcceptanceMutation } = carAcceptancesApi;
