import { WaypointDischargeFailureReason } from '@ekt-group/general-purpose-api-interfaces';
import { useEffect, useMemo, useState } from 'react';
import waypointsService from '../../../../services/discharge-waypoints.service';
import { useSelector } from 'react-redux';
import { selectIsCurrentRouteGroupItemOpenedMode } from '@/features/home';

interface DischargeFailureReasons {
  dischargeReasons: WaypointDischargeFailureReason[];
  failureReasons: WaypointDischargeFailureReason[];
}

export function useArrivedPageDischargeFailureReasons() {
  const [reasons, setReasons] = useState<WaypointDischargeFailureReason[]>([]);
  const isRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);

  const getDischargeFailureReasons = async () => {
    if (reasons.length > 0) {
      return;
    }

    const fetchedReasons = await waypointsService.getDischargeFailureReasons();
    setReasons(fetchedReasons);
  };

  const { dischargeReasons, failureReasons } = useMemo(
    () =>
      reasons.reduce<DischargeFailureReasons>(
        (acc, reason) => {
          if (reason.dischargeReason) {
            acc.dischargeReasons.push(reason);
          }
          if (reason.rideReason) {
            acc.failureReasons.push(reason);
          }

          return acc;
        },
        { dischargeReasons: [], failureReasons: [] },
      ),
    [reasons],
  );

  useEffect(() => {
    if (isRouteGroupItemOpenedMode) {
      return;
    }

    getDischargeFailureReasons();
  }, []);

  return {
    dischargeReasons: dischargeReasons.sort((a, b) => b.priority - a.priority),
    failureReasons: failureReasons.sort((a, b) => b.priority - a.priority),
  };
}
