import { getUploadPlanLink } from '@/helpers/garbage';
import { useBasicImagesModalContext } from '@/contexts/imagesModalContext';

export function useUploadPlanImages() {
  const { setImages, toggleImagesModal } = useBasicImagesModalContext();

  const setUploadPlanImages = (uploadPlan: string[]) => {
    setImages(uploadPlan.map((relativeLink) => ({ url: getUploadPlanLink(relativeLink) })));
  };

  return {
    setUploadPlanImages,
    toggleUploadPlanImagesModal: toggleImagesModal,
  };
}
