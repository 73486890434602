export enum ObjectServiceActivityType {
  Install = 1,
  DeInstall = 2,
  Lock = 3,
  LockTransport = 4,
  RoundTransport = 5,
  Wash = 6,
  Sale = 7,
  BioBag = 8,
  BioFilter = 9,
  BioFilterRent = 10,
  BioFilterChange = 11,
}
