import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const SidebarIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      className={iconStyles[iconSizeClasses[size]]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.497 3.017A2.5 2.5 0 0 0 3 5.517V18.5A2.5 2.5 0 0 0 5.5 21h13a2.5 2.5 0 0 0 2.5-2.5V5.503a2.5 2.5 0 0 0-2.503-2.5l-13 .014zM5 18.5V5.517a.5.5 0 0 1 .5-.5l8.5-.014V19H5.5a.5.5 0 0 1-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SidebarIcon;
