import { ObjectEntity } from '@ekt-group/general-purpose-api-interfaces';
import { getObjectAddress } from '../helpers/strings';
import { useCommonItemMaps } from './useCommonItemMaps';

export function useObjectAddress(object: ObjectEntity, exclude: ('city' | 'village' | 'county')[] = []) {
  const { citiesMap, countiesMap, villagesMap } = useCommonItemMaps();

  const city = citiesMap?.[object?.cityId];
  const county = countiesMap?.[object?.countyId];
  const village = villagesMap?.[object?.villageId];

  return getObjectAddress({ object, city, county, village }, exclude);
}
