export const htmlRegex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
export const urlRegex = /(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/;

export const removeHtmlTagsExceptStrong = (input: string): string => {
  const regex = /<(?!\/?(strong|b)\b)[^>]*>/gi;
  return input.replace(regex, '');
};

export const removeExtraDotsFromDecimal = (string: string) => {
  const pattern = /^([^.,]*[.,])(.*)/;
  const match = string.match(pattern);
  if (match) {
    const firstOccurrence = match[1];
    const restOfString = match[2].replace(/[.,]/g, '');
    return firstOccurrence + restOfString;
  }
  return string;
};
