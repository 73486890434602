import { useLiveQuery } from 'dexie-react-hooks';
import { WaypointsServiceStatusRequest, waypointsServiceStatusRequestsDb } from '../database/waypoints-service-status-requests.db';
import { useProcessor } from './useProcessor';
import { useUpdateServiceWaypointStatusMutation } from '@/features/route';

export function useWaypointsServiceStatusRequestsProcessor() {
  const requests = useLiveQuery(() => waypointsServiceStatusRequestsDb.requests.toArray());

  const [updateServiceWaypointStatus] = useUpdateServiceWaypointStatusMutation();

  const processRequest = async (request: WaypointsServiceStatusRequest) => {
    updateServiceWaypointStatus(request).unwrap();
  };

  const deleteRequest = (requestId: number) => waypointsServiceStatusRequestsDb.requests.delete(requestId);

  useProcessor<WaypointsServiceStatusRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing waypoint service status request failed',
  });
}
