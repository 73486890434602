import { cn } from '@/utils/cn';
import iconStyles from '../../assets/styles/icons.module.scss';
import { IconProps, iconSizeClasses } from '../../helpers/IconSettings';
import React from 'react';

export const ArrowDown = ({ size, className }: IconProps) => {
  return (
    <svg
      className={cn(iconStyles[iconSizeClasses[size]], className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      fill="currentColor"
    >
      <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
    </svg>
  );
};
