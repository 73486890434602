import { useGetCarsCheckOptionsQuery } from '@/features/home';
import { useAppMode } from '@/hooks/useAppMode';

export const useCarCheck = () => {
  const { isBasicMode } = useAppMode();
  const { data, isFetching, isLoading, status } = useGetCarsCheckOptionsQuery({ isDeleted: false }, { skip: isBasicMode });

  return {
    data,
    isFetching,
    isLoading,
    status,
  };
};
