import Dexie from 'dexie';
import { SetFailedWaypointStatusDto, SetWaypointStatusDto } from '../services/discharge-waypoints.service';
import { WaypointsStatus } from '../types/waypoints';

type WaypointsStatusRequestBodyType<Status> = Status extends WaypointsStatus.Done
  ? SetWaypointStatusDto
  : Status extends WaypointsStatus.Failed
  ? SetFailedWaypointStatusDto
  : null;

export interface WaypointsStatusRequest<Status extends WaypointsStatus> {
  id?: number;
  status: Status;
  waypointIds: number[];
  body: WaypointsStatusRequestBodyType<Status>;
}

export class WaypointsStatusRequestsDb extends Dexie {
  requests: Dexie.Table<WaypointsStatusRequest<any>, number>;

  constructor() {
    super('waypointsStatusRequestsDb');

    this.version(1).stores({
      requests: '++id, status, waypointIds, body',
    });
  }
}

export const waypointsStatusRequestsDb = new WaypointsStatusRequestsDb();
