import { useGetWaypointUnloadsQuery, useLazyGetWaypointUnloadsQuery } from '@/features/route';
import { useGetUnloadPointsQuery } from '../api/unloading.api';
import { useCallback, useEffect } from 'react';

export const useUnloads = (currentRouteGroupItemId: number) => {
  const {
    data: unloadPoints,
    isLoading: isUnloadPointLoading,
    isFetching: isUnloadPointFetching,
  } = useGetUnloadPointsQuery(currentRouteGroupItemId, {
    skip: !currentRouteGroupItemId,
  });

  const [getWaypointsUnloadQuery, { data: waypointUnloads, isLoading: isWaypointUnloadsLoading, isFetching: isWaypointUnloadsFetching }] =
    useLazyGetWaypointUnloadsQuery();

  const getWaypointUnloads = useCallback(() => {
    if (currentRouteGroupItemId) {
      getWaypointsUnloadQuery(currentRouteGroupItemId);
    }
  }, [getWaypointsUnloadQuery, currentRouteGroupItemId]);

  useEffect(() => {
    getWaypointUnloads();
  }, [getWaypointUnloads]);

  return {
    unloadPoints: unloadPoints || [],
    isUnloadPointLoading,
    isUnloadPointFetching,
    waypointUnloads: waypointUnloads || [],
    isWaypointUnloadsLoading,
    isWaypointUnloadsFetching,
    getWaypointUnloads,
  };
};
