import { getTodayDateWithoutTime } from '../dateHelper';

const PROCESSED_OBJECTS_KEY = 'processedObjects';

// map of objectId - date string
// createdDate as key, which contains arrays of objectIds - date string
interface ProcessedObjectsDateMap {
  createdDate: string;
  data: {
    objectId: number;
    recordedDate: string;
  }[];
}

export const getStoredProcessedObjectsDateMap = (): ProcessedObjectsDateMap[] => {
  const processedObjects = localStorage.getItem(PROCESSED_OBJECTS_KEY);
  if (!processedObjects) {
    return [];
  }

  return JSON.parse(processedObjects);
};

const getProcessedObjectsDateMap = (createdDate: string): ProcessedObjectsDateMap => {
  const processedObjects = getStoredProcessedObjectsDateMap();
  return processedObjects.find((processedObject) => processedObject.createdDate === createdDate);
};

export const getProcessedObjectDate = (objectId: number): string | undefined => {
  const processedObjects = getProcessedObjectsDateMap(getTodayDateWithoutTime());
  if (!processedObjects) {
    return;
  }

  return processedObjects.data.find((data) => data.objectId === objectId)?.recordedDate;
};

const setProcessedObjectsDateMap = (processedObjects: ProcessedObjectsDateMap[]) => {
  localStorage.setItem(PROCESSED_OBJECTS_KEY, JSON.stringify(processedObjects));
};

export const addToProcessedObjectDateMap = (objectId: number, date: string) => {
  const storedProcessedObjectsDateMap = getStoredProcessedObjectsDateMap();

  const todayProcessedObjectsIndex = storedProcessedObjectsDateMap.findIndex(
    (processedObject) => processedObject.createdDate === getTodayDateWithoutTime(),
  );

  if (todayProcessedObjectsIndex === -1) {
    storedProcessedObjectsDateMap.push({
      createdDate: getTodayDateWithoutTime(),
      data: [{ objectId, recordedDate: date }],
    });

    setProcessedObjectsDateMap(storedProcessedObjectsDateMap);
    return;
  }

  const storedForToday = storedProcessedObjectsDateMap[todayProcessedObjectsIndex];
  if (storedForToday.data.some((data) => data.objectId === objectId)) {
    return;
  }

  storedForToday.data.push({ objectId, recordedDate: date });
  setProcessedObjectsDateMap(storedProcessedObjectsDateMap);
};

export const removeOldProcessedObjects = (daysAgo: number) => {
  const processedObjects = getStoredProcessedObjectsDateMap();
  const currentDate = new Date();
  const daysAgoDate = new Date(currentDate.setDate(currentDate.getDate() - daysAgo));

  setProcessedObjectsDateMap(processedObjects.filter((processedObject) => new Date(processedObject.createdDate) > daysAgoDate));
};
