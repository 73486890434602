import Dexie from 'dexie';

export enum RouteStartFinishRequestType {
  Start = 'start',
  Finish = 'finish',
}

export interface RouteStartFinishRequest {
  id?: number;
  type: RouteStartFinishRequestType;
  routeGroupItemId: number;
  carId?: number;
  startTime?: string;
  endTime?: string;
  startOdometerValue?: number;
  endOdometerValue?: number;
}

export class RouteStartFinishRequestsDb extends Dexie {
  requests: Dexie.Table<RouteStartFinishRequest, number>;

  constructor() {
    super('routeStartFinishRequests');
    this.version(1).stores({
      requests: '++id, type, routeGroupItemId, carId, startTime, endTime, startOdometerValue, endOdometerValue',
    });
  }
}

export const routeStartFinishRequestsDb = new RouteStartFinishRequestsDb();
