import Dexie from 'dexie';

export enum PhotoEntity {
  ServiceWaypoint = 'serviceWaypoint',
  DischargeWaypoint = 'dischargeWaypoint',
}

export interface PhotoDb {
  id?: number;
  photo: File;
  waypointIds: number[];
  routeGroupItemName?: string;
  routeGroupName?: string;
  routeGroupItemId?: number;
  serverFilePath?: string;
  timestamp?: number;
  isBasicAppMode?: boolean;
  entity?: PhotoEntity;
}

export class PhotoDB extends Dexie {
  photos: Dexie.Table<PhotoDb, number>;

  constructor() {
    super('photoDB');

    this.version(1).stores({
      photos:
        '++id, photo, waypointIds, routeGroupItemName, routeGroupName, routeGroupItemId, serverFilePath, timestamp, isBasicAppMode, entity',
    });
  }
}

export const photoDB = new PhotoDB();
