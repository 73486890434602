import { RadioGroup as BaseRadioGroup } from '@headlessui/react';
import { cn } from '@/utils/cn';
import { RadioButtonChecked } from '@/components/icons/RadioButtonChecked';
import { RadioButtonUnchecked } from '@/components/icons/RadioButtonUnchecked';

export type RadioOption = {
  key: string | number;
  value: string | number | boolean;
  label?: string;
};

interface RadioGroupProps<T> {
  value: T;
  onChange: (val: T) => void;
  options: (T & RadioOption)[];
  renderLabel?: (option: T) => React.ReactNode;
  renderDescription?: (option: T) => React.ReactNode;
  label?: string;
  outerClassName?: string;
  optionClassName?: string;
  iconAfterText?: boolean;
  renderIcon?: (option: T) => React.ReactNode;
}

export function RadioGroup<T>({
  value,
  label,
  onChange,
  options,
  renderLabel,
  renderDescription,
  outerClassName,
  optionClassName,
  iconAfterText,
  renderIcon,
}: RadioGroupProps<T>) {
  return (
    <BaseRadioGroup value={value} by={'key' as any} onChange={onChange} className={cn('', outerClassName)}>
      <BaseRadioGroup.Label>{label}</BaseRadioGroup.Label>
      <div className={cn('flex flex-col gap-2 md:flex-row flex-1 ', optionClassName)}>
        {options.map((option) => (
          <BaseRadioGroup.Option
            key={option.key}
            value={option}
            className={({ active, checked }) =>
              cn(' relative flex flex-1 cursor-pointer rounded-lg px-2 py-1 shadow-md focus:outline-none max-h-20 bg-white', {
                'ring-4 ring-blue-800 bg-white': checked,
                'bg-gray-200': active,
              })
            }
          >
            {({ active, checked }) => (
              <>
                <div className="gap-x-2 flex items-center w-full">
                  <div
                    className={cn('flex items-center gap-x-2 order-2', {
                      'order-1': iconAfterText,
                    })}
                  >
                    {renderIcon && renderIcon(option)}
                    <BaseRadioGroup.Label
                      as="p"
                      className={cn('text-xl text-black', {
                        'font-semibold': checked,
                      })}
                    >
                      {renderLabel && renderLabel(option)}
                    </BaseRadioGroup.Label>
                    <BaseRadioGroup.Description as="span" className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'}`}>
                      {renderDescription && renderDescription(option)}
                    </BaseRadioGroup.Description>
                  </div>

                  <div
                    className={cn('relative w-6 h-6 text-blue-900 shrink-0 order-1', {
                      'order-2': iconAfterText,
                    })}
                  >
                    <RadioButtonUnchecked
                      className={cn('absolute opacity-100', {
                        'opacity-0': checked,
                      })}
                    />
                    <RadioButtonChecked
                      className={cn('absolute opacity-0', {
                        'opacity-100': checked,
                      })}
                    />
                  </div>
                </div>
              </>
            )}
          </BaseRadioGroup.Option>
        ))}
      </div>
    </BaseRadioGroup>
  );
}

function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <circle cx={12} cy={12} r={12} fill="#currentColor" opacity="0.2" />
      <path d="M7 13l3 3 7-7" stroke="#currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
