import Dexie from 'dexie';

export enum WaypointNotesEntity {
  DischargeWaypoint = 'dischargeWaypoint',
  ServiceWaypoint = 'serviceWaypoint',
}

export interface WaypointsNotesRequest {
  id?: number;
  waypointIds?: number[];
  dischargeNoteIds?: number[];
  additionalNotes: string;
  routeGroupItemId?: number;
  entity?: WaypointNotesEntity;
}

export class WaypointsNotesRequestsDb extends Dexie {
  public requests: Dexie.Table<WaypointsNotesRequest, number>;

  public constructor() {
    super('waypointsNotesRequestsDb');

    this.version(1).stores({
      requests: '++id, waypointIds, dischargeNoteIds, additionalNotes, routeGroupItemId, entity',
    });
  }
}

export const waypointsNotesRequestsDb = new WaypointsNotesRequestsDb();
