import React, { useEffect, useState } from 'react';
import { ColorType, SizeType } from '../../types/common';
import styles from '../../assets/styles/button.module.scss';
import Spinner from './Spinner';
import { useSelector } from 'react-redux';
import { selectIsAppDisabled } from '../../store/settings/slice';
import { cn } from '@/utils/cn';

import { ButtonHTMLAttributes } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text?: string | JSX.Element;
  color?: ColorType;
  size?: SizeType;
  icon?: JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  pending?: boolean;
  wide?: boolean;
  className?: string;
  innerClassName?: string;
};

const buttonConfigs = {
  primary: {
    bgColor: 'bg-blue-500',
    hoverColor: 'hover:bg-blue-700',
    color: 'text-white dark:text-gray-100',
    ring: 'focus:ring-blue-500',
  },
  danger: {
    bgColor: 'bg-red-600',
    hoverColor: 'hover:bg-red-700',
    color: 'text-white dark:text-gray-100',
    ring: 'focus:ring-red-500',
  },
  ['danger-dark']: {
    bgColor: 'bg-red-700',
    hoverColor: 'hover:bg-red-800',
    color: 'text-white dark:text-gray-100',
    ring: 'focus:ring-red-600',
  },
  warning: {
    bgColor: 'bg-orange-500',
    hoverColor: 'hover:bg-orange-600',
    color: 'text-white dark:text-gray-100',
    ring: 'focus:ring-orange-500',
  },
  disabled: {
    bgColor: 'bg-gray-400 dark:bg-gray-600',
    color: 'text-white dark:text-gray-100',
    ring: 'focus:ring-gray-500',
  },
  success: {
    bgColor: 'bg-green-600',
    hoverColor: 'hover:bg-green-700',
    color: 'text-white dark:text-gray-100',
    ring: 'focus:ring-green-500',
  },
};

const buttonSizesConfig = {
  sm: 'min-h-[2rem] p-2',
  md: 'min-h-[3.5rem] w-56',
  lg: 'min-h-[4.5rem] w-56',
  xl: 'min-h-[5.5rem] w-56',
  '2xl': 'min-h-[6.5rem] w-56',
};
const textSizeConfig = {
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-xl',
  xl: 'text-2xl',
  '2xl': 'text-3xl',
};

const mapButtonColor = (color: ColorType): string => Object.values(buttonConfigs[color]).join(' ');

const Button = ({ text, color, size, icon, onClick, disabled, pending, wide, className, innerClassName, ...rest }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cn(
        'flex gap-2 m-0 items-center w-auto transition ease-in duration-200 text-center text-base font-semibold shadow-md relative',
        'focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md ',
        {
          [buttonSizesConfig[size || 'sm']]: true,
          [textSizeConfig[size || 'sm']]: true,
          [mapButtonColor(disabled ? 'disabled' : color ?? 'primary')]: true,
          'cursor-not-allowed bg-opacity-80': disabled,

          'w-full': wide,
        },
        className,
      )}
      {...rest}
    >
      <div
        className={cn(
          'flex flex-grow justify-center items-center gap-2',
          {
            'opacity-0': pending,
          },
          innerClassName,
        )}
      >
        {icon ?? null}
        {text}
      </div>
      {pending ? (
        <div className={styles.spinnerWrapper}>
          <Spinner size={size} color={color} />
        </div>
      ) : null}
    </button>
  );
};

export default Button;
