import { getTodayDate } from '@/helpers/dateHelper';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { Feature } from '@/types/features';
import {
  CreateCarAcceptanceDto,
  GetCarAcceptanceDto,
  useCreateCarAcceptanceMutation,
  useGetCarAcceptanceQuery,
} from '../api/cars-acceptance.api';
import { useCallback, useEffect, useMemo } from 'react';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useRollbar } from '@rollbar/react';
import { selectUserId } from '@/features/auth';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { useAppMode } from '@/hooks/useAppMode';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLiveQuery } from 'dexie-react-hooks';
import { carAcceptanceRequestsDb } from '@/database';

export const useCarAcceptances = ({ carIds, driverIds }: GetCarAcceptanceDto) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isOffline = useOfflineStatus();
  const userId = useSelector(selectUserId);
  const { isBasicMode } = useAppMode();
  const { t } = useTranslation('carCheckUpModal');

  const { data, isLoading, isFetching, refetch } = useGetCarAcceptanceQuery(
    {
      carIds,
      driverIds,
      startDate: getTodayDate(),
      endDate: getTodayDate(),
    },
    {
      skip: !isFeatureEnabled(Feature.CarCheckUp) || !carIds?.length || !driverIds?.length || isBasicMode,
    },
  );

  const [createCarAcceptanceNetworkRequest, { requestId }] = useCreateCarAcceptanceMutation();

  const rollbar = useRollbar();

  const acceptanceOfflineData = useLiveQuery(() => carAcceptanceRequestsDb.requests.toArray());

  const acceptanceCarIds = useMemo(
    () => (data ?? []).map((item) => item.carId).concat(acceptanceOfflineData?.map((item) => item.payload.carId) ?? []),
    [data, acceptanceOfflineData],
  );
  const isCheckedUp = useCallback(
    (carId: number) => {
      return acceptanceCarIds.includes(carId) || false;
    },
    [acceptanceCarIds],
  );

  const createCarAcceptance = useCallback(
    async (payload: CreateCarAcceptanceDto) => {
      const table = carAcceptanceRequestsDb.requests;

      if (!navigator.onLine) {
        await table.add({ type: 'create', payload });
        return;
      }

      try {
        await createCarAcceptanceNetworkRequest(payload).unwrap();
      } catch (error) {
        toast(t('error.addCheckUpFailedOffline'), { type: 'error' });
        rollbar.error('[CarAcceptance] HTTP Request failed', error as Error, payload, { userId });
        await table.add({ type: 'create', payload });
      }
    },
    [createCarAcceptanceNetworkRequest, t, rollbar, userId],
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
    createCarAcceptance,
    isCheckedUp,
  };
};
