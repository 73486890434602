import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

interface QrReaderProps {
  onDecode: (result: QrScanner.ScanResult) => void;
}

const MAX_SCANS_PER_SECOND = 1;

const QrReader = ({ onDecode }: QrReaderProps) => {
  const [scannerInstance, setScannerInstance] = useState<QrScanner>(null);
  const videoRef = useRef();

  useEffect(() => {
    QrScanner.hasCamera().then((hasCamera) => {
      if (!hasCamera) {
        return;
      }

      setScannerInstance(
        new QrScanner(videoRef.current, onDecode, {
          highlightScanRegion: true,
          maxScansPerSecond: MAX_SCANS_PER_SECOND,
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (!scannerInstance) {
      return;
    }

    scannerInstance.start();

    return () => {
      scannerInstance.stop();
      scannerInstance.destroy();
      setScannerInstance(null);
    };
  }, [scannerInstance]);

  return <video ref={videoRef} className={'h-64 w-64 border border-gray-400 rounded object-cover'}></video>;
};

export default QrReader;
