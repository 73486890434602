import React from 'react';
import WaypointDischargeListItem from './WaypointDischargeListItem';
import WaypointServiceListItem from './WaypointServiceListItem';
import { UnloadingBaseWaypoint, Waypoint, WaypointType } from '@/features/home';
import { WaypointUnloadingAndBaseListItem } from './WaypointUnloadingAndBaseListItem';
import { cn } from '@/utils/cn';
import { selectSelectedWaypointIds } from '@/store/waypoints/slice';
import { useSelector } from 'react-redux';

interface WaypointsListGroupProps {
  sortedWaypointGroups: (Waypoint[] | UnloadingBaseWaypoint[])[];
  onWaypointClick: (waypointIds: number[]) => void;
}

const WaypointsListGroup = ({ sortedWaypointGroups, onWaypointClick }: WaypointsListGroupProps) => {
  const selectedWaypointIds = useSelector(selectSelectedWaypointIds);

  const handleClickOnList = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLElement;
    const waypointGroupElement = target.closest<HTMLDivElement>('div[data-waypoint-ids]');

    if (!waypointGroupElement) {
      return;
    }

    const waypointIds = waypointGroupElement.dataset['waypointIds'].split(',').map(Number);
    onWaypointClick && onWaypointClick(waypointIds);
  };

  return (
    <div className={cn('tablet-page__waypoints-list', 'pb-5')} onClick={handleClickOnList}>
      {sortedWaypointGroups.map((group, index) => {
        const groupType = group?.[0]?.type;

        if (!groupType) {
          return null;
        }

        if (groupType === WaypointType.Discharge) {
          return (
            <WaypointDischargeListItem
              key={`${group?.[0]?.id}-discharge-waypoint-list-item`}
              waypointsGroup={group as Waypoint<WaypointType.Discharge>[]}
              selectedWaypointIds={selectedWaypointIds}
            />
          );
        }

        if (groupType === WaypointType.Service) {
          return (
            <WaypointServiceListItem
              key={`${group?.[0]?.id}-service-waypoint-list-item`}
              waypointsGroup={group as Waypoint<WaypointType.Service>[]}
              selectedWaypointIds={selectedWaypointIds}
            />
          );
        }

        return <WaypointUnloadingAndBaseListItem key={index} type={groupType} />;
      })}
    </div>
  );
};

export default WaypointsListGroup;
