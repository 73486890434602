import React, { useContext } from 'react';
import { createContext, PropsWithChildren, useState } from 'react';
import BasicImagesModal, { BasicImageModalItem } from '../components/modals/BasicImagesModal';
import useModal from '../hooks/useModal';

interface ImagesModalContext {
  images: BasicImageModalItem[];
  setImages: (images: BasicImageModalItem[]) => void;
  toggleImagesModal: () => void;
}

const imagesModalContext = createContext<ImagesModalContext>({
  images: [],
  setImages: (images: BasicImageModalItem[]) => {},
  toggleImagesModal: () => {},
});

export function ImagesModalProvider({ children }: PropsWithChildren) {
  const [images, setImages] = useState<BasicImageModalItem[]>([]);
  const { isVisible, toggle } = useModal();

  return (
    <imagesModalContext.Provider value={{ images, setImages, toggleImagesModal: toggle }}>
      {children}
      <BasicImagesModal isVisible={isVisible} onClose={toggle} images={images} />
    </imagesModalContext.Provider>
  );
}

export function useBasicImagesModalContext() {
  return useContext(imagesModalContext);
}
