import React, { PropsWithChildren } from 'react';
import Header from '../components/navigation/Header';
import Footer from '../components/navigation/Footer';
import { useSelector } from 'react-redux';
import { selectUserState } from '../features/auth/stores/slice';
import { useTranslation } from 'react-i18next';

const MainLayout = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const userState = useSelector(selectUserState);

  return (
    <div className="main-layout">
      <Header title={t('greetings', { userName: `${userState?.firstname} ${userState?.surname}`, ns: 'header' })} />
      <div className="main-wrapper">{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
