import { MutableRefObject, useEffect } from 'react';

export function useOutsideClick(
  refs: MutableRefObject<HTMLElement>[] = [],
  callback: () => void,
  excludeRefs: MutableRefObject<HTMLElement>[] = [],
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutside = refs.every((ref) => {
        if (ref.current) {
          return !ref.current.contains(event.target as Node);
        }
        return true;
      });

      const isClickInsideExcluded = excludeRefs.some((excludeRef) => excludeRef.current?.contains(event.target as Node));
      if (isClickOutside && !isClickInsideExcluded) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [refs, excludeRefs, callback]);
}
