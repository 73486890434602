import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const RouteIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      fill="currentColor"
      className={iconStyles[iconSizeClasses[size]]}
      height="800px"
      width="800px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M14,11c-0.737,0-1.375,0.405-1.722,1H3.5C2.673,12,2,11.327,2,10.5S2.673,9,3.5,9h9C14.43,9,16,7.43,16,5.5S14.43,2,12.5,2
		H3.722C3.375,1.405,2.737,1,2,1C0.897,1,0,1.897,0,3s0.897,2,2,2c0.737,0,1.375-0.405,1.722-1H12.5C13.327,4,14,4.673,14,5.5
		S13.327,7,12.5,7h-9C1.57,7,0,8.57,0,10.5S1.57,14,3.5,14h8.778c0.347,0.595,0.985,1,1.722,1c1.103,0,2-0.897,2-2S15.103,11,14,11z
		"
        />
      </g>
    </svg>
  );
};

export default RouteIcon;
