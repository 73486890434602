import { extendedBaseQuery } from '@/lib/http.service';
import { createApi } from '@reduxjs/toolkit/query/react';

export const JOIN_CHAT_KEY = 'joinChat';
export const CAR_CHECKS_OPTIONS_KEY = 'carChecksOptions';
export const ROUTE_GROUP_ITEMS_KEY = 'routeGroupItems';
export const ALL_DEVICE_TASKS_KEY = 'allDeviceTasks';
export const CAR_ACCEPTANCE_KEY = 'carAcceptance';
export const MESSAGE_HISTORY_KEY = 'messageHistory';
export const NEW_MESSAGE_KEY = 'newMessage';
export const SERVICE_WAYPOINTS_KEY = 'serviceWaypoints';
export const WAYPOINTS_KEY = 'waypoints';
export const ROUTE_PLANNER_KEY = 'routePlanner';
export const UNLOADING_KEY = 'unloading';
export const CONSTRUCTION_ROUTES = 'constructionRoutes';
export const CONSTRUCTION_TASKS = 'constructionTasks';

export const baseApi = createApi({
  baseQuery: extendedBaseQuery,
  reducerPath: 'generalPurposeAPI',
  tagTypes: [],
  endpoints: () => ({}),
  refetchOnFocus: false,
  refetchOnReconnect: false,
  refetchOnMountOrArgChange: false,
  keepUnusedDataFor: Infinity,
});
