import { SpecialWaypointServiceItem } from '../types/common';
import { Waypoint, WaypointType } from '../features/home/types/routeGroupItems';

export const SPECIAL_SERVICE_ITEM_IDS = {
  [SpecialWaypointServiceItem.Discharge]: 'dischargesCount',
  [SpecialWaypointServiceItem.DynamicCapacity]: 'dynamicCapacity',
  [SpecialWaypointServiceItem.ManualWeight]: 'weight',
};

export const METERS_AMOUNT_TO_DISABLE_SET_COORDINATES_BUTTON = 200000;

export const MAIN_DISCHARGE_WAYPOINT_KEYS_TO_CHECK: (keyof Waypoint<WaypointType.Discharge>)[] = ['done', 'fail', 'isFiltered'];
export const MAIN_SERVICE_WAYPOINT_KEYS_TO_CHECK: (keyof Waypoint<WaypointType.Service>)[] = ['doneAt', 'isFailed', 'isFiltered'];
