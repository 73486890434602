import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../index';
import { City, County, Garbage, GarbageGroup, ItemType, Kojv, Village } from '@ekt-group/general-purpose-api-interfaces';

type CommonItemName = 'cities' | 'counties' | 'villages' | 'itemTypes' | 'garbage' | 'garbageGroups' | 'kojv';
type CommonItem = City | County | Village | ItemType | Garbage | GarbageGroup | Kojv;

type CommonItemReturnMap<Name extends CommonItemName> = Name extends 'cities'
  ? Record<string | number, City>
  : Name extends 'counties'
  ? Record<string | number, County>
  : Name extends 'villages'
  ? Record<string | number, Village>
  : Name extends 'itemTypes'
  ? Record<string | number, ItemType>
  : Name extends 'garbage'
  ? Record<string | number, Garbage>
  : Name extends 'garbageGroups'
  ? Record<string | number, GarbageGroup>
  : Name extends 'kojv'
  ? Record<string | number, Kojv>
  : never;

interface CommonItemState<Key extends CommonItemName> {
  map: CommonItemReturnMap<Key> | null;
  items: CommonItem[];
}

type CommonState = {
  [key in CommonItemName]: CommonItemState<key>;
};

const getInitialState = <T extends CommonItemName>(): CommonItemState<T> => {
  return {
    map: null,
    items: [],
  };
};

const initialState: CommonState = {
  cities: getInitialState(),
  counties: getInitialState(),
  villages: getInitialState(),
  itemTypes: getInitialState(),
  garbage: getInitialState(),
  garbageGroups: getInitialState(),
  kojv: getInitialState(),
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCommonItems: <Name extends CommonItemName>(state: CommonState, action: PayloadAction<{ name: Name; items: CommonItem[] }>) => {
      const { name, items } = action.payload;
      state[name].items = items;
      state[name].map = items.reduce<CommonItemReturnMap<Name>>((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as CommonItemReturnMap<Name>);
    },
    resetCommonState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setCommonItems, resetCommonState } = commonSlice.actions;

export const selectCommonItemMap =
  <Name extends CommonItemName>(name: Name) =>
  (state: AppState) =>
    state.common[name].map;

export default commonSlice.reducer;
