import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { IconProps, iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';
import { cn } from '@/utils/cn';

const TruckIcon = ({ size, className }: IconProps) => {
  return (
    <svg
      height="800px"
      width="800px"
      version="1.1"
      className={cn(iconStyles[iconSizeClasses[size]], className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="currentColor"
          d="M497.568,60.446H252.044c-7.972,0-14.426,6.46-14.426,14.433v226.703c0,7.972,6.454,14.432,14.426,14.432
		h245.524c7.972,0,14.432-6.46,14.432-14.432V74.879C512,66.906,505.54,60.446,497.568,60.446z"
        />
        <path
          fill="currentColor"
          d="M116.683,354.34c-26.836,0-48.606,21.764-48.606,48.6c0,26.85,21.77,48.614,48.606,48.614
		c26.844,0,48.608-21.764,48.608-48.614C165.29,376.104,143.526,354.34,116.683,354.34z M116.683,424.826
		c-12.079,0-21.871-9.799-21.871-21.886c0-12.073,9.792-21.865,21.871-21.865c12.08,0,21.872,9.792,21.872,21.865
		C138.555,415.027,128.763,424.826,116.683,424.826z"
        />
        <path
          fill="currentColor"
          d="M403.8,354.34c-26.836,0-48.6,21.764-48.6,48.6c0,26.85,21.764,48.614,48.6,48.614
		c26.843,0,48.606-21.764,48.606-48.614C452.406,376.104,430.643,354.34,403.8,354.34z M403.8,424.826
		c-12.073,0-21.865-9.799-21.865-21.886c0-12.073,9.792-21.865,21.865-21.865c12.079,0,21.871,9.792,21.871,21.865
		C425.671,415.027,415.879,424.826,403.8,424.826z"
        />
        <path
          fill="currentColor"
          d="M200.119,128.623H90.502c-3.561,0-6.957,1.582-9.23,4.331l-78.48,94.163C0.986,229.268,0,231.995,0,234.815
		v82.595v43.19c0,6.648,5.389,12.029,12.03,12.029h36.836c11.634-25.9,37.629-44.024,67.817-44.024
		c30.196,0,56.183,18.124,67.81,44.024h27.671V140.652C212.163,134.003,206.767,128.623,200.119,128.623z M43.931,236.053
		c0-2.849,0.978-5.612,2.777-7.82l50.103-61.694c2.36-2.907,5.9-4.59,9.634-4.59h49.074c6.857,0,12.404,5.554,12.404,12.411v70.011
		c0,6.849-5.547,12.404-12.404,12.404H56.327c-6.842,0-12.396-5.554-12.396-12.404V236.053z"
        />
        <path
          fill="currentColor"
          d="M243.532,338.792c-3.741,0-6.763,3.029-6.763,6.77v20.303c0,3.735,3.022,6.763,6.763,6.763h92.466
		c6.374-14.209,17.072-26.023,30.419-33.836H243.532z"
        />
        <path
          fill="currentColor"
          d="M504.381,338.792h-63.19c13.346,7.814,24.045,19.627,30.419,33.836h32.771c3.741,0,6.763-3.028,6.763-6.763
		v-20.303C511.144,341.821,508.122,338.792,504.381,338.792z"
        />
      </g>
    </svg>
  );
};

export default TruckIcon;
