import InstallIcon from '../icons/InstallIcon';
import React from 'react';

const InstallContainerBadge = () => (
  <div className="bg-teal-500 rounded-full text-white">
    <InstallIcon size={'md'} />
  </div>
);

export default InstallContainerBadge;
