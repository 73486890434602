import React, { useEffect, useState } from 'react';
import { useMessages } from '@/features/messages';
import Spinner from '@/components/elements/Spinner';
import { useTranslation } from 'react-i18next';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import MessageListItem from '../components/MessageListItem';

export const MessagesPage = () => {
  const [openedMessageId, setOpenedMessageId] = useState(null);
  const { t } = useTranslation('messages');
  const { messages, isOldMessagesLoading } = useMessages();
  const { replaceNavigationWithBackButton } = useAdvancedNavigationItems();

  useEffect(() => {
    replaceNavigationWithBackButton();
  }, [replaceNavigationWithBackButton]);

  return (
    <div className="messages-page">
      <h1 className="messages-page__title">{t('messagesLabel')}</h1>
      <div className="messages-page__list">
        {isOldMessagesLoading ? (
          <Spinner size={'md'} />
        ) : messages.length > 0 ? (
          messages.map((message) => (
            <MessageListItem
              message={message}
              onClick={() => setOpenedMessageId(message.id)}
              isOpened={message.id === openedMessageId}
              key={message.id}
            />
          ))
        ) : (
          <div className="messages-page__no-items">{t('noMessages')}</div>
        )}
      </div>
    </div>
  );
};
