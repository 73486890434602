import { useAppDispatch } from '@/store/types';
import { accidentChatApi } from '@/features/accident-chat';
import { AccidentChatAuthDto } from '@ekt-group/general-purpose-api-interfaces';

export function useAccidentChatMessages({ chatToken }: AccidentChatAuthDto) {
  const dispatch = useAppDispatch();

  const addMessageToStore = ({ content, title }: { content: string; title?: string }) => {
    const originalArgs: AccidentChatAuthDto = {
      chatToken,
    };

    const newMessage = { header: title, content, timestamp: new Date().toISOString(), isRead: false };

    dispatch(accidentChatApi.util.updateQueryData('getMessages', originalArgs, (data) => [...data, newMessage]));
  };

  return { addMessageToStore };
}
