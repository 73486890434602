import React from 'react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

// this makes array[] becomes array=1,2,3
export const cleanParams = (config: AxiosRequestConfig<any>): AxiosRequestConfig<any> => {
  if (config.params) {
    Object.keys(config.params).forEach((key) => {
      if (Array.isArray(config.params[key])) {
        if (config.params[key].length === 1) {
          config.params[key] = config.params[key][0];
        } else {
          config.params[key] = config.params[key].join(',');
        }
      }
    });
  }
  return config;
};

export const onFulfilledResponse = (response: AxiosResponse<any, any>): any => {
  return response;
};

export const onFailedResponse = (error: any) => {
  if (!navigator.onLine) {
    return;
  }

  if (error instanceof AxiosError) {
    if (error.response?.status === 401) {
      window.location.href = '/login';
      return;
    }

    if (error.config.url === '/external/ors-route') {
      return;
    }

    const isErrorMessageString = typeof error.response?.data?.message === 'string';
    const message = isErrorMessageString ? error.response?.data?.message : error.response?.statusText || 'Error occurred';
    const traceId = error.response?.headers?.['x-trace-id'];

    const toastContent = (
      <div className="flex flex-col gap-1">
        <span className="flex leading-5">{message}</span>
        {traceId && <p className={'font-bold text-xs'}>Trace ID: {traceId}</p>}
      </div>
    );

    toast(toastContent, { type: 'error' });
  }

  throw error;
};
