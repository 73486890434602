export function isEmptyObject(obj: any) {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '' && !Array.isArray(obj[key])) {
      return false;
    }
    if (Array.isArray(obj[key]) && obj[key].length !== 0) {
      return false;
    }
  }
  return true;
}
