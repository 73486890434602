import httpService from '../lib/http.service';
import { City, County, Village, ItemType, Garbage, GarbageGroup } from '@ekt-group/general-purpose-api-interfaces';

export default {
  async getCities() {
    const { data } = await httpService.get<City[]>('/cities');
    return data;
  },
  async getCounties() {
    const { data } = await httpService.get<County[]>('/counties');
    return data;
  },
  async getVillages() {
    const { data } = await httpService.get<Village[]>('/villages');
    return data;
  },
  async getItemTypes() {
    const { data } = await httpService.get<ItemType[]>('/item-types');
    return data;
  },
  async getGarbage() {
    const { data } = await httpService.get<Garbage[]>('/garbage');
    return data;
  },
  async getGarbageGroups() {
    const { data } = await httpService.get<GarbageGroup[]>('/garbage-groups');
    return data;
  },
};
