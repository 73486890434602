import Spinner from '@/components/elements/Spinner';
import { cn } from '@/utils/cn';
import { RouteGroupItemPlanning, RouteGroupItemPlanningService } from '@ekt-group/general-purpose-api-interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SortingModalPlanningItemProps {
  optimizedItem: RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>;
  onClick?: (optimizedItem: RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>) => void;
  isLoadingPlanning?: boolean;
  isPlanningSelected?: boolean;
}

export const SortingModalPlanningItem = ({
  optimizedItem,
  onClick,
  isLoadingPlanning,
  isPlanningSelected,
}: SortingModalPlanningItemProps) => {
  const { t } = useTranslation();

  if (isLoadingPlanning) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner size={'xl'} />
      </div>
    );
  }

  if (!optimizedItem && !isLoadingPlanning) {
    return null;
  }

  const planningInfo = optimizedItem?.planning.response;

  const distanceInKm = Math.round(planningInfo.summary.distance / 1000);
  // because we're taking off the base waypoint
  const numberOfUnloads = planningInfo.summary.routes - 1;

  return (
    <div
      onClick={() => onClick(optimizedItem)}
      key={optimizedItem.id}
      className={cn(
        'grid grid-cols-2 relative items-center p-2 border border-gray-400 dark:border-gray-700 rounded text-base md:text-xl cursor-pointer',
        {
          'bg-green-600 text-white': isPlanningSelected,
        },
      )}
    >
      <div className={'flex items-center gap-2 md:flex-col md:items-start md:gap-0'}>
        <span>{t('distance')}:</span>
        <span className="font-bold">{distanceInKm}km</span>
      </div>
      <div className={'flex items-center gap-2 md:flex-col md:items-start md:gap-0'}>
        <span>
          {t('sortingModal.unload', {
            ns: 'tabletPage',
          })}
        </span>
        <span className="font-bold">{numberOfUnloads}</span>
      </div>
    </div>
  );
};
