import { Checkbox } from '@/components/elements/headlessui/Checkbox';
import { cn } from '@/utils/cn';
import { Garbage, ItemType, Kojv, KojvService } from '@ekt-group/general-purpose-api-interfaces';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useWaypointOptionsForForm } from '../../../hooks/waypoints/useWaypointOptionsForForm';
import Button from '@/components/elements/Button';

export interface WaypointListFormData {
  itemTypes: ItemType[] | undefined;
  garbages: Garbage[] | undefined;
  hasKey: boolean;
  hasMobileGate: boolean;
  specialServiceTime: boolean;
  kojv: Kojv[] | undefined;
  kojvTimeSlots: string[] | undefined; //format timeAt - timeEnd
  services: KojvService[] | undefined;
}

interface WaypointListFilteringMenuFormProps {
  onSubmit: (value: WaypointListFormData) => void;
}

// The actual filtering is done in useSortedWaypointGroups, which is located at the parent component, WaypointsList.
export const WaypointListFilteringMenuForm = ({ onSubmit }: WaypointListFilteringMenuFormProps) => {
  const { t } = useTranslation('filterModal');
  const { t: articleT } = useTranslation('articleCodes');

  const defaultValues: WaypointListFormData = {
    itemTypes: [],
    garbages: [],
    hasKey: null,
    hasMobileGate: null,
    specialServiceTime: null,
    kojv: [],
    kojvTimeSlots: [],
    services: [],
  };

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { touchedFields, dirtyFields },
  } = useForm<WaypointListFormData>({
    defaultValues,
  });
  const currentValues = watch();
  const {
    itemTypesOptions,
    garbagesOptions,
    kojvNamesOptions,
    servicesOptions,
    hasKeyOptions,
    serviceTimeOptions,
    timeSlotOptions,
    mobileGateOptions,
  } = useWaypointOptionsForForm();

  const handleItemTypesOptionSelected = (value: boolean, id: number) => {
    if (value) {
      const itemType = itemTypesOptions?.find((itemType) => itemType.item.id === id);
      setValue('itemTypes', [...currentValues.itemTypes, itemType.item], { shouldTouch: true });
      return;
    }
    setValue(
      'itemTypes',
      currentValues.itemTypes?.filter((itemType) => itemType.id !== id),
    );
  };

  const handleGarbagesOptionSelected = (value: boolean, id: number) => {
    if (value) {
      const garbage = garbagesOptions?.find((garbage) => garbage.item.id === id);
      setValue('garbages', [...currentValues.garbages, garbage.item], { shouldTouch: true });
      return;
    }
    setValue(
      'garbages',
      currentValues.garbages?.filter((garbage) => garbage.id !== id),
      { shouldTouch: true },
    );
  };

  const handleKojvOptionSelected = (value: boolean, id: number) => {
    if (value) {
      const kojv = kojvNamesOptions?.find((kojv) => kojv.item.id === id);
      setValue('kojv', [...currentValues.kojv, kojv.item], { shouldTouch: true });
      return;
    }
    setValue(
      'kojv',
      currentValues.kojv?.filter((kojv) => kojv.id !== id),
      { shouldTouch: true },
    );
  };

  const handleServicesOptionSelected = (value: boolean, id: number) => {
    if (value) {
      const service = servicesOptions?.find((service) => service.item.id === id);
      setValue('services', [...currentValues.services, service.item]);
      return;
    }
    setValue(
      'services',
      currentValues.services?.filter((service) => service.id !== id),
    );
  };

  const checkIfOptionChecked = (value: number | string | boolean, type: keyof WaypointListFormData): boolean => {
    if (type === 'itemTypes') {
      return !!currentValues.itemTypes?.find((itemType) => itemType.id === value);
    }
    if (type === 'garbages') {
      return !!currentValues.garbages?.find((garbage) => garbage.id === value);
    }
    if (type === 'kojv') {
      return !!currentValues.kojv?.find((kojv) => kojv.id === value);
    }
    if (type === 'services') {
      return !!currentValues.services?.find((service) => service.id === value);
    }
    if (type === 'hasKey') {
      return currentValues.hasKey === value;
    }
    if (type === 'hasMobileGate') {
      return currentValues.hasMobileGate === value;
    }
    if (type === 'specialServiceTime') {
      return currentValues.specialServiceTime === value;
    }
    if (type === 'kojvTimeSlots') {
      return !!currentValues.kojvTimeSlots?.find((time) => time === value);
    }

    return false;
  };

  const handleHasKeyChange = (value: boolean) => {
    if (currentValues.hasKey === value) {
      setValue('hasKey', null);
      return;
    }
    setValue('hasKey', value);
  };

  const handleHasMobileGateChange = (value: boolean) => {
    if (currentValues.hasMobileGate === value) {
      setValue('hasMobileGate', null);
      return;
    }
    setValue('hasMobileGate', value);
  };

  const handleSpecialServiceTimeChange = (value: boolean) => {
    if (currentValues.specialServiceTime === value) {
      setValue('specialServiceTime', null);
      return;
    }
    setValue('specialServiceTime', value);
  };

  const handleTimeslotOptionSelected = (value: boolean, timeslot: string) => {
    if (value) {
      setValue('kojvTimeSlots', [...currentValues.kojvTimeSlots, timeslot], { shouldTouch: true });
      return;
    }
    setValue(
      'kojvTimeSlots',
      currentValues.kojvTimeSlots?.filter((time) => time !== timeslot),
      { shouldTouch: true },
    );
  };

  const handleSubmitButtonClicked = (value: WaypointListFormData) => {
    onSubmit(value);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitButtonClicked)}>
      <div className={cn('flex flex-col gap-y-8 w-full h-fit pb-5 mb-44 md:mb-24')}>
        <div className="w-full h-full">
          <label className="md:text-xl dark:text-white block pb-2 mb-2 text-lg border-b-2">{t('item_type.name')}</label>

          <div className="gap-y-3 flex flex-col">
            {itemTypesOptions.map(({ item, quantity, capacity }) => {
              const checked = checkIfOptionChecked(item.id, 'itemTypes');

              return (
                <Checkbox
                  key={item.id}
                  checked={checked}
                  label={`${item.name} - ${capacity.toFixed(2).replace('.', ',')}m³ (${quantity}) `}
                  onChange={(checked) => handleItemTypesOptionSelected(checked, item.id)}
                />
              );
            })}
          </div>
        </div>

        <div className="w-full">
          <label className="md:text-xl dark:text-white block pb-2 mb-2 text-lg border-b-2">{t('garbage.name')}</label>
          <div className="gap-y-3 flex flex-col">
            {garbagesOptions.map(({ item, quantity }) => {
              const checked = checkIfOptionChecked(item.id, 'garbages');
              return (
                <Checkbox
                  key={item.id}
                  checked={checked}
                  label={`${item.name} (${quantity}) `}
                  onChange={(checked) => handleGarbagesOptionSelected(checked, item.id)}
                />
              );
            })}
          </div>
        </div>

        <div className="w-full">
          <label className="md:text-xl dark:text-white block pb-2 mb-2 text-lg border-b-2">{t('has_key')}</label>
          <div className="gap-y-3 flex flex-col">
            {hasKeyOptions.map(({ item: { key, label, value }, quantity }) => {
              const checked = checkIfOptionChecked(value, 'hasKey');

              return (
                <Checkbox
                  key={key}
                  checked={checked}
                  label={`${label} (${quantity})`}
                  onChange={() => handleHasKeyChange(value as boolean)}
                />
              );
            })}
          </div>
        </div>

        <div className="w-full">
          <label className="md:text-xl dark:text-white block pb-2 mb-2 text-lg border-b-2">{t('has_mobile_gate')}</label>
          <div className="gap-y-3 flex flex-col">
            {mobileGateOptions.map(({ item: { key, label, value }, quantity }) => {
              const checked = checkIfOptionChecked(value, 'hasMobileGate');

              return (
                <Checkbox
                  key={key}
                  checked={checked}
                  label={`${label} (${quantity})`}
                  onChange={() => handleHasMobileGateChange(value as boolean)}
                />
              );
            })}
          </div>
        </div>

        <div className="w-full">
          <label className="md:text-xl dark:text-white block pb-2 mb-2 text-lg border-b-2">{t('special_service_time')}</label>
          <div className="gap-y-3 flex flex-col">
            {serviceTimeOptions.map(({ item: { key, label, value }, quantity }) => {
              const checked = checkIfOptionChecked(value, 'specialServiceTime');

              return (
                <Checkbox
                  key={key}
                  checked={checked}
                  label={`${label} (${quantity})`}
                  onChange={() => handleSpecialServiceTimeChange(value as boolean)}
                />
              );
            })}
          </div>
        </div>

        <div className="w-full">
          <label className="md:text-xl dark:text-white block pb-2 mb-2 text-lg border-b-2">{t('kojv.name')}</label>
          <div className="gap-y-3 flex flex-col">
            {kojvNamesOptions.map(({ item, quantity }) => {
              const checked = checkIfOptionChecked(item.id, 'kojv');

              return (
                <Checkbox
                  key={item.id}
                  checked={checked}
                  label={`${item.name} (${quantity}) `}
                  onChange={(checked) => handleKojvOptionSelected(checked, item.id)}
                />
              );
            })}
          </div>
        </div>

        <div className="gap-y-2 flex flex-col w-full">
          <label className="md:text-xl dark:text-white block pb-2 mb-2 text-lg border-b-2">{t('kojv.time_slots')}</label>

          <div className="gap-y-3 flex flex-col">
            {timeSlotOptions.map(({ item, quantity }) => {
              const checked = checkIfOptionChecked(item, 'kojvTimeSlots');
              return (
                <Checkbox
                  key={item}
                  checked={checked}
                  label={`${item} (${quantity}) `}
                  onChange={(checked) => handleTimeslotOptionSelected(checked, item)}
                />
              );
            })}
          </div>
        </div>

        <div className="w-full">
          <label className="md:text-xl dark:text-white block pb-2 mb-2 text-lg border-b-2">{t('kojv_service.name')}</label>

          <div className="gap-y-3 flex flex-col">
            {servicesOptions.map(({ item, quantity }) => {
              const checked = checkIfOptionChecked(item.id, 'services');
              return (
                <Checkbox
                  key={item.id}
                  checked={checked}
                  label={`${articleT(item.articleCode)} (${quantity}) `}
                  onChange={(checked) => handleServicesOptionSelected(checked, item.id)}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* Width must be the same as its parent in WaypointListFilteringMenu (w-2/4). Maybe in the future we can move that to state */}
      <div
        className={cn(
          'w-5/6 md:w-3/4 lg:w-2/4 fixed bg-gray-200 dark:bg-slate-900 px-4 py-4 bottom-0 right-0 flex flex-col md:flex-row gap-x-4 gap-y-3 shadow-[0_-5px_5px_0_rgba(0,0,0,.14)]',
        )}
      >
        <Button className="md:text-lg flex-1 text-base" text={'Reset'} color={'disabled'} onClick={() => reset()} />
        <Button className="md:text-lg flex-1 text-base" text={'Submit'} color={'success'} type="submit" />
      </div>
    </form>
  );
};
