import React from 'react';
import { getReadableDateTime } from '@/helpers/dateHelper';
import TruckIcon from '@/components/icons/TruckIcon';
import LoaderIcon from '@/components/icons/LoaderIcon';
import ClockIcon from '@/components/icons/ClockIcon';
import MobilePhoneIcon from '@/components/icons/MobilePhoneIcon';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';

interface AccidentDescriptionProps {
  title: string;
  carNumber: string;
  problem: string;
  timestamp: string;
  driver: string;
  driverPhone: string;
}

const AccidentDescription = ({ title, carNumber, problem, timestamp, driver, driverPhone }: AccidentDescriptionProps) => {
  const { isMobileScreen } = useWindowDimensions();
  const { t } = useTranslation('accidentChat');

  const handlePhoneClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, phone: string) => {
    event.stopPropagation();
    window.open(`tel:${phone}`, '_self');
  };

  const phoneNumbers = driverPhone.split(',');

  return (
    <div className={'text-gray-900 dark:text-gray-100'}>
      <div className={'flex flex-col gap-5'}>
        <div className={'flex flex-col gap-2'}>
          <span className={'text-lg leading-5 md:leading-6 md:text-2xl font-semibold'}>{title}</span>
          <div className={'flex flex-wrap font-semibold text-base md:text-lg gap-y-2 gap-x-4 md:gap-x-10 md:gap-y-4'}>
            <span className={'flex gap-2 items-center'}>
              <LoaderIcon size={isMobileScreen ? 'sm' : 'md'} />
              <span>{driver}</span>
            </span>
            <span className={'flex gap-2 items-center'}>
              <MobilePhoneIcon size={isMobileScreen ? 'sm' : 'md'} />
              {phoneNumbers.map((phone, index) => (
                <span className={'cursor-pointer'} key={phone} onClick={(event) => handlePhoneClick(event, phone)}>
                  {phone}
                  {index === phoneNumbers.length - 1 ? '' : ','}
                </span>
              ))}
            </span>
            <span className={'flex gap-2 items-center'}>
              <TruckIcon size={isMobileScreen ? 'sm' : 'md'} />
              <span>{carNumber}</span>
            </span>
            <span className={'flex gap-2 items-center'}>
              <ClockIcon size={isMobileScreen ? 'sm' : 'md'} />
              <span>{getReadableDateTime(timestamp, 'datetime')}</span>
            </span>
          </div>
          <div>
            <span className={'md:text-lg font-bold'}>{t('description')}: </span>
            <span className={'md:text-xl'}>{problem}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccidentDescription;
