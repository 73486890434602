import { createApi } from '@reduxjs/toolkit/query/react';
import { AccidentChatJoinDto, AccidentChatJoinResponseDto, AccidentChatMessage } from '@ekt-group/general-purpose-api-interfaces';
import { extendedBaseQuery } from '@/lib/http.service';
import { baseApi, JOIN_CHAT_KEY } from '@/store/baseApi';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [JOIN_CHAT_KEY],
});

export const accidentChatApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    joinChat: builder.query<AccidentChatJoinResponseDto, AccidentChatJoinDto>({
      query(params: AccidentChatJoinDto) {
        return {
          url: '/accident-chat/join',
          method: 'GET',
          params,
        };
      },
      providesTags: [JOIN_CHAT_KEY],
    }),
    getMessages: builder.query<AccidentChatMessage[], AccidentChatJoinDto>({
      query(params: { chatToken: string }) {
        return {
          url: '/accident-chat/messages',
          method: 'GET',
          params,
        };
      },
    }),
  }),
});

export const { useJoinChatQuery, useGetMessagesQuery, useLazyGetMessagesQuery } = accidentChatApi;
