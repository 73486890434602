import { useLiveQuery } from 'dexie-react-hooks';
import { MessagesRequest, messagesRequestsDb } from '@/database/messages-requests.db';
import { useMarkMessageAsReadMutation } from '@/features/messages';
import { useProcessor } from './useProcessor';

export function useMessagesRequestsProcessor() {
  const requests = useLiveQuery(() => messagesRequestsDb.requests.toArray());

  const [markMessageAsRead] = useMarkMessageAsReadMutation();

  const processRequest = async (body: MessagesRequest) => {
    await markMessageAsRead(body).unwrap();
  };

  const deleteRequest = (requestId: number) => messagesRequestsDb.requests.delete(requestId);

  useProcessor<MessagesRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing message request failed',
  });
}
