import React from 'react';
import { DriverTokenDto, TokenUser } from '../../../auth/types';
import Spinner from '../../../../components/elements/Spinner';
import Button from '../../../../components/elements/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  user: TokenUser;
  isLoading: boolean;
  data: DriverTokenDto;
  onClick: (user: TokenUser) => void;
}

const PhotoOnMobileQR = ({ user, data, isLoading, onClick }: Props) => {
  const { t } = useTranslation('arrivedPage');

  const buttonLabel = user === TokenUser.Driver ? t('photo.qrCodeForDriver') : t('photo.qrCodeForLoader');
  const title = user === TokenUser.Driver ? t('driver', { ns: 'common' }) : t('loader', { ns: 'common' });

  const openQrUrl = () => {
    window.open(data.url, '_blank');
  };

  return (
    <div className={'h-72 w-72 centered'}>
      {isLoading && <Spinner size={'md'} />}
      {!isLoading && !data && <Button color={'success'} text={buttonLabel} onClick={() => onClick(user)} wide size={'lg'} />}
      {!isLoading && data && (
        <div className={'centered flex-col'}>
          <span>{title}</span>
          <img className={'h-64 w-64 cursor-pointer'} src={data.qrCode} alt={`${user} QR`} onClick={openQrUrl} />
        </div>
      )}
    </div>
  );
};

export default PhotoOnMobileQR;
