import React, { useCallback, useEffect } from 'react';
import { usePreCache } from '@/hooks/usePreCache';
import { Outlet, useNavigate, useParams } from 'react-router';
import { usePhotoDbProcessor } from '@/processors/usePhotoDbProcessor';
import { useMessages } from '@/features/messages';
import { useGlobalErrorEventHandling } from '@/hooks/useGlobalErrorEventHandling';
import { SettingsProvider } from '@/contexts/settingsContext';
import SplashLoading from '../components/elements/SplashLoading';
import { useGetAndStoreCommonItems } from '@/hooks/useGetAndStoreCommonItems';
import { usePhotoDbRequestsProcessor } from '@/processors/usePhotoDbRequestsProcessor';
import MessagesModalWrapper from '../features/messages/components/MessagesModalWrapper';
import { useMessagesRequestsProcessor } from '@/processors/useMessagesRequestsProcessor';
import { selectIsCurrentRouteGroupItemOpenedMode, setCurrentRouteGroupItem, useRouteGroupItemsRequest } from '@/features/home';
import { removeOldProcessedObjects } from '@/helpers/localStorage/processedObjects';
import { getPhotoUploadingStatus, setPhotoUploadingStatus } from '@/helpers/localStorage/photoUploadingHelper';
import { PhotoUploadingStatus } from '@/features/photo';
import { useRollbar } from '@rollbar/react';
import { photoDB } from '@/database';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { useIsRoutePage } from '@/helpers/router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getTodayDate } from '@/helpers/dateHelper';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';

const DAYS_TO_KEEP_PROCESSED_OBJECTS = 3;

const AppLayout = () => {
  const rollbar = useRollbar();
  const { routeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOffline = useOfflineStatus();

  const { isLoading: isRouteGroupItemsLoading, routeGroupItems } = useRouteGroupItemsRequest();
  const { resetNavigation } = useAdvancedNavigationItems();
  const isRouteGroupItemOpenedMode = useAppSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const isRoutePage = useIsRoutePage();

  useGetAndStoreCommonItems();
  usePreCache({ routeGroupItems });

  usePhotoDbProcessor();
  usePhotoDbRequestsProcessor();
  useMessagesRequestsProcessor();

  useMessages();

  useGlobalErrorEventHandling();

  const handleOnPageUnload = useCallback(() => {
    setPhotoUploadingStatus(PhotoUploadingStatus.NotUploading);
  }, []);

  useEffect(() => {
    removeOldProcessedObjects(DAYS_TO_KEEP_PROCESSED_OBJECTS);

    document.addEventListener('beforeunload', handleOnPageUnload);

    const rollbarLoggingForPhotoUploading = async () => {
      const { status, heap } = getPhotoUploadingStatus();
      const currentIndexedDbPhotos = await photoDB.photos.toArray();

      if (status !== PhotoUploadingStatus.NotUploading) {
        rollbar.warn('[PhotoUploading - Offline] Photo uploading status is not finished', {
          status,
          heap,
          currentIndexedDbPhotos,
        });
      }
    };

    rollbarLoggingForPhotoUploading();

    return () => {
      document.removeEventListener('beforeunload', handleOnPageUnload);
    };
  }, [handleOnPageUnload, rollbar]);

  const getRouteGroupItemId = useCallback((): number => {
    if (routeId) {
      localStorage.setItem('currentRouteGroupItemId', routeId);
      return Number(routeId);
    }
    if (localStorage.getItem('currentRouteGroupItemId')) {
      return Number(localStorage.getItem('currentRouteGroupItemId'));
    }

    return null;
  }, [routeId]);

  useEffect(() => {
    const getAndSetCurrentRouteGroupItem = async () => {
      const id = getRouteGroupItemId();
      if (!id || !routeGroupItems) {
        return;
      }

      const routeGroupItem = routeGroupItems?.find((item) => item.id === id);

      if (!routeGroupItem && isRoutePage) {
        navigate('/');
        resetNavigation();
        toast(t('selectRouteGroupItem'), { type: 'warning' });
        return;
      }

      if (!isRouteGroupItemOpenedMode && routeGroupItem?.date !== getTodayDate()) {
        dispatch(setCurrentRouteGroupItem(null));
        if (isRoutePage) {
          resetNavigation();
          navigate('/');
        }
        return;
      }

      dispatch(setCurrentRouteGroupItem(routeGroupItem));
    };

    getAndSetCurrentRouteGroupItem();
  }, [getRouteGroupItemId, routeGroupItems, dispatch, isRoutePage, isRouteGroupItemOpenedMode, navigate, resetNavigation, t]);

  if (isRouteGroupItemsLoading && !isOffline) {
    return <SplashLoading />;
  }

  if (!routeGroupItems) {
    return <></>;
  }

  return (
    <SettingsProvider>
      <Outlet />
      <MessagesModalWrapper />
    </SettingsProvider>
  );
};

export default AppLayout;
