import React from 'react';
import { useLocaleSwitcher } from '../../hooks/useLocaleSwitcher';

const LocaleSwitcher = () => {
  const { locales, currentLocale, handleLocaleChange } = useLocaleSwitcher();

  return (
    <div className="locale-switcher">
      {Object.entries(locales).map(([locale, label]) => {
        return (
          <span
            key={'locale-' + locale}
            className={currentLocale === locale ? 'active-locale' : ''}
            onClick={() => handleLocaleChange(locale)}
          >
            {label}
          </span>
        );
      })}
    </div>
  );
};

export default LocaleSwitcher;
