import React, { ComponentPropsWithRef, HTMLInputTypeAttribute } from 'react';
import { removeExtraDotsFromDecimal } from '../../helpers/regex';
import { cn } from '@/utils/cn';

interface InputProps extends Omit<ComponentPropsWithRef<'input'>, 'onChange'> {
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  id?: string;
  suffix?: string;
  onChange: (value: string) => void;
  checkForNaN?: boolean;
}

const Input = ({ className, id, label, suffix, type, inputMode, onChange, checkForNaN = false, ...rest }: InputProps) => {
  return (
    <div className={cn('input-component', className)}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        type={type || 'text'}
        inputMode={inputMode || 'text'}
        onChange={(event) => {
          if (inputMode === 'decimal') {
            if (checkForNaN && isNaN(Number(event.target.value))) {
              return;
            }
            onChange(removeExtraDotsFromDecimal(event.target.value.replace(',', '.')).slice(0, 9));
            return;
          }

          onChange(event.target.value);
        }}
        {...rest}
      />
      {suffix && <span className="input-component__suffix">{suffix}</span>}
    </div>
  );
};

export default Input;
