import { cn } from '@/utils/cn';
import React from 'react';
import { Switch } from '@headlessui/react';

interface ToggleProps<T> {
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  size: 'sm' | 'md' | 'lg';
}

const TRANSLATE_X = {
  sm: 'translate-x-8',
  md: 'translate-x-9',
  lg: 'translate-x-12',
};

const SWITCH_SIZE = {
  sm: 'h-[34px] w-[66px]',
  md: 'h-[38px] w-[74px]',
  lg: 'h-[48px] w-[96px]',
};

const CIRCLE_SIZE = {
  sm: 'h-[30px] w-[30px]',
  md: 'h-[34px] w-[34px]',
  lg: 'h-[44px] w-[44px]',
};

export function Toggle<T>({ checked, onChange, className, size }: ToggleProps<T>) {
  const translateX = TRANSLATE_X[size];
  const switchSize = SWITCH_SIZE[size];
  const circleSize = CIRCLE_SIZE[size];

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={cn([
        'relative inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75',
        { 'bg-gray-200 dark:bg-gray-600': !checked, 'bg-green-600': checked },
        switchSize,
        className,
      ])}
    >
      <span
        aria-hidden="true"
        className={cn([
          'pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
          {
            'translate-x-0': !checked,
            [translateX]: checked,
          },
          circleSize,
        ])}
      />
    </Switch>
  );
}
