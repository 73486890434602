import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@/store';
import { RouteGroupItem, RouteSheet, UnloadPoint } from '@ekt-group/general-purpose-api-interfaces';
import { CurrentRouteGroupItemMode } from '@/features/home';

export interface RouteGroupItemsState {
  currentItem: RouteGroupItem | null;
  currentRouteSheet: RouteSheet | null;
  currentItemMode: CurrentRouteGroupItemMode;
}

const savedCurrentRouteGroupItemMode = localStorage.getItem('currentRouteGroupItemMode') as CurrentRouteGroupItemMode;
const initialState: RouteGroupItemsState = {
  currentItem: null,
  currentRouteSheet: null,
  currentItemMode:
    savedCurrentRouteGroupItemMode && Object.values(CurrentRouteGroupItemMode).includes(savedCurrentRouteGroupItemMode)
      ? savedCurrentRouteGroupItemMode
      : CurrentRouteGroupItemMode.Open,
};

export const routeGroupItemsSlice = createSlice({
  name: 'routeGroupItemsStore',
  initialState,
  reducers: {
    setCurrentRouteGroupItem(state, { payload }: PayloadAction<RouteGroupItem>) {
      state.currentItem = payload;
    },
    setCurrentRouteSheet(state, { payload }: PayloadAction<RouteSheet>) {
      state.currentRouteSheet = payload;
    },
    setCurrentRouteGroupItemMode(state, { payload }: PayloadAction<CurrentRouteGroupItemMode>) {
      state.currentItemMode = payload;
    },
    resetRouteGroupItemsState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { resetRouteGroupItemsState, setCurrentRouteGroupItem, setCurrentRouteGroupItemMode, setCurrentRouteSheet } =
  routeGroupItemsSlice.actions;

export const selectCurrentRouteGroupItem = (state: AppState) => state.routeGroupItemsStore.currentItem;
export const selectCurrentRouteSheet = (state: AppState) => state.routeGroupItemsStore.currentRouteSheet;
export const selectIsCurrentRouteGroupItemOpenedMode = (state: AppState) =>
  state.routeGroupItemsStore.currentItemMode === CurrentRouteGroupItemMode.Open;

export default routeGroupItemsSlice.reducer;
