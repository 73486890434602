import React from 'react';
import { useTranslation } from 'react-i18next';
import { setDocumentTitle } from '@/hooks/useDocumentTitle';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/elements/Button';

export const NotFoundPage = () => {
  const { t } = useTranslation('errors');
  const { t: loginT } = useTranslation('loginPage');
  setDocumentTitle(t('notFoundError'));
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
      return;
    }

    navigate('/');
  };

  return (
    <div className="error-page__wrapper not-found-page">
      <div className="not-found-page__content">
        <span className="error-page__code">404</span>
        <div className="error-page__separator"></div>
        <span className="error-page__text">{t('notFoundError')}</span>
      </div>
      <Button onClick={handleGoBack} text={loginT('goBack')} size={'md'} />
    </div>
  );
};
