import { useState } from 'react';
import gatesService from '../api/gates.service';
import objectsService from '../../../services/objects.service';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useSelector } from 'react-redux';
import { selectIsCurrentRouteGroupItemOpenedMode } from '@/features/home';
import useGeolocation from 'react-hook-geolocation';

export function useGatePhoneCall() {
  const [isPending, setIsPending] = useState(false);

  const isRouteGroupItemInOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const isOffline = useOfflineStatus();
  const { longitude, latitude } = useGeolocation();

  const handleOpenGate = async (gateId: number) => {
    if (isPending || isOffline || isRouteGroupItemInOpenedMode) {
      return;
    }

    setIsPending(true);
    try {
      await gatesService.openGate(gateId, { longitude, latitude, routeGroupItemWaypointId: 0 });
    } finally {
      setTimeout(() => {
        setIsPending(false);
      }, 10000);
    }
  };

  const handleOpenObjectGate = async (objectId: number, routeGroupItemWaypointId: number, isSecondNumber = false) => {
    if (isPending || isOffline || isRouteGroupItemInOpenedMode) {
      return;
    }

    setIsPending(true);
    try {
      await objectsService.openObjectGate(objectId, { longitude, latitude, routeGroupItemWaypointId, isSecondNumber });
    } finally {
      setTimeout(() => {
        setIsPending(false);
      }, 10000);
    }
  };

  const handleCloseObjectGate = async (objectId: number, routeGroupItemWaypointId: number) => {
    if (isPending || isOffline || isRouteGroupItemInOpenedMode) {
      return;
    }

    setIsPending(true);
    try {
      await objectsService.closeObjectGate(objectId, { longitude, latitude, routeGroupItemWaypointId });
    } finally {
      setTimeout(() => {
        setIsPending(false);
      }, 10000);
    }
  };

  return {
    isPending,
    handleOpenGate,
    handleOpenObjectGate,
    handleCloseObjectGate,
  };
}
