import React, { forwardRef, useState } from 'react';
import { useOfflineSummary } from '../../hooks/useOfflineSummary';
import OfflineSummary from '../pwa/OfflineSummary';
import { useTranslation } from 'react-i18next';

const SidebarOfflineSummaryWrapper = forwardRef<HTMLDivElement, any>(({}, ref) => {
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const { t } = useTranslation('offline');

  const { totalPendingRequests, routeStartFinishRequests, dischargeNotes, discharges, unloads, tanks, photos, acceptances, tasks } =
    useOfflineSummary();

  return (
    <>
      {totalPendingRequests > 0 && (
        <div className="pending-requests-indicator" onClick={() => setIsSummaryVisible(true)}>
          {t('summary.notification', { amount: totalPendingRequests })}
        </div>
      )}
      <OfflineSummary
        ref={ref}
        isSummaryVisible={isSummaryVisible}
        onClose={() => setIsSummaryVisible(false)}
        routeStartFinishRequests={routeStartFinishRequests}
        unloads={unloads}
        discharges={discharges}
        dischargeNotes={dischargeNotes}
        tanks={tanks}
        photos={photos}
        acceptances={acceptances}
        tasks={tasks}
      />
    </>
  );
});

SidebarOfflineSummaryWrapper.displayName = 'SidebarOfflineSummaryWrapper';

export default SidebarOfflineSummaryWrapper;
