import { useCallback, useEffect, useState } from 'react';
import { Gate } from '@ekt-group/general-purpose-api-interfaces';
import { useCoordinatesHelper } from '@/helpers/coordinates';
import { useSelector } from 'react-redux';
import { selectGates } from '@/features/route';

const RADIUS_FOR_DISPLAY_CALL_GATE_ACTION = 100;

export function useFilteredGatesByRadius() {
  const [filteredGates, setFilteredGates] = useState<Gate[]>([]);

  const { filterByRadius } = useCoordinatesHelper();

  const gates = useSelector(selectGates);

  const filterGates = useCallback(() => {
    const filteredByRadiusGates = filterByRadius<Gate>(gates, RADIUS_FOR_DISPLAY_CALL_GATE_ACTION);
    setFilteredGates(filteredByRadiusGates);
  }, [gates, filterByRadius]);

  useEffect(() => {
    filterGates();
  }, [gates, filterGates]);

  return {
    filteredGates,
  };
}
