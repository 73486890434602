import {
  constructionApi,
  FinishRouteSheetDto,
  RouteSheetSideloading,
  StartRouteSheetDto,
  useStartConstructionRouteSheetMutation,
  useLazyGetConstructionRoutesQuery,
  useFinishConstructionRouteSheetMutation,
} from '@/features/route';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { GetRouteSheetsQuery } from '@ekt-group/general-purpose-api-interfaces';
import { useCallback } from 'react';
import { setCurrentRouteSheet } from '../stores';
import { selectUserId } from '@/features/auth';
import { CONSTRUCTION_PAGE_SIDELOADINGS, useConstructionTaskRequest } from './useConstructionTaskRequest';
import { getTodayDate, getTomorrowDate } from '@/helpers/dateHelper';

export const HOME_PAGE_SIDELOADINGS = [
  RouteSheetSideloading.Base,
  RouteSheetSideloading.Car,
  RouteSheetSideloading.Driver,
  RouteSheetSideloading.Region,
];

export const useConstructionRouteRequest = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);

  const [lazyGetConstructionRoutes, { data, currentData, isLoading, isFetching, originalArgs }] = useLazyGetConstructionRoutesQuery();
  const { lazyGetConstructionTasks } = useConstructionTaskRequest();

  const reselectConstructionRoutesCache = useCallback(async (params: GetRouteSheetsQuery) => {
    const store = await import('@/store').then((module) => module.store);

    const { data } = constructionApi.endpoints.getConstructionRoutes.select(params)(store.getState());

    return data;
  }, []);

  const setRouteSheetAsCurrent = (routeSheetId: number) => {
    const routeSheet = data?.find((routeSheet) => routeSheet.id === routeSheetId);
    if (routeSheet) {
      dispatch(setCurrentRouteSheet(routeSheet));
    }
  };

  const clearRouteSheetAsCurrent = () => {
    dispatch(setCurrentRouteSheet(null));
  };

  const [startSheet, { isError: isStartError, isLoading: isStartLoading }] = useStartConstructionRouteSheetMutation();
  const [finishSheet, { isError: isFinishError, isLoading: isFinishLoading }] = useFinishConstructionRouteSheetMutation();
  const startRouteSheet = async (body: StartRouteSheetDto) => {
    await startSheet({ ...body, originalArgs });
  };

  const finishRouteSheet = async (body: FinishRouteSheetDto) => {
    await finishSheet({ ...body, originalArgs });
  };

  const refetchRouteSheetAndTasks = useCallback(async () => {
    const params: GetRouteSheetsQuery = {
      startDate: getTodayDate(),
      endDate: getTomorrowDate(),
      driverIds: [userId],
      sideloading: HOME_PAGE_SIDELOADINGS,
    };

    const constructionRouteData = await lazyGetConstructionRoutes(params).unwrap();

    if (constructionRouteData) {
      const routeSheetIds = constructionRouteData?.map((route) => route?.id) || [];

      if (routeSheetIds?.length) {
        const tasksParams = {
          routeSheetIds,
          sideloading: CONSTRUCTION_PAGE_SIDELOADINGS,
        };

        await lazyGetConstructionTasks(tasksParams);
      }
    }
  }, [lazyGetConstructionRoutes, lazyGetConstructionTasks, userId]);

  return {
    data: data || [],
    isLoading,
    isFetching,
    lazyGetConstructionRoutes,
    reselectConstructionRoutesCache,
    setRouteSheetAsCurrent,
    clearRouteSheetAsCurrent,
    startRouteSheet,
    finishRouteSheet,
    isStartError,
    isStartLoading,
    isFinishError,
    isFinishLoading,
    refetchRouteSheetAndTasks,
  };
};
