import Dexie from 'dexie';
import { Waypoint } from '../features/home/types/routeGroupItems';

export enum WaypointsUpdatesItemMark {
  Initial = 'initial',
  Update = 'update',
}

export interface WaypointsUpdatesItem {
  id?: number;
  routeGroupItemId: number;
  items: Waypoint[];
  deletedIds: number[];
  insertedIds: number[];
  mark: WaypointsUpdatesItemMark;
  isSeen: boolean;
  timestamp: Date;
}

export class WaypointsUpdatesDB extends Dexie {
  updates: Dexie.Table<WaypointsUpdatesItem, number>;

  constructor() {
    super('waypointsUpdatesDB');

    this.version(1).stores({
      updates: '++id, routeGroupItemId, items, deletedIds, insertedIds, mark, isSeen, timestamp',
    });
  }
}

export const waypointsUpdatesDB = new WaypointsUpdatesDB();
