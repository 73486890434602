import { CreateCarAcceptanceDto } from '@/features/home';
import Dexie, { Table } from 'dexie';

export interface CarsAcceptanceRequest {
  id?: number;
  type: 'create' | 'update' | 'delete';
  payload: CreateCarAcceptanceDto;
}

export class CarAcceptanceRequestsDb extends Dexie {
  requests!: Table<CarsAcceptanceRequest>;

  constructor() {
    super('carAcceptanceRequestsDb');
    this.version(1).stores({
      requests: '++id, type, payload',
    });
  }
}

export const carAcceptanceRequestsDb = new CarAcceptanceRequestsDb();
