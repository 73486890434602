import authService from '../api';
import { setUserState } from '@/features/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function useVerifyUserRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyUser = async (token: string) => {
    const { data: user } = await authService.verifyUserToken(token);

    if (user === null) {
      navigate('/login');
      return;
    }

    if (user.isLogged) {
      dispatch(setUserState(user.user));
      return;
    }

    navigate('/login');
  };

  return { verifyUser };
}
