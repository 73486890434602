import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedUnloadPoint, setCreatedWaypointResult, setSelectedUnloadPoint } from '@/features/unloading';
import Input from '@/components/elements/Input';
import Button from '@/components/elements/Button';
import { selectCommonItemMap } from '@/store/common/slice';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { useTranslation } from 'react-i18next';
import { selectCurrentGarbageIds } from '@/store/waypoints/slice';
import { SaveUnloadPayload, UpdateUnloadPayload, useUnloadFormRequests } from '../hooks/useUnloadFormRequests';
import { useUnloadWeightsForm, WeightForm } from '../hooks';
import { unloadsRequestsDb } from '@/database';
import { debounce } from 'lodash';
import { WaypointUnload, WaypointUnloadCreateDto } from '@ekt-group/general-purpose-api-interfaces';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { UnloadFormConfirmCloseModal } from './UnloadFormConfirmCloseModal';
import { useLiveQuery } from 'dexie-react-hooks';

const DEBOUNCE_PERIOD = 3000;

const UnloadFormSidebar = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('unloadingPage');

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  const unloadPoint = useSelector(selectSelectedUnloadPoint);
  const currentGarbageIds = useSelector(selectCurrentGarbageIds);
  const garbageMap = useSelector(selectCommonItemMap('garbage'));

  const possibleGarbageIds = useMemo<(string | number)[]>(
    () => currentRouteGroupItem?.routeGroup?.garbageIds || currentGarbageIds || [],
    [currentRouteGroupItem, currentGarbageIds],
  );

  const isOffline = useOfflineStatus();

  const [selectedGarbageId, setSelectedGarbageId] = useState<number | null>(Number(possibleGarbageIds?.[0]) || null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const {
    saveUnload,
    setNavigationToUnloadPoint,
    isPending,
    createOrUpdateUnloadIndexDb,
    updateUnload,
    createdWaypointResult,
    deleteUnload,
  } = useUnloadFormRequests(unloadPoint);

  const editingUnloadRequest = useLiveQuery(() => unloadsRequestsDb.requests.toArray())?.find((request) => request.isEditing) || null;
  const editingUnloadRequestBody = (editingUnloadRequest?.body as WaypointUnloadCreateDto) || null;

  // const pendingUnloadItem = editingUnloadRequestBody || createdWaypointResult;

  const clearUnloadStore = useCallback(() => {
    dispatch(setSelectedUnloadPoint(null));
    dispatch(setCreatedWaypointResult(null));
  }, [dispatch]);

  const onCloseSidebarClick = useCallback(() => {
    // if (createdWaypointResult || editingUnloadRequest) {
    //   setIsConfirmModalOpen(true);
    //   return;
    // }
    clearUnloadStore();
  }, [clearUnloadStore]);

  const { weight, grossWeight, netWeight, isInvalidWeight, setValue } = useUnloadWeightsForm(
    {
      initialWeight: 0,
      initialGrossWeight: 0,
      initialNetWeight: 0,
    },
    unloadPoint?.allowGrossNetInput,
  );

  const isSaveDisabled = useMemo(() => {
    return isInvalidWeight(weight, grossWeight, netWeight) || !selectedGarbageId || isPending;
  }, [grossWeight, isInvalidWeight, netWeight, selectedGarbageId, weight, isPending]);

  useEffect(() => {
    setNavigationToUnloadPoint();
  }, []);

  const debouncedSaveUnload = useCallback(
    debounce(async (saveUnloadPayload: SaveUnloadPayload) => {
      saveUnload(saveUnloadPayload);
    }, DEBOUNCE_PERIOD),
    [],
  );

  const debouncedUpdateUnload = useCallback(
    debounce(async (updateUnloadPayload: UpdateUnloadPayload, unload) => {
      updateUnload(updateUnloadPayload, unload);
    }, DEBOUNCE_PERIOD),
    [],
  );

  const handleOnlineSaveOrUpdateUnload = async (saveUnloadPayload: SaveUnloadPayload, isDebounce: boolean, afterSaving?: () => void) => {
    saveUnload(saveUnloadPayload);
    afterSaving && afterSaving();
  };

  const handleOfflineSaveOrUpdateUnload = async (saveUnloadPayload: SaveUnloadPayload, isDebounce: boolean, afterSaving?: () => void) => {
    await createOrUpdateUnloadIndexDb(saveUnloadPayload);
    afterSaving && afterSaving();
  };

  const handleSaveUnload = async ({
    weight,
    grossWeight,
    netWeight,
    isFromSaveButton = false,
  }: {
    weight: number;
    grossWeight: number;
    netWeight: number;
    isFromSaveButton?: boolean;
  }) => {
    if (isInvalidWeight(weight, grossWeight, netWeight)) {
      return;
    }

    const unloadRequestsIndexedDb = await unloadsRequestsDb.requests.toArray();
    const editingUnloadRequest = unloadRequestsIndexedDb.find((request) => request.isEditing);

    const saveUnloadPayload: SaveUnloadPayload = {
      weight,
      grossWeight,
      netWeight,
      garbageId: selectedGarbageId,
    };

    const updateUnloadPayload: UpdateUnloadPayload = {
      weight,
      grossWeight,
      netWeight,
    };

    // Online, but if there's an entry ("editingUnloadRequest") in the indexedDb, we consider this the offline flow
    if (!isOffline && !editingUnloadRequest) {
      if (isFromSaveButton) {
        await handleOnlineSaveOrUpdateUnload(saveUnloadPayload, false, clearUnloadStore);
        dispatch(setCreatedWaypointResult(null));

        return;
      }

      await handleOnlineSaveOrUpdateUnload(saveUnloadPayload, true);
      return;
    }

    // Offline
    if (isFromSaveButton) {
      if (editingUnloadRequest) {
        // Simply remove the isEditing flag and let the processor handle the creation later
        await unloadsRequestsDb.requests.update(editingUnloadRequest?.id, { isEditing: false });
        dispatch(setCreatedWaypointResult(null));
        clearUnloadStore();
        return;
      }

      await handleOfflineSaveOrUpdateUnload(saveUnloadPayload, false, clearUnloadStore);
      dispatch(setCreatedWaypointResult(null));
      return;
    }

    handleOfflineSaveOrUpdateUnload(saveUnloadPayload, true);
  };

  const handleInputsChange = (key: keyof WeightForm, value: number) => {
    switch (key) {
      case 'grossWeight': {
        setValue('grossWeight', value);
        break;
      }
      case 'netWeight': {
        setValue('netWeight', value);
        break;
      }
      default: {
        setValue('weight', value);
      }
    }
  };

  const handleDeleteConfirmButtonClicked = () => {
    if (createdWaypointResult) {
      deleteUnload(createdWaypointResult);
    }
    if (editingUnloadRequest) {
      unloadsRequestsDb.requests.delete(editingUnloadRequest.id);
    }
    setIsConfirmModalOpen(false);
    clearUnloadStore();
  };

  return (
    <div className={`unloading-form-sidebar ${unloadPoint?.id ? 'active' : ''}`}>
      <UnloadFormConfirmCloseModal
        isVisible={isConfirmModalOpen}
        onConfirm={handleDeleteConfirmButtonClicked}
        onCancel={() => {
          setIsConfirmModalOpen(false);
        }}
      />
      <div className="unloading-form-sidebar__inputs">
        {unloadPoint?.allowGrossNetInput ? (
          <>
            <div>
              <span>{t('grossWeight')}</span>
              <div>
                <Input
                  value={grossWeight.toString() || '0'}
                  placeholder="0"
                  inputMode={'decimal'}
                  suffix={'kg'}
                  onChange={(value) => {
                    handleInputsChange('grossWeight', +value);
                  }}
                  checkForNaN={true}
                />
              </div>
            </div>
            <div>
              <span>{t('netWeight')}</span>
              <Input
                value={netWeight.toString() || '0'}
                placeholder="0"
                inputMode={'decimal'}
                suffix={'kg'}
                onChange={(value) => {
                  handleInputsChange('netWeight', +value);
                }}
                checkForNaN={true}
              />
            </div>
            <div className="unloading-page__list-item__unload__inputs-weight">
              <span>{t('weight')}</span>
              <span>{weight}</span>
            </div>
          </>
        ) : (
          <div>
            {t('weight')}:{' '}
            <Input
              value={weight.toString() || '0'}
              inputMode={'decimal'}
              suffix={'kg'}
              placeholder={'0'}
              onChange={(value) => {
                handleInputsChange('weight', +value);
              }}
              checkForNaN={true}
            />
          </div>
        )}
      </div>
      {isInvalidWeight(weight, grossWeight, netWeight) && (
        <div className="unloading-form-sidebar__error">
          <span>{t('netWeightError')}</span>
        </div>
      )}
      <div className="unloading-form-sidebar__garbage">
        <span>{t('selectGarbage')}</span>
        <div className="unloading-form-sidebar__garbage__list">
          {garbageMap &&
            possibleGarbageIds.map((garbageId) => (
              <div
                key={garbageId}
                className={`unloading-form-sidebar__garbage__list-item ${Number(garbageId) === selectedGarbageId ? 'active' : ''}`}
                onClick={() => setSelectedGarbageId(Number(garbageId))}
              >
                {garbageMap[garbageId]?.name}
              </div>
            ))}
        </div>
      </div>

      <div className="unloading-form-sidebar__actions">
        <Button text={t('close', { ns: 'common' })} color={'disabled'} size="md" onClick={onCloseSidebarClick} wide />
        <Button
          text={t('save', { ns: 'common' })}
          color={'success'}
          disabled={isSaveDisabled}
          pending={isPending}
          size="md"
          onClick={() => {
            handleSaveUnload({
              weight,
              grossWeight,
              netWeight,
              isFromSaveButton: true,
            });
          }}
          wide
        />
      </div>
    </div>
  );
};

export default React.memo(UnloadFormSidebar);
