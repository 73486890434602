import React from 'react';
import WazeLogo from '@/assets/images/waze-logo.png';
import { Waypoint } from '@/features/home';

interface WazeButtonProps {
  waypoints: Waypoint[];
}

const WAZE_WEB_LINK = 'https://waze.com/ul';
const WAZE_APP_LINK = 'waze://';
const WAZE_ZOOM_LEVEL = 17;

const WazeButton = ({ waypoints }: WazeButtonProps) => {
  const clickHandler = () => {
    const objectItemWithCoordinates = waypoints.find(({ objectItem }) => objectItem.longitude && objectItem.latitude);
    const targetCoordinates = objectItemWithCoordinates
      ? [objectItemWithCoordinates.objectItem.longitude, objectItemWithCoordinates.objectItem.latitude]
      : [waypoints[0]?.object.longitude, waypoints[0]?.object.latitude];

    window.open(`${WAZE_APP_LINK}?navigate=yes&zoom=${WAZE_ZOOM_LEVEL}&ll=${targetCoordinates.reverse().join()}`);
  };

  return (
    <button
      onClick={clickHandler}
      className={
        'fixed right-[0.75rem] top-52 md:right-auto z-[1] md:left-6 md:top-[30rem] bg-white border border-gray-400 dark:border-gray-600 dark:bg-gray-700 p-2 rounded-lg w-12 h-12 md:w-16 md:h-16 centered'
      }
    >
      <img src={WazeLogo} className={'w-8 h-8 md:w-10 md:h-10'} alt={'waze logo'} />
    </button>
  );
};

export default WazeButton;
