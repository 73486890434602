import React, { useEffect, useState } from 'react';
import ModalWrapper from './ModalWrapper';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';
import ReactSimpleImageViewer from 'react-simple-image-viewer';
import VerticalShadowScroll from '../elements/VerticalShadowScroll';

export interface BasicImageModalItem {
  url: string;
}

interface BasicImageModalProps {
  isVisible: boolean;
  onClose: () => void;
  images: BasicImageModalItem[];
}

const BasicImagesModal = ({ isVisible, onClose, images }: BasicImageModalProps) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible && !images.length) {
      onClose();
    }
  }, [isVisible, images]);

  return (
    <ModalWrapper isVisible={isVisible} wide>
      <div className="images-modal">
        <VerticalShadowScroll className="images-modal__images">
          {images.map((image, index) => (
            <div key={index} className="images-modal__image flex flex-col gap-2 p-2">
              <img
                width={300}
                height={200}
                src={image.url}
                onClick={() => {
                  setCurrentImage(index);
                  setIsViewerOpen(true);
                }}
              />
            </div>
          ))}
        </VerticalShadowScroll>
        {isViewerOpen && (
          <ReactSimpleImageViewer
            src={images.map(({ url }) => url)}
            currentIndex={currentImage}
            closeOnClickOutside
            disableScroll
            onClose={() => setIsViewerOpen(false)}
          />
        )}
        <div className="images-modal__footer">
          <Button text={t('close')} size="md" wide color={'disabled'} onClick={onClose} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default BasicImagesModal;
