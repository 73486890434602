import { useCallback, useEffect, useState } from 'react';
import { useArrivedWaypoints } from './useArrivedWaypoints';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetWaypointsQuery, useWaypointsRequest } from '@/features/route';
import { NavigationType, useNavigationType } from 'react-router';
import { selectSelectedWaypointIds, setSelectedWaypointIds } from '@/store/waypoints/slice';
import { setCurrentRouteGroupItem, useRouteGroupItemsRequest, Waypoint, WaypointType } from '@/features/home';

export function useWaypointForPhotoUpload() {
  const [waypointsForUpload, setWaypointsForUpload] = useState<(Waypoint<WaypointType.Service> | Waypoint<WaypointType.Discharge>)[]>([]);

  const [getWaypoints, { isFetching }] = useLazyGetWaypointsQuery();
  const { data: waypoints, isLoading: isWaypointsLoading } = useWaypointsRequest();
  const { setRouteGroupItemAsCurrent } = useRouteGroupItemsRequest();

  const navigationType = useNavigationType();
  const dispatch = useDispatch();
  const selectedWaypointIds = useSelector(selectSelectedWaypointIds);

  const {
    isLoading: isArrivedWaypointLoading,
    isDischargeArrivedRoute,
    getArrivedWaypointIds,
    arrivedWaypointsIds,
  } = useArrivedWaypoints();

  const fetchByArrivedWaypoints = useCallback(async () => {
    dispatch(setSelectedWaypointIds([]));
    localStorage.removeItem('selectedWaypointIds');
    await getArrivedWaypointIds();
  }, [dispatch, getArrivedWaypointIds]);

  const setWaypoints = useCallback(
    async (waypointIds: number[]) => {
      const storedMatchedWaypoints = waypoints?.filter((waypoint) => waypointIds.includes(waypoint.id)) || [];
      if (storedMatchedWaypoints.length) {
        // set waypoints that match arrived waypoints id from store without request
        setWaypointsForUpload(storedMatchedWaypoints);
        setRouteGroupItemAsCurrent(storedMatchedWaypoints?.[0].routeGroupItemId);

        return;
      }

      const { data } = await getWaypoints({ waypointIds: waypointIds, isDischargeSheet: isDischargeArrivedRoute });
      setWaypointsForUpload(data);
      setRouteGroupItemAsCurrent(data?.[0].routeGroupItemId);
    },
    [getWaypoints, isDischargeArrivedRoute, setRouteGroupItemAsCurrent, waypoints],
  );

  // set waypoints on first page opening
  useEffect(() => {
    if (selectedWaypointIds.length > 0) {
      setWaypoints(selectedWaypointIds);
      return;
    }
    if (navigationType === NavigationType.Pop) {
      getArrivedWaypointIds();
    }
  }, []);

  // set arrived waypoints only on refresh or first page opening
  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      getArrivedWaypointIds();
    }
  }, [navigationType]);

  // set by arrived waypoints change
  useEffect(() => {
    if (!arrivedWaypointsIds.length) {
      return;
    }
    setWaypoints(arrivedWaypointsIds);
  }, [setWaypoints, arrivedWaypointsIds]);

  return {
    waypoints,
    waypointsForUpload: waypointsForUpload,
    isLoading: isFetching || isArrivedWaypointLoading,
    fetchByArrivedWaypoints,
    setWaypoints,
  };
}
