import InstallIcon from '../icons/InstallIcon';
import React from 'react';

const DeInstallContainerBadge = () => (
  <div className="bg-fuchsia-500 rounded-full text-white rotate-180">
    <InstallIcon size={'md'} />
  </div>
);

export default DeInstallContainerBadge;
