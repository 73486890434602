import React, { PropsWithChildren, useEffect, useRef } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface Props {
  className?: string;
}

const HorizontalShadowScroll = ({ children, className }: PropsWithChildren<Props>) => {
  const { width } = useWindowDimensions();

  const containerRef = useRef<HTMLDivElement>(null);
  const leftShadowRef = useRef<HTMLDivElement>(null);
  const rightShadowRef = useRef<HTMLDivElement>(null);

  const calculateShadows = ({ scrollWidth, scrollLeft, offsetWidth }: { scrollWidth: number; scrollLeft: number; offsetWidth: number }) => {
    if (offsetWidth >= scrollWidth) {
      leftShadowRef.current.style.opacity = '0';
      rightShadowRef.current.style.opacity = '0';
      return;
    }

    const currentScroll = scrollLeft / (scrollWidth - offsetWidth);
    leftShadowRef.current.style.opacity = currentScroll.toString();
    rightShadowRef.current.style.opacity = (1 - currentScroll).toString();
  };

  const handleScroll = (event: Event) => {
    const { scrollWidth, scrollLeft, offsetWidth } = (event as unknown as React.UIEvent<HTMLDivElement, UIEvent>).currentTarget;
    requestAnimationFrame(() => calculateShadows({ scrollWidth, scrollLeft, offsetWidth }));
  };

  const handleResize = (container: HTMLDivElement) => {
    const { scrollWidth, scrollLeft, offsetWidth } = container;
    calculateShadows({ scrollWidth, scrollLeft, offsetWidth });
  };

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    handleResize(container);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    handleResize(containerRef.current);
  }, [width, containerRef.current]);

  return (
    <div className="shadow-scroll horizontal">
      <div className={`shadow-scroll__content horizontal ${className}`} ref={containerRef}>
        {children}
      </div>
      <div className="shadow-scroll__shadow shadow-scroll__shadow-left" ref={leftShadowRef} />
      <div className="shadow-scroll__shadow shadow-scroll__shadow-right" ref={rightShadowRef} />
    </div>
  );
};

export default HorizontalShadowScroll;
