import React, { MouseEvent, MouseEventHandler, useMemo, useRef, useState } from 'react';
import { Combobox, Listbox } from '@headlessui/react';
import { CarCheckOption } from '@ekt-group/general-purpose-api-interfaces';
import { CheckUpFormData, FORM_TYPE } from './CheckUpModal';
import { AllDeviceTask } from '@ekt-group/bi-services-api-interfaces';
import { ValueOf } from '@/utils/value-of';
import { PhotoUploads } from '@/components/elements/PhotoUploads';
import { cn } from '@/utils/cn';
import HamburgerIcon from '@/components/icons/HamburgerIcon';
import { ComboBoxOption } from '@/types/common';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';
import { CheckUpCarProblemsRadioGroup } from './CheckUpCarProblemsRadioGroup';

interface CheckUpCarProblemFormProps {
  carCheckOptions: CarCheckOption[];
  priorityOptions: ComboBoxOption[];

  updateFields: (fields: Partial<CheckUpFormData>, source: ValueOf<typeof FORM_TYPE>) => void;
  currentValues: CheckUpFormData['carProblemForm'];
}

export const CheckUpCarProblemForm = ({ carCheckOptions, updateFields, currentValues, priorityOptions }: CheckUpCarProblemFormProps) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
  const [selectedCarCheckId, setSelectedCarCheckId] = useState<number>(null);
  const currentPriority = priorityOptions.find((priority) => priority.key === currentValues.priority) || priorityOptions[0];
  const currentCarCheckOption = carCheckOptions.find((option) => option.id === selectedCarCheckId);
  const problemButtonRef = useRef<HTMLButtonElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('carCheckUpModal');

  const handleComboBoxChange = (value: CarCheckOption) => {
    setSelectedCarCheckId(value.id);
    setIsOptionsVisible(false);

    const serviceName = `${value.name}/${value.nameRu}`;
    updateFields({ carProblemForm: { serviceName } }, FORM_TYPE.CAR_PROBLEM);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCarCheckId(null);
    setIsOptionsVisible(false);

    updateFields({ carProblemForm: { serviceName: event.target.value } }, FORM_TYPE.CAR_PROBLEM);
  };

  const handleOptionsButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOptionsVisible(!isOptionsVisible);
  };

  const handlePriorityOptionsChange = (value: ComboBoxOption) => {
    updateFields({ carProblemForm: { priority: value.key as number } }, FORM_TYPE.CAR_PROBLEM);
  };

  const handlePhotoAdded = (file: File) => {
    if (currentValues.photos) {
      updateFields({ carProblemForm: { photos: [...currentValues.photos, file] } }, FORM_TYPE.CAR_PROBLEM);
      return;
    }
    updateFields({ carProblemForm: { photos: [file] } }, FORM_TYPE.CAR_PROBLEM);
  };

  const handleSetPhotos = (files: File[]) => {
    updateFields({ carProblemForm: { photos: files } }, FORM_TYPE.CAR_PROBLEM);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateFields({ carProblemForm: { description: event.target.value } }, FORM_TYPE.CAR_PROBLEM);
  };

  useOutsideClick([problemButtonRef], () => {
    setIsOptionsVisible(false);
  });

  return (
    <div className="flex flex-col flex-1 px-4 md:px-5 py-1 md:py-0 w-full">
      <div className="flex md:flex-row flex-col gap-x-10 gap-y-2 md:gap-y-10 w-full h-full md:h-[unset]" ref={parentRef}>
        <div className="flex flex-col flex-1 md:justify-between gap-y-4 md:gap-y-6">
          <div className="flex flex-col flex-1 gap-y-3">
            <label className="flex items-center font-bold text-2xl">{t('problemForm.priorityLabel')}</label>
            <CheckUpCarProblemsRadioGroup
              priorityOptions={priorityOptions}
              onOptionChange={handlePriorityOptionsChange}
              selectedOption={currentPriority}
            />
          </div>

          <div className="flex flex-col gap-y-3">
            <label className="font-bold text-2xl">{t('problemForm.problemLabel')}</label>
            <Combobox value={currentCarCheckOption || currentValues.serviceName} onChange={handleComboBoxChange}>
              <div className="relative">
                <div className="relative shadow-md w-full text-left text-sm md:text-lg cursor-default overflow-hidden">
                  <Combobox.Input
                    className="bg-white py-4 pr-16 md:pr-10 pl-3 rounded-md focus:ring-0 w-full text-gray-900 text-lg dark:text-black leading-5"
                    onChange={handleInputChange}
                    value={currentCarCheckOption ? currentCarCheckOption?.name : currentValues.serviceName}
                  />
                  <Combobox.Button
                    onClick={handleOptionsButtonClick}
                    className="right-0 absolute inset-y-0 flex items-center pr-5 text-slate-700 hover:text-sky-800"
                  >
                    <HamburgerIcon size="md" />
                  </Combobox.Button>
                </div>
                {isOptionsVisible ? (
                  <Combobox.Options
                    static
                    style={{
                      maxHeight: parentRef.current?.offsetHeight ? `${parentRef.current?.offsetHeight - 20}px` : '96px',
                    }}
                    className={() => {
                      return cn(
                        'z-10 absolute bg-white shadow-lg mt-1 py-1 rounded-md ring-1 ring-black/5 w-full text-lg sm:text-sm md:text-2xl overflow-auto focus:outline-none',
                      );
                    }}
                  >
                    {carCheckOptions
                      .slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((option) => (
                        <Combobox.Option
                          key={option.id}
                          value={option}
                          className={({ active }) =>
                            `border-b-2 relative cursor-default select-none hover:cursor-pointer px-2 py-3 z-10 ${
                              active ? 'bg-sky-700 text-white' : 'text-gray-900'
                            }`
                          }
                        >
                          {option.name}
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                ) : (
                  <></>
                )}
              </div>
            </Combobox>
          </div>
        </div>

        <div className="flex flex-col flex-1 gap-y-9">
          <div className="flex flex-col gap-y-3 h-full">
            <label className="font-bold text-2xl">{t('problemForm.descriptionLabel')}</label>
            <div className="flex-1 bg-red-100">
              <textarea
                onChange={handleDescriptionChange}
                value={currentValues.description}
                className="bg-white shadow-md p-2 focus:ring-0 focus-visible:ring-2 w-full h-full text-gray-900 text-lg resize-none"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-7 md:min-h-[calc(67%-3rem)]">
        <label className="font-bold text-2xl">{t('problemForm.photoLabel')}</label>
        <PhotoUploads
          className="flex-1 h-fit"
          doAfterCompression={handlePhotoAdded}
          onPhotoRemoved={handleSetPhotos}
          defaultValues={currentValues.photos}
        />
      </div>
    </div>
  );
};
