import { getTodayDate } from '@/helpers/dateHelper';

const LOCAL_PROCESSED_WAYPOINTS_LIST_KEY = 'locally-processed-waypoints-list';

interface LocalProcessedWaypointsData {
  date: string;
  list: Record<string, boolean>;
}

const getDefaultList = (): LocalProcessedWaypointsData => ({
  date: getTodayDate(),
  list: {},
});

export const getLocalProcessedWaypointsData = (): LocalProcessedWaypointsData => {
  const savedList = localStorage.getItem(LOCAL_PROCESSED_WAYPOINTS_LIST_KEY);

  try {
    const dateList = JSON.parse(savedList) as LocalProcessedWaypointsData;

    if (dateList.date !== getTodayDate()) {
      return getDefaultList();
    }

    return dateList;
  } catch {
    return getDefaultList();
  }
};

const setLocalProcessedWaypoints = (waypointIds: number[], dateList: LocalProcessedWaypointsData) => {
  if (!waypointIds?.length) {
    return;
  }

  waypointIds.forEach((waypointId) => {
    dateList.list[waypointId] = true;
  });

  localStorage.setItem(LOCAL_PROCESSED_WAYPOINTS_LIST_KEY, JSON.stringify(dateList));
};

const removeProcessedWaypoints = (waypointIds: number[], dateList: LocalProcessedWaypointsData) => {
  if (!waypointIds?.length) {
    return;
  }

  waypointIds.forEach((id) => {
    delete dateList.list[id];
  });

  localStorage.setItem(LOCAL_PROCESSED_WAYPOINTS_LIST_KEY, JSON.stringify(dateList));
};

export const getAndRemoveLocalProcessedWaypoints = (waypointIds: number[]) => {
  const dateList = getLocalProcessedWaypointsData();

  removeProcessedWaypoints(waypointIds, dateList);
};

export const getAndSetLocalProcessedWaypoints = (waypointIds: number[]) => {
  const dateList = getLocalProcessedWaypointsData();

  setLocalProcessedWaypoints(waypointIds, dateList);
};
