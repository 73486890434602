import serviceWaypoints, { UploadServiceWaypointPicturePayload } from '@/services/service-waypoints.service';
import { baseApi, SERVICE_WAYPOINTS_KEY } from '@/store/baseApi';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [SERVICE_WAYPOINTS_KEY],
});

export const serviceWaypointsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadWaypointPicture: builder.mutation<{ filePath: string }, UploadServiceWaypointPicturePayload>({
      queryFn: async (body) => {
        const result = await serviceWaypoints.uploadWaypointPicture(body);
        return { data: result };
      },
    }),
    getWaypointPictures: builder.query<any[], number[]>({
      queryFn: async (serviceIds) => {
        const result = await serviceWaypoints.getWaypointPictures(serviceIds);
        return { data: result };
      },
    }),
  }),
});

export const { useUploadWaypointPictureMutation, useLazyGetWaypointPicturesQuery } = serviceWaypointsApi;
