import React, { ComponentPropsWithoutRef, PropsWithChildren, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import LogoImage from '@/assets/images/logo.png';
import NotificationBadgeWrapper from '../elements/NotificationBadgeWrapper';
import HorizontalShadowScroll from '../elements/HorizontalShadowScroll';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAppDisabled } from '@/store/settings/slice';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import Sidebar from './Sidebar';
import HeaderIndicators from '../elements/HeaderIndicators';
import HamburgerIcon from '../icons/HamburgerIcon';
import { useSidebar } from '@/hooks/useSidebar';
import Spinner from '../elements/Spinner';
import { setDisplaySidebarOnMobile, useWaypointsRequest } from '@/features/route';
import SidebarIcon from '../icons/SidebarIcon';
import { cn } from '@/utils/cn';

interface AdvancedHeaderProps extends ComponentPropsWithoutRef<'div'> {
  shouldDisplayMenu?: boolean;
  shouldHeaderIconsDisplay?: boolean;
}

const AdvancedHeader = ({ shouldDisplayMenu, shouldHeaderIconsDisplay }: PropsWithChildren<AdvancedHeaderProps>) => {
  const isAppDisabled = useSelector(selectIsAppDisabled);
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { isSidebarDisplayed, closeSidebar, toggleSidebar } = useSidebar();
  const { navigationItems } = useAdvancedNavigationItems();

  const { isLoading: isWaypointsLoading } = useWaypointsRequest();

  const { isSidebarDisplayed: isAlcoholPopupVisible, closeSidebar: closeAlcoholPopup, toggleSidebar: toggleAlcoholPopup } = useSidebar();
  const alcoholSpanRef = useRef<HTMLSpanElement>(null);

  return (
    <div className="tablet-header">
      {pathname === '/' && (
        <div className="tablet-header__logo">
          <div className="header-logo-link">
            <img src={LogoImage} width={50} height={50} alt={'logo'} />
          </div>
        </div>
      )}
      <div className="tablet-header__navigation scrollbar-hide overflow-x-auto">
        {navigationItems.map((item, index) => (
          <button
            onClick={item.action}
            key={index}
            className={cn({
              'tablet-header__navigation-link': true,
              [`tablet-header__navigation-link--${item.style}`]: !!item.style,
              'header__link-disabled': item.disabled || isAppDisabled,
            })}
            disabled={item.disabled || item.isPending || isAppDisabled}
          >
            {item.isPending ? (
              <Spinner size="lg" color="disabled" />
            ) : (
              <NotificationBadgeWrapper notificationsAmount={item.notificationsAmount ?? 0}>{item.icon}</NotificationBadgeWrapper>
            )}

            <span>{item.label}</span>
          </button>
        ))}
      </div>
      {shouldDisplayMenu && (
        <div className="waypoints-list-bar__item__hamburger md:mr-6 pl-4 ml-auto mr-2">
          {shouldHeaderIconsDisplay && (
            <HeaderIndicators
              isAlcoholPopupVisible={isAlcoholPopupVisible}
              onAlcoholPopupIconClick={toggleAlcoholPopup}
              alcoholSpanRef={alcoholSpanRef}
            />
          )}
          <button onClick={toggleSidebar} className="hamburger-button">
            <HamburgerIcon size={'md'} />
          </button>
        </div>
      )}

      {!shouldDisplayMenu && (
        <button
          className="tablet-header__navigation-link tablet-header__navigation-link--primary tablet-header__mobile-list-toggle"
          onClick={() => dispatch(setDisplaySidebarOnMobile(true))}
        >
          {isWaypointsLoading ? <Spinner size={'lg'} color="primary" /> : <SidebarIcon size={'md'} />}
        </button>
      )}
      {isSidebarDisplayed && <Sidebar close={closeSidebar} isAdvancedHeader />}
    </div>
  );
};

export default AdvancedHeader;
