import React, { forwardRef } from 'react';
import ModalWrapper from './ModalWrapper';
import ToggleButton from '../elements/ToggleButton';
import { useTheme } from '@/contexts/themeContext';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppMode } from '@/types/common';
import { useUserProfession } from '@/features/home';
import { useAppMode } from '@/hooks/useAppMode';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { selectIsSyncingEnabled, setSyncingEnabled } from '@/store/settings/slice';
import { useSocketStatus } from '@/contexts/socketContext';
import { cn } from '@/utils/cn';

interface SettingsModalProps {
  isVisible: boolean;
  onClose: () => void;
}

interface SettingToggleOption {
  label: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  id: string;
}

const SettingsModal = forwardRef<HTMLDivElement, SettingsModalProps>(({ isVisible, onClose }, ref) => {
  const { theme, setTheme } = useTheme();
  const { t } = useTranslation();
  const { isUserLoader } = useUserProfession();

  const { appMode, setAppMode } = useAppMode();
  const navigate = useNavigate();
  const isSocketConnected = useSocketStatus();

  const dispatch = useAppDispatch();
  const isSyncingEnabled = useAppSelector(selectIsSyncingEnabled);

  const handleModeChange = (checked: boolean) => {
    const mode = checked ? AppMode.Advanced : AppMode.Basic;
    setAppMode(mode);
    localStorage.setItem('appMode', mode);
    navigate('/');
    onClose();
  };

  const handleDarkModeChange = (checked: boolean) => {
    setTheme(checked ? 'dark' : 'light');
  };

  const handleSyncingEnabledChange = (checked: boolean) => {
    dispatch(setSyncingEnabled(checked));
  };

  const options: SettingToggleOption[] = [
    ...(!isUserLoader()
      ? [
          {
            id: 'advancedMode',
            label: t('advancedMode'),
            value: appMode === AppMode.Advanced,
            onChange: handleModeChange,
          },
        ]
      : []),
    {
      id: 'darkMode',
      label: t('darkMode'),
      value: theme === 'dark',
      onChange: handleDarkModeChange,
    },
    {
      id: 'syncingEnabled',
      label: t('syncingEnabled'),
      value: isSyncingEnabled,
      onChange: handleSyncingEnabledChange,
    },
  ];

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose();
    event.stopPropagation();
  };

  return (
    <ModalWrapper
      ref={ref}
      isVisible={isVisible}
      title={t('settings', { ns: 'common' })}
      titleAdditionalElement={<small className={'text-gray-600 ml-auto'}>{__APP_VERSION__}</small>}
      className="mt-10 h-fit self-start"
    >
      <div className="settings-modal">
        <div className="settings-modal__list">
          {options.map((option) => (
            <div className="settings-modal__list-item" key={option.id}>
              <label className="settings-modal__label" htmlFor={option.id}>
                {option.label}
              </label>
              <ToggleButton checked={option.value} inputId={option.id} onChange={option.onChange} />
            </div>
          ))}
          <div className="settings-modal__list-item" key="socket">
            <label className="settings-modal__label">
              {t('socketConnection', {
                ns: 'common',
              })}
            </label>
            <span className={cn('font-bold', { 'text-green-500': isSocketConnected, 'text-red-500': !isSocketConnected })}>
              {isSocketConnected
                ? t('connected', {
                    ns: 'common',
                  })
                : t('disconnected', {
                    ns: 'common',
                  })}
            </span>
          </div>
        </div>
        <div className="settings-modal__footer">
          <Button text={t('close')} color={'disabled'} size={'md'} wide onClick={handleClose} />
        </div>
      </div>
    </ModalWrapper>
  );
});

SettingsModal.displayName = 'SettingsModal';

export default SettingsModal;
