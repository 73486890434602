import { cn } from '@/utils/cn';
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';

const colors = {
  primary: 'bg-blue-600 dark:bg-blue-300 text-white dark:text-black',
  danger: 'bg-red-600 dark:bg-red-300 text-white dark:text-black',
  warning: 'bg-amber-500 dark:bg-yellow-300 text-white dark:text-black',
  success: 'bg-green-600 dark:bg-green-300 text-white dark:text-black',
};

type ChipProps = ComponentPropsWithoutRef<'div'> & {
  variant: keyof typeof colors;
  size: 'sm' | 'md' | 'lg' | 'xl';
  children: ReactNode;
};

const sizes = {
  sm: 'py-1 px-2 text-sm',
  md: 'py-2 px-3 text-base',
  lg: 'py-3 px-4 text-lg',
  xl: 'py-4 px-5 text-xl',
};

const Chip = ({ variant, size, children, className, ...rest }: ChipProps) => {
  const colorClasses = colors[variant] || colors.primary;
  const sizeClasses = sizes[size] || sizes.md;

  return (
    <div
      className={cn(`inline-flex items-center justify-center text-center ${colorClasses} ${sizeClasses} rounded-full`, className)}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Chip;
