import { useGetPlanningForRouteGroupItemQuery, useLazyGetPlanningForRouteGroupItemQuery } from '@/features/home';
import { useAppDispatch } from '@/store/types';
import { RouteGroupItemPlanning, RouteGroupItemPlanningService } from '@ekt-group/general-purpose-api-interfaces';

type RouteGroupItemPlanningStatus = RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>['status'];

export const usePlanning = () => {
  const [getPlanningItems, { data: allPlanningItems, isLoading: isLoadingPlanning, isFetching: isFetchingPlanning }] =
    useLazyGetPlanningForRouteGroupItemQuery();

  const optimizedItem: RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom> = allPlanningItems
    ?.slice()
    ?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
    .find(({ status }) => status === 'finished');

  return {
    getPlanningItems,
    allPlanningItems,
    optimizedItem,
    isLoadingPlanning,
    isFetchingPlanning,
  };
};
