import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const SocketIcon = ({ size }: IconSizeProps) => (
  <svg
    fill="currentColor"
    height="800px"
    width="800px"
    className={iconStyles[iconSizeClasses[size]]}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
  >
    <path
      d="M49.833,24.917c0,13.739-11.178,24.917-24.916,24.917c-0.828,0-1.5-0.671-1.5-1.5V36.259
 c-5.606-0.739-9.952-5.537-9.952-11.342c0-0.829,0.672-1.5,1.5-1.5h3.073V13.94c0-0.829,0.672-1.5,1.5-1.5
 c0.828,0,1.5,0.671,1.5,1.5v9.477h7.757V13.94c0-0.829,0.672-1.5,1.5-1.5c0.828,0,1.5,0.671,1.5,1.5v9.477h3.074
 c0.828,0,1.5,0.671,1.5,1.5c0,5.805-4.346,10.603-9.952,11.342v10.524c11.388-0.773,20.416-10.285,20.416-21.866
 C46.833,12.832,37.002,3,24.917,3C12.832,3,3,12.832,3,24.917c0,4.599,1.408,9,4.071,12.727c0.481,0.674,0.325,1.611-0.349,2.092
 c-0.675,0.482-1.611,0.326-2.093-0.348C1.602,35.148,0,30.145,0,24.917C0,11.178,11.178,0,24.917,0
 C38.655,0,49.833,11.178,49.833,24.917z"
    />
  </svg>
);

export default SocketIcon;
