import React, { useEffect } from 'react';
import { CheckUpFormData, FORM_TYPE } from './CheckUpModal';
import { ValueOf } from '@/utils/value-of';
import { usePhotoUploadForm, PhotoUploadFormInput, Mode } from '@/features/photo';
import truck1 from '@/assets/images/car-check-up/truck-1.webp';
import truck2 from '@/assets/images/car-check-up/truck-2.webp';
import truck3 from '@/assets/images/car-check-up/truck-3.webp';
import truck4 from '@/assets/images/car-check-up/truck-4.webp';
import { useTranslation } from 'react-i18next';
interface CheckUpPicturesFormProps {
  updateFields: (fields: Partial<CheckUpFormData>, source: ValueOf<typeof FORM_TYPE>) => void;
  values: CheckUpFormData['picturesForm'];
}

// TODO: Move everything in usePhotoUploadForm to be used internally by PhotoUploadFormInput, so we can refactor this
// into something more readable, not photo1, photo2, etc.
export const CheckUpPicturesForm = ({ updateFields, values }: CheckUpPicturesFormProps) => {
  const { t } = useTranslation('carCheckUpModal');

  const {
    inputRef: ref1,
    photo: photo1,
    setPhoto: setPhoto1,
    photoPreview: preview1,
    resetInput: reset1,
  } = usePhotoUploadForm({
    defaultValues: {
      photo: values.files.find((file) => file.position === 1)?.file || null,
    },
  });
  const {
    inputRef: ref2,
    photo: photo2,
    setPhoto: setPhoto2,
    photoPreview: preview2,
    resetInput: reset2,
  } = usePhotoUploadForm({
    defaultValues: {
      photo: values.files.find((file) => file.position === 2)?.file || null,
    },
  });
  const {
    inputRef: ref3,
    photo: photo3,
    setPhoto: setPhoto3,
    photoPreview: preview3,
    resetInput: reset3,
  } = usePhotoUploadForm({
    defaultValues: {
      photo: values.files.find((file) => file.position === 3)?.file || null,
    },
  });
  const {
    inputRef: ref4,
    photo: photo4,
    setPhoto: setPhoto4,
    photoPreview: preview4,
    resetInput: reset4,
  } = usePhotoUploadForm({
    defaultValues: {
      photo: values.files.find((file) => file.position === 4)?.file || null,
    },
  });

  const handlePhotoAdded = (file: File, position?: number) => {
    updateFields({ picturesForm: { files: [...values.files, { file, position }] } }, FORM_TYPE.PICTURES);
  };

  useEffect(() => {
    if (!values.files || values.files.length === 0) {
      reset1();
      reset2();
      reset3();
      reset4();
    }
  }, [reset1, reset2, reset3, reset4, values.files]);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full h-full text-lg font-bold text-black dark:text-white">
        <div className="flex items-center flex-col text-left h-full w-full">
          <div className="max-w-[52rem] flex-1 w-full">
            <label className="self-start text-lg font-bold">{t('picturesForm.photoLabel1')}</label>
            <div className="h-dynamic-35">
              <PhotoUploadFormInput
                classNames=""
                mode={Mode.ALLOW_EDIT}
                backgroundPhoto={truck1}
                setPhoto={setPhoto1}
                photo={photo1}
                photoPreview={preview1}
                resetInput={reset1}
                inputRef={ref1}
                doAfterCompression={(file) => handlePhotoAdded(file, 1)}
                enabledFeatures={['camera']}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center flex-col text-left h-full w-full">
          <div className="max-w-[52rem] flex-1 h-dynamic-40 w-full">
            <label className="self-start text-lg font-bold">{t('picturesForm.photoLabel2')}</label>
            <div className="h-dynamic-35">
              <PhotoUploadFormInput
                classNames=""
                mode={Mode.ALLOW_EDIT}
                backgroundPhoto={truck2}
                setPhoto={setPhoto2}
                photo={photo2}
                photoPreview={preview2}
                resetInput={reset2}
                inputRef={ref2}
                doAfterCompression={(file) => handlePhotoAdded(file, 2)}
                enabledFeatures={['camera']}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center flex-col text-left h-full w-full">
          <div className="max-w-[52rem] flex-1 h-dynamic-40 w-full">
            <label className="self-start text-lg font-bold">{t('picturesForm.photoLabel3')}</label>
            <div className="h-dynamic-35">
              <PhotoUploadFormInput
                classNames=""
                mode={Mode.ALLOW_EDIT}
                backgroundPhoto={truck3}
                setPhoto={setPhoto3}
                photo={photo3}
                photoPreview={preview3}
                resetInput={reset3}
                inputRef={ref3}
                doAfterCompression={(file) => handlePhotoAdded(file, 3)}
                enabledFeatures={['camera']}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center flex-col text-left h-full w-full">
          <div className="max-w-[52rem] flex-1 h-dynamic-40 w-full">
            <label className="self-start text-lg font-bold">{t('picturesForm.photoLabel4')}</label>
            <div className="h-dynamic-35">
              <PhotoUploadFormInput
                classNames=""
                mode={Mode.ALLOW_EDIT}
                backgroundPhoto={truck4}
                setPhoto={setPhoto4}
                photo={photo4}
                photoPreview={preview4}
                resetInput={reset4}
                inputRef={ref4}
                doAfterCompression={(file) => handlePhotoAdded(file, 4)}
                enabledFeatures={['camera']}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CheckUpPicturesForm.displayName = 'PicturesForm';
