import React from 'react';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';
import iconStyles from '../../assets/styles/icons.module.scss';

const MailIcon = ({ size }: IconSizeProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    className={iconStyles[iconSizeClasses[size]]}
    fill="currentColor"
  >
    <g>
      <path d="M612,591.7C556.7,636,522.2,658,487.8,658c-33,0-66.4-20.6-118-60.8L62.7,867.5h864.9L615.5,589.1C614.3,589.9,613,591,612,591.7z" />
      <path d="M247.7,502.7C174.8,446.5,90.7,381.5,10,317.6v509.5c0,1.6,0.8,3,0.9,4.5l309.6-272.5C298.1,541.6,274,523.1,247.7,502.7z" />
      <path d="M987.6,838.9c1.2-3.7,2.4-7.6,2.4-11.8V296c-96.9,75.4-237.2,184.6-325.8,254.5L987.6,838.9z" />
      <path d="M928.8,132.5H71.3c-22.4,0-61.3,38.9-61.3,61.3V242c90.3,71.9,188.4,147.6,271.5,211.7c45.8,35.4,86.9,67.2,119.3,92.5c87.4,68.5,87.4,68.5,176.6-2.8C658.1,479,875.2,310.1,990,220.8v-26.9C990,171.4,951.1,132.5,928.8,132.5z" />
    </g>
  </svg>
);

export default MailIcon;
