import React from 'react';
import { useDispatch } from 'react-redux';
import { setDisplayArrivedPage, setDisplaySidebarOnMobile } from '@/features/route';
import WaypointListItemTemplate from './WaypointListItemTemplate';
import { Waypoint, WaypointType } from '@/features/home';
import InstallContainerBadge from '@/components/elements/InstallContainerBadge';
import DeInstallContainerBadge from '@/components/elements/DeInstallContainerBadge';
import { unifiedString } from '@/helpers/strings';
import { useObjectAddress } from '@/hooks/useObjectAddress';
import { ObjectServiceActivityType } from '@/types/objects';

interface WaypointServiceListItemProps {
  waypointsGroup: Waypoint<WaypointType.Service>[];
  selectedWaypointIds: number[];
  isOnMainScreen?: boolean;
}

const WaypointServiceListItem = ({ waypointsGroup, selectedWaypointIds, isOnMainScreen }: WaypointServiceListItemProps) => {
  const dispatch = useDispatch();

  const { object, owner, notes } = waypointsGroup[0];

  const address = useObjectAddress(object, ['county']);
  const isSelected = waypointsGroup.some((waypoint) => selectedWaypointIds.includes(waypoint.id));
  const isDone = waypointsGroup.every((waypoint) => waypoint.doneAt && !waypoint.isFailed);
  const isFailed = waypointsGroup.every((waypoint) => waypoint.doneAt && waypoint.isFailed);
  const isPartiallyDone = !isDone && !isFailed && waypointsGroup.some((waypoint) => waypoint.doneAt);

  const handleArrivedClick = () => {
    dispatch(setDisplayArrivedPage(true));
    dispatch(setDisplaySidebarOnMobile(false));
  };

  const { hasInstallation, hasDeInstallation } = waypointsGroup.reduce<{ hasInstallation: boolean; hasDeInstallation: boolean }>(
    (acc, { activityType }) => {
      if (activityType === ObjectServiceActivityType.Install) {
        acc.hasInstallation = true;
      }
      if (activityType === ObjectServiceActivityType.DeInstall) {
        acc.hasDeInstallation = true;
      }

      return acc;
    },
    {
      hasInstallation: false,
      hasDeInstallation: false,
    },
  );

  return (
    <WaypointListItemTemplate
      waypointsGroup={waypointsGroup}
      isPartiallyDone={isPartiallyDone}
      isDone={isDone}
      isSelected={isSelected}
      isFailed={isFailed}
      address={address}
      isOnMainScreen={isOnMainScreen}
      isDisabled={!waypointsGroup.some((waypoint) => waypoint.isFiltered)}
      ownerName={owner?.companyName || unifiedString([owner?.firstname, owner?.surname], ' ')}
      notes={notes}
      onArrivedButtonClick={handleArrivedClick}
      addressPrefixSlot={
        <>
          {hasInstallation && <InstallContainerBadge />}
          {hasDeInstallation && <DeInstallContainerBadge />}
        </>
      }
      showContainers
    />
  );
};

export default WaypointServiceListItem;
