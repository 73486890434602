import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@/store';
import { Fuel, GasCard } from '@ekt-group/general-purpose-api-interfaces';

export interface FuelingPageState {
  gasCards: GasCard[];
  fuel: Fuel[];
  selectedGasCardId: number | null;
  selectedFuelId: number | null;
  isLoadingGasCards: boolean;
  isLoadingFuel: boolean;
  isSaving: boolean;
  fuelAmount: number;
}

const initialState: FuelingPageState = {
  gasCards: [],
  fuel: [],
  selectedGasCardId: null,
  selectedFuelId: null,
  isLoadingGasCards: false,
  isLoadingFuel: false,
  isSaving: false,
  fuelAmount: 0,
};

export const fuelingPageSlice = createSlice({
  name: 'fuelingPage',
  initialState,
  reducers: {
    setGasCards: (state, action) => {
      state.gasCards = action.payload;
    },
    setFuel: (state, action) => {
      state.fuel = action.payload;
    },
    setSelectedGasCardId: (state, action) => {
      state.selectedGasCardId = action.payload;
    },
    setSelectedFuelId: (state, action) => {
      state.selectedFuelId = action.payload;
    },
    setIsLoadingGasCards: (state, action) => {
      state.isLoadingGasCards = action.payload;
    },
    setIsLoadingFuel: (state, action) => {
      state.isLoadingFuel = action.payload;
    },
    setFuelAmount: (state, action) => {
      state.fuelAmount = action.payload;
    },
    setIsFuelingSaving: (state, action) => {
      state.isSaving = action.payload;
    },
    resetFuelingPageState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setGasCards,
  setSelectedGasCardId,
  setSelectedFuelId,
  setFuel,
  setIsLoadingGasCards,
  setIsLoadingFuel,
  setFuelAmount,
  setIsFuelingSaving,
  resetFuelingPageState,
} = fuelingPageSlice.actions;

export const selectGasCards = (state: AppState) => state.fuelingPage.gasCards;
export const selectFuel = (state: AppState) => state.fuelingPage.fuel;
export const selectSelectedGasCardId = (state: AppState) => state.fuelingPage.selectedGasCardId;
export const selectSelectedFuelId = (state: AppState) => state.fuelingPage.selectedFuelId;
export const selectIsLoadingGasCards = (state: AppState) => state.fuelingPage.isLoadingGasCards;
export const selectIsLoadingFuel = (state: AppState) => state.fuelingPage.isLoadingFuel;
export const selectSelectedGasCard = (state: AppState) => {
  return state.fuelingPage.gasCards.find((gasCard) => gasCard.id === state.fuelingPage.selectedGasCardId);
};
export const selectFuelAmount = (state: AppState) => state.fuelingPage.fuelAmount;
export const selectIsFuelingSaving = (state: AppState) => state.fuelingPage.isSaving;

export default fuelingPageSlice.reducer;
