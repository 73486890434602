import React, { useEffect } from 'react';
import ModalWrapper from '@/components/modals/ModalWrapper';
import Button from '@/components/elements/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArrivedPageForm,
  selectIsTakePhotoModalVisible,
  setFormWaypointIdsForPhotoUpload,
  setIsTakePhotoModalVisible,
  toggleWaypointIdForPhotoUpload,
} from '@/features/route';
import { selectCurrentRouteGroupItem, Waypoint } from '@/features/home';
import WaypointSelectItem from '../../../photo/components/WaypointSelectItem';
import VerticalShadowScroll from '@/components/elements/VerticalShadowScroll';
import { getPhotoUploadingStatus, setPhotoUploadingStatus } from '@/helpers/localStorage/photoUploadingHelper';
import { PhotoUploadingStatus, usePhotoUploadForm } from '@/features/photo';
import { toast } from 'react-toastify';
import { isToday } from '@/helpers/dateHelper';
import { photoDB, PhotoEntity } from '@/database';
import { AppMode } from '@/types/common';
import { selectUserId } from '@/features/auth';
import { useRollbar } from '@rollbar/react';
import { selectMode } from '@/store/settings/slice';
import { PhotoInputWithCoordinates } from '@/features/photo';
import waypointsService from '@/services/discharge-waypoints.service';
import serviceWaypoints from '@/services/service-waypoints.service';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { socket } from '@/lib/socket';

interface TakePhotoModalProps {
  arrivedWaypoints: Waypoint[];

  onPhotoUpload?: () => void;
  onPhotoSubmitted?: () => void;
  onPhotoDeleted?: () => void;
  onPhotoAdded?: () => void;
}

const TakePhotoModal = ({ arrivedWaypoints, onPhotoUpload, onPhotoSubmitted, onPhotoDeleted, onPhotoAdded }: TakePhotoModalProps) => {
  const dispatch = useDispatch();
  const rollbar = useRollbar();
  const isOffline = useOfflineStatus();

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const appMode = useSelector(selectMode);
  const isVisible = useSelector(selectIsTakePhotoModalVisible);
  const userId = useSelector(selectUserId);
  const { waypointIdsForPhotoUpload } = useSelector(selectArrivedPageForm) || {};

  const { photo, photoPreview, inputRef, uploadPhoto, setPhoto, resetInput } = usePhotoUploadForm({
    onUpload: async (photo: File) => {
      if (!waypointIdsForPhotoUpload?.length) {
        toast(t('selectWaypoint', { ns: 'photoPage' }), {
          type: 'error',
        });
        return;
      }

      onPhotoUpload?.();

      const photoEntity = currentRouteGroupItem?.isDischargeSheet ? PhotoEntity.DischargeWaypoint : PhotoEntity.ServiceWaypoint;
      const isBasicAppMode = appMode === AppMode.Basic;

      let serverFilePath = '';

      if (!isOffline) {
        try {
          const startTime = performance.now();

          const { filePath } = currentRouteGroupItem?.isDischargeSheet
            ? await waypointsService.uploadPicture({ picture: photo, waypointIds: waypointIdsForPhotoUpload, isBasicAppMode })
            : await serviceWaypoints.uploadWaypointPicture({
                picture: photo,
                serviceIds: waypointIdsForPhotoUpload,
                routeGroupItemId: currentRouteGroupItem?.id,
              });

          serverFilePath = filePath;
          socket.volatile.emit('PhotoUploaded', { type: 'PhotoUploaded', payload: { filePath, waypointIds: waypointIdsForPhotoUpload } });
          const endTime = performance.now();

          if (endTime - startTime > 5000) {
            rollbar.warn('[PhotoUploading - Online] Long photo upload time', {
              duration: endTime - startTime,
              currentPhotoStatus: getPhotoUploadingStatus(),
              userId,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }

      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadRequestAddingToDatabase);

      await photoDB.photos.add({
        photo,
        waypointIds: waypointIdsForPhotoUpload,
        routeGroupItemName: currentRouteGroupItem?.name,
        routeGroupName: currentRouteGroupItem?.routeGroup?.name,
        routeGroupItemId: currentRouteGroupItem?.id,
        timestamp: Date.now(),
        entity: photoEntity,
        serverFilePath,
        isBasicAppMode,
      });

      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadRequestAddedToDatabase);
    },
  });

  const { t } = useTranslation();

  const handleUpload = () => {
    dispatch(setIsTakePhotoModalVisible(false));
    setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadButtonClicked);
    uploadPhoto();
  };

  useEffect(() => {
    if (!isVisible) {
      dispatch(setFormWaypointIdsForPhotoUpload([]));
      setPhotoUploadingStatus(PhotoUploadingStatus.NotUploading);
      return;
    }

    setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadModalOpened);
  }, [isVisible]);

  return (
    <ModalWrapper isVisible={isVisible} className="h-fit overflow-y-auto">
      <div className={'flex flex-col gap-4'}>
        <PhotoInputWithCoordinates
          arrivedObject={arrivedWaypoints.length ? arrivedWaypoints[0].object : null}
          photo={photo}
          photoPreview={photoPreview}
          setPhoto={setPhoto}
          resetInput={resetInput}
          inputRef={inputRef}
          doAfterCompression={onPhotoAdded}
        />
        <div className={'flex gap-4'}>
          <Button
            disabled={!Boolean(photo)}
            text={t('delete')}
            color="danger"
            size="lg"
            onClick={() => {
              resetInput();
              onPhotoDeleted?.();

              setPhotoUploadingStatus(PhotoUploadingStatus.NotUploading);
            }}
            wide={true}
          />
          <Button
            disabled={!Boolean(photo)}
            text={t('submit')}
            color="success"
            size="lg"
            onClick={() => {
              handleUpload();
              onPhotoSubmitted?.();
            }}
            wide={true}
          />
        </div>

        <VerticalShadowScroll className="photo__waypoint-items-wrapper max-h-[25vh]">
          {arrivedWaypoints?.map((waypoint) => (
            <div key={waypoint.id} className="take-photo-modal__waypoint">
              <WaypointSelectItem
                waypoint={waypoint}
                isSelected={waypointIdsForPhotoUpload?.includes(waypoint.id)}
                clickHandler={() => dispatch(toggleWaypointIdForPhotoUpload(waypoint.id))}
              />
            </div>
          ))}
        </VerticalShadowScroll>

        <Button
          text={t('close')}
          color="disabled"
          size="lg"
          onClick={() => {
            resetInput();
            dispatch(setIsTakePhotoModalVisible(false));
          }}
          wide={true}
        />
      </div>
    </ModalWrapper>
  );
};

export default TakePhotoModal;
