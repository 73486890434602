import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ObjectGate } from '../types/gates';
import OpenGatesAction from './OpenGatesAction';
import { selectObjectGates } from '@/store/waypoints/slice';
import { useFilteredGatesByRadius } from '../hooks/useFilteredGatesByRadius';

interface OpenGatesActionsProps {
  selectedWaypointIds: number[];
}

const areEqual = (prev: OpenGatesActionsProps, next: OpenGatesActionsProps) => {
  if (prev.selectedWaypointIds.length !== next.selectedWaypointIds.length) {
    return false;
  }

  const prevIdsMatched = prev.selectedWaypointIds.every((id) => next.selectedWaypointIds.includes(id));
  const nextIdsMatched = next.selectedWaypointIds.every((id) => prev.selectedWaypointIds.includes(id));

  return prevIdsMatched && nextIdsMatched;
};

const OpenGatesActions = ({ selectedWaypointIds }: OpenGatesActionsProps) => {
  const [selectedObjectGate, setSelectedObjectGate] = useState<ObjectGate>(null);

  const { filteredGates } = useFilteredGatesByRadius();

  const objectGates = useSelector(selectObjectGates);

  const setObjectGate = useCallback(() => {
    const foundGate = objectGates.find((gate) => selectedWaypointIds.includes(gate.routeGroupItemWaypointId));

    if (foundGate) {
      setSelectedObjectGate(foundGate);
    } else {
      setSelectedObjectGate(null);
    }
  }, [objectGates, selectedWaypointIds]);

  useEffect(() => {
    if (!selectedWaypointIds.length || !objectGates.length) {
      return;
    }

    setObjectGate();
  }, [objectGates, selectedWaypointIds, setObjectGate]);

  const objectMobileInfoCard = document.querySelector<HTMLDivElement>('.tablet-page__waypoints-list__group-item--mobile');

  return (
    <div
      className="fixed left-2 md:left-4 flex flex-col gap-2 justify-between z-[3] open-gates-actions-wrapper"
      style={{ bottom: Boolean(objectMobileInfoCard) ? `calc(${objectMobileInfoCard.offsetHeight}px + 1rem)` : '0.5rem' }}
    >
      {filteredGates.map((gate) => (
        <OpenGatesAction gate={gate} key={`open-object-gate-${gate.phone}`} />
      ))}
      {selectedObjectGate && <OpenGatesAction gate={selectedObjectGate} />}
      {selectedObjectGate && selectedObjectGate.secondPhone && <OpenGatesAction gate={selectedObjectGate} isSecondNumber />}
    </div>
  );
};

export default React.memo(OpenGatesActions);
