import { Waypoint } from '@/features/home';
import { selectCommonItemMap } from '@/store/common/slice';
import { setWaypointGroupsWithAdditionalInfo } from '@/store/waypoints/slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// given an array of Waypoint[]
// set values into the store of merged waypoints with itemtypes and garbages
export const useMergedWaypointGroups = (waypointGroups: Waypoint[][]) => {
  const dispatch = useDispatch();
  const itemTypes = useSelector(selectCommonItemMap('itemTypes'));
  const garbages = useSelector(selectCommonItemMap('garbage'));

  useEffect(() => {
    const waypointGroupsWithAdditionalInfo = waypointGroups.map((group) => {
      if (!itemTypes || !garbages) {
        return [];
      }

      return group.map((waypoint) => {
        return {
          ...waypoint,
          itemType: itemTypes[waypoint.objectItem.itemTypeId],
          garbage: garbages[waypoint.objectItem.garbageId],
        };
      });
    });

    dispatch(setWaypointGroupsWithAdditionalInfo(waypointGroupsWithAdditionalInfo));
  }, [waypointGroups, dispatch, itemTypes, garbages]);

  return {};
};
