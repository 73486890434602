export enum PhotoUploadingStatus {
  NotUploading = 'not-uploading',
  PhotoUploadModalOpened = 'photo-upload-modal-opened',
  PhotoTakeButtonClicked = 'photo-take-button-clicked',
  PhotoSelectButtonClicked = 'select-photo-button-clicked',
  PhotoInputReturn = 'photo-input-return',
  PhotoCompressionStarted = 'photo-compression-started',
  PhotoCompressionFinished = 'photo-compression-finished',
  PhotoUploadButtonClicked = 'photo-upload-button-clicked',
  PhotoUploadRequestAddingToDatabase = 'photo-upload-request-adding-to-database',
  PhotoUploadRequestAddedToDatabase = 'photo-upload-request-added-to-database',
  PhotoUploadingStarted = 'photo-uploading-started',
  PhotoUploadingFinished = 'photo-uploading-finished',
}
