import React from 'react';
import styles from '../../assets/styles/sidebarItem.module.scss';
import NotificationBadgeWrapper from '../elements/NotificationBadgeWrapper';
import { Link } from 'react-router-dom';

interface SidebarItemProps {
  icon?: JSX.Element;
  label: string;
  link: string;
  onClick: () => void;
  notificationAmount?: number;
}

const SidebarItem = ({ icon, link, label, onClick, notificationAmount }: SidebarItemProps) => {
  return (
    <Link to={link}>
      <div className={styles['sidebar-item']} onClick={onClick}>
        <NotificationBadgeWrapper notificationsAmount={notificationAmount ?? 0}>{icon}</NotificationBadgeWrapper>
        <span className="mx-4 text-lg font-normal leading-5">{label}</span>
      </div>
    </Link>
  );
};

export default SidebarItem;
