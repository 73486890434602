import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAlcoholCheckPassed,
  selectIsAutomaticAlcoholCheckCompleted,
  selectUserId,
  setIsAlcoholCheckPassed,
  setIsAutomaticAlcoholCheckCompleted,
} from '@/features/auth';
import externalService from '../../external/api';
import { GetScansResponseDto } from '@ekt-group/bi-services-api-interfaces/src/modules/alcoscan/dto';
import useGeolocation from 'react-hook-geolocation';

const MAX_ALLOWED_ALCOHOL_VALUE = 0.15;

const getLatestScan = (alcoholScans: GetScansResponseDto[]) => {
  return alcoholScans.reduce((lastScan, currentScan) => {
    if (!lastScan) {
      return currentScan;
    }
    if (new Date(currentScan.timestamp) > new Date(lastScan.timestamp)) {
      return currentScan;
    }

    return lastScan;
  }, null);
};

export function useAlcoholCheck() {
  const [isAlcoholCheckLoading, setIsAlcoholCheckLoading] = useState(false);
  const [isAlcoholMeasurementStartLoading, setIsAlcoholMeasurementStartLoading] = useState(false);

  const { latitude, longitude } = useGeolocation();
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();

  const isAlcoholCheckPassed = useSelector(selectIsAlcoholCheckPassed);
  const isAutomaticAlcoholCheckCompleted = useSelector(selectIsAutomaticAlcoholCheckCompleted);

  const checkAlcoholPass = async (enforce = false) => {
    if (isAlcoholCheckLoading || (isAutomaticAlcoholCheckCompleted && !enforce)) {
      return;
    }

    setIsAlcoholCheckLoading(true);

    dispatch(setIsAutomaticAlcoholCheckCompleted(true));

    try {
      const alcoholScans = await externalService.getTodayAlcoscanScans(userId);

      if (alcoholScans.length > 0) {
        const scan = getLatestScan(alcoholScans);

        dispatch(setIsAlcoholCheckPassed(scan.result < MAX_ALLOWED_ALCOHOL_VALUE));
      }
    } finally {
      setIsAlcoholCheckLoading(false);
    }
  };

  const startAlcoholMeasurement = async () => {
    setIsAlcoholMeasurementStartLoading(true);

    try {
      await externalService.startAlcoscanMeasurement(longitude, latitude);
    } finally {
      setIsAlcoholMeasurementStartLoading(false);
    }
  };

  useEffect(() => {
    checkAlcoholPass();
  }, []);

  return {
    isAlcoholCheckPassed,
    isAlcoholCheckLoading,
    isAlcoholMeasurementStartLoading,
    checkAlcoholPass: () => checkAlcoholPass(true),
    startAlcoholMeasurement,
  };
}
