import React from 'react';
import Sidebar from '@/components/navigation/Sidebar';
import HamburgerIcon from '@/components/icons/HamburgerIcon';
import { createPortal } from 'react-dom';
import { cn } from '@/utils/cn';

interface WaypointListHamburgerMenuProps {
  isSidebarVisible: boolean;
  onHamburgerMenuClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closeSidebar: () => void;
  buttonClassName?: string;
}

const WaypointListHamburgerMenu = ({
  isSidebarVisible,
  onHamburgerMenuClick,
  closeSidebar,
  buttonClassName,
}: WaypointListHamburgerMenuProps) => {
  return (
    <>
      <button onClick={onHamburgerMenuClick} role="menu-button" className={cn('hamburger-button', buttonClassName)}>
        <HamburgerIcon size={'md'} />
      </button>

      {isSidebarVisible &&
        createPortal(
          <Sidebar close={closeSidebar} isAdvancedHeader className="top-14 md:top-16 md:[max-w-[300px]" />,
          document.querySelector('.waypoints-list-bar'),
        )}
    </>
  );
};

export default WaypointListHamburgerMenu;
