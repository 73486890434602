import kojvService from '../../api/kojv.service';
import { setArrivedKojv, setArrivedKojvConditions } from '@/features/route';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Waypoint, WaypointType } from '@/features/home';

export function useArrivedPageKojvConditions(arrivedWaypoint: Waypoint) {
  const dispatch = useDispatch();

  const getKojvConditions = async () => {
    if (!arrivedWaypoint || arrivedWaypoint.type !== WaypointType.Discharge) {
      return;
    }

    const { kojv } = arrivedWaypoint;
    const { detailed } = await kojvService.getKojvConditions(kojv.id);
    dispatch(setArrivedKojvConditions(detailed));
    dispatch(setArrivedKojv(kojv));
  };

  useEffect(() => {
    getKojvConditions();
  }, [arrivedWaypoint]);
}
