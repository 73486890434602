import React from 'react';
import Spinner from './Spinner';

const SplashLoading = () => {
  return (
    <div className="centered h-screen">
      <Spinner size={'lg'} />
    </div>
  );
};

export default SplashLoading;
