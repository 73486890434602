import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const GarageIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      className={iconStyles[iconSizeClasses[size]]}
    >
      <g>
        <g>
          <rect x="139.637" y="372.364" width="232.727" height="46.545" />
        </g>
      </g>
      <g>
        <g>
          <rect x="139.637" y="279.273" width="232.727" height="46.545" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M500.379,137.724L267.652,3.127c-7.208-4.169-16.094-4.169-23.304,0L11.621,137.724C4.431,141.883,0,149.562,0,157.87
			v330.858C0,501.58,10.422,512,23.273,512h69.818v-23.273v-23.273v-23.273v-93.091V256c0-12.853,10.422-23.273,23.273-23.273
			h279.273c12.854,0,23.273,10.42,23.273,23.273v93.091v93.091v23.273v23.273V512h69.818C501.582,512,512,501.58,512,488.728V157.87
			C512,149.562,507.571,141.883,500.379,137.724z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon points="139.637,465.455 139.637,488.728 139.637,512 372.364,512 372.364,488.728 372.364,465.455 		" />
        </g>
      </g>
    </svg>
  );
};

export default GarageIcon;
