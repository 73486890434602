import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const HamburgerIcon = ({ size }: IconSizeProps) => (
  <svg
    width="20"
    height="20"
    fill="currentColor"
    className={iconStyles[iconSizeClasses[size]]}
    viewBox="0 0 1792 1792"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
  </svg>
);

export default HamburgerIcon;
