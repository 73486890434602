import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { carAcceptanceRequestsDb } from '@/database';
import { selectUserId } from '@/features/auth';
import { useCreateCarAcceptanceMutation } from '@/features/home';
import { useRollbar } from '@rollbar/react';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export const useCarAcceptanceProcessor = () => {
  const [createCarAcceptanceNetworkRequest] = useCreateCarAcceptanceMutation();

  const userId = useSelector(selectUserId);
  const rollbar = useRollbar();
  const isOffline = useOfflineStatus();

  const processCarAcceptancesQueue = useCallback(async () => {
    const table = carAcceptanceRequestsDb.requests;
    const tableItems = await table.toArray();
    if (!tableItems.length) {
      return;
    }

    const itemsToCreate = tableItems.filter((item) => item.type === 'create');

    for (const item of itemsToCreate) {
      try {
        await createCarAcceptanceNetworkRequest(item.payload).unwrap();
        await table.delete(item.id);
      } catch (error) {
        rollbar.error('[CarAcceptance] Queue Processing failed', error as Error, item.payload, { userId });
        toast(t('error.addCheckUpFailedOffline'), { type: 'error' });
        if ((error as Response).status === 403) {
          await table.delete(item.id);
        }
      }
    }
  }, [createCarAcceptanceNetworkRequest, rollbar, userId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isOffline) {
        processCarAcceptancesQueue();
      }
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOffline, processCarAcceptancesQueue]);
};
