import { useLocation, useMatches } from 'react-router';

// could have been a derived variable from checking the url
// but this allows for more flexibility in the future
export interface RouteIdentifier {
  isPageWithId: boolean;
}

export const useRouteSpecificProps = () => {
  const location = useLocation();
  const matches = useMatches();

  const match = matches.find((match) => match.pathname === location.pathname);

  return { ...match, handle: match.handle as RouteIdentifier };
};
