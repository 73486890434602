import { AppState } from '@/store';
import { Coordinates } from '@/types/common';
import { RouteGroupItemPlanning, RouteGroupItemPlanningService } from '@ekt-group/general-purpose-api-interfaces';
import { PlanningRoutes } from '@ekt-group/map-component';
import { createSlice } from '@reduxjs/toolkit';

// These are the items we use to draw the objects on the map

interface MapObjectsState {
  selectedPlanningItem?: RouteGroupItemPlanning<RouteGroupItemPlanningService.Vroom>;
  coordinatesToObject: number[][];
  planningObject: PlanningRoutes;
  carLogCoordinates: Coordinates[];
}

const initialState: MapObjectsState = {
  selectedPlanningItem: null,
  coordinatesToObject: [],
  planningObject: null,
  carLogCoordinates: null,
};

export const mapObjectsSlice = createSlice({
  name: 'mapObjects',
  initialState,
  reducers: {
    setSelectedPlanningItem: (state, action) => {
      state.selectedPlanningItem = action.payload;
    },
    setCoordinatesToObject: (state, action) => {
      state.coordinatesToObject = action.payload;
    },
    setPlanningObject: (state, action) => {
      state.planningObject = action.payload;
    },
    setCarLogCoordinates: (state, action) => {
      state.carLogCoordinates = action.payload;
    },
  },
});

export const { setCarLogCoordinates, setCoordinatesToObject, setPlanningObject, setSelectedPlanningItem } = mapObjectsSlice.actions;

export const selectSelectedPlanningItem = (state: AppState) => state.mapObjects.selectedPlanningItem;
export const selectCoordinatesToObject = (state: AppState) => state.mapObjects.coordinatesToObject;
export const selectPlanningRoutes = (state: AppState) => state.mapObjects.planningObject;
export const selectCarLogCoordinates = (state: AppState) => state.mapObjects.carLogCoordinates;

export default mapObjectsSlice.reducer;
