import React, { createContext, PropsWithChildren, useContext, useEffect, useRef } from 'react';
import SettingsModal from '../components/modals/SettingsModal';
import useModal from '../hooks/useModal';
import { useDispatch } from 'react-redux';
import { setMode } from '@/store/settings/slice';
import { AppMode } from '@/types/common';
import { useUserProfession } from '@/features/home';
import { useSearchParams } from 'react-router-dom';

interface SettingsContext {
  isVisible: boolean;
  showSettings: () => void;
  hideSettings: () => void;
}

const settingsContext = createContext<SettingsContext>({ showSettings: () => {}, isVisible: false, hideSettings: () => {} });

export function SettingsProvider({ children }: PropsWithChildren) {
  const { isVisible, show, hide } = useModal();
  const { isUserLoader, isUserDriver } = useUserProfession();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const savedAppMode = localStorage.getItem('appMode');
  const queryAppMode = searchParams.get('appMode');

  useEffect(() => {
    if (isUserLoader()) {
      dispatch(setMode(AppMode.Basic));
      return;
    }
    if (queryAppMode && Object.values(AppMode).includes(queryAppMode as AppMode)) {
      dispatch(setMode(queryAppMode as AppMode));
      return;
    }
    if (savedAppMode && Object.values(AppMode).includes(savedAppMode as AppMode)) {
      dispatch(setMode(savedAppMode as AppMode));
      return;
    }
    if (isUserDriver()) {
      dispatch(setMode(AppMode.Advanced));
      return;
    }

    dispatch(setMode(AppMode.Basic));
  }, []);

  return <settingsContext.Provider value={{ showSettings: show, isVisible, hideSettings: hide }}>{children}</settingsContext.Provider>;
}

export function useSettings() {
  return useContext(settingsContext);
}
