import httpService from '../lib/http.service';
import {
  RouteGroupItemWaypoint,
  WaypointDischargeFailureReason,
  WaypointLogDetails,
  WaypointTank,
  WaypointUnload,
  WaypointUnloadCreateDto,
  WaypointUnloadUpdateDto,
} from '@ekt-group/general-purpose-api-interfaces';
import { Coordinates } from '../types/common';
import { DeletedInsertedWaypointIdsResponse, GetArrivedWaypointIdsResponse, WaypointsStatus } from '../types/waypoints';

export interface SaveDischargeNotesDto {
  waypointIds: number[];
  reasonIds: number[];
  additionalNotes: string;
}

export interface SetWaypointStatusDto {
  coordinates: Coordinates;
  dischargesCount: Record<string, number>;
  dynamicCapacities: Record<string, number>;
  weights: Record<string, number>;
  services: Record<string, { id: number; value: number }[]>;
  updateWaypointIds?: number[];
  routeGroupItemId?: number;
  doneAt?: string; // .doneAt for service waypoint, .time or .timestamp for discharge waypoint, we use .doneAt for both in request body
}

export interface UploadWaypointPicturePayload {
  picture: File;
  waypointIds: number[];
  isBasicAppMode: boolean;
}

export interface SetFailedWaypointStatusDto extends SetWaypointStatusDto {
  reasonIds: number[];
  content: string;
}

export interface WaypointUnloadDeleteDto {
  id: number;
  routeGroupItemId: number;
}

export default {
  async getArrivedWaypointIds() {
    const { data } = await httpService.get<GetArrivedWaypointIdsResponse>('/waypoints/arrived');

    return data;
  },
  setArrivedWaypointIds(waypointIds: number[]) {
    if (!waypointIds?.length) {
      return;
    }
    return httpService.post('/waypoints/arrived', { waypointIds });
  },
  async uploadPicture({ picture, waypointIds, isBasicAppMode = false }: UploadWaypointPicturePayload) {
    const formData = new FormData();

    formData.append('file', picture);
    formData.append('waypointIds', JSON.stringify(waypointIds));
    formData.append('isBasicAppMode', isBasicAppMode.toString());

    const { data } = await httpService.post<{ filePath: string }>('/waypoints/picture', formData);

    return data;
  },
  async deletePicture(waypointIds: number[], path: string) {
    const { data } = await httpService.delete('/waypoints/picture', {
      params: {
        waypointIds: waypointIds.join(),
        path,
      },
    });

    return data;
  },
  async getWaypointUnloads(routeGroupItemId: number) {
    return httpService.get<WaypointUnload[]>('/waypoints/unloads', {
      params: {
        routeGroupItemIds: routeGroupItemId,
        orderBy: 'issued',
        order: 'asc',
        disablePagination: true,
      },
    });
  },
  async createWaypointUnload(body: WaypointUnloadCreateDto) {
    return httpService.post<WaypointUnload>('/waypoints/unloads', body);
  },
  async updateWaypointUnload(id: number, body: WaypointUnloadUpdateDto) {
    return httpService.patch(`/waypoints/unloads/${id}`, body);
  },
  async deleteWaypointUnload({ id, routeGroupItemId }: WaypointUnloadDeleteDto) {
    return httpService.delete(`/waypoints/unloads/${id}`, {
      params: {
        routeGroupItemId,
      },
    });
  },
  async createWaypointTank(dto: Partial<WaypointTank>) {
    return httpService.post('/waypoints/tanks', dto);
  },
  async getDischargeFailureReasons() {
    const { data } = await httpService.get<WaypointDischargeFailureReason[]>('/waypoints/discharge-failure-reasons?usedBy=driver');
    return data;
  },
  async saveDischargeWaypointNotes({ waypointIds, reasonIds, additionalNotes }: SaveDischargeNotesDto) {
    return httpService.post('/waypoints/logs/way-problems', {
      waypointIds: waypointIds.join(),
      reasonIds: reasonIds.join(),
      additionalNotes,
      sendType: 'object-notes',
    });
  },
  async getWaypointPhotos(waypointIds: number[]) {
    const { data } = await httpService.get<WaypointLogDetails[]>('/waypoints/logs/photos', {
      params: {
        waypointIds: waypointIds.join(),
      },
    });

    return data;
  },
  async markWaypointsAsDone(waypointIds: number[], body: SetWaypointStatusDto) {
    const { coordinates, dischargesCount, dynamicCapacities, weights, services, updateWaypointIds, doneAt } = body;

    return httpService.post<RouteGroupItemWaypoint[]>('/waypoints/status', {
      ids: waypointIds,
      routeGroupItemId: body.routeGroupItemId,
      longitude: coordinates.longitude,
      latitude: coordinates.latitude,
      dischargesCount,
      dynamicCapacities,
      weights,
      services,
      updateWaypointIds,
      status: WaypointsStatus.Done,
      doneAt,
    });
  },

  async markWaypointsAsFailed(waypointIds: number[], body: SetFailedWaypointStatusDto) {
    const { coordinates, dischargesCount, dynamicCapacities, weights, services, content, reasonIds, updateWaypointIds, doneAt } = body;

    return httpService.post<RouteGroupItemWaypoint[]>('/waypoints/status', {
      ids: waypointIds,
      routeGroupItemId: body.routeGroupItemId,
      longitude: coordinates.longitude,
      latitude: coordinates.latitude,
      dischargesCount,
      dynamicCapacities,
      weights,
      services,
      status: WaypointsStatus.Failed,
      content,
      reasonIds,
      updateWaypointIds,
      doneAt,
    });
  },

  async deleteStatusData(waypointIds: number[]) {
    const { data } = await httpService.delete(`/waypoints/status`, {
      params: {
        waypointIds: waypointIds.join(),
      },
    });

    return data;
  },

  async getDeletedAndInsertedWaypoints(routeGroupItemId: number, lastUpdatedWaypointIdsTimestamp: string) {
    const params = {
      routeGroupItemId,
      lastUpdatedWaypointIdsTimestamp,
    };
    const response = await httpService.get<DeletedInsertedWaypointIdsResponse>('/waypoints/logs/deleted-and-inserted', {
      params,
    });

    return response?.data || null;
  },

  async updateWaypointCoordinates(waypointIds: number[], longitude: number, latitude: number) {
    return httpService.patch('/waypoints/coordinates', {
      waypointIds,
      longitude,
      latitude,
    });
  },
};
