import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const TruckUnloadingIcon = ({ size }: IconSizeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      xmlSpace="preserve"
      fill="currentColor"
      className={iconStyles[iconSizeClasses[size]]}
    >
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M2315.9,3480.9c-473.1-465.9-869-871.4-876.2-900.4c-9.7-36.2,2.4-70,33.8-101.4c48.3-48.3,77.2-50.7,690.3-50.7h639.7V1373.7c0-1011.4,2.4-1059.7,48.3-1136.9C2921.7,123.4,3035.2,63,3180,63c156.9,0,267.9,70,342.8,217.2c53.1,106.2,53.1,115.9,53.1,1127.2v1021h622.8c668.6,0,729,9.7,729,125.5c0,57.9-306.6,376.5-1182.8,1226.2l-567.2,547.9L2315.9,3480.9z" />
          <path d="M5967.9,2230.6c-60.3-31.4-130.3-86.9-156.9-123.1c-50.7-67.6-50.7-96.6-57.9-1612.4l-4.8-1542.4H3346.6H944.8v767.6c0,480.4-9.7,801.4-26.6,861.7C865.2,770.2,643.1,910.2,454.8,874c-125.5-24.1-239-111-299.3-231.7L100,536.1v-1771.7v-1771.7l70-70l70-70h364.5h362.1L981-3058c62.8,407.9,164.1,622.8,405.5,861.7c202.8,200.4,352.4,287.3,610.7,352.4c470.7,120.7,939-9.6,1289-362.1c226.9-226.9,386.2-579.3,386.2-849.7v-91.7h1267.2h1267.2v103.8c0,282.4,149.7,603.5,398.3,849.7c531,531,1354.1,531,1882.8,0c246.2-246.2,398.3-572.1,398.3-854.5v-99h436.9H9760l70,70l70,70v388.6c0,231.7-9.7,410.4-26.5,444.1c-29,62.8-113.5,113.5-190.7,113.5c-48.3,0-48.3,26.6-50.7,1250.3c0,1115.2-4.8,1260-41,1349.3c-57.9,147.2-1190,1624.5-1289,1684.8c-74.8,45.9-127.9,48.3-1153.8,55.5l-1074.1,7.3L5967.9,2230.6z M7751.7,1511.3c48.3-12.1,120.7-57.9,161.7-101.4c74.8-77.2,755.5-999.3,755.5-1021c0-7.2-456.2-12.1-1013.8-12.1H6641.4v579.3v579.3h514.1C7435.5,1535.4,7705.9,1523.4,7751.7,1511.3z" />
          <path d="M2079.3-2215.6c-480.3-140-784.5-610.7-700-1081.4c115.9-634.8,774.8-987.2,1342.1-721.7c207.6,96.6,379,263.1,475.5,458.6c72.4,149.6,77.2,176.2,77.2,412.7c0,239-4.8,265.5-79.6,415.2c-101.4,205.2-311.4,405.5-504.5,477.9C2518.6-2189.1,2233.8-2169.7,2079.3-2215.6z M2492.1-2872.2c70-33.8,164.1-193.1,166.6-272.7c0-72.4-99-234.1-166.6-275.2c-169-99-395.9-16.9-461,171.4c-31.4,89.3-31.4,113.4,0,202.8C2096.2-2860.1,2313.5-2778,2492.1-2872.2z" />
          <path d="M7261.7-2227.7c-263.1-86.9-461-258.3-581.7-509.3c-70-144.8-74.8-176.2-74.8-410.4c0-224.5,7.2-267.9,65.2-393.4c135.2-287.2,417.6-502.1,733.8-555.2c403.1-70,837.6,166.5,1018.6,555.2c57.9,125.5,65.2,169,65.2,393.4c0,229.3-7.2,267.9-70,405.5c-91.7,197.9-318.6,412.7-514.1,487.6C7708.3-2177,7442.8-2167.3,7261.7-2227.7z M7717.9-2879.4c77.2-48.3,154.5-178.6,154.5-267.9c0-29-19.3-91.7-41-137.6c-111-234.1-458.6-234.1-569.6,0c-50.7,106.2-50.7,169,0,275.2c50.7,108.6,161.7,176.2,284.8,176.2C7599.7-2833.5,7676.9-2855.3,7717.9-2879.4z" />
        </g>
      </g>
    </svg>
  );
};

export default TruckUnloadingIcon;
