import React, { useRef } from 'react';
import HamburgerIcon from '../icons/HamburgerIcon';
import LogoImage from '../../assets/images/logo.png';
import { useSelector } from 'react-redux';
import NotificationBadgeWrapper from '../elements/NotificationBadgeWrapper';
import Sidebar from './Sidebar';
import Spinner from '../elements/Spinner';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderIndicators from '../elements/HeaderIndicators';
import { selectIsAppDisabled } from '@/store/settings/slice';
import { useSidebar } from '@/hooks/useSidebar';
import {
  useUserProfession,
  useRouteGroupItemsRequest,
  selectCurrentRouteGroupItem,
  selectIsCurrentRouteGroupItemOpenedMode,
} from '@/features/home';
import PhotoIcon from '@/components/icons/PhotoIcon';
import MailIcon from '@/components/icons/MailIcon';
import { useMessages } from '@/features/messages';
import { HeaderItem } from '@/types/common';

interface HeaderProps {
  title?: string;
  isTiny?: boolean;
}

const Header = ({ title, isTiny }: HeaderProps) => {
  const isAppDisabled = useSelector(selectIsAppDisabled);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const isRouteInOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const { newMessagesCount } = useMessages();

  const navigate = useNavigate();
  const { isLoading: isRouteGroupItemsLoading } = useRouteGroupItemsRequest();
  const { t } = useTranslation('photoPage');
  const { isUserLoader } = useUserProfession();

  const { isSidebarDisplayed, closeSidebar, toggleSidebar } = useSidebar();
  const { isSidebarDisplayed: isAlcoholPopupVisible, closeSidebar: closeAlcoholPopup, toggleSidebar: toggleAlcoholPopup } = useSidebar();
  const alcoholSpanRef = useRef<HTMLSpanElement>(null);

  const navigationItems: HeaderItem[] = [
    {
      label: t('navigationItems.takePicture', { ns: 'header' }),
      link: '/photo',
      icon: <PhotoIcon size="md" />,
      action: () => navigate('/photo'),
      disabled: isRouteInOpenedMode,
    },
  ];

  const loaderNavigationItems: HeaderItem[] = [
    {
      label: t('messagesLabel', { ns: 'messages' }),
      link: '/messages',
      icon: <MailIcon size={'md'} />,
      notificationsAmount: newMessagesCount,
      action: () => navigate('/messages'),
    },
  ];

  if (!isUserLoader()) {
    navigationItems.push(...loaderNavigationItems);
  }

  const logoWidth = isTiny ? 40 : 55;

  return (
    <>
      <nav className="header">
        <div className={isTiny ? 'header-wrapper-tiny' : 'header-wrapper'}>
          <div className="header-logo">
            <Link to="/">
              <div className="header-logo-link">
                <img src={LogoImage} width={logoWidth} height={50} alt={'logo'} />
                <div className="header-logo-text leading-6">
                  <span className="header__title leading-5">{title}</span>
                  {isRouteGroupItemsLoading && !currentRouteGroupItem ? (
                    <Spinner size={'sm'} />
                  ) : currentRouteGroupItem ? (
                    <span>
                      {currentRouteGroupItem.isDischargeSheet ? currentRouteGroupItem.routeGroup.name : currentRouteGroupItem.name}
                    </span>
                  ) : (
                    <span className="header__subtitle">{t('selectRouteGroupItem')}</span>
                  )}
                </div>
              </div>
            </Link>
            <div className="hidden md:block">
              <div className="header-link-wrapper">
                {navigationItems.map((item, index) => (
                  <Link
                    to={item.link}
                    className={`${isTiny ? 'header__tiny-link' : 'header__link'} ${isAppDisabled ? 'header__link-disabled' : ''}`}
                    key={index}
                  >
                    <NotificationBadgeWrapper notificationsAmount={item.notificationsAmount ?? 0}>{item.icon}</NotificationBadgeWrapper>
                    {!isTiny && <span>{item.label}</span>}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <HeaderIndicators
            isAlcoholPopupVisible={isAlcoholPopupVisible}
            onAlcoholPopupIconClick={toggleAlcoholPopup}
            alcoholSpanRef={alcoholSpanRef}
          />
          <div className="hamburger-wrapper">
            <button onClick={toggleSidebar} className="hamburger-button">
              <NotificationBadgeWrapper
                onlyOnMobile={true}
                notificationsAmount={navigationItems.reduce((acc, item) => (acc += item?.notificationsAmount ?? 0), 0)}
              >
                <HamburgerIcon size={'md'} />
              </NotificationBadgeWrapper>
            </button>
          </div>
        </div>
      </nav>

      <div>{isSidebarDisplayed && <Sidebar sidebarItems={navigationItems} isTinyHeader={isTiny} close={closeSidebar} />}</div>
    </>
  );
};

export default Header;
