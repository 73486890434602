import { selectMapSettings } from '@/features/route';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useSelector } from 'react-redux';
import { selectIsCurrentRouteGroupItemOpenedMode, Waypoint } from '@/features/home';
import { useCallback } from 'react';
import { useLazyGetOrsRouteQuery } from '../api/route-planner.api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useGeolocation from 'react-hook-geolocation';
import { getLocation } from '@/lib/geolocation';

export function useCoordinatesToNextObject(selectedWaypoints: Waypoint[]) {
  const isOffline = useOfflineStatus();
  const { showNavigationLine } = useSelector(selectMapSettings);
  const isRouteInOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const [getOrsRoute] = useLazyGetOrsRouteQuery();
  const { t } = useTranslation('common');

  const { latitude, longitude, error } = useGeolocation();

  const getCoordinatesToNextObject = async () => {
    if (isRouteInOpenedMode || !showNavigationLine) {
      return;
    }
    if (error) {
      toast(t('locationNotEnabled'), { type: 'error' });
      return;
    }

    const currentCoordinates = [longitude, latitude];

    const objectItemWithCoordinates = selectedWaypoints.find(({ objectItem }) => objectItem.longitude && objectItem.latitude);
    const targetCoordinates = objectItemWithCoordinates
      ? [objectItemWithCoordinates.objectItem.longitude, objectItemWithCoordinates.objectItem.latitude]
      : [selectedWaypoints[0]?.object.longitude, selectedWaypoints[0]?.object.latitude];

    if (targetCoordinates?.some((coord) => !coord)) {
      return [];
    }

    if (isOffline) {
      return [currentCoordinates, targetCoordinates];
    }

    if (!currentCoordinates || !targetCoordinates) {
      return [];
    }

    const { data: orsRouteData } = await getOrsRoute({
      start: currentCoordinates,
      end: targetCoordinates,
    });

    if (!orsRouteData) {
      return [currentCoordinates, targetCoordinates];
    }

    return orsRouteData.features[0].geometry.coordinates;
  };

  return { getCoordinatesToNextObject };
}
