import { WaypointsTanksRequest, waypointsTanksRequestsDb } from '../database';
import { useLiveQuery } from 'dexie-react-hooks';
import { useOfflineStatus } from '../contexts/offlineStatusContext';
import { useProcessor } from './useProcessor';
import { useCreateWaypointTankMutation } from '@/features/route';

export function useWaypointsTanksRequestsProcessor() {
  const requests = useLiveQuery(() => waypointsTanksRequestsDb.requests.toArray());
  const isOffline = useOfflineStatus();
  const [createWaypointTank] = useCreateWaypointTankMutation();

  const processRequest = async ({ body }: WaypointsTanksRequest) => {
    if (isOffline) {
      return;
    }

    await createWaypointTank(body).unwrap();
  };

  const deleteRequest = (requestId: number) => waypointsTanksRequestsDb.requests.delete(requestId);

  useProcessor<WaypointsTanksRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing tank request failed',
  });
}
