import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const ClipboardIcon = ({ size }: IconSizeProps) => (
  <svg
    fill="currentColor"
    className={iconStyles[iconSizeClasses[size]]}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="800px"
    height="800px"
    viewBox="0 0 96.668 96.668"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M93.739,31.585l-10.846-6.664c0.157-2.303-0.543-4.6-1.967-6.432c-0.127-3.51-1.169-9.511-6.428-12.742
			c-1.959-1.204-4.173-1.814-6.582-1.814c-3.027,0-5.806,0.967-7.689,1.837c-2.284-0.446-4.652-0.029-6.627,1.15L42.754,0.255
			c-0.831-0.509-1.991-0.23-2.501,0.598L2.33,62.568c-0.523,0.854-0.256,1.976,0.598,2.501l50.985,31.329
			c0.287,0.177,0.615,0.27,0.951,0.27c0.638,0,1.217-0.323,1.551-0.866l37.922-61.714C94.861,33.234,94.595,32.112,93.739,31.585z
			 M58.137,74.747l-2.926,4.762c-0.208,0.34-0.542,0.582-0.929,0.675c-0.115,0.026-0.232,0.041-0.35,0.041
			c-0.274,0-0.547-0.076-0.785-0.223L17.908,58.349c-0.706-0.435-0.927-1.357-0.493-2.063l2.926-4.762
			c0.208-0.339,0.542-0.582,0.929-0.674c0.389-0.092,0.796-0.027,1.134,0.181l35.24,21.654C58.35,73.118,58.57,74.042,58.137,74.747
			z M67.427,59.628l-2.926,4.763c-0.208,0.339-0.542,0.582-0.93,0.674c-0.114,0.026-0.231,0.041-0.349,0.041
			c-0.275,0-0.547-0.076-0.785-0.223l-35.24-21.653c-0.706-0.434-0.927-1.357-0.493-2.063l2.926-4.762
			c0.208-0.339,0.542-0.582,0.929-0.674c0.389-0.091,0.796-0.026,1.134,0.181l35.24,21.654C67.64,57.999,67.86,58.923,67.427,59.628
			z M76.717,44.51l-2.926,4.762c-0.283,0.461-0.775,0.715-1.279,0.715c-0.268,0-0.539-0.071-0.784-0.222l-35.24-21.654
			c-0.706-0.434-0.927-1.357-0.493-2.063l2.926-4.762c0.208-0.339,0.542-0.582,0.929-0.674c0.389-0.091,0.795-0.027,1.134,0.181
			l35.24,21.654c0.339,0.208,0.582,0.542,0.674,0.93C76.989,43.765,76.925,44.171,76.717,44.51z M76.374,25.589
			c-0.78,1.269-2.141,2.027-3.637,2.027c-0.782,0-1.551-0.217-2.22-0.629L57.35,18.896c-2-1.229-2.627-3.856-1.397-5.856
			c0.779-1.27,2.139-2.027,3.635-2.027c0.527,0,1.044,0.097,1.533,0.288l0.417,0.162l0.393-0.214
			c1.161-0.633,3.479-1.694,5.954-1.694c1.368,0,2.603,0.333,3.67,0.988c3.533,2.171,3.777,6.95,3.718,8.904l-0.014,0.447
			l0.333,0.299C77.122,21.565,77.451,23.835,76.374,25.589z"
        />
        <path
          d="M69.997,13.074c-0.551-0.338-1.183-0.517-1.825-0.517c-1.225,0-2.337,0.623-2.979,1.664
			c-0.487,0.795-0.638,1.732-0.421,2.639c0.216,0.907,0.773,1.675,1.568,2.164c0.551,0.338,1.182,0.517,1.825,0.517
			c1.224,0,2.337-0.623,2.977-1.664c0.488-0.794,0.638-1.731,0.422-2.639C71.349,14.331,70.792,13.562,69.997,13.074z"
        />
      </g>
    </g>
  </svg>
);

export default ClipboardIcon;
