import { useSelector } from 'react-redux';
import { selectUserState } from '../stores/slice';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export function useRedirectOnLogin() {
  const userState = useSelector(selectUserState);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState) {
      navigate('/');
    }
  }, [userState]);
}
