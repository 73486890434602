import { useSelector } from 'react-redux';
import { isRouteTypeWithWaypoints, selectCurrentRouteGroupItem, selectIsCurrentRouteGroupItemOpenedMode, Waypoint } from '@/features/home';
import waypointsService from '@/services/discharge-waypoints.service';
import { useEffect, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { waypointsUpdatesDB, WaypointsUpdatesItemMark } from '@/database';
import { GetWaypointsDto, useLazyGetWaypointsQuery, useWaypointsRequest, waypointsApi } from '@/features/route';
import { FULL_DAY_TIME_IN_SECONDS } from '@/helpers/dateHelper';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import serviceWaypoints from '@/services/service-waypoints.service';
import { updateTabletLastNewRequestTimestamps } from '../../api/update-last-request-timestamps';
import { useAppDispatch } from '@/store/types';
import {
  getAndRemoveLocalProcessedWaypoints,
  getLocalProcessedWaypointsData,
} from '@/helpers/localStorage/locallyProcessedWaypointsHelper';

interface UseUpdateWaypointsProps {
  openWaypointsUpdatesModal: () => void;
}

const INTERVAL_FOR_GETTING_DELETED_AND_INSERTED = 90000;

export default function useWaypointUpdates({ openWaypointsUpdatesModal }: UseUpdateWaypointsProps) {
  const [lastUpdatedWaypointIdsTimestamp, setLastUpdatedWaypointIdsTimestamp] = useState<string>(new Date().toISOString());

  const isCurrentRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const { data: currentWaypoints } = useWaypointsRequest();
  const [getWaypoints] = useLazyGetWaypointsQuery();

  const isOffline = useOfflineStatus();
  const dispatch = useAppDispatch();

  const updatesDBItems = useLiveQuery(() => waypointsUpdatesDB.updates.toArray());

  const updateWaypointsCache = (updatedWaypoints: Waypoint[]) => {
    const originalArgs: GetWaypointsDto = {
      routeGroupItemIds: [currentRouteGroupItem?.id],
      waypointIds: [],
      isDischargeSheet: true,
    };
    const updatedWaypointIds = updatedWaypoints.map(({ id }) => id);

    dispatch(
      waypointsApi.util.updateQueryData('getWaypoints', originalArgs, (data) => {
        return data.map((waypoint) => {
          if (!updatedWaypointIds.includes(waypoint.id)) {
            return waypoint;
          }

          const updatedWaypoint = updatedWaypoints.find(({ id }) => id === waypoint.id);

          return {
            ...waypoint,
            ...updatedWaypoint,
          };
        });
      }),
    );
  };

  const getDeletedAndInsertedWaypointIds = async () => {
    const { deletedIds, insertedIds, updatedIds } = currentRouteGroupItem?.isDischargeSheet
      ? await waypointsService.getDeletedAndInsertedWaypoints(currentRouteGroupItem?.id, lastUpdatedWaypointIdsTimestamp)
      : await serviceWaypoints.getDeletedAndInsertedWaypoints(currentRouteGroupItem?.id, lastUpdatedWaypointIdsTimestamp);

    if (updatedIds.length) {
      const locallyProcessedWaypointsList = getLocalProcessedWaypointsData().list;

      setLastUpdatedWaypointIdsTimestamp(new Date().toISOString());

      if (updatedIds.every((id) => locallyProcessedWaypointsList[id])) {
        getAndRemoveLocalProcessedWaypoints(updatedIds);
        return;
      }
      const { data, isError } = await getWaypoints({
        waypointIds: updatedIds,
        isDischargeSheet: currentRouteGroupItem?.isDischargeSheet,
      });

      if (!isError) {
        updateWaypointsCache(data);
      }
    }

    if (!deletedIds.length && !insertedIds.length) {
      return;
    }

    const { data } = await getWaypoints({
      waypointIds: [],
      routeGroupItemIds: [currentRouteGroupItem?.id],
      isDischargeSheet: currentRouteGroupItem?.isDischargeSheet,
    });

    waypointsUpdatesDB.updates.add({
      routeGroupItemId: currentRouteGroupItem.id,
      items: data || [],
      deletedIds,
      insertedIds,
      mark: WaypointsUpdatesItemMark.Update,
      isSeen: false,
      timestamp: new Date(),
    });

    await updateTabletLastNewRequestTimestamps(currentRouteGroupItem.id);
  };

  // interval for requesting updates
  useEffect(() => {
    if (isCurrentRouteGroupItemOpenedMode || isOffline || !isRouteTypeWithWaypoints(currentRouteGroupItem?.sheetType)) {
      return;
    }

    const interval = setInterval(() => {
      getDeletedAndInsertedWaypointIds();
    }, INTERVAL_FOR_GETTING_DELETED_AND_INSERTED);

    return () => {
      clearInterval(interval);
    };
  }, [isOffline, lastUpdatedWaypointIdsTimestamp, isCurrentRouteGroupItemOpenedMode]);

  // set initial list of items
  useEffect(() => {
    if (isCurrentRouteGroupItemOpenedMode || !currentWaypoints?.length || !updatesDBItems) {
      return;
    }

    const initialItemsAreSet = updatesDBItems
      .filter(({ routeGroupItemId }) => routeGroupItemId === currentRouteGroupItem?.id)
      .some((update) => update.mark === WaypointsUpdatesItemMark.Initial);

    if (initialItemsAreSet) {
      return;
    }

    waypointsUpdatesDB.updates.add({
      routeGroupItemId: currentRouteGroupItem.id,
      items: currentWaypoints,
      deletedIds: [],
      insertedIds: [],
      mark: WaypointsUpdatesItemMark.Initial,
      isSeen: true,
      timestamp: new Date(),
    });
  }, [currentWaypoints, updatesDBItems]);

  // check for not seen updates and delete old data
  useEffect(() => {
    if (isCurrentRouteGroupItemOpenedMode || !updatesDBItems) {
      return;
    }

    const notSeenUpdatesExists = updatesDBItems
      .filter(({ routeGroupItemId }) => routeGroupItemId === currentRouteGroupItem?.id)
      .some((update) => !update.isSeen);

    if (notSeenUpdatesExists) {
      openWaypointsUpdatesModal();
    }

    const outdatedUpdates = updatesDBItems.filter(({ timestamp }) => timestamp < new Date(Date.now() - 1000 * FULL_DAY_TIME_IN_SECONDS));

    if (outdatedUpdates.length) {
      waypointsUpdatesDB.updates.bulkDelete(outdatedUpdates.map(({ id }) => id));
    }
  }, [updatesDBItems]);
}
