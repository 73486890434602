import { selectUserProfessionId } from '../../auth/stores/slice';
import { useSelector } from 'react-redux';
import { UserProfession } from '../../auth/types';

export function useUserProfession() {
  const userProfessionId = useSelector(selectUserProfessionId);

  const isUserDriver = () => userProfessionId === UserProfession.Driver;
  const isUserLoader = () => userProfessionId === UserProfession.Loader;
  const isUserDriverLoader = () => userProfessionId === UserProfession.DriverLoader;

  return {
    isUserDriver,
    isUserLoader,
    isUserDriverLoader,
  };
}
