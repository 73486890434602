import CheckMark from '@/components/icons/CheckMark';
import { cn } from '@/utils/cn';
import { Switch } from '@headlessui/react';
import React from 'react';

interface CheckboxProps {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
  className?: string;
  labelClassName?: string;
  checkboxClassName?: string;
}

export const Checkbox = ({ checked, label, onChange, checkboxClassName, className, labelClassName }: CheckboxProps) => {
  return (
    <Switch.Group>
      <div
        className={cn(
          'flex relative pr-1 items-center justify-between w-full text-slate-900 rounded-sm  dark:text-white text-base md:text-xl select-none bg-slate-200 hover:bg-slate-300 dark:bg-slate-500 dark:hover:bg-slate-700',
          className,
        )}
      >
        <Switch.Label className="h-full w-full px-2 py-2 hover:cursor-pointer">
          <div className={cn('w-5/6', labelClassName)}>{label}</div>
        </Switch.Label>
        <Switch
          checked={checked}
          onClick={(e) => e.stopPropagation()}
          onChange={onChange}
          className={cn(
            'absolute bg-white grid place-items-center right-2 h-8 w-8 md:h-10 md:w-10 rounded border-none text-green-500',
            checkboxClassName,
          )}
        >
          {checked && <CheckMark size="md" />}
        </Switch>
      </div>
    </Switch.Group>
  );
};
