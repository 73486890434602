import { useLiveQuery } from 'dexie-react-hooks';
import {
  allDeviceRequestsDb,
  carAcceptanceRequestsDb,
  photoDB,
  routeStartFinishRequestsDb,
  unloadsRequestsDb,
  waypointsNotesRequestsDb,
  waypointsStatusRequestsDb,
  waypointsTanksRequestsDb,
} from '../database';

export function useOfflineSummary() {
  const photos = useLiveQuery(() => photoDB.photos.toArray());
  const routeStartFinishRequests = useLiveQuery(() => routeStartFinishRequestsDb.requests.toArray());
  const unloads = useLiveQuery(() => unloadsRequestsDb.requests.toArray());
  const dischargeNotes = useLiveQuery(() => waypointsNotesRequestsDb.requests.toArray());
  const tanks = useLiveQuery(() => waypointsTanksRequestsDb.requests.toArray());
  const discharges = useLiveQuery(() => waypointsStatusRequestsDb.requests.toArray());
  const acceptances = useLiveQuery(() => carAcceptanceRequestsDb.requests.toArray());
  const tasks = useLiveQuery(() => allDeviceRequestsDb.requests.toArray());

  const notSentPhotoAmount = photos?.filter(({ serverFilePath }) => !serverFilePath).length || 0;

  const totalPendingRequests =
    notSentPhotoAmount +
    (routeStartFinishRequests?.length || 0) +
    (unloads?.length || 0) +
    (dischargeNotes?.length || 0) +
    (tanks?.length || 0) +
    (discharges?.length || 0) +
    (acceptances?.length || 0) +
    (tasks?.length || 0);

  return {
    totalPendingRequests,
    photos,
    routeStartFinishRequests,
    unloads,
    dischargeNotes,
    tanks,
    discharges,
    acceptances,
    tasks,
  };
}
