import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const MagnetIcon = ({ size }: IconSizeProps) => (
  <svg
    className={iconStyles[iconSizeClasses[size]]}
    fill="currentColor"
    width="800px"
    height="800px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M30 0.75h-8c-0.69 0-1.25 0.56-1.25 1.25v14c0.005 0.076 0.007 0.165 0.007 0.254 0 2.487-2.016 4.503-4.503 4.503-0.090 0-0.178-0.003-0.267-0.008l0.012 0.001c-0.076 0.005-0.165 0.007-0.254 0.007-2.487 0-4.503-2.016-4.503-4.503 0-0.089 0.003-0.178 0.008-0.266l-0.001 0.012v-14c0-0.69-0.56-1.25-1.25-1.25v0h-8c-0.69 0-1.25 0.56-1.25 1.25v0 14c-0.005 0.146-0.008 0.319-0.008 0.491 0 8.155 6.611 14.767 14.767 14.767 0.173 0 0.345-0.003 0.516-0.009l-0.025 0.001c0.146 0.005 0.319 0.008 0.491 0.008 8.155 0 14.767-6.611 14.767-14.767 0-0.173-0.003-0.345-0.009-0.516l0.001 0.025v-14c-0-0.69-0.56-1.25-1.25-1.25h-0zM28.75 3.25v3.5l-5.5 0.001v-3.502zM8.75 3.25v3.499h-5.5v-3.499zM16 28.75c-0.162 0.008-0.351 0.012-0.542 0.012-6.749 0-12.22-5.471-12.22-12.22 0-0.191 0.004-0.38 0.013-0.569l-0.001 0.027v-6.751h5.5v6.751c-0.002 0.070-0.004 0.152-0.004 0.235 0 2.058 0.884 3.908 2.293 5.194l0.006 0.005c1.294 1.129 2.999 1.817 4.864 1.817 0.032 0 0.064-0 0.096-0.001l-0.005 0c0.070 0.003 0.152 0.004 0.234 0.004 3.877 0 7.020-3.143 7.020-7.020 0-0.082-0.001-0.164-0.004-0.246l0 0.012v-6.748l5.5-0.001v6.75c0.008 0.162 0.012 0.351 0.012 0.542 0 6.749-5.471 12.22-12.22 12.22-0.191 0-0.38-0.004-0.569-0.013l0.027 0.001z"></path>
  </svg>
);

export default MagnetIcon;
