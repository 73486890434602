import { selectUserId } from '@/features/auth';
import {
  constructionApi,
  selectGetConstructionTasksCache,
  useGetConstructionTasksQuery,
  useLazyGetConstructionTasksQuery,
} from '@/features/route';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { GetConstructionTasksQueryDto } from '@ekt-group/general-purpose-api-interfaces';
import { useCallback } from 'react';
import { TaskSideloading } from '@ekt-group/general-purpose-api-interfaces/src/modules/construction/types/tasks.enum';

export const CONSTRUCTION_PAGE_SIDELOADINGS = [
  TaskSideloading.Base,
  TaskSideloading.Car,
  TaskSideloading.Contract,
  TaskSideloading.Driver,
  TaskSideloading.Garbage,
  TaskSideloading.ItemType,
  TaskSideloading.RouteSheet,
  TaskSideloading.Weight,
  TaskSideloading.UnloadPoint,
];

export const useConstructionTaskRequest = (routeSheetId?: number) => {
  const userId = useAppSelector(selectUserId);
  const params: GetConstructionTasksQueryDto = {
    routeSheetIds: [routeSheetId],
    sideloading: CONSTRUCTION_PAGE_SIDELOADINGS,
  };

  const { data } = useAppSelector(selectGetConstructionTasksCache(params));

  const { refetch } = useGetConstructionTasksQuery(params, {
    skip: !userId || !routeSheetId,
  });
  const [lazyGetConstructionTasks, { isLoading, isFetching }] = useLazyGetConstructionTasksQuery();

  const reselectConstructionTasksCache = useCallback(async (params: GetConstructionTasksQueryDto) => {
    const store = await import('@/store').then((module) => module.store);

    const { data } = constructionApi.endpoints.getConstructionTasks.select(params)(store.getState());

    return data;
  }, []);

  return {
    data,
    refetch,
    isLoading,
    isFetching,
    reselectConstructionTasksCache,
    lazyGetConstructionTasks,
  };
};
