import { socket } from '@/lib/socket';
import React, { useState, useEffect, useContext, PropsWithChildren } from 'react';

const socketStatusContext = React.createContext(false);

export const SocketStatusContextProvider = ({ children }: PropsWithChildren) => {
  const [socketStatus, setSocketStatus] = useState<boolean>(socket?.connected);

  useEffect(() => {
    const connectHandler = () => {
      setSocketStatus(true);
    };
    const disconnectHandler = () => {
      setSocketStatus(false);
    };

    socket.on('connect', connectHandler);
    socket.on('disconnect', disconnectHandler);

    return () => {
      socket.off('connect', connectHandler);
      socket.off('disconnect', disconnectHandler);
    };
  }, []);

  return <socketStatusContext.Provider value={socketStatus}>{children}</socketStatusContext.Provider>;
};

export const useSocketStatus = () => {
  const context = useContext(socketStatusContext);
  if (context === undefined) {
    throw new Error('useSocketStatus must be used within a SocketStatusProvider');
  }
  return context;
};
