import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WaypointsListBar from './WaypointListBar/WaypointsListBar';
import {
  selectDisplayArrivedPage,
  selectDisplaySidebarOnMobile,
  selectMapSettings,
  setDisplaySidebarOnMobile,
  useWaypointsRequest,
  WaypointListFormData,
} from '@/features/route';
import { UnloadingBaseWaypoint, Waypoint, WaypointType } from '@/features/home';

import WaypointsListGroup from './WaypointsListGroup';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon';
import Spinner from '@/components/elements/Spinner';
import MobileSelectedWaypointGroup from '../MobileSelectedWaypointGroup';
import { useSortedWaypointGroups } from '../../hooks/waypoints/useSortedWaypointGroups';
import { useMergedWaypointGroups } from '../../hooks/waypoints/useMergedWaypointGroups';
import { cn } from '@/utils/cn';
import { setFilters, setSelectedWaypointIds } from '@/store/waypoints/slice';

interface WaypointsListProps {
  onWaypointClick?: (waypointIds: number[]) => void;
}

const WaypointsList = ({ onWaypointClick }: WaypointsListProps) => {
  const dispatch = useDispatch();

  const { data: waypoints, isLoading: isWaypointsLoading, isFetching: isWaypointsFetching } = useWaypointsRequest();
  const isDisplaySidebarOnMobile = useSelector(selectDisplaySidebarOnMobile);
  const { showDoneWaypoints } = useSelector(selectMapSettings);
  const isDisplayArrivedPage = useSelector(selectDisplayArrivedPage);

  const waypointGroups = useMemo(() => {
    if (!waypoints) {
      return [];
    }

    const groups = waypoints.reduce<Record<number, Waypoint[]>>((acc, waypoint) => {
      if (!acc[waypoint.object.id]) {
        acc[waypoint.object.id] = [];
      }

      acc[waypoint.object.id].push(waypoint);

      return acc;
    }, {});

    const filteredGroups = showDoneWaypoints
      ? Object.values(groups)
      : Object.values(groups).filter(
          (group) =>
            !group.every((waypoint) => {
              return waypoint.type === WaypointType.Discharge ? waypoint.done : waypoint.doneAt;
            }),
        );

    return filteredGroups.reduce<Waypoint[][]>((acc, group) => {
      if (group[0].object.isSeparatedItems) {
        group.forEach((waypoint) => {
          acc.push([waypoint]);
        });

        return acc;
      }

      acc.push(group);

      return acc;
    }, []);
  }, [waypoints, showDoneWaypoints]);

  // Set next selected waypoint ids when current selected waypoint ids are done
  const setNextSelectedWaypointIds = useCallback(
    (waypointGroups: (Waypoint[] | UnloadingBaseWaypoint[])[]) => {
      if (!waypointGroups?.length) {
        return;
      }

      const isAllDone = waypointGroups.flat().every((waypoint) => {
        if (waypoint.type === WaypointType.Discharge) {
          return waypoint.done;
        }
        if (waypoint.type === WaypointType.Service) {
          return waypoint.doneAt;
        }
        return false;
      });

      if (isAllDone) {
        dispatch(setSelectedWaypointIds(waypointGroups[0].map(({ id }) => id)));
        return;
      }

      const nextNotDoneWaypointGroupIds = waypointGroups
        .find((waypoints) => {
          return (waypoints as Waypoint[]).every((waypoint) => {
            if (waypoint.type === WaypointType.Discharge) {
              return !waypoint.done;
            }
            if (waypoint.type === WaypointType.Service) {
              return !waypoint.doneAt;
            }
            return true;
          });
        })
        ?.map(({ id }) => id);

      dispatch(setSelectedWaypointIds(nextNotDoneWaypointGroupIds || []));
    },
    [dispatch],
  );

  const { sortedWaypointGroups } = useSortedWaypointGroups(waypointGroups, setNextSelectedWaypointIds);
  useMergedWaypointGroups(waypointGroups);

  const handleFilterChange = (val: WaypointListFormData) => {
    dispatch(setFilters(val));
  };

  return (
    <div
      className={cn(
        'z-20 fixed w-5/6 md:w-auto -right-full md:right-0 top-0 md:z-10 h-dynamic-100 transition-[right] duration-150 ease-in',
        'bg-gray-200 dark:bg-gray-600',
        {
          'right-0': isDisplaySidebarOnMobile,
        },
      )}
    >
      <div
        className={cn('modal-overlay hidden md:hidden', 'z-40', {
          'block md:hidden': isDisplaySidebarOnMobile,
        })}
        onClick={() => dispatch(setDisplaySidebarOnMobile(false))}
      ></div>
      <button
        className={cn({
          'tablet-page__waypoints-list__overlay-list-toggle': true,
          'tablet-page__waypoints-list__overlay-list-toggle--visible': isDisplaySidebarOnMobile,
        })}
        onClick={() => dispatch(setDisplaySidebarOnMobile(false))}
      >
        <ArrowLeftIcon size={'md'} />
      </button>
      <WaypointsListBar onFilterSubmit={handleFilterChange} shouldDisplayFilterAndSorting={!isDisplayArrivedPage} />
      <div
        className={cn({
          'tablet-page__waypoints-list-wrapper': true,
          'tablet-page__waypoints-list--mobile-visible': isDisplaySidebarOnMobile,
          centered: !waypointGroups.length,
        })}
      >
        {(isWaypointsLoading || isWaypointsFetching) && !waypointGroups.length && <Spinner size={'lg'} />}
        {!isWaypointsLoading && !waypointGroups.length && <h3>Waypoints not found</h3>}
        {sortedWaypointGroups.length > 0 && (
          <WaypointsListGroup sortedWaypointGroups={sortedWaypointGroups} onWaypointClick={onWaypointClick} />
        )}
      </div>
      <MobileSelectedWaypointGroup waypointGroups={sortedWaypointGroups} />
    </div>
  );
};

export default React.memo(WaypointsList);
