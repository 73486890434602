import React from 'react';
import { useTranslation } from 'react-i18next';

const SortingModalLegend = () => {
  const { t } = useTranslation('tabletPage');

  return (
    <div className="hidden md:flex items-center gap-4">
      <div className={'flex items-center gap-2'}>
        <div className={'w-6 h-6 bg-green-500 rounded-full border-2 border-white'} />
        {t('historicalSortingModal.matched')}
      </div>
      <div className={'flex items-center gap-2'}>
        <div className={'w-6 h-6 bg-fuchsia-600 rounded-full border-2 border-white'} />
        {t('historicalSortingModal.inMainButNotInTargetRoute')}
      </div>
      <div className={'flex items-center gap-2'}>
        <div className={'w-6 h-6 bg-gray-500 rounded-full border-2 border-white'} />
        {t('historicalSortingModal.inTargetButNotInMainRoute')}
      </div>
      <div className={'flex items-center gap-2'}>
        <div className={'w-6 h-6 bg-yellow-500 rounded-full border-2 border-white'} />
        {t('historicalSortingModal.unloads')}
      </div>
    </div>
  );
};

export default SortingModalLegend;
