import { useEffect, useState } from 'react';
import waypointsService from '@/services/discharge-waypoints.service';
import { useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem, selectIsCurrentRouteGroupItemOpenedMode } from '@/features/home';
import serviceWaypoints from '@/services/service-waypoints.service';

export function useArrivedPageArrivedRequest(arrivedWaypointsIds: number[]) {
  const [isArrivedRequestPending, setIsArrivedRequestPending] = useState(false);

  const isCurrentRouteInOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);

  const markArrivedWaypoints = async () => {
    if (isArrivedRequestPending || isCurrentRouteInOpenedMode) {
      return;
    }

    setIsArrivedRequestPending(true);
    try {
      if (currentRouteGroupItem?.isDischargeSheet) {
        await waypointsService.setArrivedWaypointIds(arrivedWaypointsIds);
      } else {
        await serviceWaypoints.setArrivedWaypoints(arrivedWaypointsIds, currentRouteGroupItem?.id);
      }
    } finally {
      setIsArrivedRequestPending(false);
    }
  };

  useEffect(() => {
    markArrivedWaypoints();
  }, []);
}
