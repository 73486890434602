import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  removeFormSelectedWaypointIds,
  selectArrivedPageForm,
  selectFailedRequestPendingState,
  useSaveDischargeWaypointNotesMutation,
  useSaveServiceWaypointNotesMutation,
} from '@/features/route';
import ArrivedPageWaypointDischargeItem from '../components/arrived/ArrivedPageWaypointDischargeItem';
import { WaypointInfoForm } from '@/features/photo';
import Spinner from '@/components/elements/Spinner';
import { useArrivedPageInitialForm } from '../hooks/arrived/useArrivedPageInitialForm';
import { useArrivedPageDischargeFailureReasons } from '../hooks/arrived/useArrivedPageDischargeFailureReasons';
import FullScreenModal from '@/components/modals/FullScreenModal';
import { useTranslation } from 'react-i18next';
import { WaypointDischargeFailureReason } from '@ekt-group/general-purpose-api-interfaces';
import KojvConditionsModal from '../components/arrived/KojvConditionsModal';
import { useArrivedPageKojvConditions } from '../hooks/arrived/useArrivedPageKojvConditions';
import { useArrivedPageNavigation } from '../hooks/arrived/useArrivedPageNavigation';
import PhotoOnMobileModal from '../components/arrived/PhotoOnMobileModal';
import { useArrivedPageChangeStatusRequests } from '../hooks/arrived/useArrivedPageChangeStatusRequests';
import { selectCurrentRouteGroupItem, Waypoint, WaypointType } from '@/features/home';
import TakePhotoModal from '../components/arrived/TakePhotoModal';
import { WaypointNotesEntity, WaypointsNotesRequest, waypointsNotesRequestsDb } from '@/database';
import { toast } from 'react-toastify';
import { useArrivedPageUploadedPhotos } from '../hooks/arrived/useArrivedPageUploadedPhotos';
import ArrivedPageWaypointServiceItem from '../components/arrived/ArrivedPageWaypointServiceItem';
import { useArrivedPageArrivedRequest } from '../hooks/arrived/useArrivedPageArrivedRequest';
import { isEqual } from 'lodash';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';

interface ArrivedPageProps {
  arrivedWaypoints: Waypoint[];
  onArrivedWaypointsStatusChange: (changedWaypointIds: number[]) => void;

  onPhotoUpload?: () => void;
  onPhotoSubmitted?: () => void;
  onPhotoDeleted?: () => void;
  onPhotoAdded?: () => void;
}

const areEqual = (prev: ArrivedPageProps, next: ArrivedPageProps) => {
  if (prev.arrivedWaypoints.length !== next.arrivedWaypoints.length) {
    return false;
  }

  if (prev.onArrivedWaypointsStatusChange !== next.onArrivedWaypointsStatusChange) {
    return false;
  }

  return prev.arrivedWaypoints.every((prevWaypoint) => {
    const nextWaypoint = next.arrivedWaypoints.find(({ id }) => prevWaypoint.id === id);
    return isEqual(prevWaypoint, nextWaypoint);
  });
};

// This is a full-screen modal, "page" is a misnomer
const ArrivedPage = ({
  arrivedWaypoints,
  onArrivedWaypointsStatusChange,
  onPhotoUpload,
  onPhotoSubmitted,
  onPhotoAdded,
  onPhotoDeleted,
}: ArrivedPageProps) => {
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const arrivedPageForm = useSelector(selectArrivedPageForm);
  const isFailedRequestPending = useSelector(selectFailedRequestPendingState);
  const { t, i18n } = useTranslation('arrivedPage');
  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [isDischargeNotesModalVisible, setIsDischargeNotesModalVisible] = useState(false);
  const isOffline = useOfflineStatus();
  const [saveDischargeWaypointNotes] = useSaveDischargeWaypointNotesMutation();
  const [saveServiceWaypointNotes] = useSaveServiceWaypointNotesMutation();

  const { markWaypointsAsDone, markWaypointsAsFailed, markServiceWaypointsAsDone, markServiceWaypointsAsFailed } =
    useArrivedPageChangeStatusRequests(arrivedWaypoints);

  useArrivedPageArrivedRequest(arrivedWaypoints?.map(({ id }) => id));

  const handleCheckMarkAction = useCallback(async () => {
    const changedIds = [];
    let serverTimestamp = new Date().toISOString();
    for (const waypoint of arrivedWaypoints) {
      const dischargedWaypoint = waypoint as Waypoint<WaypointType.Discharge>;
      changedIds.push(dischargedWaypoint?.id);
      serverTimestamp = dischargedWaypoint?.timestamp ?? serverTimestamp;
    }

    if (currentRouteGroupItem?.isDischargeSheet) {
      await markWaypointsAsDone(arrivedPageForm?.selectedWaypointIds, arrivedWaypoints?.[0]?.object.id, serverTimestamp);
    } else {
      await markServiceWaypointsAsDone(arrivedPageForm?.selectedWaypointIds, arrivedWaypoints?.[0]?.object.id, serverTimestamp);
    }

    onArrivedWaypointsStatusChange(changedIds);
  }, [
    arrivedWaypoints,
    onArrivedWaypointsStatusChange,
    arrivedPageForm?.selectedWaypointIds,
    currentRouteGroupItem,
    markServiceWaypointsAsDone,
    markWaypointsAsDone,
  ]);

  const handleCrossMarkAction = useCallback(() => {
    setIsFailureModalVisible(true);
  }, []);

  const handleDischargeNotesAction = useCallback(() => {
    setIsDischargeNotesModalVisible(true);
  }, []);

  const arrivedWaypointFirstItem = useMemo(() => arrivedWaypoints[0], [arrivedWaypoints]);

  useArrivedPageNavigation({
    checkMarkAction: handleCheckMarkAction,
    crossMarkAction: handleCrossMarkAction,
    dischargeNotesAction: handleDischargeNotesAction,
    arrivedWaypoint: arrivedWaypointFirstItem,
  });

  useArrivedPageUploadedPhotos(arrivedWaypoints);
  useArrivedPageKojvConditions(arrivedWaypoints[0]);
  const { isFormInitialized } = useArrivedPageInitialForm(arrivedWaypoints);
  const { dischargeReasons, failureReasons } = useArrivedPageDischargeFailureReasons();

  const transformReasonToBasicOption = (reason: WaypointDischargeFailureReason) => ({
    value: reason.id,
    label: i18n.resolvedLanguage === 'ru' ? reason.nameRus : reason.name,
  });

  const saveNotes = async (reasonIds: number[], additionalNotes: string) => {
    try {
      const noteRequest: WaypointsNotesRequest = {
        entity: currentRouteGroupItem?.isDischargeSheet ? WaypointNotesEntity.DischargeWaypoint : WaypointNotesEntity.ServiceWaypoint,
        dischargeNoteIds: reasonIds,
        waypointIds: arrivedPageForm?.selectedWaypointIds,
        routeGroupItemId: currentRouteGroupItem?.id,
        additionalNotes,
      };

      if (!noteRequest?.waypointIds?.length) {
        return;
      }

      if (currentRouteGroupItem?.isDischargeSheet) {
        await saveDischargeWaypointNotes({
          waypointIds: noteRequest?.waypointIds,
          reasonIds: noteRequest?.dischargeNoteIds,
          additionalNotes: noteRequest?.additionalNotes || '',
        })
          .unwrap()
          .catch((error) => {
            waypointsNotesRequestsDb.requests.add(noteRequest);
          });
        return;
      }

      await saveServiceWaypointNotes({
        waypointIds: noteRequest?.waypointIds,
        routeGroupItemId: noteRequest?.routeGroupItemId,
        additionalNotes: noteRequest?.additionalNotes,
      })
        .unwrap()
        .catch((error) => {
          waypointsNotesRequestsDb.requests.add(noteRequest);
        });
    } finally {
      setIsDischargeNotesModalVisible(false);
    }
  };

  const handleMarkFailedWaypoints = useCallback(
    async (reasonIds: number[], additionalNotes: string) => {
      if (!reasonIds.length) {
        toast(t('fullscreenModal.selectAReason'), { type: 'warning' });
        return;
      }

      setIsFailureModalVisible(false);
      const changedIds = [];
      let serverTimestamp = new Date().toISOString();

      if (arrivedWaypoints?.length) {
        for (const waypoint of arrivedWaypoints) {
          const dischargeWaypoint = waypoint as Waypoint<WaypointType.Discharge>;
          changedIds.push(waypoint?.id);
          serverTimestamp = dischargeWaypoint?.timestamp ?? serverTimestamp;
        }

        await markWaypointsAsFailed(
          arrivedPageForm?.selectedWaypointIds,
          arrivedWaypoints?.[0]?.object.id,
          serverTimestamp,
          reasonIds,
          additionalNotes,
        );
        onArrivedWaypointsStatusChange(changedIds);
      }
    },
    [t, arrivedPageForm?.selectedWaypointIds, arrivedWaypoints, onArrivedWaypointsStatusChange, markWaypointsAsFailed],
  );

  const handleMarkFailedServiceWaypoints = useCallback(
    async (reasonIds: number[], additionalNotes: string) => {
      if (arrivedWaypoints?.length) {
        const changedIds = [];
        let serverTimestamp = new Date().toISOString();
        for (const waypoint of arrivedWaypoints) {
          const serviceWaypoint = waypoint as Waypoint<WaypointType.Service>;
          changedIds.push(serviceWaypoint?.id);
          serverTimestamp = serviceWaypoint?.doneAt ?? serverTimestamp;
        }

        if (!reasonIds.length) {
          toast(t('fullscreenModal.selectAReason'), { type: 'warning' });
          return;
        }
        const reason = failureReasons.find((reason) => reason.id === reasonIds[0]);
        await markServiceWaypointsAsFailed(
          arrivedPageForm?.selectedWaypointIds,
          arrivedWaypoints[0].object.id,
          serverTimestamp,
          reason.name,
          additionalNotes,
        );
        onArrivedWaypointsStatusChange(changedIds);
      }
      setIsFailureModalVisible(false);
    },
    [
      t,
      failureReasons,
      arrivedPageForm?.selectedWaypointIds,
      arrivedWaypoints,
      onArrivedWaypointsStatusChange,
      markServiceWaypointsAsFailed,
    ],
  );

  const openGatesActionsWrapper = document.querySelector<HTMLDivElement>('.open-gates-actions-wrapper');
  return (
    <div className="arrived-page z-[2] relative" style={{ paddingBottom: `calc(${openGatesActionsWrapper?.offsetHeight}px + 2rem)` }}>
      {!isFormInitialized ? (
        <Spinner size={'lg'} />
      ) : (
        <>
          <WaypointInfoForm waypoint={arrivedWaypoints[0]} waypointsAmount={arrivedWaypoints.length} />
          <div className={`arrived-page__list flex ${currentRouteGroupItem?.isDischargeSheet ? '' : 'flex-col'}`}>
            {arrivedWaypoints.length && currentRouteGroupItem?.isDischargeSheet
              ? arrivedWaypoints.map((waypoint) => (
                  <ArrivedPageWaypointDischargeItem waypoint={waypoint as Waypoint<WaypointType.Discharge>} key={waypoint.id} />
                ))
              : arrivedWaypoints.map((waypoint) => (
                  <ArrivedPageWaypointServiceItem waypoint={waypoint as Waypoint<WaypointType.Service>} key={waypoint.id} />
                ))}
          </div>

          {isDischargeNotesModalVisible && (
            <FullScreenModal
              items={dischargeReasons?.map(transformReasonToBasicOption) || []}
              onClose={() => setIsDischargeNotesModalVisible(false)}
              onSave={saveNotes}
              multipleSelect
              notesRequired={!currentRouteGroupItem?.isDischargeSheet}
            />
          )}
          {isFailureModalVisible && (
            <FullScreenModal
              items={failureReasons.map(transformReasonToBasicOption)}
              onClose={() => setIsFailureModalVisible(false)}
              onSave={(itemIds: number[], itemNote: string) => {
                currentRouteGroupItem?.isDischargeSheet
                  ? handleMarkFailedWaypoints(itemIds, itemNote)
                  : handleMarkFailedServiceWaypoints(itemIds, itemNote);
              }}
              isSaveRequestPending={isFailedRequestPending}
              reasonRequired
            />
          )}
          <KojvConditionsModal />
          <TakePhotoModal
            arrivedWaypoints={arrivedWaypoints}
            onPhotoUpload={onPhotoUpload}
            onPhotoSubmitted={onPhotoSubmitted}
            onPhotoAdded={onPhotoAdded}
            onPhotoDeleted={onPhotoDeleted}
          />
          <PhotoOnMobileModal />
        </>
      )}
    </div>
  );
};

export default React.memo(ArrivedPage, areEqual);
