import { useEffect } from 'react';
import { sendErrorEvent } from '../analytics/google-analytics';

export function useGlobalErrorEventHandling() {
  const handleError = (event: ErrorEvent) => {
    sendErrorEvent(event.message, event.filename, event.lineno, event.colno, event.error?.stack ?? '');
  };

  useEffect(() => {
    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);
}
