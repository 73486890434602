import {
  CoordinateUpdateEntity,
  CoordinateUpdateObjectItemBodyRequest,
  CoordinateUpdateRequest,
  WaypointsStatusRequest,
  waypointsStatusRequestsDb,
} from '@/database';
import { WaypointsServiceStatusRequest, waypointsServiceStatusRequestsDb } from '@/database/waypoints-service-status-requests.db';
import { IWaypointDischarge, Waypoint, WaypointType } from '@/features/home';
import generalService from '@/services/general.service';
import serviceWaypoints from '@/services/service-waypoints.service';
import { createSelector } from '@reduxjs/toolkit';
import waypointsService, { SaveDischargeNotesDto, WaypointUnloadDeleteDto } from '@/services/discharge-waypoints.service';
import { AppDispatch, onQueryStartedSecondParamType } from '@/store';
import { DeletedInsertedWaypointIdsResponse, WaypointsStatus } from '@/types/waypoints';
import {
  RouteGroupItemWaypoint,
  WaypointLogDetails,
  WaypointTank,
  WaypointUnload,
  WaypointUnloadCreateDto,
  WaypointUnloadUpdateDto,
} from '@ekt-group/general-purpose-api-interfaces';
import { baseApi, WAYPOINTS_KEY } from '@/store/baseApi';
import { AxiosError } from 'axios';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';

export interface GetWaypointsDto {
  routeGroupItemIds?: number[];
  waypointIds?: number[];
  isDischargeSheet?: boolean;
}

interface GetDeletedAndInsertedWaypointsDto {
  routeGroupItemId: number;
  lastUpdatedWaypointIdsTimestamp: string;
  isDischargeSheet: boolean;
}

export const updateWaypointsRTKCache = (
  { waypointIds, body, status }: WaypointsStatusRequest<any>,
  draft: MaybeDrafted<(Waypoint<WaypointType.Discharge> | Waypoint<WaypointType.Service>)[]>,
) => {
  for (const waypoint of draft) {
    if (waypointIds.includes(waypoint.id)) {
      const dischargeWaypoint = waypoint as Waypoint<WaypointType.Discharge>;
      const waypointId = waypoint.id;
      const waypointPayload = body?.services?.[waypointId];
      const servicesPayload: WaypointLogDetails[] =
        (waypointPayload?.length &&
          Object.values(waypointPayload).map(({ id, value }) => ({
            type: 'extra_service' as WaypointLogDetails['type'],
            kojvServiceId: id,
            value,
            id: Math.floor(Math.random() * 1000000),
            issued: '',
            routeGroupItemWaypoint: null,
          }))) ||
        [];

      switch (status) {
        case WaypointsStatus.Done:
          dischargeWaypoint.waypointLogDetails.push(...servicesPayload);
          dischargeWaypoint.dynamicGarbageM3 = body?.dynamicCapacities?.[waypointId] || 0;
          dischargeWaypoint.childrenCount = body?.dischargesCount?.[waypointId] - 1 || 0;
          dischargeWaypoint.done = true;
          dischargeWaypoint.fail = false;
          dischargeWaypoint.time = body?.doneAt;
          break;

        case WaypointsStatus.Failed:
          dischargeWaypoint.waypointLogDetails.push(...servicesPayload);
          dischargeWaypoint.done = true;
          dischargeWaypoint.fail = true;
          break;

        case WaypointsStatus.DeleteData:
          dischargeWaypoint.waypointLogDetails = [];
          dischargeWaypoint.done = false;
          dischargeWaypoint.fail = false;
          dischargeWaypoint.time = null;
          dischargeWaypoint.childrenCount = 0;
          dischargeWaypoint.dynamicGarbageM3 = 0;

          break;
        default:
          throw new Error(`Unknown status: ${status}`);
      }
    }
  }
};

const updateWaypointStatusCache = (
  payload: WaypointsStatusRequest<any>,
  { dispatch, getState }: onQueryStartedSecondParamType<WaypointsStatusRequest<any>, RouteGroupItemWaypoint[], 'generalPurposeAPI'>,
) => {
  if (!payload?.waypointIds?.length) {
    return;
  }

  const endpoints = waypointsApi.util.selectInvalidatedBy(getState(), [
    {
      type: WAYPOINTS_KEY,
    },
  ]);

  for (const endpoint of endpoints) {
    const { endpointName, originalArgs } = endpoint;
    if (endpointName !== 'getWaypoints') {
      continue;
    }

    dispatch(
      waypointsApi.util.updateQueryData('getWaypoints', originalArgs, (draft) => {
        updateWaypointsRTKCache(payload, draft);
      }),
    );
  }
};

export const updateServiceWaypointsRTKCache = (
  { serviceIds: waypointIds, status, doneAt }: WaypointsServiceStatusRequest,
  draft: MaybeDrafted<(Waypoint<WaypointType.Service> | Waypoint<WaypointType.Discharge>)[]>,
) => {
  draft.map((waypoint) => {
    if (waypointIds.includes(waypoint.id)) {
      const serviceWaypoint = waypoint as Waypoint<WaypointType.Service>;
      switch (status) {
        case WaypointsStatus.Done: {
          serviceWaypoint.doneAt = doneAt;
          serviceWaypoint.isFailed = false;
          break;
        }
        case WaypointsStatus.Failed: {
          serviceWaypoint.doneAt = null;
          serviceWaypoint.isFailed = true;
          break;
        }
      }
    }
  });
};

const updateServiceWaypointStatusCache = async (
  data: WaypointsServiceStatusRequest,
  {
    dispatch,
    getState,
    queryFulfilled,
  }: onQueryStartedSecondParamType<WaypointsServiceStatusRequest, RouteGroupItemWaypoint[], 'generalPurposeAPI'>,
) => {
  const endpoints = waypointsApi.util.selectInvalidatedBy(getState(), [
    {
      type: WAYPOINTS_KEY,
    },
  ]);

  for (const endpoint of endpoints) {
    const { endpointName, originalArgs } = endpoint;
    if (endpointName !== 'getWaypoints') {
      continue;
    }
    dispatch(
      waypointsApi.util.updateQueryData('getWaypoints', originalArgs, (draft) => {
        updateServiceWaypointsRTKCache(data, draft);
      }),
    );
  }
};

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [WAYPOINTS_KEY],
});

export const waypointsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getWaypoints: builder.query<(Waypoint<WaypointType.Discharge> | Waypoint<WaypointType.Service>)[], GetWaypointsDto>({
      queryFn: async ({ routeGroupItemIds, waypointIds, isDischargeSheet }) => {
        if (!routeGroupItemIds?.length && !waypointIds?.length) {
          throw new Error('routeGroupItemIds or waypointIds must be defined');
        }

        if (isDischargeSheet) {
          const { data: result } = await generalService.getWaypoints({ routeGroupItemIds, waypointIds });
          return {
            data: result.map((waypoint) => ({ ...waypoint, type: WaypointType.Discharge, isFiltered: true } as Waypoint)),
          };
        }

        const { data: result } = await generalService.getServiceWaypoints({ routeGroupItemIds, waypointIds });
        return { data: result.map((waypoint) => ({ ...waypoint, type: WaypointType.Service, isFiltered: true } as Waypoint)) };
      },
      providesTags: [WAYPOINTS_KEY],
      onCacheEntryAdded: async (originalArgs, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
        const pendingDischargeRequests = await waypointsStatusRequestsDb.requests.toArray();
        const pendingServiceRequests = await waypointsServiceStatusRequestsDb.requests.toArray();

        try {
          await cacheDataLoaded;
          updateCachedData((draft) => {
            for (const request of pendingDischargeRequests) {
              updateWaypointsRTKCache(request, draft);
            }
            for (const request of pendingServiceRequests) {
              updateServiceWaypointsRTKCache(request, draft);
            }
          });
        } catch {}
        await cacheEntryRemoved;
      },
    }),
    getDeletedAndInsertedWaypoints: builder.query<DeletedInsertedWaypointIdsResponse, GetDeletedAndInsertedWaypointsDto>({
      queryFn: async ({ routeGroupItemId, isDischargeSheet, lastUpdatedWaypointIdsTimestamp }) => {
        const result = isDischargeSheet
          ? await waypointsService.getDeletedAndInsertedWaypoints(routeGroupItemId, lastUpdatedWaypointIdsTimestamp)
          : await serviceWaypoints.getDeletedAndInsertedWaypoints(routeGroupItemId, lastUpdatedWaypointIdsTimestamp);

        return { data: result };
      },
    }),
    markWaypointAsDone: builder.mutation<RouteGroupItemWaypoint[], WaypointsStatusRequest<WaypointsStatus.Done>>({
      queryFn: async ({ waypointIds, body }) => {
        return waypointsService.markWaypointsAsDone(waypointIds, body);
      },
      onQueryStarted: updateWaypointStatusCache,
    }),
    markWaypointAsFailed: builder.mutation<RouteGroupItemWaypoint[], WaypointsStatusRequest<WaypointsStatus.Failed>>({
      queryFn: async ({ waypointIds, body }) => {
        return waypointsService.markWaypointsAsFailed(waypointIds, body);
      },
      onQueryStarted: updateWaypointStatusCache,
    }),
    deleteStatusData: builder.mutation<unknown, WaypointsStatusRequest<WaypointsStatus.DeleteData>>({
      queryFn: async ({ waypointIds }) => {
        await waypointsService.deleteStatusData(waypointIds);
        return { data: [] };
      },
      onQueryStarted: updateWaypointStatusCache,
    }),
    updateServiceWaypointStatus: builder.mutation<unknown, WaypointsServiceStatusRequest>({
      queryFn: async ({ status, serviceIds, routeGroupItemId, discharges, doneAt, content }) => {
        switch (status) {
          case WaypointsStatus.Done: {
            return serviceWaypoints.updateWaypointStatus({ status, serviceIds, routeGroupItemId, discharges, doneAt });
          }
          case WaypointsStatus.Failed: {
            return serviceWaypoints.updateWaypointStatus({ status, content, serviceIds, routeGroupItemId });
          }
          default: {
            throw new Error(`Unknown status: ${status}`);
          }
        }
      },
      onQueryStarted: updateServiceWaypointStatusCache,
    }),
    updateWaypointCoordinates: builder.mutation<unknown, CoordinateUpdateRequest>({
      queryFn: async ({ body, routeGroupItemId }) => {
        const { longitude, latitude } = body;

        switch (body.entity) {
          case CoordinateUpdateEntity.ServiceWaypoint: {
            return serviceWaypoints.updateWaypointCoordinates(body.serviceIds, routeGroupItemId, { longitude, latitude });
          }
          case CoordinateUpdateEntity.DischargeWaypoint: {
            return waypointsService.updateWaypointCoordinates(body.waypointIds, longitude, latitude);
          }
          default: {
            throw new Error('Not supported entity request provided.');
          }
        }
      },
      onQueryStarted: async ({ body }, { dispatch, queryFulfilled, getState }) => {
        let waypointIds: number[] = [];

        switch (body.entity) {
          case CoordinateUpdateEntity.ServiceWaypoint: {
            waypointIds = body.serviceIds;
            break;
          }
          case CoordinateUpdateEntity.DischargeWaypoint: {
            waypointIds = body.waypointIds;
            break;
          }
          default: {
            return;
          }
        }

        if (!waypointIds?.length) {
          return;
        }
        const { longitude, latitude } = body;

        const endpoints = waypointsApi.util.selectInvalidatedBy(getState(), [
          {
            type: WAYPOINTS_KEY,
          },
        ]);

        for (const endpoint of endpoints) {
          const { endpointName, originalArgs } = endpoint;
          if (endpointName !== 'getWaypoints') {
            continue;
          }

          dispatch(
            waypointsApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
              for (const waypointId of waypointIds) {
                const waypoint = draft.find(({ id }) => id === waypointId);
                if (waypoint) {
                  waypoint.objectItem.longitude = longitude;
                  waypoint.objectItem.latitude = latitude;
                }
              }
            }),
          );
        }
      },
    }),
    getWaypointUnloads: builder.query<WaypointUnload[], number>({
      queryFn: async (waypointId) => {
        return waypointsService.getWaypointUnloads(waypointId);
      },
    }),
    createWaypointUnload: builder.mutation<WaypointUnload, WaypointUnloadCreateDto>({
      queryFn: async (body) => {
        return waypointsService.createWaypointUnload(body);
      },
    }),
    updateWaypointUnload: builder.mutation<unknown, WaypointUnloadUpdateDto & { id: number }>({
      queryFn: async ({ id, ...rest }) => {
        return waypointsService.updateWaypointUnload(id, rest);
      },
    }),
    deleteWaypointUnload: builder.mutation<unknown, WaypointUnloadDeleteDto>({
      queryFn: async (body) => {
        return waypointsService.deleteWaypointUnload(body);
      },
    }),
    saveDischargeWaypointNotes: builder.mutation<unknown, SaveDischargeNotesDto>({
      queryFn: async (body) => {
        return waypointsService.saveDischargeWaypointNotes(body);
      },
    }),
    saveServiceWaypointNotes: builder.mutation<unknown, { waypointIds: number[]; routeGroupItemId: number; additionalNotes: string }>({
      queryFn: async ({ waypointIds, routeGroupItemId, additionalNotes }) => {
        return serviceWaypoints.saveServiceWaypointNotes(waypointIds, routeGroupItemId, additionalNotes);
      },
    }),
    createWaypointTank: builder.mutation<unknown, Partial<WaypointTank>>({
      queryFn: async (body) => {
        return waypointsService.createWaypointTank(body);
      },
    }),
    deletePicture: builder.mutation<unknown, { waypointIds: number[]; filePath: string }>({
      queryFn: async ({ waypointIds, filePath }) => {
        await waypointsService.deletePicture(waypointIds, filePath);
        return { data: null };
      },
    }),
    uploadPicture: builder.mutation<{ filePath: string }, { picture: File; waypointIds: number[]; isBasicAppMode: boolean }>({
      queryFn: async (body) => {
        const result = await waypointsService.uploadPicture(body);
        return { data: result };
      },
    }),
    getWaypointPhotos: builder.query<WaypointLogDetails[], { waypointIds: number[] }>({
      queryFn: async ({ waypointIds }) => {
        const result = await waypointsService.getWaypointPhotos(waypointIds);
        return { data: result };
      },
    }),
  }),
});

const createGetWaypointsSelector = createSelector(
  (query: GetWaypointsDto) => query,
  (query) => waypointsApi.endpoints.getWaypoints.select(query),
);

export const selectGetWaypointsCache = (query: GetWaypointsDto) => createGetWaypointsSelector(query);

export const {
  useGetWaypointsQuery,
  useLazyGetWaypointsQuery,
  useGetDeletedAndInsertedWaypointsQuery,
  useMarkWaypointAsDoneMutation,
  useMarkWaypointAsFailedMutation,
  useDeleteStatusDataMutation,
  useUpdateServiceWaypointStatusMutation,
  useUpdateWaypointCoordinatesMutation,
  useCreateWaypointUnloadMutation,
  useUpdateWaypointUnloadMutation,
  useDeleteWaypointUnloadMutation,
  useSaveDischargeWaypointNotesMutation,
  useSaveServiceWaypointNotesMutation,
  useCreateWaypointTankMutation,
  useDeletePictureMutation,
  useUploadPictureMutation,
  useLazyGetWaypointPhotosQuery,
  useGetWaypointUnloadsQuery,
  useLazyGetWaypointUnloadsQuery,
} = waypointsApi;
