import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { cleanParams, onFailedResponse, onFulfilledResponse } from './http.interceptors';
import { BaseQueryFn, retry } from '@reduxjs/toolkit/query/react';
import { getBackendHost } from '@/helpers/backend';

const axiosInstance = axios.create({
  baseURL: getBackendHost(),
  withCredentials: true,
});

axiosInstance.interceptors.response.use(onFulfilledResponse, onFailedResponse);
axiosInstance.interceptors.request.use(cleanParams);

export default {
  async get<RequestEntity>(url: string, config: AxiosRequestConfig = {}) {
    return axiosInstance.get<RequestEntity>(url, config);
  },
  post<RequestEntity>(url: string, body: Record<string, any> | FormData, config: AxiosRequestConfig = {}) {
    return axiosInstance.post<RequestEntity>(url, body, config);
  },
  put<RequestEntity>(url: string, body: Record<string, any>, config: AxiosRequestConfig = {}) {
    return axiosInstance.put<RequestEntity>(url, body, config);
  },
  patch<RequestEntity>(url: string, body: Record<string, any>, config: AxiosRequestConfig = {}) {
    return axiosInstance.patch<RequestEntity>(url, body, config);
  },
  delete<RequestEntity>(url: string, config: AxiosRequestConfig = {}) {
    return axiosInstance.delete<RequestEntity>(url, config);
  },
};

export interface AxiosArgs {
  url: string;
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
}

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<AxiosArgs, unknown, { status: string; data: AxiosError['response']['data']; error: AxiosError }> =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.status,
          data: JSON.parse(JSON.stringify(err.response?.data)) || err.message,
          error: JSON.parse(JSON.stringify(err)),
        },
      };
    }
  };

const backoffDelays = [500, 2000, 5000, 10000, 60000];

const customBackOff = async (attempt = 0, maxRetries = 5) => {
  const attempts = Math.min(attempt, backoffDelays.length - 1);

  await new Promise((resolve) => {
    setTimeout(resolve, backoffDelays[attempts]);
  });
};

export const extendedBaseQuery = retry(
  async (args: AxiosArgs, api, extraOptions) => {
    return await axiosBaseQuery({ baseUrl: getBackendHost() })(args, api, extraOptions);
  },
  {
    // is the default behavior, but included here for clarity
    maxRetries: 5,
    backoff: customBackOff,
  },
);
