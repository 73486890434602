import MapSettingsIcon from '@/components/icons/MapSettingsIcon';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import { CONSTRUCTION_PAGE_SIDELOADINGS, useConstructionTaskRequest } from '@/features/home';
import { MapSettingsSidebar, selectMapSettings, toggleMapSettingsDisplayedState } from '@/features/route/';
import { ConstructionTaskListWithMenu } from '@/features/route';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Task } from '@ekt-group/general-purpose-api-interfaces';
import { MapComponent, MapMode } from '@ekt-group/map-component';

export const ConstructionPage = () => {
  const { routeSheetId } = useParams();

  const { data: tasksData, isLoading, isFetching, lazyGetConstructionTasks } = useConstructionTaskRequest(Number(routeSheetId));

  const { setNavigationItems } = useAdvancedNavigationItems();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedTask, setSelectedTask] = useState<Task>(tasksData?.[0]);
  const listRef = useRef<HTMLDivElement>(null);
  const mapSettings = useAppSelector(selectMapSettings);

  const constructionNavigationItems = useMemo(() => {
    return [
      {
        label: t('navbar.mapSettings', { ns: 'tabletPage' }),
        link: '/settings',
        icon: <MapSettingsIcon size={'md'} />,
        action: () => dispatch(toggleMapSettingsDisplayedState()),
      },
    ];
  }, [dispatch, t]);

  useEffect(() => {
    setNavigationItems(constructionNavigationItems);
  }, [constructionNavigationItems, setNavigationItems]);

  useEffect(() => {
    const getAndSetFirstTaskAsSelected = async () => {
      const tasks = await lazyGetConstructionTasks({
        routeSheetIds: [Number(routeSheetId)],
        sideloading: CONSTRUCTION_PAGE_SIDELOADINGS,
      }).unwrap();

      if (tasks?.length) {
        setSelectedTask(tasks[0]);
      }
    };
    if (routeSheetId) {
      getAndSetFirstTaskAsSelected();
    }
  }, [lazyGetConstructionTasks, routeSheetId]);

  const handleTaskClick = (task: Task) => {
    setSelectedTask(task);
    const element = listRef.current.querySelector<HTMLDivElement>(`[data-id="${task.id}"]`);
    const parentElement = listRef?.current;

    if (element && parentElement) {
      parentElement.scrollTo({
        top: element.offsetTop - 115,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="relative h-[calc(var(--viewport-height)-var(--mobile-header-height))] md:h-[calc(var(--viewport-height)-var(--tablet-header-height))]">
      <MapSettingsSidebar />
      <ConstructionTaskListWithMenu tasksData={tasksData} onTaskClick={handleTaskClick} selectedTask={selectedTask} listRef={listRef} />
    </div>
  );
};
