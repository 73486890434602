import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useOfflineStatus } from '../contexts/offlineStatusContext';
import { sendPhotoUploadEvent } from '../analytics/google-analytics';
import { useTranslation } from 'react-i18next';
import { useLiveQuery } from 'dexie-react-hooks';
import { photoDB, PhotoDb, PhotoEntity } from '../database';
import { useSelector } from 'react-redux';
import { selectMode } from '../store/settings/slice';
import { AppMode } from '../types/common';
import { getPhotoUploadingStatus, setPhotoUploadingStatus } from '../helpers/localStorage/photoUploadingHelper';
import { PhotoUploadingStatus } from '../features/photo/types/photo';
import { useRollbar } from '@rollbar/react';
import { selectUserId } from '../features/auth/stores/slice';
import { useUploadPictureMutation, useUploadWaypointPictureMutation } from '@/features/route';
import { socket } from '@/lib/socket';
import { TIME_TO_CALL_ROLLBAR } from '@/configs/debug.config';

export function usePhotoDbProcessor() {
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation('offline');
  const photos = useLiveQuery(() => photoDB.photos.toArray());
  const isOffline = useOfflineStatus();
  const appMode = useSelector(selectMode);
  const rollbar = useRollbar();
  const userId = useSelector(selectUserId);

  // TODO: Possibly make use of status here to reduce code lines?
  const [uploadWaypointPicture, { status: waypointPictureUploadStatus }] = useUploadWaypointPictureMutation();
  const [uploadPicture, { status: pictureUploadStatus }] = useUploadPictureMutation();

  const deleteNotTodayPhotos = async () => {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    const notTodayPhotos = photos?.filter(({ timestamp }) => timestamp < today.getTime()) || [];
    await Promise.all(notTodayPhotos.map((photo) => photoDB.photos.delete(photo.id)));
  };

  const processPhoto = async (photo: PhotoDb) => {
    const { waypointIds, photo: photoFile, routeGroupName, routeGroupItemName, routeGroupItemId, isBasicAppMode, entity } = photo;

    try {
      const startTime = performance.now();
      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadingStarted);

      const { filePath } =
        entity === PhotoEntity.DischargeWaypoint
          ? await uploadPicture({ picture: photoFile, waypointIds, isBasicAppMode }).unwrap()
          : await uploadWaypointPicture({ picture: photoFile, serviceIds: waypointIds, routeGroupItemId }).unwrap();

      sendPhotoUploadEvent(routeGroupName, routeGroupItemName);
      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadingFinished);

      const endTime = performance.now();

      if (endTime - startTime > TIME_TO_CALL_ROLLBAR) {
        rollbar.warn('[PhotoUploading - Offline] Long photo upload time', {
          duration: endTime - startTime,
          currentPhotoStatus: getPhotoUploadingStatus(),
          userId,
        });
      }

      if (appMode === AppMode.Basic) {
        toast(t('photoUploadedSuccessfully', { ns: 'photoPage' }), {
          type: 'success',
        });
      }

      socket.volatile.emit('PhotoUploaded', { type: 'PhotoUploaded', payload: { filePath, waypointIds } });

      return filePath;
    } catch (error) {
      toast(t('photoUploadingFailed', { ns: 'photoPage' }), {
        type: 'error',
      });
      throw error;
    }
  };

  const process = async () => {
    if (isOffline || isProcessing || !photos?.length) {
      return;
    }

    setIsProcessing(true);

    for (const photo of photos) {
      if (photo.serverFilePath) {
        continue;
      }

      try {
        const filePath = await processPhoto(photo);
        await photoDB.photos.update(photo.id, { serverFilePath: filePath });
      } catch (error) {
        rollbar.error('Processing photo failed', error as Error, photo, { userId });
      }
    }

    setPhotoUploadingStatus(PhotoUploadingStatus.NotUploading);
    setIsProcessing(false);
  };

  useEffect(() => {
    const { status, heap } = getPhotoUploadingStatus();

    if (status && status !== PhotoUploadingStatus.NotUploading) {
      setPhotoUploadingStatus(PhotoUploadingStatus.NotUploading);
    }

    process();
  }, []);

  useEffect(() => {
    deleteNotTodayPhotos();
  }, [photos]);

  useEffect(() => {
    process();
  }, [isOffline, photos]);
}
