import React, { useEffect, useRef } from 'react';
import { Message } from '@/features/messages';
import { formatToEstonianDate } from '@/helpers/dateHelper';
import { removeHtmlTagsExceptStrong } from '@/helpers/regex';

interface MessageListItemProps {
  message: Message;
  isOpened: boolean;
  onClick: () => void;
}

const MessageListItem = ({ message, onClick, isOpened }: MessageListItemProps) => {
  const { readAt, messageHeader, messageBody, createdAt } = message;
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpened) {
      elementRef.current.style.maxHeight = `calc(28px + ${elementRef.current.scrollHeight}px)`;
    } else {
      elementRef.current.style.maxHeight = '28px';
    }
  }, [isOpened]);

  return (
    <div className="message-list-item" onClick={onClick}>
      <div className={`message-list-item__badge ${readAt ? 'opacity-0' : ''}`}></div>
      <div className={`message-list-item__received-at ${readAt ? 'mr-0' : ''}`}>{formatToEstonianDate(createdAt)}</div>
      {messageHeader && <h5 dangerouslySetInnerHTML={{ __html: messageHeader }} />}
      <div
        ref={elementRef}
        dangerouslySetInnerHTML={{ __html: isOpened ? messageBody : removeHtmlTagsExceptStrong(messageBody) }}
        className={`message-list-item__description  ${isOpened ? 'message-list-item__description__opened' : ''}`}
      />
    </div>
  );
};

export default MessageListItem;
