import httpService from '@/lib/http.service';
import { getTodayDate } from '@/helpers/dateHelper';
import { GetScansResponseDto } from '@ekt-group/bi-services-api-interfaces/src/modules/alcoscan/dto';

export default {
  async getCarState(carNumber: string) {
    const response = await httpService.get(`/external/car-state`, {
      params: {
        carNumber,
      },
    });

    return response?.data ?? null;
  },

  async getTodayAlcoscanScans(driverId: number) {
    const response = await httpService.get<GetScansResponseDto[]>('/external/alcoscan-scans', {
      params: {
        employeeId: driverId,
        startDate: getTodayDate(),
        endDate: getTodayDate(),
      },
    });

    return response?.data ?? [];
  },

  async startAlcoscanMeasurement(longitude: number, latitude: number) {
    const response = await httpService.post('/external/alcoscan-start-measurement', null, {
      params: {
        longitude,
        latitude,
      },
    });

    return response?.data ?? null;
  },
};
