import httpService from '../../../lib/http.service';

export default {
  getKeysAndRemotes: async (routeGroupItemId: number) => {
    const response = await httpService.get('/cars/keys-and-remotes', {
      params: {
        routeGroupItemId,
      },
    });

    return response?.data || [];
  },
};
