import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnloadingBaseWaypoint, Waypoint, WaypointType } from '@/features/home';
import { AppState } from '@/store';
import { WaypointsStatus } from '@/types/waypoints';
import { ObjectGate } from '@/features/route';
import { SetWaypointStatusDto } from '@/services/discharge-waypoints.service';
import { Garbage, ItemType } from '@ekt-group/general-purpose-api-interfaces';
import { WaypointListFormData } from '@/features/route';
import { isEqual } from 'lodash';

export interface WaypointsState {
  selectedWaypointIds: number[];
  objectGates: ObjectGate[];
  currentGarbageIds: number[];

  waypointGroupsWithAdditionalInfo: ((Waypoint<WaypointType.Discharge> | Waypoint<WaypointType.Service>) & {
    itemType: ItemType | undefined;
    garbage: Garbage | undefined;
  })[][];
  filters?: WaypointListFormData;
  filteredWaypoints?: (Waypoint | UnloadingBaseWaypoint)[];
}

interface SetDischargeWaypointsStatusPayload {
  waypointIds: number[];
  status: WaypointsStatus;
  dischargesCount?: Record<string, number>;
  dynamicCapacities?: Record<string, number>;
  services?: SetWaypointStatusDto['services'];
}

const savedSelectedWaypointIds = localStorage.getItem('selectedWaypointIds');

const initialState: WaypointsState = {
  objectGates: [],
  selectedWaypointIds: savedSelectedWaypointIds ? JSON.parse(savedSelectedWaypointIds) : [],
  currentGarbageIds: [],
  waypointGroupsWithAdditionalInfo: [],
  filters: undefined,
};

export const waypointsSlice = createSlice({
  name: 'waypoints',
  initialState,
  reducers: {
    setObjectGates(state, { payload }: PayloadAction<Waypoint[]>) {
      state.objectGates = payload.reduce<ObjectGate[]>((acc, item) => {
        const { object, owner } = item;

        if (acc.some(({ objectId }) => object.id === objectId)) {
          return acc;
        }
        if (object.gatePhone2 || object.gatePhone) {
          const ownerName = owner?.companyName || `${owner?.firstname} ${owner?.surname}` || '---';
          acc.push({
            objectId: object.id,
            routeGroupItemWaypointId: item.id,
            phone: object.gatePhone,
            secondPhone: object.gatePhone2,
            closePhone: object.gateClosePhone,
            description: `${ownerName}. ${object.street} ${object.houseNumber}`.trim(),
            latitude: Number(object.latitude),
            longitude: Number(object.longitude),
          });
        }
        return acc;
      }, []);
    },
    setSelectedWaypointIds(state, action) {
      if (!isEqual(state.selectedWaypointIds, action.payload)) {
        state.selectedWaypointIds = action.payload;
      }
    },
    setCurrentGarbageIds(state, action) {
      state.currentGarbageIds = action.payload;
    },
    setWaypointGroupsWithAdditionalInfo(state, action: PayloadAction<WaypointsState['waypointGroupsWithAdditionalInfo']>) {
      state.waypointGroupsWithAdditionalInfo = action.payload;
    },
    setFilters(state, action: PayloadAction<WaypointsState['filters']>) {
      state.filters = action.payload;
    },
    setFilteredWaypoints(state, action: PayloadAction<WaypointsState['filteredWaypoints']>) {
      state.filteredWaypoints = action.payload;
    },
    resetWaypointsState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setObjectGates,
  setSelectedWaypointIds,
  setCurrentGarbageIds,
  resetWaypointsState,
  setWaypointGroupsWithAdditionalInfo,
  setFilteredWaypoints,
  setFilters,
} = waypointsSlice.actions;

export const selectSelectedWaypointIds = (state: AppState) => state.waypoints.selectedWaypointIds;
export const selectObjectGates = (state: AppState) => state.waypoints.objectGates;
export const selectCurrentGarbageIds = (state: AppState) => state.waypoints.currentGarbageIds;

export const selectWaypointGroupsWithAdditionalInfo = (state: AppState) => state.waypoints.waypointGroupsWithAdditionalInfo;
export const selectFilters = (state: AppState) => state.waypoints.filters;
export const selectFilteredWaypoints = (state: AppState) => state.waypoints.filteredWaypoints;

export default waypointsSlice.reducer;
