import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetMessagesQuery, useJoinChatQuery } from '../api/chat.api';
import AccidentDescription from '../components/AccidentDescription';
import ThemeToggleButton from '@/components/elements/ThemeToggleButton';
import { LoginLocaleSwitcher } from '@/features/auth';
import Tabs, { Tab } from '@/components/Tabs';
import MapIcon from '@/components/icons/MapIcon';
import MailIcon from '@/components/icons/MailIcon';
import { cn } from '@/utils/cn';
import ImageGalleryIcon from '@/components/icons/ImageGalleryIcon';
import { useGetTaskPicturesQuery } from '@/features/home';
import AccidentChatMessagesTab from '../components/AccidentChatMessagesTab';
import AccidentChatMapTab from '../components/AccidentChatMapTab';
import AccidentChatPicturesTab from '../components/AccidentChatPicturesTab';
import { useTranslation } from 'react-i18next';

enum AccidentChatTab {
  Messages = 'messages',
  Map = 'map',
  Pictures = 'pictures',
}

const MESSAGES_REQUEST_INTERVAL = 10000;

export const AccidentChatPage = () => {
  const [currentTab, setCurrentTab] = useState(AccidentChatTab.Messages);
  const [searchParams] = useSearchParams();
  const chatToken = searchParams.get('chatToken');
  const userId = searchParams.get('userId');
  const user = searchParams.get('user');

  const { t } = useTranslation('accidentChat');

  const tabs: Tab[] = [
    {
      id: AccidentChatTab.Messages,
      label: t('tabs.messages'),
      icon: <MailIcon size={'sm'} />,
    },
    {
      id: AccidentChatTab.Map,
      label: t('tabs.map'),
      icon: <MapIcon size={'sm'} />,
    },
    {
      id: AccidentChatTab.Pictures,
      label: t('tabs.pictures'),
      icon: <ImageGalleryIcon size={'sm'} />,
    },
  ];

  const { data, isLoading, isError, error } = useJoinChatQuery({ chatToken, userId: Number(userId) || null }, { skip: !chatToken });
  const { data: messages, isLoading: isMessagesLoading } = useGetMessagesQuery(
    { chatToken },
    { skip: !chatToken, pollingInterval: MESSAGES_REQUEST_INTERVAL },
  );
  const picturesQuery = useGetTaskPicturesQuery({ taskId: data?.taskId, params: { chatToken } }, { skip: !data?.taskId || !chatToken });

  if (!chatToken) {
    return (
      <div className={'min-h-dynamic-100 p-4 pt-12 md:p-8 md:pt-10 bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-100'}>
        <pre>{t('unauthorized')}</pre>
      </div>
    );
  }

  if (isError) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const errorMessage = `${error?.status} - ${JSON.stringify(error?.data?.message)}`;
    return (
      <div className={'min-h-dynamic-100 p-4 pt-12 md:p-8 md:pt-10 bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-100'}>
        <pre>{errorMessage}</pre>
      </div>
    );
  }

  return (
    <div
      className={'h-dynamic-100 overflow-y-hidden p-4 pt-12 md:p-8 md:pt-10 bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-100'}
    >
      <div className={'flex gap-4 absolute top-2 right-4 md:right-8'}>
        <ThemeToggleButton />
        <LoginLocaleSwitcher showLabel={false} className={'m-0'} />
      </div>
      {isLoading ? (
        t('loading')
      ) : (
        <div className={'h-[calc(var(--viewport-height)-4rem)] items-start mx-auto flex flex-col gap-2 md:gap-4 rounded'}>
          <AccidentDescription
            title={data?.title}
            carNumber={data?.carNumber}
            problem={data?.problem}
            timestamp={data?.timestamp}
            driver={data?.driver}
            driverPhone={data?.driverPhone}
          />
          <div className={'w-full'}>
            <div className={'h-[1px] border border-gray-900 dark:border-gray-100 mt-1 mb-1 md:mt-2'}></div>
            <Tabs tabs={tabs} currentTabId={currentTab} onChange={(tabId) => setCurrentTab(tabId as AccidentChatTab)} />
          </div>

          {currentTab === AccidentChatTab.Messages && (
            <AccidentChatMessagesTab
              messages={messages}
              isLoading={isMessagesLoading}
              chatToken={chatToken}
              userId={Number(userId)}
              userName={data?.currentUser?.name || user || ''}
            />
          )}

          <div
            className={cn('h-[calc(var(--viewport-height)-10rem)] w-full', {
              hidden: currentTab !== AccidentChatTab.Map,
            })}
          >
            <AccidentChatMapTab
              carNumber={data.carNumber}
              chatToken={chatToken}
              accidentLocation={{ longitude: data.longitude, latitude: data.latitude }}
            />
          </div>

          {currentTab === AccidentChatTab.Pictures && <AccidentChatPicturesTab queryData={picturesQuery} />}
        </div>
      )}
    </div>
  );
};
