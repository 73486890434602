import React from 'react';
import { getOwnerName } from '../../../helpers/strings';
import { useTranslation } from 'react-i18next';
import { ObjectKey } from '@ekt-group/general-purpose-api-interfaces';

interface Props {
  data: ObjectKey;
}

const KeysModalListKeyAndRemoteItem = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="keys-modal__list-key-and-remote-item">
      <span>
        {t('contractNumber')}: {data?.contract?.contractNumber}
      </span>
      <span>
        {t('owner')}: {getOwnerName(data.owner)}
      </span>
      <span>
        {t('address')}: {data?.address?.address}
      </span>
      <span>
        {t('type')}: {data.type ?? t('notSpecified')}
      </span>
    </div>
  );
};

export default KeysModalListKeyAndRemoteItem;
