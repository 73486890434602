import React from 'react';
import iconStyles from '../../assets/styles/icons.module.scss';
import { iconSizeClasses, IconSizeProps } from '../../helpers/IconSettings';

const InfoIcon = ({ size }: IconSizeProps) => {
  return (
    <svg width="800px" height="800px" className={iconStyles[iconSizeClasses[size]]} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <g>
            <circle cx="12" cy="12" fill="none" r="10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />

            <line
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              x1="12"
              x2="12"
              y1="12"
              y2="16"
            />

            <line
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              x1="12"
              x2="12"
              y1="8"
              y2="8"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InfoIcon;
