import { selectCommonItemMap } from '../store/common/slice';
import { useSelector } from 'react-redux';

export function useCommonItemMaps() {
  const citiesMap = useSelector(selectCommonItemMap('cities')) ?? {};
  const countiesMap = useSelector(selectCommonItemMap('counties')) ?? {};
  const villagesMap = useSelector(selectCommonItemMap('villages')) ?? {};
  const itemTypesMap = useSelector(selectCommonItemMap('itemTypes')) ?? {};
  const garbageMap = useSelector(selectCommonItemMap('garbage')) ?? {};
  const garbageGroupsMap = useSelector(selectCommonItemMap('garbageGroups')) ?? {};
  const kojvMap = useSelector(selectCommonItemMap('kojv')) ?? {};

  return {
    citiesMap,
    countiesMap,
    villagesMap,
    itemTypesMap,
    garbageMap,
    garbageGroupsMap,
    kojvMap,
  };
}
