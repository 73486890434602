export type ColorType = 'primary' | 'danger' | 'danger-dark' | 'warning' | 'disabled' | 'success';
export type SizeType = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export interface BasicOption<ValueType = string | number> {
  label: string;
  value: ValueType;
}

export enum AppMode {
  Basic = 'basic',
  Advanced = 'advanced',
}

export enum ImageUploadType {
  Photo = 'photo',
  File = 'file',
}

export interface HeaderItem {
  label: string;
  link?: string;
  icon?: JSX.Element;
  notificationsAmount?: number;
  action?: () => void;
  style?: ColorType;
  disabled?: boolean;
  isPending?: boolean;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export enum PermissionState {
  Granted = 'granted',
  Denied = 'denied',
  Prompt = 'prompt',
}

export enum SpecialWaypointServiceItem {
  Discharge = 'discharge',
  DynamicCapacity = 'dynamicCapacity',
  ManualWeight = 'manualWeight',
}

export interface ComboBoxOption {
  key: string | number;
  value: string;
}
