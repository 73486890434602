import React from 'react';
import styles from '@/assets/styles/waypointSelectItem.module.scss';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';
import { Waypoint } from '@/features/home';

interface WaypointSelectItemProps {
  waypoint: Waypoint;
  isSelected: boolean;
  clickHandler: (id: number) => void;
}

export const WaypointSelectItem = ({ waypoint, isSelected, clickHandler }: WaypointSelectItemProps) => {
  const { objectItem } = waypoint;
  const { itemTypeId, garbageId } = objectItem;
  const { itemTypesMap, garbageMap } = useCommonItemMaps();

  return (
    <div
      className={`${styles.waypointSelectItem} ${isSelected ? styles.waypointSelectItemSelected : ''}`}
      onClick={() => clickHandler(waypoint.id)}
    >
      <div>{itemTypesMap[itemTypeId]?.name}</div>
      <div className={'text-sm'}>({garbageMap[garbageId]?.name})</div>
      <div className={'text-sm'}>ID:{waypoint.id}</div>

      <div className={styles.checkbox}>{isSelected ? <div className={styles.checkMark}></div> : null}</div>
    </div>
  );
};

export default WaypointSelectItem;
